import { MAX_TOKEN_DOWNLOADS, TokenLayer } from "lasagna-commons";
import React from "react";
import Button from "../../../../components/Button";
import { useCollectionDispatch } from "../../../../components/CollectionContext";
import { useCurrentCollection } from "../../../../components/CollectionContext/hooks";
import Drawer from "../../../../components/Drawer";
import { API_ENDPOINT } from "../../../../config";
import DownloadIcon from "../../../../icons/Download";
import useCustomFetch from "../../../../utils/hooks/useCustomFetch";

const DownloadButton: React.FC<{
  tokenId: number;
  tokenLayers: TokenLayer[];
}> = ({ tokenId, tokenLayers }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const fetcher = useCustomFetch();
  const collection = useCurrentCollection();
  const collectionDispatch = useCollectionDispatch();

  const handleClick = React.useCallback(async () => {
    if (collection.downloadedTokenCount < MAX_TOKEN_DOWNLOADS) {
      setDownloading(true);
      const data = await fetcher<{ url: string }>({
        url: `${API_ENDPOINT}/collections/${collection.id}/tokens/download`,
        method: "POST",
        body: {
          token: { id: tokenId, tokenLayers },
        },
      });

      if (data) {
        collectionDispatch((current) => {
          if (!current) {
            return;
          }
          return {
            ...current,
            [collection.id]: {
              ...current[collection.id],
              downloadedTokenCount:
                current[collection.id].downloadedTokenCount + 1,
            },
          };
        });
        window.open(data.url, "_blank");
      }

      setDownloading(false);
    } else {
      setDrawerOpen(true);
    }
  }, [fetcher, collection, tokenId, tokenLayers, collectionDispatch]);

  return (
    <>
      <Button variant="gray" onClick={handleClick} disabled={downloading}>
        <DownloadIcon />
        <span className="ml-2">
          {downloading ? "Downloading..." : "Download"}
        </span>
      </Button>
      <Drawer
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <h2>Max token downloads reached</h2>
        <p className="opacity-60">
          You cannot download more than {MAX_TOKEN_DOWNLOADS} tokens without
          purchasing your collection.
        </p>
      </Drawer>
    </>
  );
};

export default DownloadButton;
