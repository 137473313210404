import { generateTokenSetKey } from "lasagna-commons";
import type { AssetWithUrls, TokenLayer } from "lasagna-commons";
import type { TokenState } from "../../../../types";

export function calculateUniqueness({
  tokenState,
  layerId,
  assetId,
}: {
  tokenState: TokenState;
  layerId: string;
  assetId: string;
}) {
  let percentage = (
    (100 * (tokenState.meta.assetCounts[layerId][assetId] || 0)) /
    tokenState.tokens.length
  ).toFixed(1);
  if (percentage.slice(-2) === ".0") {
    percentage = percentage.slice(0, -2);
  }
  return `${percentage}% have this`;
}

export function itemCombinationExists({
  tokenSet,
  layerId,
  tokenLayers,
  asset,
  isCurrent,
}: {
  tokenSet: TokenState["meta"]["tokenSet"];
  layerId: string;
  tokenLayers: TokenLayer[];
  asset: AssetWithUrls;
  isCurrent: boolean;
}) {
  if (isCurrent) {
    return false;
  }
  const index = tokenLayers.findIndex(
    (tokenLayer) => tokenLayer.layerId === layerId
  );
  const updatedTokenLayers = [...tokenLayers];
  updatedTokenLayers.splice(index, 1, {
    ...updatedTokenLayers[index],
    assetId: asset.id,
  });

  return tokenSet.has(generateTokenSetKey(updatedTokenLayers));
}
