import React from "react";
import Plus from "../../icons/Plus";
import type { RecipeAssetType, RecipeStateDispatch } from "./types";
import { addRecipeAssetKey } from "./utils/useRecipeForm";

const AddAssetButton: React.FC<{
  recipeAssetType: RecipeAssetType;
  recipeStateDispatch: RecipeStateDispatch;
}> = ({ recipeAssetType, recipeStateDispatch }) => {
  const handleClick = () => {
    addRecipeAssetKey(recipeAssetType, recipeStateDispatch);
  };

  return (
    <div>
      <button
        className="text-primary underline inline-flex items-center transition-colors hover:text-secondary text-sm"
        onClick={handleClick}
        type="button"
      >
        <Plus size={12} />
        <span className="ml-2">Add more assets</span>
      </button>
    </div>
  );
};

export default AddAssetButton;
