import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import LayerContainer from "./LayerContainer";
import AddLayer from "../../AddLayer";
import { useViewport } from "../../../../components/ViewportContext";
import { CAROUSEL_CONTAINER_STYLES } from "../../../../constants";
import AssetWarningDrawer from "../../WarningDrawer";
import PageMainContent from "../../../../components/PageMainContent";
import { useAssetViewProps } from "../../hooks";
import PlusIcon from "../../../../icons/Plus";
import LottieIcon from "../../../../components/LottieIcon";
import animationData from "../../../../components/LottieIcon/animations/new-layer.json";
import { calculateButtonStyles } from "../../../../components/Button/utils";

const buttonStyles = calculateButtonStyles({
  variant: "primary",
  size: "small",
});

const AssetEditCollapsedView: React.FC = () => {
  const {
    order,
    layers,
    handleDragEnd,
    setOrder,
    setIsDeleting,
    setUpdateWarning,
    updateWarning,
    originalSetUpdateWarning,
    warningDrawerOpen,
    setWarningDrawerOpen,
    isDeleting,
    isReadOnly,
  } = useAssetViewProps();

  const viewport = useViewport();

  return (
    <>
      <PageMainContent addLayoutContainer centerContent>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="list" direction="horizontal">
            {({ innerRef, droppableProps, placeholder }) => (
              <div
                ref={innerRef}
                {...droppableProps}
                className={`${CAROUSEL_CONTAINER_STYLES} h-full hide-scrollbar`}
              >
                {order.map((layerId, index) => {
                  return (
                    <LayerContainer
                      layers={layers}
                      key={layerId}
                      index={index}
                      layerId={layerId}
                      setOrder={setOrder}
                      order={order}
                      viewportHeight={viewport.height}
                      setUpdateWarning={setUpdateWarning}
                      isDeleting={isDeleting}
                      setIsDeleting={setIsDeleting}
                      isReadOnly={isReadOnly}
                    />
                  );
                })}
                {placeholder}
                {!isReadOnly && (
                  <div className="flex-shrink-0 w-96 mr-4">
                    <AddLayer setOrder={setOrder}>
                      <div className="cursor-pointer p-8 h-88 bg-gray shadow-layer-gray">
                        <LottieIcon
                          animationData={animationData}
                          translateX="-translate-x-7"
                        />
                        <h2 className="mb-4">Add a new layer</h2>
                        <p className="opacity-60 mb-6">
                          Select an entire folder to upload at one time
                        </p>
                        <div>
                          <span className={buttonStyles.containerClassName}>
                            <span className={buttonStyles.contentClassName}>
                              <PlusIcon />
                              <span className="ml-3">Add layer</span>
                            </span>
                            <span
                              className={buttonStyles.backgroundClassName}
                            />
                          </span>
                        </div>
                      </div>
                    </AddLayer>
                  </div>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </PageMainContent>
      <AssetWarningDrawer
        updateWarning={updateWarning}
        setUpdateWarning={originalSetUpdateWarning}
        open={warningDrawerOpen}
        setOpen={setWarningDrawerOpen}
      />
    </>
  );
};

export default AssetEditCollapsedView;
