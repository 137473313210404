import { calculateAssetKey } from "lasagna-commons";
import React from "react";
import RecipeModal from ".";
import { useAssets } from "../../../components/AssetContext/hooks";
import { createCtx } from "../../../utils";
import type {
  RecipeAssetMap,
  RecipeModalDispatch,
  RecipeModalState,
} from "../types";

const [useRecipeModal, RecipeModalContextProvider] = createCtx<{
  setActiveRecipe: RecipeModalDispatch;
  recipeAssetMap: RecipeAssetMap;
}>();

const RecipeModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeRecipe, setActiveRecipe] =
    React.useState<RecipeModalState | null>(null);

  const { layers } = useAssets();

  const value = React.useMemo(() => {
    const recipeAssetMap: RecipeAssetMap = {};

    Object.keys(layers).forEach((layerId) => {
      Object.keys(layers[layerId].assets).forEach((assetId) => {
        const value = calculateAssetKey({ layerId, assetId });
        recipeAssetMap[value] = {
          ...layers[layerId].assets[assetId],
          layerId,
          layerLabel: layers[layerId].label,
        };
      });
    });

    return { recipeAssetMap, setActiveRecipe };
  }, [layers]);

  return (
    <RecipeModalContextProvider value={value}>
      {children}
      {activeRecipe && (
        <RecipeModal
          activeRecipe={activeRecipe}
          recipeAssetMap={value.recipeAssetMap}
        />
      )}
    </RecipeModalContextProvider>
  );
};

export { useRecipeModal, RecipeModalProvider };
