import React from "react";
import capitalize from "lodash/capitalize";
import type { RecipeType } from "lasagna-commons";
import type {
  RecipeAssetKey,
  RecipeAssetMap,
  RecipeStateDispatch,
} from "./types";
import { updateRecipeType } from "./utils/useRecipeForm";
import { useSnackbarDispatch } from "../../components/SnackbarContext";

const options: RecipeType[] = ["never", "only", "always"];

const RecipeTypeRadio: React.FC<{
  recipeType: RecipeType;
  recipeStateDispatch: RecipeStateDispatch;
  recipeAssetMap: RecipeAssetMap;
  pairedAssetKeys: RecipeAssetKey[];
  isReadOnly: boolean;
}> = ({
  recipeType,
  recipeStateDispatch,
  recipeAssetMap,
  pairedAssetKeys,
  isReadOnly,
}) => {
  const setSnackbarMessage = useSnackbarDispatch();

  return (
    <ul className="grid grid-cols-3 gap-1">
      {options.map((option) => {
        const selected = option === recipeType;

        const handleClick = () => {
          if (option === "always") {
            let maxAssetsPerLayer = 0;
            const countMap: { [layerId: string]: number } = {};

            pairedAssetKeys.forEach((key) => {
              if (key) {
                const recipeAsset = recipeAssetMap[key];
                if (countMap[recipeAsset.layerId]) {
                  countMap[recipeAsset.layerId]++;
                } else {
                  countMap[recipeAsset.layerId] = 1;
                }
                maxAssetsPerLayer = Math.max(
                  maxAssetsPerLayer,
                  countMap[recipeAsset.layerId]
                );
              }
            });

            if (maxAssetsPerLayer > 1) {
              setSnackbarMessage(
                "You can't select 'ALWAYS' with multiple assets from the same layer",
                "error"
              );
              return;
            }
          }
          updateRecipeType(option, recipeStateDispatch);
        };

        return (
          <li key={option}>
            <button
              aria-selected={selected}
              role="option"
              type="button"
              className={`py-2 w-full disabled:pointer-events-none ${
                selected
                  ? "bg-primary text-black"
                  : "bg-black text-white transition-colors hover:bg-gray-dark"
              }`}
              onClick={handleClick}
              disabled={isReadOnly}
            >
              {capitalize(option)}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default RecipeTypeRadio;
