import React from "react";
import type { FileWithPath } from "react-dropzone";
import FileInput from "../../../../components/Form/DragInput";
import { processInitialFiles } from "./utils";
import DownloadIcon from "../../../../icons/Download";
import useAssetUpload from "../../../../utils/hooks/useAssetUpload";
import { AssetUploadError } from "../../../../utils/AssetUploadError";
import { useCollectionDispatch } from "../../../../components/CollectionContext";
import { MAX_ASSET_COUNT } from "lasagna-commons";
import LottieIcon from "../../../../components/LottieIcon";
import animationData from "../../../../components/LottieIcon/animations/upload-asset.json";
import { getCollectionId, getErrorMessage } from "../../../../utils";

const UploadSection: React.FC = () => {
  const {
    setLoading,
    setSnackbarMessage,
    setProcessedFileProgress,
    assetDispatch,
    run,
    modals,
  } = useAssetUpload();

  const collectionDispatch = useCollectionDispatch();
  const collectionId = getCollectionId();

  const handleDrop = async (files: FileWithPath[]) => {
    try {
      setProcessedFileProgress({ numerator: 0, denominator: files.length });

      setLoading(true);

      const {
        assets,
        layers: inputLayers,
        assetMeta,
        errors,
      } = await processInitialFiles(files, setProcessedFileProgress);

      const { newLayers } = await run(assets, inputLayers, assetMeta);

      assetDispatch({
        layers: newLayers,
        collectionId,
        updateStack: [],
        errors,
        isInitialLoad: true,
      });

      collectionDispatch((current) => {
        if (!current) {
          return;
        }
        return {
          ...current,
          [collectionId]: {
            ...current[collectionId],
            assetMeta,
            assetCount: current[collectionId].assetCount + assets.length,
          },
        };
      });
    } catch (err) {
      if (err instanceof AssetUploadError) {
        const errors = err.messages;
        assetDispatch((current) => {
          if (!current) {
            return;
          }
          return {
            ...current,
            errors,
          };
        });
      } else {
        setSnackbarMessage(`Error - ${getErrorMessage(err)}`, "error");
      }

      setLoading(false);

      setProcessedFileProgress({ numerator: 0, denominator: 0 });
    }
  };

  return (
    <>
      <div className="w-full">
        <LottieIcon animationData={animationData} />
        <h2>Upload your assets</h2>
        <p className="opacity-80 mb-6">
          You can add up to {MAX_ASSET_COUNT} asset files
        </p>
        <div>
          <FileInput
            getInputStyles={getInputStyles}
            onDrop={handleDrop}
            type="directory"
          >
            <div className="font-secondary">
              <div className="flex items-center justify-center mb-2">
                <DownloadIcon />
                <span className="ml-2">Drop project folder here</span>
              </div>
              <div className="text-sm opacity-60 text-center">
                Include top-level directory with all layers and assets
              </div>
            </div>
          </FileInput>
        </div>
      </div>
      {modals}
    </>
  );
};

function getInputStyles(draggingOver: boolean) {
  return `py-24 px-4 border-2 border-dashed block border-white bg-white ${
    draggingOver
      ? "border-opacity-100 bg-opacity-10"
      : "bg-gray border-opacity-60 bg-opacity-0"
  }`;
}

export default UploadSection;
