import React from "react";
import type { Recipe } from "lasagna-commons";
import Button from "../../components/Button";
import { API_ENDPOINT } from "../../config";
import RecipeTypeRadio from "./RecipeTypeRadio";
import PlusIcon from "../../icons/Plus";
import useRecipeForm from "./utils/useRecipeForm";
import { useSnackbarDispatch } from "../../components/SnackbarContext";
import {
  calculateRecipeAssets,
  calculateRecipeId,
  duplicateRecipeExists,
  filterRecipeAssetKeys,
} from "./utils";
import { useRecipes } from "../../components/RecipeContext/hooks";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";
import useCustomFetch from "../../utils/hooks/useCustomFetch";
import { useRecipeModal } from "./RecipeModal/RecipeModalContext";
import { useRecipeDispatch } from "../../components/RecipeContext";
import RecipeFormInputList from "./RecipeFormInputList";
import LottieIcon from "../../components/LottieIcon";
import animationData from "../../components/LottieIcon/animations/new-recipe.json";
import { collectionIsReadOnly } from "../../utils";

const readOnlyMessage =
  "Sorry, but recipes can't be created when a collection is read-only - you might want to ask the owner for edit access.";

const NewRecipeForm: React.FC = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const setSnackbarMessage = useSnackbarDispatch();
  const { recipes } = useRecipes();
  const collection = useCurrentCollection();
  const fetcher = useCustomFetch();
  const { recipeAssetMap } = useRecipeModal();
  const recipeDispatch = useRecipeDispatch();

  const isReadOnly = collectionIsReadOnly(collection);

  const {
    recipeStateDispatch,
    recipeState: { recipeType, targetAssetKeys, pairedAssetKeys },
    lists: { targetAssetList, pairedAssetList },
  } = useRecipeForm();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    const { keys: targetKeys } = filterRecipeAssetKeys(targetAssetKeys);
    const { keys: pairedKeys } = filterRecipeAssetKeys(pairedAssetKeys);

    const duplicateRecipe = duplicateRecipeExists({
      targetAssetKeys: targetKeys,
      pairedAssetKeys: pairedKeys,
      recipes,
      currentRecipeId: null,
    });

    if (duplicateRecipe) {
      setSnackbarMessage(
        `Recipe ${duplicateRecipe} has duplicate rules`,
        "error"
      );
    } else {
      const recipe: Recipe = {
        id: calculateRecipeId(recipes),
        recipeType,
        label: "",
        ...calculateRecipeAssets({
          targetAssetKeys: targetKeys,
          pairedAssetKeys: pairedKeys,
          recipeAssetMap,
        }),
      };
      const data = await fetcher<{ recipe: Recipe }>({
        url: `${API_ENDPOINT}/collections/${collection.id}/recipes`,
        method: "POST",
        body: {
          recipe,
        },
      });

      if (data) {
        recipeDispatch((current) => {
          if (!current) {
            return;
          }
          return {
            ...current,
            recipes: [...current.recipes, recipe],
          };
        });

        recipeStateDispatch({
          recipeType: "never",
          targetAssetKeys: [null],
          pairedAssetKeys: [null],
        });
        setSnackbarMessage("Recipe created successfully");
      }
    }

    setSubmitting(false);
  };

  const disabled = submitting || !targetAssetKeys[0] || !pairedAssetKeys[0];
  const hasRecipes = recipes.length > 0;

  return (
    <>
      <LottieIcon animationData={animationData} translateX="-translate-x-9" />
      {hasRecipes ? (
        <>
          <h2 className="mb-6">New recipe</h2>
          {isReadOnly && <p className="opacity-60 mb-6">{readOnlyMessage}</p>}
        </>
      ) : (
        <>
          <h2>Your first recipe</h2>
          <p className="opacity-60 mb-6">
            {isReadOnly
              ? readOnlyMessage
              : "You can add more recipes once you get started."}
          </p>
        </>
      )}
      {!isReadOnly && (
        <form action="#" onSubmit={handleSubmit}>
          <div className="mb-4">
            <RecipeTypeRadio
              recipeType={recipeType}
              recipeStateDispatch={recipeStateDispatch}
              pairedAssetKeys={pairedAssetKeys}
              recipeAssetMap={recipeAssetMap}
              isReadOnly={isReadOnly}
            />
          </div>
          <div className="mb-2">
            <RecipeFormInputList
              targetAssetKeys={targetAssetKeys}
              pairedAssetKeys={pairedAssetKeys}
              recipeAssetType="targetAssetKeys"
              recipeStateDispatch={recipeStateDispatch}
              recipeType={recipeType}
              recipeId={null}
              list={targetAssetList}
              isReadOnly={isReadOnly}
            />
          </div>
          <div className="mb-6">
            <RecipeFormInputList
              targetAssetKeys={targetAssetKeys}
              pairedAssetKeys={pairedAssetKeys}
              recipeAssetType="pairedAssetKeys"
              recipeStateDispatch={recipeStateDispatch}
              recipeType={recipeType}
              recipeId={null}
              list={pairedAssetList}
              isReadOnly={isReadOnly}
            />
          </div>
          <Button fullWidth disabled={disabled} type="submit">
            <PlusIcon />
            <span className="ml-3">Create recipe</span>
          </Button>
        </form>
      )}
    </>
  );
};

export default NewRecipeForm;
