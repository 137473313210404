import React from "react";
import { searchFilter, sortByLabel } from "../../../../../utils";
import { useAssetDispatch } from "../../../../../components/AssetContext";
import AddAsset from "../../../../../components/AddAsset";
import LayerListItem from "./LayerListItem";
import type { LayerInput } from "lasagna-commons";
import type { SetUpdateWarning } from "../../../hooks";
import { useAssets } from "../../../../../components/AssetContext/hooks";
import {
  ASSET_CONDENSED_LAYER_FOOTER_HEIGHT,
  ASSET_CONDENSED_LAYER_TOTAL_HEIGHT,
  ASSET_CONDENSED_LIST_ITEM_HEIGHT,
} from "../../../../../constants/assetPageConstants";
import PlusIcon from "../../../../../icons/Plus";
import { Virtuoso } from "react-virtuoso";

interface LayerBodyProps {
  layerInput: LayerInput;
  searchValue: string;
  currentAssetIds: string[];
  viewportHeight: number;
  setUpdateWarning: SetUpdateWarning | undefined;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  isDeleting: boolean;
  isReadOnly: boolean;
}

const LayerBody: React.FC<LayerBodyProps> = ({
  layerInput,
  searchValue,
  currentAssetIds,
  viewportHeight,
  setUpdateWarning,
  setIsDeleting,
  isDeleting,
  isReadOnly,
}) => {
  const { layers } = useAssets();
  const assetDispatch = useAssetDispatch();

  const assets = Object.values(layers[layerInput.id].assets);

  const filteredAssets = React.useMemo(
    () =>
      assets
        .filter(({ label }) => searchFilter(label, searchValue))
        .sort(sortByLabel),
    [assets, searchValue]
  );

  const height = viewportHeight - ASSET_CONDENSED_LAYER_TOTAL_HEIGHT;

  return (
    <div>
      <Virtuoso
        style={{ height }}
        totalCount={filteredAssets.length}
        defaultItemHeight={ASSET_CONDENSED_LIST_ITEM_HEIGHT}
        itemContent={(index) => (
          <LayerListItem
            asset={filteredAssets[index]}
            layerId={layerInput.id}
            assetDispatch={assetDispatch}
            setUpdateWarning={setUpdateWarning}
            isDeleting={isDeleting}
            setIsDeleting={setIsDeleting}
            isReadOnly={isReadOnly}
          />
        )}
        overscan={20}
      />
      {!isReadOnly && (
        <AddAsset layerInput={layerInput} currentAssetIds={currentAssetIds}>
          <div
            className={`flex items-center justify-center`}
            style={{ height: ASSET_CONDENSED_LAYER_FOOTER_HEIGHT }}
          >
            <span className="cursor-pointer text-sm inline-flex items-center border border-white px-4 py-1.5 font-secondary hover:text-primary hover:border-primary transition-colors">
              <PlusIcon size={12} />
              <span className="ml-2">Add another asset</span>
            </span>
          </div>
        </AddAsset>
      )}
    </div>
  );
};

export default React.memo(LayerBody);
