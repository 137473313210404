import Button from "../components/Button";
import ChevronRightIcon from "../icons/ChevronRight";
import PageWrapper from "../components/PageWrapper";
import PageMainContent from "../components/PageMainContent";

export default function PageNotFound() {
  return (
    <PageWrapper>
      <PageMainContent centerContent addLayoutContainer>
        <div className="flex justify-center flex-col items-center">
          <h3>Page not found 😔</h3>
          <p className="opacity-60 mb-6">
            We were unable to load this page at this time - go home to try
            again.
          </p>
          <div>
            <Button element="link" href="/" size="large">
              <span className="mr-3">Go home</span>
              <ChevronRightIcon />
            </Button>
          </div>
        </div>
      </PageMainContent>
    </PageWrapper>
  );
}
