import React from "react";
import SaveTokenIcon from "../../../../icons/SaveToken";
import type { TempCollectionState } from "./types";
import { TempCollectionAction, TOGGLE_SAVED } from "./hooks";
import InfoTooltip from "../../../../components/Popper/InfoTooltip";
import Checkbox from "../../../../components/Form/Checkbox";

const SaveTokenButton: React.FC<{
  tempCollectionState: TempCollectionState;
  tempCollectionDispatch: React.Dispatch<TempCollectionAction>;
}> = ({ tempCollectionState: { saved }, tempCollectionDispatch }) => {
  const handleClick = () => {
    tempCollectionDispatch({ type: TOGGLE_SAVED });
  };

  return (
    <div className="flex items-center">
      <div className="flex-grow">
        <button
          className={`flex w-full text-left items-center py-3 px-4 disabled:pointer-events-none disabled:opacity-30 ${
            saved
              ? "bg-primary text-black shadow-layer-primary"
              : "bg-gray text-white shadow-layer-gray hover:bg-gray-light hover:shadow-layer-gray-light"
          }`}
          onClick={handleClick}
        >
          <div className="flex-grow flex items-center">
            <SaveTokenIcon />
            <span className="ml-2">Save{saved ? "d" : ""} as custom token</span>
          </div>
          <div className="flex-shrink-0 flex items-center px-2">
            <Checkbox checked={saved} variant="secondary" />
          </div>
        </button>
      </div>
      <div className="flex-shrink-0 ml-4">
        <InfoTooltip
          title="Saving as a custom token will persist this combination to your collection, even after regenerating."
          placement="left"
        />
      </div>
    </div>
  );
};

export default SaveTokenButton;
