import React from "react";
import { useSearchParams } from "react-router-dom";
import { VirtuosoGrid } from "react-virtuoso";
import TokenGridItem from "./TokenGridItem";
import type { GridStyleOption, TokenFilterProps } from "../types";
import { TOKEN_ID_QUERY_PARAM } from "../../../constants";
import EditModal from "./EditModal";
import { calculateGridHeight } from "../utils";
import {
  TOKEN_PAGE_GRID_ITEM_FOOTER_BIG_HEIGHT,
  TOKEN_PAGE_GRID_ITEM_FOOTER_SMALL_HEIGHT,
} from "../../../constants/tokenPageConstants";
import type { TokenState, TokenWithRarityRank } from "../../../types";
import { useAssets } from "../../../components/AssetContext/hooks";
import type { ViewportState } from "../../../components/ViewportContext";

const TokenGrid: React.FC<{
  gridStyle: GridStyleOption;
  aspectRatio: number;
  viewport: ViewportState;
  filteredTokens: TokenWithRarityRank[];
  tokenSet: TokenState["meta"]["tokenSet"];
  tokens: TokenWithRarityRank[];
  assetCounts: TokenState["meta"]["assetCounts"];
  highlightTokenId: boolean;
  setTokenFilters: React.Dispatch<
    React.SetStateAction<TokenFilterProps["tokenFilters"]>
  >;
  isReadOnly?: boolean;
  isLoading: boolean;
}> = ({
  gridStyle,
  aspectRatio,
  viewport,
  filteredTokens,
  tokenSet,
  assetCounts,
  highlightTokenId,
  isReadOnly,
  tokens,
  setTokenFilters,
  isLoading,
}) => {
  const scrollPosition = React.useRef(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTokenId = React.useMemo(() => {
    const idParam = searchParams.get(TOKEN_ID_QUERY_PARAM);
    if (idParam) {
      return parseInt(idParam);
    }
    return null;
  }, [searchParams]);

  const handleClick = React.useCallback(
    (tokenId: number) => {
      scrollPosition.current = window.scrollY;
      setSearchParams(`?${TOKEN_ID_QUERY_PARAM}=${tokenId}`);
    },
    [setSearchParams]
  );

  const { layers } = useAssets();

  React.useEffect(() => {
    if (activeTokenId === null && scrollPosition.current) {
      window.scrollTo({ top: scrollPosition.current });
    }
  }, [activeTokenId]);

  const handleClose = React.useCallback(() => {
    setSearchParams("");
  }, [setSearchParams]);

  const tokenMap = React.useMemo(() => {
    return tokens.reduce<{ [id: number]: TokenWithRarityRank }>((a, b) => {
      a[b.id] = b;
      return a;
    }, {});
  }, [tokens]);

  const activeToken = activeTokenId === null ? null : tokenMap[activeTokenId];

  const gridProps = React.useMemo(() => {
    const [numColumns, footerHeight] =
      gridStyle === "large"
        ? [4, TOKEN_PAGE_GRID_ITEM_FOOTER_BIG_HEIGHT]
        : [8, TOKEN_PAGE_GRID_ITEM_FOOTER_SMALL_HEIGHT];

    return {
      height: calculateGridHeight({
        viewportWidth: viewport.width,
        numColumns,
        footerHeight,
        aspectRatio,
        totalTokens: filteredTokens.length,
      }),
      itemClassName: `${gridStyle === "large" ? "w-1/4" : "w-1/8"} p-1.5`,
      footerHeight,
      filteredTokens,
      itemProps: {
        onClick: handleClick,
        aspectRatio,
        layers,
        highlightTokenId,
        gridStyle,
      },
    };
  }, [
    viewport,
    gridStyle,
    filteredTokens,
    aspectRatio,
    layers,
    highlightTokenId,
    handleClick,
  ]);

  return (
    <>
      <VirtuosoGrid
        useWindowScroll
        style={{ height: gridProps.height }}
        className="w-full -mx-1.5 -mt-1.5"
        totalCount={filteredTokens.length}
        overscan={100}
        itemClassName={gridProps.itemClassName}
        listClassName="flex flex-wrap"
        itemContent={(index) => (
          <TokenGridItem
            token={gridProps.filteredTokens[index]}
            footerHeight={gridProps.footerHeight}
            {...gridProps.itemProps}
          />
        )}
      />
      {activeToken && !isLoading && (
        <EditModal
          onClose={handleClose}
          token={activeToken}
          tokenSet={tokenSet}
          aspectRatio={aspectRatio}
          assetCounts={assetCounts}
          isReadOnly={isReadOnly}
          tokens={tokens}
          setTokenFilters={setTokenFilters}
          gridStyle={gridStyle}
        />
      )}
    </>
  );
};

export default TokenGrid;
