import React from "react";
import ProgressBar from "./ProgressBar";
import type { ProgressItemStage } from "./types";
import LottieIcon from "../LottieIcon";

interface ProgressItemProps {
  step: 1 | 2 | 3;
  headings: Record<ProgressItemStage, string>;
  description: { top: string; bottom: string };
  stage: ProgressItemStage;
  progress: { numerator: number; denominator: number };
  animationData: any;
  disableTransition?: boolean;
}

const ProgressItem: React.FC<ProgressItemProps> = ({
  step,
  stage,
  headings,
  description,
  progress,
  animationData,
  disableTransition,
}) => {
  return (
    <div>
      {stage === "inProgress" && <LottieIcon animationData={animationData} />}
      <h5 className="gradient-text text-sm mb-1">Step {step}</h5>
      <h3>{headings[stage]}</h3>
      {stage === "inProgress" && (
        <div>
          <p>{description.top}</p>
          <p className="mb-6">{description.bottom}</p>
          <ProgressBar {...progress} disableTransition={disableTransition} />
        </div>
      )}
    </div>
  );
};

export default ProgressItem;
