import { useCallback } from "react";
import { getCollectionId } from "..";
import { useCollectionDispatch } from "../../components/CollectionContext";

export default function useUpdateAssetCount() {
  const collectionId = getCollectionId();
  const collectionDispatch = useCollectionDispatch();

  return useCallback(
    (increment: number) => {
      collectionDispatch((current) => {
        if (!current) {
          return;
        }
        if (current) {
          return {
            ...current,
            [collectionId]: {
              ...current[collectionId],
              assetCount: current[collectionId].assetCount + increment,
            },
          };
        }
      });
    },
    [collectionId, collectionDispatch]
  );
}
