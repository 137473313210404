import React from "react";
import CheckIcon from "../../icons/Check";

const variants = {
  primary: {
    checkedStyles: "border-primary bg-primary text-black",
    uncheckedStyles:
      "border-white border-opacity-80 hover:border-primary text-black",
  },
  secondary: {
    checkedStyles: "border-black bg-black text-primary",
    uncheckedStyles: "border-white hover:border-primary text-primary",
  },
};

const Checkbox: React.FC<{
  checked: boolean;
  variant?: "primary" | "secondary";
}> = ({ checked, variant = "primary" }) => {
  const { checkedStyles, uncheckedStyles } = variants[variant];

  return (
    <span
      className={`w-5 h-5 border-2 flex flex-shrink-0 items-center justify-center relative ${
        checked ? checkedStyles : uncheckedStyles
      }`}
    >
      {checked && <CheckIcon />}
    </span>
  );
};

export default Checkbox;
