import React from "react";
import PageWrapper from "../../components/PageWrapper";
import { useCollectionsEffect } from "../../utils/hooks/useFetchCollections";
import LoadingIcon from "../../components/LoadingIcon";
import CollectionForm from "./CollectionForm";
import PageMainContent from "../../components/PageMainContent";

const CollectionPage: React.FC = () => {
  const collections = useCollectionsEffect();

  if (!collections) {
    return (
      <PageWrapper>
        <PageMainContent centerContent>
          <LoadingIcon />
        </PageMainContent>
      </PageWrapper>
    );
  }

  return <CollectionForm />;
};

export default CollectionPage;
