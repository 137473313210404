import { useRecipesInit } from ".";

export function useRecipes() {
  const recipeState = useRecipesInit();

  if (!recipeState) {
    throw new Error("Recipes have not been fetched yet");
  }

  return recipeState;
}
