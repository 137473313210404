import React from "react";
import { useAssetDispatch } from "../../../../components/AssetContext";
import NumberInput from "../../../../components/Form/NumberInput";
import TextInput from "../../../../components/Form/TextInput";
import { useRecipeDispatch } from "../../../../components/RecipeContext";
import { ASSET_DEFAULT_HEADER_HEIGHT } from "../../../../constants/assetPageConstants";
import TrashIcon from "../../../../icons/Trash";
import type { AssetState } from "../../../../types";
import {
  useLayerHeaderState,
  useToggleLayerMetadata,
} from "../AssetEditCollapsedView/LayerContainer/hooks";
import { SetUpdateWarning, useLayerDelete } from "../../hooks";
import EllipsisMenu from "../../../../components/Popper/EllipsisMenu";
import EyeSlashIcon from "../../../../icons/EyeSlash";
import EyeIcon from "../../../../icons/Eye";

const buttonStyles =
  "py-2 px-4 w-full bg-gray-dark hover:bg-gray transition-colors flex items-center font-secondary text-sm text-left";

const LayerHeader: React.FC<{
  layers: AssetState["layers"];
  layerId: string;
  order: string[];
  setOrder: React.Dispatch<React.SetStateAction<string[]>>;
  isDeleting: boolean;
  isReadOnly: boolean;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateWarning: SetUpdateWarning | undefined;
}> = ({
  layers,
  layerId,
  order,
  setOrder,
  isDeleting,
  isReadOnly,
  setIsDeleting,
  setUpdateWarning,
}) => {
  const assetDispatch = useAssetDispatch();
  const recipeDispatch = useRecipeDispatch();
  const layer = layers[layerId];

  const labelState = useLayerHeaderState({
    layer,
    layerId,
    updateKey: "label",
    assetDispatch,
  });

  const weightState = useLayerHeaderState({
    layer,
    layerId,
    updateKey: "weight",
    assetDispatch,
  });

  const deleteLayer = useLayerDelete({
    order,
    layerId,
    layers,
    setOrder,
    assetDispatch,
    recipeDispatch,
    setIsDeleting,
    setUpdateWarning,
  });

  const toggleHideMetadata = useToggleLayerMetadata({
    layer,
    layerId,
    assetDispatch,
  });

  return (
    <div
      className="flex items-center border-b-2 border-black px-4"
      style={{
        height: ASSET_DEFAULT_HEADER_HEIGHT,
      }}
    >
      <div className="flex-grow">
        <TextInput
          name={`layer-label-${layerId}`}
          placeholder={`Layer label`}
          value={labelState.value}
          setValue={labelState.setValue}
          onBlur={labelState.handleBlur}
          error={labelState.error}
          readOnly={isReadOnly}
        />
      </div>
      <div className="w-20 flex-shrink-0 mx-6">
        <NumberInput
          name={`layer-weight-${layerId}`}
          value={weightState.value}
          setValue={weightState.setValue}
          onBlur={weightState.handleBlur}
          endAdornment={<span className="opacity-60">%</span>}
          min={1}
          max={100}
          error={weightState.error}
          readOnly={isReadOnly}
        />
      </div>
      <div>
        <EllipsisMenu dimensions="w-8 h-8">
          <ul>
            <li>
              <button
                className={buttonStyles}
                onClick={deleteLayer}
                disabled={isDeleting}
              >
                <TrashIcon size={18} />
                <span className="ml-2 whitespace-nowrap">Delete layer</span>
              </button>
            </li>
            <li>
              <button
                className={buttonStyles}
                onClick={toggleHideMetadata}
                title={
                  layer.hideMetadata
                    ? "Show metadata for this layer in your finalized metadata package. This is the default behavior."
                    : "Optionally hide metadata for this layer in your finalized collection metadata package. You will only see these changes take effect after your collection is finalized."
                }
              >
                {layer.hideMetadata ? <EyeIcon /> : <EyeSlashIcon size={18} />}
                <span className="ml-2 whitespace-nowrap">
                  {layer.hideMetadata ? "Include" : "Hide"} metadata
                </span>
              </button>
            </li>
          </ul>
        </EllipsisMenu>
      </div>
    </div>
  );
};

export default LayerHeader;
