import React, { FormEvent } from "react";
import { calculateTokenName } from "lasagna-commons";
import ProjectNavbar from "../../components/Navbar/ProjectNavbar";
import PageWrapper from "../../components/PageWrapper";
import PageGridCard from "../../components/PageGrid/PageGridCard";
import PageGridInfo from "../../components/PageGrid/PageGridInfo";
import PageGrid from "../../components/PageGrid";
import H6 from "../../components/H6";
import InfoCard from "../../components/InfoCard";
import Card from "../../components/Card";
import GuidesIcon from "../../icons/Guides";
import DiscordIcon from "../../icons/Discord";
import TextInput from "../../components/Form/TextInput";
import Button from "../../components/Button";
import useCustomFetch from "../../utils/hooks/useCustomFetch";
import { API_ENDPOINT } from "../../config";
import { useSnackbarDispatch } from "../../components/SnackbarContext";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";
import { useCollectionDispatch } from "../../components/CollectionContext";
import { DISCORD_URL, TOKEN_DESCRIPTION_TOOLTIP } from "../../constants";
import PageMainContent from "../../components/PageMainContent";
import LottieIcon from "../../components/LottieIcon";
import animationData from "../../components/LottieIcon/animations/new-collection.json";
import { collectionIsReadOnly } from "../../utils";
import InfoTooltip from "../../components/Popper/InfoTooltip";
import MetadataEditor from "./MetadataEditor";
import ChevronDownIcon from "../../icons/ChevronDown";
import Checkbox from "../../components/Form/Checkbox";

const tokenStartsAtOptions = [0, 1] as const;

const CollectionForm: React.FC = () => {
  const collection = useCurrentCollection();
  const [submitting, setSubmitting] = React.useState(false);
  const [tokenName, setTokenName] = React.useState(() => collection.tokenName);
  const [description, setDescription] = React.useState(
    () => collection.description
  );
  const [tokenIdStartsAt, setTokenIdStartsAt] = React.useState(
    () => collection.tokenIdStartsAt
  );
  const [customTokenMetadata, setCustomTokenMetadata] = React.useState(
    () => collection.customTokenMetadata || {}
  );
  const [includeTraitUsageData, setIncludeTraitUsageData] = React.useState(
    () => collection.includeTraitUsageData || false
  );
  const [showAdvanced, setShowAdvanced] = React.useState(false);
  const fetcher = useCustomFetch();
  const setSnackbarMessage = useSnackbarDispatch();
  const collectionDispatch = useCollectionDispatch();

  const toggleAdvanced = () => {
    setShowAdvanced((val) => !val);
  };

  const toggleRarityIncluded = () => {
    setIncludeTraitUsageData((val) => !val);
  };

  const isReadOnly = collectionIsReadOnly(collection);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    const data = await fetcher<{ success: boolean }>({
      url: `${API_ENDPOINT}/collections/${collection.id}`,
      method: "PUT",
      body: {
        tokenName,
        description,
        tokenIdStartsAt,
        customTokenMetadata,
        includeTraitUsageData,
      },
    });
    if (data) {
      collectionDispatch((current) => {
        if (!current) {
          return;
        }

        const newCollection = {
          ...current[collection.id],
          tokenName,
          description,
          tokenIdStartsAt,
          customTokenMetadata,
          includeTraitUsageData,
        };

        if (newCollection.tokenIdStartsAt !== tokenIdStartsAt) {
          newCollection.mustRegenerate = true;
        }

        newCollection.tokenIdStartsAt = tokenIdStartsAt;

        return {
          ...current,
          [collection.id]: newCollection,
        };
      });
      setSnackbarMessage("Collection updated successfully");
    }
    setSubmitting(false);
  };

  return (
    <PageWrapper navbar={<ProjectNavbar />}>
      <PageMainContent addLayoutContainer centerContent>
        <PageGrid>
          <PageGridCard>
            <>
              <LottieIcon
                animationData={animationData}
                translateX="-translate-x-9"
              />
              <form onSubmit={handleSubmit}>
                <div className="mb-6">
                  <TextInput
                    label={{
                      text: "Collection name",
                      tooltipText:
                        "Your name is your collection's unique identifier - it cannot be changed.",
                      tooltipPlacement: "right",
                      tooltipContentBackgroundColor: "bg-black",
                    }}
                    value={collection.name}
                    readOnly
                    name="collection-name"
                  />
                </div>
                <div className="mb-6">
                  <TextInput
                    label={{
                      text: "Token name",
                      tooltipText: (
                        <span>
                          This can be used to dynamically name your tokens based
                          on their ID. We will substitute the placeholder of{" "}
                          <span className="text-primary">{`{id}`}</span> with
                          the token ID.
                        </span>
                      ),
                      tooltipPlacement: "right",
                      tooltipContentBackgroundColor: "bg-black",
                    }}
                    value={tokenName}
                    setValue={setTokenName}
                    name="tokenName"
                    readOnly={isReadOnly}
                  />
                  <span className="block text-xs mt-1.5">
                    <span className="opacity-60">Example: </span>
                    <span>{calculateTokenName(tokenName, 99)}</span>
                  </span>
                </div>
                <div className="mb-6">
                  <TextInput
                    label={{
                      text: "Token description",
                      tooltipText: TOKEN_DESCRIPTION_TOOLTIP,
                      tooltipPlacement: "right",
                      tooltipContentBackgroundColor: "bg-black",
                    }}
                    value={description}
                    setValue={setDescription}
                    name="description"
                    componentType="textarea"
                    maxRows={5}
                    readOnly={isReadOnly}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    className="inline-flex items-center text-sm py-1 font-secondary opacity-60 hover:opacity-100"
                    onClick={toggleAdvanced}
                  >
                    <span className="mr-3">Advanced options</span>
                    <span
                      className={`flex items-center justify-center transform ${
                        showAdvanced ? "rotate-180" : ""
                      }`}
                    >
                      <ChevronDownIcon />
                    </span>
                  </button>
                </div>
                {showAdvanced && (
                  <div className="mb-8 mt-2">
                    <div className="grid grid-cols-2">
                      <div>
                        <label className="flex items-center pb-2">
                          <span className="mr-3 text-sm font-secondary opacity-60">
                            First token ID
                          </span>
                          <InfoTooltip
                            title="Choose whether your token IDs start incrementing from the number 0 or 1. The default is 0."
                            placement="right"
                            contentBackgroundColor="bg-black"
                          />
                        </label>
                        <div className="flex items-center w-40">
                          {tokenStartsAtOptions.map((option) => {
                            const handleClick = () => {
                              setTokenIdStartsAt(option);
                            };
                            const selected = tokenIdStartsAt === option;

                            return (
                              <div className="flex-grow" key={option}>
                                <button
                                  onClick={handleClick}
                                  className={`w-full py-1.5 border-2 text-sm ${
                                    selected
                                      ? "bg-black text-primary cursor-default border-primary"
                                      : "bg-gray-light text-white border-gray-light hover:bg-gray-dark hover:border-gray-dark hover:opacity-100"
                                  }`}
                                  type="button"
                                >
                                  {option}
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div>
                        <label className="flex items-center pb-2">
                          <span className="mr-3 text-sm font-secondary opacity-60">
                            Custom metadata
                          </span>
                          <InfoTooltip
                            title="You can add additional metadata fields in addition to the ones lasagna already provides"
                            placement="right"
                            contentBackgroundColor="bg-black"
                          />
                        </label>
                        <div>
                          <MetadataEditor
                            customTokenMetadata={customTokenMetadata}
                            setCustomTokenMetadata={setCustomTokenMetadata}
                          />
                        </div>
                      </div>
                      <div className="flex items-center mt-5">
                        <button
                          className="inline-flex items-center text-white group mr-3 whitespace-nowrap"
                          type="button"
                          onClick={toggleRarityIncluded}
                        >
                          <Checkbox checked={includeTraitUsageData} />
                          <span
                            className={`ml-2 font-secondary text-sm ${
                              includeTraitUsageData
                                ? ""
                                : "opacity-80 group-hover:opacity-100"
                            }`}
                          >
                            Add trait usage data
                          </span>
                        </button>
                        <InfoTooltip
                          title="If checked, trait usage metadata will be included in your download package once your collection is finalized."
                          placement="right"
                          contentBackgroundColor="bg-black"
                        />
                      </div>
                    </div>
                    {collection.tokenIdStartsAt !== tokenIdStartsAt &&
                      collection.tokenCount > 0 && (
                        <div className="mt-3 bg-secondary shadow-layer-secondary text-xs text-secondary">
                          <div className="bg-gray py-2 px-3 border-2">
                            Updating the starting token ID will force you to
                            regenerate your collection. Make sure this is
                            intentional before submitting.
                          </div>
                        </div>
                      )}
                  </div>
                )}
                <div className="mt-6">
                  <Button
                    fullWidth
                    type="submit"
                    disabled={submitting || isReadOnly}
                  >
                    Update collection
                  </Button>
                </div>
              </form>
            </>
          </PageGridCard>
          <PageGridInfo gutter="left">
            <div>
              <H6>Collection</H6>
              <h1>Manage your base</h1>
              <p className="opacity-80 mb-6">
                Edit your collection and token attributes.
              </p>
              <div className="mb-8">
                <InfoCard
                  headerText="Getting started"
                  bodyText="I'm launching an NFT collection"
                  fullWidth
                  href={"https://lasagna.xyz/guides/create-a-collection"}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Card
                  headerIcon={<GuidesIcon />}
                  headerText="Guides"
                  bodyText="We've written a lot of useful guides so that you can learn the basics of launching your own project using lasagna."
                  button={{
                    type: "link",
                    linkHref: "https://lasagna.xyz/guides/welcome",
                    linkText: "View our guides",
                  }}
                />
                <Card
                  headerIcon={<DiscordIcon />}
                  headerText="Join our Discord"
                  bodyText="Join our Discord if you'd like to have personal assitance or ask any questions to other creators on lasagna."
                  button={{
                    type: "link",
                    linkHref: DISCORD_URL,
                    linkText: "Join our Discord",
                  }}
                />
              </div>
            </div>
          </PageGridInfo>
        </PageGrid>
      </PageMainContent>
    </PageWrapper>
  );
};
export default CollectionForm;
