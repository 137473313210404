import React from "react";
import type { FileWithPath } from "react-dropzone";
import { AssetWithUrls, LayerInput, MAX_ASSET_COUNT } from "lasagna-commons";
import FileInput from "../Form/FileInput";
import { addAssets } from "../AssetContext/utils";
import { processAssets } from "./utils";
import useAssetUpload from "../../utils/hooks/useAssetUpload";
import useUpdateAssetCount from "../../utils/hooks/useUpdateAssetCount";
import {
  useAssetMetadata,
  useAssetTraitData,
} from "../CollectionContext/hooks";
import { getErrorMessage } from "../../utils";

const AddAsset: React.FC<{
  layerInput: LayerInput;
  currentAssetIds: string[];
  children: React.ReactNode;
  callback?: (asset: AssetWithUrls) => void;
}> = ({ layerInput, currentAssetIds, children, callback }) => {
  const { setLoading, setSnackbarMessage, layers, assetDispatch, run, modals } =
    useAssetUpload();

  const { totalTraits } = useAssetTraitData(layers);
  const updateAssetCount = useUpdateAssetCount();
  const assetMeta = useAssetMetadata();

  const handleFilesAdded = async (files: FileWithPath[]) => {
    setLoading(true);

    try {
      const { assets, errors } = await processAssets({
        files,
        currentAssetIds,
        assetMeta,
        layerId: layerInput.id,
      });

      const { newLayers } = await run(assets, [layerInput]);

      const newAssets = Object.values(newLayers)[0].assets;

      addAssets({
        newAssets,
        layerId: layerInput.id,
        assetDispatch,
        errors,
      });

      updateAssetCount(assets.length);

      if (callback) {
        callback(Object.values(newAssets)[0]);
      }
    } catch (err) {
      setSnackbarMessage(`Error - ${getErrorMessage(err)}`, "error");
    }
    setLoading(false);
  };

  const disabled = totalTraits >= MAX_ASSET_COUNT;

  return (
    <>
      <FileInput
        onFilesAdded={handleFilesAdded}
        disabled={disabled}
        type="file"
        multiple={true}
      >
        {children}
      </FileInput>
      {modals}
    </>
  );
};

export default AddAsset;
