import React from "react";
import Navbar from ".";
import LogoLink from "./LogoLink";

const DefaultNavbar: React.FC = () => {
  return (
    <Navbar>
      <div className="flex items-center">
        <LogoLink />
      </div>
    </Navbar>
  );
};

export default DefaultNavbar;
