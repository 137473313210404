import type { Placement } from "@popperjs/core";
import React from "react";
import { useTargetElement } from "../../utils/hooks";
import Menu from "./Menu";

const EllipsisMenu: React.FC<{
  children: React.ReactNode;
  placement?: Placement;
  dimensions?: string;
}> = ({ children, placement, dimensions = "w-6 h-6" }) => {
  const { targetElement, open, close } = useTargetElement();

  return (
    <>
      <button
        className={`${dimensions} bg-black z-10 flex items-center justify-center transition-colors hover:bg-gray-dark`}
        onClick={open}
      >
        <span className="w-0.5 h-0.5 bg-white" />
        <span className="w-0.5 h-0.5 bg-white mx-1" />
        <span className="w-0.5 h-0.5 bg-white" />
      </button>
      <Menu
        targetElement={targetElement}
        onClose={close}
        offset={4}
        placement={placement}
      >
        {children}
      </Menu>
    </>
  );
};

export default EllipsisMenu;
