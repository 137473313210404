import React from "react";
import { RecipeType } from "lasagna-commons";
import type { RecipeAssetKey, RecipeAssetMap } from "../types";
import AssetImageContainer from "../../../components/AssetImage/AssetImageContainer";
import AssetImage from "../../../components/AssetImage";
import { pluralize } from "../../../utils";

const MAX_ASSET_DISPLAY = 2;

const RecipeModalHeaderLabel: React.FC<{
  recipeType: RecipeType;
  assetKeys: RecipeAssetKey[];
  recipeAssetMap: RecipeAssetMap;
}> = ({ recipeType, assetKeys: initialAssetKeys, recipeAssetMap }) => {
  const assetKeys = initialAssetKeys.filter(Boolean);
  if (assetKeys.length > 0) {
    const sliceLimit = Math.min(assetKeys.length, MAX_ASSET_DISPLAY);
    const assetsRemaining = assetKeys.length - sliceLimit;
    return (
      <div className="flex items-center justify-center">
        <span>Select assets to</span>
        <span className="uppercase text-primary mx-1.5">{recipeType}</span>
        <span>go with</span>
        <div className="flex items-center">
          {assetKeys.slice(0, sliceLimit).map((assetKey, index) => {
            if (!assetKey) {
              return null;
            }
            const asset = recipeAssetMap[assetKey];

            return (
              <div key={assetKey} className="flex items-center ml-2">
                <div className="w-8 bg-gray-light mr-2">
                  <AssetImageContainer aspectRatio={1}>
                    <AssetImage asset={asset} size="tiny" />
                  </AssetImageContainer>
                </div>
                <div className="text-left font-secondary tracking-tighest text-xs">
                  <span className="opacity-70 whitespace-nowrap block leading-none">
                    {asset.layerLabel}
                  </span>
                  <span className="whitespace-nowrap">{asset.label}</span>
                </div>
                {index < sliceLimit - 1 && (
                  <span className="mx-1">
                    {assetsRemaining === 0 ? (
                      <span className="pl-1">and</span>
                    ) : (
                      ","
                    )}
                  </span>
                )}
              </div>
            );
          })}
          {assetsRemaining > 0 && (
            <span className="ml-2 opacity-80 text-xs tracking-tightest font-secondary">
              and {pluralize(assetsRemaining, "other")}
            </span>
          )}
        </div>
      </div>
    );
  }
  return <>Select assets</>;
};

export default RecipeModalHeaderLabel;
