import React from "react";
import { useAddress } from "@thirdweb-dev/react";
import ConnectWalletButtons from "./ConnectWalletButtons";
import SignMessage from "./SignMessage";

const ConnectWalletSection: React.FC = () => {
  const address = useAddress();

  return (
    <div className="bg-primary text-black h-full flex flex-col justify-center">
      <div className="max-w-2xl m-auto">
        {address ? <SignMessage address={address} /> : <ConnectWalletButtons />}
      </div>
    </div>
  );
};

export default ConnectWalletSection;
