import React from "react";
import { METADATA_SECTIONS } from ".";
import Checkbox from "../../../components/Form/Checkbox";
import InfoTooltip from "../../../components/Popper/InfoTooltip";
import Check from "../../../icons/Check";
import type { CustomTokenMetadata } from "./types";

const defaultFields = ["id", "attributes", "description", "image", "name"];

const MetadataForm: React.FC<{
  customTokenMetadata: CustomTokenMetadata;
  setCustomTokenMetadata: React.Dispatch<
    React.SetStateAction<CustomTokenMetadata>
  >;
}> = ({ customTokenMetadata, setCustomTokenMetadata }) => {
  return (
    <div>
      <FormSection
        label="Required fields"
        tooltipText="These fields are required for most chains and are populated by default with lasagna"
      >
        <div className="grid grid-cols-3 gap-2">
          {defaultFields.map((field) => (
            <div className="flex items-center">
              <span className="flex items-center justify-center h-5 w-5 bg-primary text-black">
                <Check />
              </span>
              <span className="ml-2 font-secondary opacity-80">{field}</span>
            </div>
          ))}
        </div>
      </FormSection>
      {METADATA_SECTIONS.map(({ label, tooltipText, options }) => {
        return (
          <FormSection label={label} tooltipText={tooltipText}>
            <ul>
              {options.map(
                ({
                  key,
                  initialValue,
                  label: optionLabel,
                  Component,
                  calculatedByLasagna,
                  tooltipText: componentTooltipText,
                }) => {
                  const checked = customTokenMetadata.hasOwnProperty(key);

                  const handleClick = () => {
                    setCustomTokenMetadata((current) => {
                      const newFields = { ...current };

                      if (checked) {
                        delete newFields[key];
                      } else {
                        newFields[key] = initialValue;
                      }

                      return newFields;
                    });
                  };

                  return (
                    <li key={key} className="mb-3 last:mb-0">
                      <div>
                        <button
                          className="flex items-center group"
                          type="button"
                          onClick={handleClick}
                        >
                          <Checkbox checked={checked} />
                          <span
                            className={`ml-2 font-secondary ${
                              checked
                                ? ""
                                : "opacity-80 group-hover:opacity-100"
                            }`}
                          >
                            {key}
                          </span>
                          {checked && calculatedByLasagna && (
                            <span className="ml-2 opacity-60">
                              (calculated by lasagna)
                            </span>
                          )}
                        </button>
                      </div>
                      {checked && Component && (
                        <div className="mt-2 mb-4">
                          <Component
                            label={optionLabel}
                            metadataKey={key}
                            customTokenMetadata={customTokenMetadata}
                            setCustomTokenMetadata={setCustomTokenMetadata}
                            tooltipText={componentTooltipText}
                          />
                        </div>
                      )}
                    </li>
                  );
                }
              )}
            </ul>
          </FormSection>
        );
      })}
    </div>
  );
};

export default MetadataForm;

function FormSection({
  children,
  label,
  tooltipText,
}: {
  children: React.ReactNode;
  label: string;
  tooltipText: string;
}) {
  return (
    <section className="bg-gray shadow-layer-gray p-4 mb-6">
      <h5 className="text-lg mb-2 flex items-center">
        <span className="mr-3">{label}</span>
        <InfoTooltip
          title={tooltipText}
          placement="right"
          contentBackgroundColor="bg-black"
        />
      </h5>
      {children}
    </section>
  );
}
