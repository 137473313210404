import ReactDOM from "react-dom/client";
import "./index.css";
import "./fonts/mabry-regular-pro.otf";
import "./fonts/mabry-bold-pro.otf";
import "./fonts/mabry-mono-regular-pro.otf";
import Root from "./components/Root";

window.Buffer = window.Buffer || require("buffer").Buffer; // Preventing "ReferenceError: Buffer is not defined."

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<Root />);
