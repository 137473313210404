import React from "react";

const WarningMessage: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className="my-6 bg-secondary shadow-layer-secondary text-sm text-secondary">
      <div className="bg-gray-dark p-4 border-2">{children}</div>
    </div>
  );
};

export default WarningMessage;
