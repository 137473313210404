export default function Download({ size = 20 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 21.67V4" strokeWidth="2" strokeLinecap="square" />
      <path d="M26.6663 28H5.33301" strokeWidth="2" strokeLinecap="square" />
      <path
        d="M22.6667 16L15.9987 22.668L9.33203 16"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
}
