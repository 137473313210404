export default function File() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M10.0009 0V4H14.0009L10.0009 0ZM9.22273 9.22188C9.1571 9.08438 9.00085 9 8.83523 9C8.6696 9 8.51179 9.08356 8.41929 9.22266L7.00085 11.35L6.58366 10.7241C6.49148 10.5844 6.33523 10.5 6.16648 10.5C5.99773 10.5 5.84304 10.5836 5.75054 10.7227L4.08398 13.2227C3.98169 13.376 3.97216 13.5733 4.05913 13.7358C4.1471 13.9 4.31648 14 4.50085 14H11.5009C11.6852 14 11.8546 13.8985 11.9418 13.7359C12.0288 13.5734 12.0193 13.3759 11.9169 13.2228L9.22273 9.22188Z"
      />
      <path d="M10 4V0H3.5C2.67156 0 2 0.671562 2 1.5V14.5C2 15.3281 2.67156 16 3.5 16H12.5C13.3284 16 14 15.3284 14 14.5V4H10ZM11.9406 13.7344C11.8531 13.9 11.6844 14 11.5 14H4.5C4.31562 14 4.14625 13.8985 4.05906 13.7359C3.97209 13.5734 3.98159 13.3759 4.0839 13.2228L5.75047 10.7228C5.81562 10.5844 5.97188 10.5 6.16563 10.5C6.35938 10.5 6.48906 10.5836 6.58156 10.7227L7 11.35L8.41719 9.22406C8.50938 9.08438 8.66563 9 8.83437 9C9.00312 9 9.15781 9.08356 9.25031 9.22266L11.9169 13.2227C12.0188 13.375 12.0281 13.5719 11.9406 13.7344Z" />
    </svg>
  );
}
