import React from "react";
import type { URLSearchParamsInit } from "react-router-dom";
import AssetImageStack from "../../../../../components/AssetImage/AssetImageStack";
import { TOKEN_ID_QUERY_PARAM } from "../../../../../constants";
import type { AssetState, TokenWithRarityRank } from "../../../../../types";

const SimilarTokensItem: React.FC<{
  layers: AssetState["layers"];
  token: TokenWithRarityRank;
  aspectRatio: number;
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined
  ) => void;
}> = ({ setSearchParams, token, aspectRatio, layers }) => {
  const handleClick = () => {
    setSearchParams(`?${TOKEN_ID_QUERY_PARAM}=${token.id}`);
  };

  return (
    <div className="w-40 mr-2 flex-shrink-0">
      <button className="w-full" onClick={handleClick}>
        <div>
          <AssetImageStack
            tokenLayers={token.tokenLayers}
            aspectRatio={aspectRatio}
            layers={layers}
            size="tiny"
          />
        </div>
        <div className="flex items-center justify-between text-xs px-2 py-1 bg-gray-light">
          <span>{token.id}</span>
          {token.rarityRank && (
            <span className="opacity-60">#{token.rarityRank}</span>
          )}
        </div>
      </button>
    </div>
  );
};

export default SimilarTokensItem;
