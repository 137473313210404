import React from "react";
import NoRecipes from "./NoRecipes";
import RecipeCarousel from "./RecipeCarousel";
import { RecipeModalProvider } from "./RecipeModal/RecipeModalContext";

const RecipesMain: React.FC<{ hasRecipes: boolean }> = ({ hasRecipes }) => {
  return (
    <RecipeModalProvider>
      {hasRecipes ? <RecipeCarousel /> : <NoRecipes />}
    </RecipeModalProvider>
  );
};

export default RecipesMain;
