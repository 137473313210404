import React from "react";
import Menu from "../../../components/Popper/Menu";
import ArrowDownIcon from "../../../icons/ArrowDown";
import ArrowUpIcon from "../../../icons/ArrowUp";
import { useTargetElement } from "../../../utils/hooks";
import { SortTypeOption } from "../types";

const sortTypeOptions: {
  value: SortTypeOption;
  label: string;
  icon: React.ReactNode;
}[] = [
  { value: "idDescending", label: "Token", icon: <ArrowDownIcon /> },
  { value: "idAscending", label: "Token", icon: <ArrowUpIcon /> },
  { value: "rarityDescending", label: "Rarity", icon: <ArrowDownIcon /> },
  { value: "rarityAscending", label: "Rarity", icon: <ArrowUpIcon /> },
];

const sortTypeOptionMap = sortTypeOptions.reduce<
  Partial<Record<SortTypeOption, React.ReactNode>>
>((a, { value, label, icon }) => {
  a[value] = (
    <>
      <span className="mr-2">{label}</span>
      {icon}
    </>
  );
  return a;
}, {});

const buttonStyles =
  "font-secondary flex items-center w-32 px-3 cursor-pointer";

const SortButton: React.FC<{
  sortType: SortTypeOption;
  setSortType: React.Dispatch<React.SetStateAction<SortTypeOption>>;
}> = ({ sortType, setSortType }) => {
  const { open, close, targetElement } = useTargetElement();

  return (
    <div className="ml-4">
      <button className={`${buttonStyles} text-primary`} onClick={open}>
        {sortTypeOptionMap[sortType]}
      </button>
      <Menu targetElement={targetElement} onClose={close}>
        <ul className="py-1">
          {sortTypeOptions.map(({ value, label, icon }) => {
            const handleClick = () => {
              setSortType(value);
            };
            const selected = value === sortType;

            return (
              <li key={value}>
                <button
                  onClick={handleClick}
                  className={`${buttonStyles} py-1.5 justify-between ${
                    selected
                      ? "text-primary bg-gray-dark"
                      : "text-white bg-gray hover:bg-gray-dark"
                  }`}
                >
                  <span>{label}</span>
                  {icon}
                </button>
              </li>
            );
          })}
        </ul>
      </Menu>
    </div>
  );
};

export default SortButton;
