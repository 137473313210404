import { FOOTER_HEIGHT } from "./layoutConstants";

export const RECIPE_PAGE_MODAL_HEADER_HEIGHT = 80;
export const RECIPE_PAGE_LAYER_HEADER_HEIGHT = 96;
export const RECIPE_PAGE_LAYER_WIDTH = 360;
export const RECIPE_PAGE_LIST_ITEM_HEIGHT = 96;
export const RECIPE_PAGE_LAYER_TOTAL_HEIGHT =
  RECIPE_PAGE_MODAL_HEADER_HEIGHT +
  FOOTER_HEIGHT +
  RECIPE_PAGE_LAYER_HEADER_HEIGHT;
