import React from "react";
import { useParams } from "react-router-dom";
import { useAuthDispatch } from "../../../components/AuthContext";
import type { AuthUser } from "../../../components/AuthContext/types";
import Button from "../../../components/Button";
import LoadingIcon from "../../../components/LoadingIcon";
import PageMainContent from "../../../components/PageMainContent";
import PageWrapper from "../../../components/PageWrapper";
import { useSnackbarDispatch } from "../../../components/SnackbarContext";
import { API_ENDPOINT } from "../../../config";
import { LOCAL_STORAGE_EMAIL_KEY } from "../../../constants";
import RotateIcon from "../../../icons/Rotate";
import { customFetch, getErrorMessage } from "../../../utils";
import EmailFormDrawer from "./EmailFormDrawer";
import UpdateEmailForm from "./UpdateEmailForm";

const initialEmail = window.localStorage.getItem(LOCAL_STORAGE_EMAIL_KEY);

const EmailVerificationPage: React.FC = () => {
  const [error, setError] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState(() => initialEmail || "");
  const [loading, setLoading] = React.useState(Boolean(initialEmail));

  const params = useParams<{ userId: string; nonce: string }>();
  const setSnackbarMessage = useSnackbarDispatch();
  const authDispatch = useAuthDispatch();

  const callback = React.useCallback(async () => {
    if (email && !error) {
      try {
        setLoading(true);

        const data = await customFetch<AuthUser>({
          method: "POST",
          url: `${API_ENDPOINT}/auth/login-email`,
          body: { email, userId: params.userId, nonce: params.nonce },
        });

        authDispatch((current) => ({
          ...current,
          user: data,
        }));

        setSnackbarMessage("Logged in successfully");
      } catch (err) {
        setLoading(false);

        setError(getErrorMessage(err));
      }
    }
  }, [email, error, params, authDispatch, setSnackbarMessage]);

  React.useEffect(() => {
    callback();
  }, [callback]);

  const tryAgain = () => {
    setError(null);
  };

  return (
    <PageWrapper>
      <PageMainContent centerContent>
        {loading ? (
          <LoadingIcon />
        ) : error ? (
          <div className="text-center">
            <p className="text-red mb-6 text-xl">{error}</p>
            <div className="grid grid-cols-2 gap-8 max-w-xl m-auto">
              <div>
                <Button fullWidth onClick={tryAgain} variant="gray">
                  <RotateIcon />
                  <span className="ml-2.5">Try again</span>
                </Button>
              </div>
              <div>
                <EmailFormDrawer email={email} />
              </div>
            </div>
          </div>
        ) : (
          <UpdateEmailForm setEmail={setEmail} />
        )}
      </PageMainContent>
    </PageWrapper>
  );
};

export default EmailVerificationPage;
