import type { FileWithPath } from "react-dropzone";
import type { Dispatch, SetStateAction } from "react";
import type {
  AssetErrorMessage,
  AssetState,
  InitialClientAssetWithLayerId,
} from "../../../types";
import { validateUploadedFile } from "../../../utils";
import { AssetMeta, LayerInput, MAX_ASSET_COUNT } from "lasagna-commons";
import { ProgressProps } from "../../../components/Progress/types";

export async function processLayerFiles({
  files,
  layers,
  totalTraits,
  assetMeta,
  setProcessedFileProgress,
}: {
  files: FileWithPath[];
  layers: AssetState["layers"];
  assetMeta: AssetMeta;
  totalTraits: number;
  setProcessedFileProgress: Dispatch<SetStateAction<ProgressProps>>;
}): Promise<{
  layer: LayerInput;
  assets: InitialClientAssetWithLayerId[];
  errors: AssetErrorMessage[];
}> {
  const errors: AssetErrorMessage[] = [];
  const assets: InitialClientAssetWithLayerId[] = [];
  let layer: LayerInput | null = null;

  const newAssetTotal = totalTraits + files.length;

  if (newAssetTotal > MAX_ASSET_COUNT) {
    errors.push({
      name: undefined,
      reason: `You cannot upload more than ${MAX_ASSET_COUNT} assets. You already have uploaded ${totalTraits} assets and are attempting to load ${files.length} more, which would total ${newAssetTotal} assets.`,
    });
  }

  let fileCount = 0;

  for (const file of files) {
    const { pathArray, asset, assetError } = await validateUploadedFile({
      file,
      assetMeta,
      deriveAssetId: fileCount++,
      directoryValidation: {
        layerCount: 2,
        errorMessage: "all files must be in a parent directory",
      },
    });
    if (assetError) {
      errors.push(assetError);
    } else if (asset) {
      if (!layer) {
        layer = {
          id: generateUniqueLayerId(layers),
          label: pathArray[0],
          layerOrder: Object.keys(layers).length,
        };
      }

      assets.push({ ...asset, layerId: layer.id });

      setProcessedFileProgress(({ numerator, denominator }) => ({
        numerator: numerator + 1,
        denominator,
      }));
    }
  }

  if (!layer) {
    throw new Error("None of these files were compatible with your collection");
  }

  return { layer, assets, errors };
}

function generateUniqueLayerId(layers: AssetState["layers"]): string {
  let id = Object.keys(layers).length;
  while (layers[id]) {
    id++;
  }
  return `${id}`;
}
