import React from "react";
import debounce from "lodash/debounce";
import { createCtx } from "../utils";
import { MIN_SCREEN_WIDTH } from "../constants";

export interface ViewportState {
  width: number;
  height: number;
}

const [useViewport, ViewportContextProvider] = createCtx<ViewportState>();

const ViewportProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [viewport, setViewport] = React.useState<{
    width: number;
    height: number;
  }>(() => ({
    width: setViewportWidth(window.innerWidth),
    height: window.innerHeight,
  }));

  React.useEffect(() => {
    const listener = debounce(() => {
      setViewport({
        width: setViewportWidth(window.innerWidth),
        height: window.innerHeight,
      });
    }, 500);
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  return (
    <ViewportContextProvider value={viewport}>
      {children}
    </ViewportContextProvider>
  );
};

function setViewportWidth(windowInnerWidth: number) {
  return Math.max(MIN_SCREEN_WIDTH, windowInnerWidth);
}

export { useViewport, ViewportProvider };
