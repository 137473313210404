import React from "react";
import Button from "../../../components/Button";
import Drawer from "../../../components/Drawer";
import ChevronRight from "../../../icons/ChevronRight";
import ShareForm from "./ShareForm";

const SharePreviewDrawer: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} variant="gray" size="small">
        <span className="mr-3">Share access</span>
        <ChevronRight />
      </Button>
      <Drawer open={open} onClose={handleClose} width="wide">
        <ShareForm open={open} />
      </Drawer>
    </>
  );
};

export default SharePreviewDrawer;
