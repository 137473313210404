import React from "react";
import Drawer from "../../../components/Drawer";
import TrashIcon from "../../../icons/Trash";
import Button from "../../../components/Button";
import ChevronLeftIcon from "../../../icons/ChevronLeft";
import useCustomFetch from "../../../utils/hooks/useCustomFetch";
import { API_ENDPOINT } from "../../../config";
import { useSnackbarDispatch } from "../../../components/SnackbarContext";
import { useCollectionDispatch } from "../../../components/CollectionContext";
import WarningMessage from "../../../components/WarningMessage";
import EllipsisMenu from "../../../components/Popper/EllipsisMenu";
import LottieIcon from "../../../components/LottieIcon";
import animationData from "../../../components/LottieIcon/animations/delete-warning.json";

const CollectionDeleteButton: React.FC<{
  collectionName: string;
  collectionId: string; // The client ID and server ID will always match because collaborators cannot delete collections
}> = ({ collectionName, collectionId }) => {
  const [deleting, setDeleting] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleOpen = () => {
    setDrawerOpen(true);
  };

  const handleClose = () => {
    if (!deleting) {
      setDrawerOpen(false);
    }
  };

  const fetcher = useCustomFetch();
  const setSnackbarMessage = useSnackbarDispatch();
  const collectionDispatch = useCollectionDispatch();

  const handleDelete = async () => {
    setDeleting(true);
    const data = await fetcher<{ success: boolean }>({
      method: "DELETE",
      url: `${API_ENDPOINT}/collections/${collectionId}`,
    });

    if (data) {
      setSnackbarMessage("Collection deleted successfully");
      setDrawerOpen(false);
      collectionDispatch((current) => {
        if (!current) {
          return;
        }

        const newState = { ...current };
        delete newState[collectionId];

        return newState;
      });
    } else {
      setDeleting(false);
    }
  };

  return (
    <>
      <div className="absolute top-2 right-2 z-10">
        <EllipsisMenu>
          <button
            className="py-2 px-4 w-auto bg-gray-dark hover:bg-gray transition-colors flex items-center justify-center font-secondary text-sm"
            onClick={handleOpen}
          >
            <TrashIcon size={18} />
            <span className="ml-2 whitespace-nowrap">Delete collection</span>
          </button>
        </EllipsisMenu>
      </div>
      <Drawer open={drawerOpen} onClose={handleClose}>
        <div>
          <LottieIcon animationData={animationData} />
          <h2>Are you sure you want to delete {collectionName}?</h2>
          <WarningMessage>
            Deleting a collection will permanently remove it from lasagna. You
            will NOT be able to recover your assets, recipes or tokens once it
            is deleted.
          </WarningMessage>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Button
                size="small"
                fullWidth
                onClick={handleClose}
                disabled={deleting}
              >
                <ChevronLeftIcon />
                <span className="ml-3">No, don't delete</span>
              </Button>
            </div>
            <div>
              <Button
                size="small"
                fullWidth
                onClick={handleDelete}
                variant="secondary"
                disabled={deleting}
              >
                <TrashIcon size={18} />
                <span className="ml-3">Yes, delete it</span>
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default CollectionDeleteButton;
