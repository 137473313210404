export const CAROUSEL_CONTAINER_STYLES =
  "flex overflow-auto pl-16 pr-12 py-1 -mx-layout -my-1";
export const CAROUSEL_ITEM_STYLES = "h-full flex-shrink-0 mr-4";

export const MIN_SCREEN_WIDTH = 1280;

export const REDIRECT_TO_QUERY_PARAM = "redirect_to";
export const TOKEN_ID_QUERY_PARAM = "token_id";

export const TOKEN_DESCRIPTION_TOOLTIP =
  "Add a description to your tokens which will be displayed on the token description field on OpenSea, LooksRare, etc.";

export const DISCORD_URL = "https://discord.gg/h2UFpW3hHP";

export const LOCAL_STORAGE_EMAIL_KEY = "lasagna_email";

export class NoCollectionError extends Error {
  constructor() {
    super("Collection not found");
  }
}
