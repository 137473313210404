import React from "react";
import ExternalLinkButton from "../../../components/ExternalLink/ExternalLinkButton";
import DownloadIcon from "../../../icons/Download";
import ImagePackageIcon from "../../../icons/ImagePackage";
import MetadataPackageIcon from "../../../icons/MetadataPackage";
import { pluralize } from "../../../utils";

interface DownloadButtonProps {
  type: "Images" | "Metadata";
  tokenCount: number;
  layerCount?: number;
  href: string | undefined;
  label: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  href,
  type,
  label,
  tokenCount,
  layerCount,
}) => {
  return (
    <ExternalLinkButton href={href} fullWidth disabled={!href}>
      <div className="flex justify-between items-center w-full py-2 pr-3 font-primary">
        <div className="flex flex-shrink-0 transition-colors text-white group-hover:text-primary">
          {type === "Images" ? <ImagePackageIcon /> : <MetadataPackageIcon />}
        </div>
        <div className="overflow-hidden flex-grow ml-2 -mt-1">
          <div className="text-lg mb-1 truncate">
            {href ? label : "Preparing link..."}
          </div>
          <div className="text-xs flex items-center">
            <span>{type}</span>
            <span className="border-l border-black border-opacity-50 pl-4 ml-4">
              {layerCount
                ? pluralize(layerCount, "layer")
                : pluralize(tokenCount, "token")}
            </span>
          </div>
        </div>
        <div className="ml-2">
          <DownloadIcon size={24} />
        </div>
      </div>
    </ExternalLinkButton>
  );
};

export default DownloadButton;
