import React from "react";
import {
  AssetWithUrls,
  RARITY_LOWER_BOUND,
  RARITY_UPPER_BOUND,
} from "lasagna-commons";
import TextInput from "../../../../../components/Form/TextInput";
import TrashIcon from "../../../../../icons/Trash";
import IconButton from "../../../../../components/Button/IconButton";
import type { AssetDispatch } from "../../../../../types";
import { useLayerListItemState } from "./hooks";
import Slider from "../../../Slider";
import { SetUpdateWarning, useAssetDelete } from "../../../hooks";
import LayerListItemAsset from "./LayerListItemAsset";
import { ASSET_CONDENSED_LIST_ITEM_HEIGHT } from "../../../../../constants/assetPageConstants";
import NumberInput from "../../../../../components/Form/NumberInput";

interface LayerListItemProps {
  asset: AssetWithUrls;
  layerId: string;
  assetDispatch: AssetDispatch;
  setUpdateWarning: SetUpdateWarning | undefined;
  isDeleting: boolean;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  isReadOnly: boolean;
}

const LayerListItem: React.FC<LayerListItemProps> = ({
  asset,
  layerId,
  assetDispatch,
  setUpdateWarning,
  isDeleting,
  setIsDeleting,
  isReadOnly,
}) => {
  const [mouseIsOver, setMouseIsOver] = React.useState(false);

  const labelState = useLayerListItemState({
    asset,
    updateKey: "label",
    layerId,
    assetDispatch,
  });

  const rarityState = useLayerListItemState({
    asset,
    updateKey: "rarity",
    layerId,
    assetDispatch,
  });

  const deleteAsset = useAssetDelete({
    layerId,
    asset,
    assetDispatch,
    setIsDeleting,
    setUpdateWarning,
  });

  return (
    <div
      className={`flex items-center px-4`}
      style={{ height: ASSET_CONDENSED_LIST_ITEM_HEIGHT }}
    >
      <LayerListItemAsset asset={asset} />
      <div className="flex-grow pr-4 pl-3">
        <div>
          <TextInput
            name={`asset-label-${asset.id}`}
            value={labelState.value}
            setValue={labelState.setValue}
            onBlur={labelState.handleBlur}
            placeholder="Trait label"
            error={labelState.error}
            readOnly={isReadOnly}
          />
        </div>
        <div className="flex items-center">
          <div className="flex-grow">
            <Slider
              value={rarityState.value}
              setValue={rarityState.setValue}
              onBlur={rarityState.handleBlur}
              mouseIsOver={mouseIsOver}
              setMouseIsOver={setMouseIsOver}
              disabled={isReadOnly}
            />
          </div>
          <div className="flex-shrink-0 w-16 pl-4">
            <NumberInput
              name={`asset-rarity-${asset.id}`}
              value={rarityState.value}
              setValue={rarityState.setValue}
              onBlur={rarityState.handleBlur}
              endAdornment={<span className="opacity-60">%</span>}
              min={RARITY_LOWER_BOUND}
              max={RARITY_UPPER_BOUND}
              error={rarityState.error}
              readOnly={isReadOnly}
            />
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 flex items-center">
        <IconButton
          onClick={deleteAsset}
          title="Remove this trait"
          disabled={isDeleting || isReadOnly}
        >
          <TrashIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default React.memo(LayerListItem);
