import React from "react";
import Footer from "../../components/Footer";
import DownloadDrawer from "./DownloadDrawer";
import RegenerateTokensDrawer from "../../components/GenerateTokens/RegenerateTokensDrawer";
import { ASIDE_WIDTH } from "../../constants/layoutConstants";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";

const TokenFooter: React.FC = () => {
  const collection = useCurrentCollection();

  const labels = React.useMemo(() => {
    if (collection.hasAllTokenZipFiles && collection.hasMetadataZipFile) {
      return {
        topText: "Your collection is ready!",
        bottomText: "You can now download all tokens and metadata.",
        drawerLabel: "Download my collection",
      };
    }
    if (collection.printedTokenCount > 0) {
      return {
        topText: "Your collection is in progress.",
        bottomText: "Once ready, you can download all tokens and metadata.",
        drawerLabel: "View collection status",
      };
    }
    return {
      topText: "Are you ready to finish your collection?",
      bottomText: "Once ready, you can download all tokens and metadata.",
      drawerLabel: "My collection is ready",
    };
  }, [collection]);

  return (
    <Footer>
      <div>
        <RegenerateTokensDrawer
          tokensPage="afterGen"
          collectionId={collection.id}
        />
      </div>
      <div className="flex items-center justify-end text-right">
        <div className="text-sm mr-3">
          <span className="font-secondary block">{labels.topText}</span>
          <span className="block opacity-60">{labels.bottomText}</span>
        </div>
        <div style={{ width: ASIDE_WIDTH }} className="pl-3">
          <DownloadDrawer label={labels.drawerLabel} />
        </div>
      </div>
    </Footer>
  );
};

export default TokenFooter;
