import { AssetWithUrls, RecipeType, sortLayers } from "lasagna-commons";
import React from "react";
import { useAssets } from "../../../components/AssetContext/hooks";
import { useViewport } from "../../../components/ViewportContext";
import type { RecipeAssetData } from "../types";
import { useRecipeModal } from "./RecipeModalContext";
import RecipeModalGridLayer from "./RecipeModalLayer";

const RecipeModalGrid: React.FC<{
  activeRecipe: RecipeAssetData;
  recipeType: RecipeType | undefined | null;
  addAsset: (assetKey: string) => void;
  removeAsset: (index: number) => void;
  addAllAssetsForLayer: (layerId: string, assets: AssetWithUrls[]) => void;
  removeAllAssetsForLayer: (layerId: string) => void;
}> = ({
  activeRecipe,
  recipeType,
  addAsset,
  removeAsset,
  addAllAssetsForLayer,
  removeAllAssetsForLayer,
}) => {
  const { layers } = useAssets();
  const { recipeAssetMap } = useRecipeModal();

  const layerArray = Object.values(layers).sort(sortLayers);

  const { height } = useViewport();

  const currentAssetKeys = activeRecipe[activeRecipe.recipeAssetType];
  const otherAssetKeys =
    activeRecipe[
      activeRecipe.recipeAssetType === "pairedAssetKeys"
        ? "targetAssetKeys"
        : "pairedAssetKeys"
    ];

  return (
    <div className="flex overflow-auto pl-layout pr-12 pb-2 hide-scrollbar">
      {layerArray.map((layer) => {
        const disabled = Boolean(
          otherAssetKeys.find(
            (assetKey) =>
              assetKey && recipeAssetMap[assetKey].layerId === layer.id
          ) ||
            (recipeType === "always" &&
              currentAssetKeys.find(
                (assetKey) =>
                  assetKey && recipeAssetMap[assetKey].layerId === layer.id
              ))
        );

        return (
          <RecipeModalGridLayer
            key={layer.id}
            layer={layer}
            viewportHeight={height}
            currentAssetKeys={currentAssetKeys}
            addAsset={addAsset}
            removeAsset={removeAsset}
            disabled={disabled}
            addAllAssetsForLayer={addAllAssetsForLayer}
            removeAllAssetsForLayer={removeAllAssetsForLayer}
            recipeType={recipeType}
          />
        );
      })}
    </div>
  );
};

export default RecipeModalGrid;
