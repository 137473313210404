import React, { useCallback, useEffect, useRef, useState } from "react";
import { useCollectionDispatch } from "../../../../../components/CollectionContext";
import { useSnackbarDispatch } from "../../../../../components/SnackbarContext";
import { API_ENDPOINT } from "../../../../../config";
import useCustomFetch from "../../../../../utils/hooks/useCustomFetch";
import {
  LASAGNA_STYLES,
  SQUARE_APP_ID,
  SQUARE_LOCATION_ID,
  SQUARE_SRC,
} from "./config";

declare global {
  interface Window {
    Square: any;
  }
}

export function useSquare({
  collectionId,
  setShowPinningForm,
}: {
  collectionId: string;
  setShowPinningForm: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [submitting, setSubmitting] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState({
    squareIsLoaded: false,
    hasError: false,
    cardIsLoaded: false,
  });

  const cardContainerRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<any>(null);
  const fetcher = useCustomFetch();
  const setSnackbarMessage = useSnackbarDispatch();
  const collectionDispatch = useCollectionDispatch();

  useEffect(() => {
    if (!loadingStatus.squareIsLoaded && !loadingStatus.hasError) {
      const script = document.createElement("script");
      script.src = SQUARE_SRC;
      script.onerror = (err) => {
        console.error(err);
        setLoadingStatus({
          squareIsLoaded: false,
          hasError: true,
          cardIsLoaded: false,
        });
      };
      script.onload = () => {
        setLoadingStatus({
          squareIsLoaded: true,
          hasError: false,
          cardIsLoaded: false,
        });
      };
      window.document.body.appendChild(script);
    }
  }, [loadingStatus]);

  const cardCallback = useCallback(async () => {
    if (
      loadingStatus.squareIsLoaded &&
      !loadingStatus.hasError &&
      !loadingStatus.cardIsLoaded
    ) {
      try {
        const payments = window.Square.payments(
          SQUARE_APP_ID,
          SQUARE_LOCATION_ID
        );

        const card = await payments.card({
          style: LASAGNA_STYLES,
        });

        cardRef.current = card;

        await card.attach(cardContainerRef.current);

        setLoadingStatus({
          squareIsLoaded: true,
          hasError: false,
          cardIsLoaded: true,
        });
      } catch (err) {
        console.error(err);
        setLoadingStatus({
          squareIsLoaded: true,
          hasError: true,
          cardIsLoaded: false,
        });
      }
    }
  }, [loadingStatus]);

  useEffect(() => {
    cardCallback();
  }, [cardCallback]);

  const submitPayment = useCallback(async () => {
    try {
      setSubmitting(true);

      const token = await tokenize(cardRef.current);

      const data = await fetcher<{
        amountPaid: number;
        amountOwed: number;
      }>({
        url: `${API_ENDPOINT}/collections/${collectionId}/payment`,
        method: "POST",
        body: {
          locationId: SQUARE_LOCATION_ID,
          sourceId: token,
        },
      });

      if (!data) {
        throw new Error("Payment could not be processed");
      }

      collectionDispatch((current) => {
        if (!current) {
          return;
        }
        return {
          ...current,
          [collectionId]: {
            ...current[collectionId],
            amountPaid: data.amountPaid,
          },
        };
      });

      setSnackbarMessage("Payment received successfully!");
      setShowPinningForm(true);
    } catch (err) {
      console.error(err);
      setSubmitting(false);
      setSnackbarMessage(
        "Payment could not be processed - check for errors",
        "error"
      );
    }
  }, [
    setSnackbarMessage,
    setShowPinningForm,
    collectionDispatch,
    collectionId,
    fetcher,
  ]);

  return {
    cardContainerRef,
    loadingStatus,
    submitting,
    submitPayment,
  };
}

async function tokenize(card: any) {
  if (!card) {
    throw new Error("Invalid card");
  }

  console.log(card);

  const tokenResult = await card.tokenize();

  if (tokenResult.status === "OK") {
    return tokenResult.token;
  } else {
    throw new Error(
      `Tokenization failed with status: ${
        tokenResult.status
      } and errors ${JSON.stringify(tokenResult.errors)}`
    );
  }
}
