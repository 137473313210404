import React from "react";
import cloneDeep from "lodash/cloneDeep";
import {
  generateTokenSetKey,
  calculateTokenName,
  calculateTokenDescription,
} from "lasagna-commons";
import type { AssetCounts } from "lasagna-commons";
import ChevronLeftIcon from "../../../../icons/ChevronLeft";
import Button from "../../../../components/Button";
import FullScreenModal from "../../../../components/FullScreenModal";
import EditModalLayers from "./EditModalLayers";
import { useTokenDispatch } from "../../../../components/TokenContext";
import { UPDATE_TOKEN, useTemporaryCollection } from "./hooks";
import { useSnackbarDispatch } from "../../../../components/SnackbarContext";
import type { TokenLayerMap } from "./types";
import type { TokenWithRarityRank } from "../../../../types";
import { API_ENDPOINT } from "../../../../config";
import { calculateRarityScore } from "../../../../utils";
import { useCurrentCollection } from "../../../../components/CollectionContext/hooks";
import { useAssets } from "../../../../components/AssetContext/hooks";
import TextInput from "../../../../components/Form/TextInput";
import { TOKEN_DESCRIPTION_TOOLTIP } from "../../../../constants";
import Check from "../../../../icons/Check";
import SaveTokenButton from "./SaveTokenButton";
import PreviewMode from "./PreviewMode";
import SimilarTokens from "./SimilarTokens";
import QuicklyViewButtons from "./QuicklyViewButtons";
import type { GridStyleOption, TokenFilterProps } from "../../types";
import { TOKEN_PAGE_EDIT_MODAL_GUTTER } from "../../../../constants/tokenPageConstants";
import DownloadButton from "./DownloadButton";
import CodeBrackets from "../../../../icons/CodeBrackets";
import Metadata from "./Metadata";
import AssetImageStackWithFallback from "../../../../components/AssetImage/AssetImageStackWithFallback";
import { GRID_STYLE_IMAGE_SIZE_MAP } from "../constants";

const EditModal: React.FC<{
  onClose: () => void;
  token: TokenWithRarityRank;
  tokens: TokenWithRarityRank[];
  tokenSet: Set<string>;
  assetCounts: AssetCounts;
  aspectRatio: number;
  isReadOnly?: boolean;
  setTokenFilters: React.Dispatch<
    React.SetStateAction<TokenFilterProps["tokenFilters"]>
  >;
  gridStyle: GridStyleOption;
}> = ({
  onClose,
  token,
  tokenSet: initialTokenSet,
  aspectRatio,
  assetCounts,
  isReadOnly,
  tokens,
  setTokenFilters,
  gridStyle,
}) => {
  const [searchKey, setSearchKey] = React.useState<string | null>(null);
  const [showMetadata, setShowMetadata] = React.useState(false);
  const collection = useCurrentCollection();
  const [description, setDescription] = React.useState(() =>
    calculateTokenDescription({
      tokenDescription: token.description,
      collectionDescription: collection.description,
    })
  );
  const { layers } = useAssets();
  const setMessage = useSnackbarDispatch();

  const initializer = React.useCallback(
    () => ({
      tokenLayers: [...token.tokenLayers],
      tokenSet: new Set(initialTokenSet),
      assetCounts: { ...assetCounts },
      saved: Boolean(token.saved),
    }),
    [token, initialTokenSet, assetCounts]
  );

  const [tempCollectionState, tempCollectionDispatch] =
    useTemporaryCollection(initializer);

  const [itemOrder, setItemOrder] = React.useState<string[]>(() =>
    tempCollectionState.tokenLayers.map(({ layerId }) => layerId)
  );

  const tokenLayerMap = React.useMemo<TokenLayerMap>(() => {
    return tempCollectionState.tokenLayers.reduce<TokenLayerMap>((a, b) => {
      a[b.layerId] = b;
      return a;
    }, {});
  }, [tempCollectionState.tokenLayers]);

  const tokenDispatch = useTokenDispatch();

  const handleUpdateClick = () => {
    const newKey = generateTokenSetKey(tempCollectionState.tokenLayers);
    if (
      initialTokenSet.has(newKey) &&
      newKey !== generateTokenSetKey(token.tokenLayers)
    ) {
      setMessage("A token with this combination already exists", "error");
    } else {
      tokenDispatch((current) => {
        if (!current) {
          return;
        }

        const tokenState = cloneDeep(current);
        tokenState.meta.tokenSet = tempCollectionState.tokenSet;
        tokenState.meta.assetCounts = tempCollectionState.assetCounts;
        const tokenIndex = token.id - collection.tokenIdStartsAt;

        const updatedToken = {
          ...tokenState.tokens[tokenIndex],
          tokenLayers: itemOrder.map((key) => {
            return tokenLayerMap[key];
          }),
          description:
            description === collection.description ? undefined : description,
          saved: tempCollectionState.saved,
        };

        updatedToken.rarityScore = calculateRarityScore({
          token: updatedToken,
          assetCounts: tempCollectionState.assetCounts,
          tokenCount: tokenState.tokens.length,
        });

        tokenState.tokens[tokenIndex] = updatedToken;
        tokenState.updateStack.push({
          url: `${API_ENDPOINT}/collections/${collection.id}/tokens`,
          method: "PUT",
          body: {
            token: {
              id: updatedToken.id,
              tokenLayers: updatedToken.tokenLayers,
              description: updatedToken.description,
              saved: updatedToken.saved,
            },
          },
        });
        return tokenState;
      });
      onClose();
    }
  };

  React.useEffect(() => {
    tempCollectionDispatch({
      type: UPDATE_TOKEN,
      payload: { state: initializer() },
    });
  }, [initializer, tempCollectionDispatch]);

  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (searchKey && containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [searchKey]);

  const tokenName = calculateTokenName(collection.tokenName, token.id);

  const toggleMetadata = () => {
    setShowMetadata((val) => !val);
  };

  return (
    <FullScreenModal className="flex" onClose={onClose}>
      {isReadOnly ? (
        <>
          <PreviewMode
            aspectRatio={aspectRatio}
            itemOrder={itemOrder}
            saved={tempCollectionState.saved}
            description={description}
            tokenLayerMap={tokenLayerMap}
            token={token}
            tokenName={tokenName}
            layers={layers}
            onClose={onClose}
          >
            <QuicklyViewButtons
              layers={layers}
              tokenLayers={token.tokenLayers}
              setTokenFilters={setTokenFilters}
              onClose={onClose}
            />
            <SimilarTokens
              tempCollectionState={tempCollectionState}
              layers={layers}
              aspectRatio={aspectRatio}
              currentTokenId={token.id}
              tokens={tokens}
            />
          </PreviewMode>
        </>
      ) : (
        <>
          <div className="bg-gray w-1/2 flex items-center justify-center p-20">
            <div className="w-full">
              {showMetadata ? (
                <Metadata
                  id={token.id}
                  description={description}
                  tokenLayers={tempCollectionState.tokenLayers}
                  onClose={toggleMetadata}
                />
              ) : (
                <AssetImageStackWithFallback
                  aspectRatio={1}
                  tokenLayers={itemOrder.map((key) => {
                    return tokenLayerMap[key];
                  })}
                  layers={layers}
                  size="large"
                  fallbackSize={GRID_STYLE_IMAGE_SIZE_MAP[gridStyle]}
                  bgClassName="bg-opacity-0"
                />
              )}
            </div>
          </div>
          <div
            className="w-1/2 flex flex-col"
            style={{
              paddingLeft: TOKEN_PAGE_EDIT_MODAL_GUTTER,
              paddingRight: TOKEN_PAGE_EDIT_MODAL_GUTTER,
            }}
          >
            <div className="py-4">
              <div className="flex items-center justify-between mb-2">
                <span className="text-3xl font-bold truncate">{tokenName}</span>
                {token.rarityRank && (
                  <div className="flex-shrink-0 pl-2 text-right">
                    <span className="block font-secondary opacity-80 text-xs">
                      Rarity rank
                    </span>
                    <span>#{token.rarityRank}</span>
                  </div>
                )}
              </div>
              <div>
                <TextInput
                  label={{
                    text: "Token description",
                    tooltipText: TOKEN_DESCRIPTION_TOOLTIP,
                    tooltipPlacement: "right",
                    tooltipContentBackgroundColor: "bg-gray",
                  }}
                  value={description}
                  setValue={setDescription}
                  name="description"
                  componentType="textarea"
                />
              </div>
              <div className="mt-4 mb-1 pl-1">
                <SaveTokenButton
                  tempCollectionState={tempCollectionState}
                  tempCollectionDispatch={tempCollectionDispatch}
                />
              </div>
            </div>
            <div className="flex-grow overflow-auto" ref={containerRef}>
              <EditModalLayers
                itemOrder={itemOrder}
                setItemOrder={setItemOrder}
                tokenLayerMap={tokenLayerMap}
                tempCollectionState={tempCollectionState}
                tempCollectionDispatch={tempCollectionDispatch}
                aspectRatio={aspectRatio}
                layers={layers}
                searchKey={searchKey}
                setSearchKey={setSearchKey}
              />
              <div className={searchKey ? "hidden" : ""}>
                <QuicklyViewButtons
                  layers={layers}
                  tokenLayers={token.tokenLayers}
                  setTokenFilters={setTokenFilters}
                  onClose={onClose}
                />
                <SimilarTokens
                  tempCollectionState={tempCollectionState}
                  layers={layers}
                  aspectRatio={aspectRatio}
                  currentTokenId={token.id}
                  tokens={tokens}
                />
              </div>
            </div>
            <div className="flex justify-between items-center py-4">
              <div>
                <Button onClick={onClose} variant="gray">
                  <ChevronLeftIcon />
                  <span className="ml-2">Back</span>
                </Button>
              </div>
              <div className="flex items-center">
                <div className="mr-6">
                  <Button variant="gray" onClick={toggleMetadata}>
                    <CodeBrackets />
                    <span className="ml-2">Metadata</span>
                  </Button>
                </div>
                <div className="mr-6">
                  <DownloadButton
                    tokenId={token.id}
                    tokenLayers={tempCollectionState.tokenLayers}
                  />
                </div>
                <div>
                  <Button onClick={handleUpdateClick}>
                    <Check size={20} />
                    <span className="mx-2">Update</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </FullScreenModal>
  );
};

export default EditModal;
