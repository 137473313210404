import React from "react";
import DefaultNavbar from "./Navbar/DefaultNavbar";
import { MIN_SCREEN_WIDTH } from "../constants";

const PageWrapper: React.FC<{
  navbar?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
}> = ({ navbar = <DefaultNavbar />, children, footer }) => {
  return (
    <div className="h-full" style={{ minWidth: MIN_SCREEN_WIDTH }}>
      {navbar}
      {children}
      {footer}
    </div>
  );
};

export default PageWrapper;
