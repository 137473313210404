import React from "react";
import { AssetImageProps } from "./types";

const AssetImage: React.FC<AssetImageProps> = ({ asset, size, zIndex }) => {
  return (
    <div
      className="absolute inset-0 flex items-center justify-center"
      style={{ zIndex }}
    >
      <img
        src={asset.urls[size] || asset.urls.default}
        alt={asset.label}
        className="object-center object-contain w-full h-full"
        loading="lazy"
      />
    </div>
  );
};

export default React.memo(AssetImage);
