import React from "react";
import {
  AssetWithUrls,
  getLayerIdFromAssetKey,
  LayerWithAssets,
  RecipeType,
} from "lasagna-commons";
import SearchInput from "../../../components/Form/SearchInput";
import { CAROUSEL_ITEM_STYLES } from "../../../constants";
import { searchFilter, sortByLabel } from "../../../utils";
import RecipeModalListItem from "./RecipeModalListItem";
import type { RecipeAssetKey } from "../types";
import Checkbox from "../../../components/Form/Checkbox";
import PuzzlePieceIcon from "../../../icons/PuzzlePiece";
import {
  RECIPE_PAGE_LAYER_HEADER_HEIGHT,
  RECIPE_PAGE_LAYER_TOTAL_HEIGHT,
  RECIPE_PAGE_LAYER_WIDTH,
  RECIPE_PAGE_LIST_ITEM_HEIGHT,
} from "../../../constants/recipePageConstants";
import { Virtuoso } from "react-virtuoso";

const RecipeModalLayer: React.FC<{
  layer: LayerWithAssets;
  viewportHeight: number;
  currentAssetKeys: RecipeAssetKey[];
  addAsset: (assetKey: string) => void;
  removeAsset: (index: number) => void;
  addAllAssetsForLayer: (layerId: string, assets: AssetWithUrls[]) => void;
  removeAllAssetsForLayer: (layerId: string) => void;
  disabled: boolean;
  recipeType: RecipeType | undefined | null;
}> = ({
  layer,
  viewportHeight,
  addAsset,
  removeAsset,
  addAllAssetsForLayer,
  removeAllAssetsForLayer,
  currentAssetKeys,
  disabled,
  recipeType,
}) => {
  const [searchValue, setSearchValue] = React.useState("");

  const filteredAssets = React.useMemo(
    () =>
      Object.values(layer.assets)
        .filter(({ label }) => searchFilter(label, searchValue))
        .sort(sortByLabel),
    [layer, searchValue]
  );

  const layerKeyCounts = currentAssetKeys.reduce((a, b) => {
    if (b && getLayerIdFromAssetKey(b) === layer.id) {
      a++;
    }
    return a;
  }, 0);

  const allSelected = layerKeyCounts === Object.keys(layer.assets).length;

  const handleClick = () => {
    if (allSelected) {
      removeAllAssetsForLayer(layer.id);
    } else {
      addAllAssetsForLayer(layer.id, Object.values(layer.assets));
    }
  };

  const height = viewportHeight - RECIPE_PAGE_LAYER_TOTAL_HEIGHT - 32;

  return (
    <div
      className={`${CAROUSEL_ITEM_STYLES} ${
        allSelected
          ? "bg-primary shadow-layer-primary text-black"
          : "bg-gray shadow-layer-gray"
      }`}
      style={{ width: RECIPE_PAGE_LAYER_WIDTH }}
    >
      <div
        className="px-4 border-b-2 border-black flex flex-col justify-center"
        style={{ height: RECIPE_PAGE_LAYER_HEADER_HEIGHT }}
      >
        <div className="flex items-center justify-between mb-4">
          <h5 className="truncate text-lg">{layer.label}</h5>
          <div className="flex-shrink-0">
            <button
              className="flex items-center disabled:opacity-50 disabled:pointer-events-none"
              disabled={disabled || recipeType === "always"}
              onClick={handleClick}
            >
              <Checkbox checked={allSelected} variant="secondary" />
            </button>
          </div>
        </div>
        <div className="flex items-center">
          <div
            className={`flex-shrink-0 flex items-center w-16 ${
              allSelected ? "" : "opacity-60"
            }`}
          >
            <PuzzlePieceIcon />
            <span className="ml-2">{Object.keys(layer.assets).length}</span>
          </div>
          <div className="flex-grow-1 w-full">
            <SearchInput
              name={`layer-search-${layer.id}`}
              placeholder="Search"
              value={searchValue}
              setValue={setSearchValue}
              isDark={allSelected}
            />
          </div>
        </div>
      </div>
      <Virtuoso
        style={{ height }}
        totalCount={filteredAssets.length}
        defaultItemHeight={RECIPE_PAGE_LIST_ITEM_HEIGHT}
        itemContent={(index) => (
          <RecipeModalListItem
            asset={filteredAssets[index]}
            layerId={layer.id}
            currentAssetKeys={currentAssetKeys}
            addAsset={addAsset}
            removeAsset={removeAsset}
            disabled={disabled}
          />
        )}
      />
    </div>
  );
};

export default RecipeModalLayer;
