import React from "react";
import { LOCAL_STORAGE_EMAIL_KEY } from "../../constants";
import EmailForm from "./EmailForm";

const initialEmail = window.localStorage.getItem(LOCAL_STORAGE_EMAIL_KEY) || "";

const EmailSection: React.FC = () => {
  return (
    <div className="flex flex-col justify-center h-full">
      <div className="w-96 m-auto">
        <h2 className="mb-6">Sign in with email</h2>
        <EmailForm initialValue={initialEmail} />
        <p className="mt-10 text-xs opacity-40">
          Some features are limited when an email is used to log in. For full
          functionality, connect your wallet.
        </p>
      </div>
    </div>
  );
};

export default EmailSection;
