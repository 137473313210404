import React, { useState, useEffect, useMemo } from "react";
import { useSpring } from "react-spring";
import type { SpringValue } from "react-spring";
import type { AssetErrorMessage } from "../../types";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "../../config";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";
import { getCollectionId } from "..";
import { useAuth } from "../../components/AuthContext";

export function usePortalAnimation(
  open: boolean,
  onExited?: () => void
): {
  mounted: boolean;
  opacity: SpringValue<number>;
  scale: SpringValue<number>;
  translate: SpringValue<number>;
} {
  const [mounted, setMounted] = useState(false);
  const { opacity, scale, translate } = useSpring({
    opacity: open ? 1 : 0,
    scale: open ? 1 : 0.95,
    translate: open ? 0 : 100,
    onStart: () => {
      setMounted(true);
    },
    onRest: () => {
      setMounted(open);
      if (!open && onExited) {
        onExited();
      }
    },
    config: { duration: 100 },
  });

  return { mounted: open || mounted, opacity, scale, translate };
}

export function useTargetElement() {
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);

  return useMemo(() => {
    return {
      targetElement,
      open(e: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>) {
        setTargetElement(e.currentTarget);
      },
      close() {
        setTargetElement(null);
      },
    };
  }, [targetElement]);
}

export function useAssetErrors() {
  return useState<AssetErrorMessage[]>([]);
}

export function useProjectNavItems() {
  const collectionId = getCollectionId();
  const { name, preview } = useCurrentCollection();
  const { user } = useAuth();

  const navItems = useMemo(() => {
    const items = [
      {
        label: (
          <span className="whitespace-nowrap">
            {name}{" "}
            {preview ? (
              <span className="uppercase text-xs opacity-60">(shared)</span>
            ) : null}
          </span>
        ),
        href: `/collections/${collectionId}`,
      },
      {
        label: "Assets",
        href: `/collections/${collectionId}/assets`,
      },
      {
        label: "Recipes",
        href: `/collections/${collectionId}/recipes`,
      },
      {
        label: "Tokens",
        href: `/collections/${collectionId}/tokens`,
      },
    ];
    if (!preview && user?.address) {
      items.push({
        label: "Launch",
        href: `/collections/${collectionId}/launch`,
      });
    }
    return items;
  }, [preview, name, collectionId, user]);

  return navItems;
}

export function useRecaptcha():
  | (() => Promise<{ grecaptcha: string }>)
  | undefined {
  const [loaded, setLoaded] = useState(Boolean(window.grecaptcha));

  useEffect(() => {
    if (!loaded) {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_SITE_KEY}`;
      document.body.appendChild(script);
      script.onload = () => {
        setLoaded(true);
      };
    }
  }, [loaded]);

  return useMemo(() => {
    if (loaded) {
      return async () => {
        const grecaptcha = await window.grecaptcha.execute(
          GOOGLE_RECAPTCHA_SITE_KEY,
          { action: "submit" }
        );
        return { grecaptcha };
      };
    }
    return undefined;
  }, [loaded]);
}
