import { Collection } from "lasagna-commons";
import React from "react";
import { useCollectionDispatch } from "../../../components/CollectionContext";
import { useCurrentCollection } from "../../../components/CollectionContext/hooks";
import { API_ENDPOINT } from "../../../config";
import useCustomFetch from "../../../utils/hooks/useCustomFetch";
import ProgressItem from "../../../components/Progress/ProgressItem";
import type { ProgressItemStage } from "../../../components/Progress/types";
import finalizingAnimationData from "../../../components/LottieIcon/animations/finalizing-tokens.json";
import zippingAnimationData from "../../../components/LottieIcon/animations/zip-collection.json";
import ProgressFooter from "./ProgressFooter";
import Divider from "../../../components/Divider";

const ProgressSection: React.FC<{
  drawerOpen: boolean;
  closeDrawer: () => void;
}> = ({ drawerOpen, closeDrawer }) => {
  const [shouldClearInterval, setShouldClearInterval] = React.useState(false);
  const interval = React.useRef<number>();

  const collectionDispatch = useCollectionDispatch();

  const collection = useCurrentCollection();
  const collectionId = collection.id;

  const fetcher = useCustomFetch();

  const callback = React.useCallback(async () => {
    const data = await fetcher<{ collection: Collection }>({
      url: `${API_ENDPOINT}/collections/${collectionId}`,
      method: "GET",
    });

    if (data) {
      collectionDispatch((current) => {
        if (!current) {
          return;
        }

        return {
          ...current,
          [collectionId]: data.collection,
        };
      });

      if (
        data.collection.hasAllTokenZipFiles &&
        data.collection.hasMetadataZipFile
      ) {
        setShouldClearInterval(true);
      }
    } else {
      setShouldClearInterval(true);
    }
  }, [collectionDispatch, fetcher, collectionId]);

  React.useEffect(() => {
    if (drawerOpen) {
      interval.current = window.setInterval(callback, 3000);
      return () => {
        window.clearInterval(interval.current);
      };
    }
  }, [callback, drawerOpen]);

  React.useEffect(() => {
    if (interval.current !== undefined && shouldClearInterval) {
      window.clearInterval(interval.current);
    }
  }, [shouldClearInterval]);

  const progressData = React.useMemo<
    Record<string, { progress: number; stage: ProgressItemStage }>
  >(() => {
    const {
      printedTokenCount,
      tokenZipFileProgress = 0,
      pinnedTokenCount,
      tokenCount,
      pinTokens,
    } = collection;

    const metadataProgressIndicator = pinTokens
      ? pinnedTokenCount
      : printedTokenCount;

    return {
      tokens: {
        progress: metadataProgressIndicator,
        stage:
          metadataProgressIndicator === tokenCount ? "finished" : "inProgress",
      },
      metadata: {
        progress: tokenZipFileProgress,
        stage: metadataProgressIndicator === tokenCount ? "inProgress" : "idle",
      },
    };
  }, [collection]);

  return (
    <div className="pb-16">
      <ProgressItem
        step={1}
        animationData={finalizingAnimationData}
        headings={{
          idle: "Finalize your tokens",
          inProgress: "Finalizing your tokens",
          finished: "Your tokens are finalized",
        }}
        description={{
          top: `We're rendering all images for your collection${
            collection.pinTokens ? " and pinning them to IPFS" : ""
          }.`,
          bottom: "This could take a while.",
        }}
        stage={progressData.tokens.stage}
        progress={{
          numerator: progressData.tokens.progress,
          denominator: collection.tokenCount,
        }}
      />
      <Divider className="my-8" />
      <ProgressItem
        step={2}
        animationData={zippingAnimationData}
        headings={{
          idle: "Package your collection",
          inProgress: "Zipping your collection",
          finished: "Your collection is ready",
        }}
        description={{
          top: "We're packaging your images and metadata.",
          bottom: "This could take a few minutes.",
        }}
        stage={progressData.metadata.stage}
        progress={{
          numerator: progressData.metadata.progress,
          denominator: collection.tokenCount,
        }}
      />
      <ProgressFooter closeDrawer={closeDrawer} />
    </div>
  );
};

export default ProgressSection;
