import React from "react";
import SimilarTokensList from "./SimilarTokensList";
import { SimilarTokensProps } from "./types";

const SimilarTokens: React.FC<SimilarTokensProps> = (props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  React.useEffect(() => {
    if (window.IntersectionObserver && ref.current && !isIntersecting) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
        }
      });

      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isIntersecting]);

  return (
    <div ref={ref}>{isIntersecting && <SimilarTokensList {...props} />}</div>
  );
};

export default SimilarTokens;
