import React from "react";
import type { Preview, PreviewRole } from "lasagna-commons";
import Button from "../../../components/Button";
import IconButton from "../../../components/Button/IconButton";
import TextInput from "../../../components/Form/TextInput";
import LoadingIcon from "../../../components/LoadingIcon";
import { API_ENDPOINT } from "../../../config";
import CheckIcon from "../../../icons/Check";
import Plus from "../../../icons/Plus";
import TrashIcon from "../../../icons/Trash";
import { getCollectionId, pluralize } from "../../../utils";
import useCustomFetch from "../../../utils/hooks/useCustomFetch";
import LottieIcon from "../../../components/LottieIcon";
import animationData from "../../../components/LottieIcon/animations/share-preview.json";
import { useSnackbarDispatch } from "../../../components/SnackbarContext";
import CopyIcon from "../../../icons/Copy";

const WALLET_LENGTH = 42;
const ROLES: { value: PreviewRole; label: string }[] = [
  { value: "view", label: "View" },
  { value: "edit", label: "Edit" },
];

interface ClientPreview {
  role: PreviewRole;
  address: string;
}

const ShareForm: React.FC<{ open: boolean }> = ({ open }) => {
  const [value, setValue] = React.useState("");
  const [touched, setTouched] = React.useState(false);
  const [error, setError] = React.useState("");
  const [previews, setPreviews] = React.useState<ClientPreview[] | null>(null);

  const collectionId = getCollectionId();
  const fetcher = useCustomFetch();
  const setSnackbarMessage = useSnackbarDispatch();

  const handleBlur = () => {
    setTouched(true);
  };

  const previewUrl = `https://${window.location.host}/collections/${collectionId}/tokens`;

  const copyUrl = async () => {
    await window.navigator.clipboard.writeText(previewUrl);
    setSnackbarMessage("URL copied to clipboard");
  };

  const callback = React.useCallback(async () => {
    const data = await fetcher<{ previews: Preview[] }>({
      url: `${API_ENDPOINT}/previews/${collectionId}`,
      method: "GET",
    });

    if (data) {
      setPreviews(data.previews);
    }
  }, [fetcher, collectionId]);

  React.useEffect(() => {
    if (open) {
      callback();
    }
  }, [open, callback]);

  React.useEffect(() => {
    if (value.length !== WALLET_LENGTH) {
      setError(`Wallet address should be ${WALLET_LENGTH} characters`);
    } else if (previews && previews.find(({ address }) => address === value)) {
      setError("Wallet address already exists in preview list");
    } else {
      setError("");
    }
  }, [value, previews]);

  if (previews === null) {
    return (
      <div>
        <LoadingIcon />
      </div>
    );
  }

  const removeWallet = (index: number) => {
    const newPreviews = [...previews];
    const previewToDelete = newPreviews[index];
    newPreviews.splice(index, 1);
    setPreviews(newPreviews);

    fetcher({
      url: `${API_ENDPOINT}/previews`,
      method: "DELETE",
      body: {
        preview: { collectionId, address: previewToDelete.address },
      },
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setTouched(true);

    if (error) {
      return;
    }

    const newPreview: ClientPreview = { address: value, role: "view" };

    fetcher({
      url: `${API_ENDPOINT}/previews`,
      method: "POST",
      body: { preview: { collectionId, ...newPreview } },
    });

    setPreviews([...previews, newPreview]);

    setValue("");
    setError("");
    setTouched(false);
  };

  return (
    <div>
      <LottieIcon animationData={animationData} />
      <h2>Share access</h2>
      <p className="opacity-60 mb-6">
        Collaborate by sharing view-only or full-edit access to your collection
        with others. Collaborators will see this collection listed on their home
        page.
      </p>
      <div className="flex justify-between items-center bg-gray shadow-layer-gray px-4 py-3 mb-6">
        <div className="overflow-hidden">
          <span className="block font-secondary gradient-text py-1 text-xs">
            Share URL
          </span>
          <span className="block truncate text-sm">{previewUrl}</span>
        </div>
        <div className="flex-shrink-0 ml-4">
          <Button size="small" onClick={copyUrl}>
            <CopyIcon />
            <span className="ml-2">Copy</span>
          </Button>
        </div>
      </div>
      <h5 className="text-xl mb-4">Allow wallets to access</h5>
      <form onSubmit={handleSubmit} action="#">
        <TextInput
          value={value}
          setValue={setValue}
          name="wallet"
          label={{
            text: "Enter wallet address",
          }}
          placeholder="E.g. 0xabcdefghijklmnopqrstuvwqyz1234567890ABCD"
          onBlur={handleBlur}
          error={touched ? error : undefined}
          addErrorGutter
          spellCheck={false}
        />
        <div className="mt-4">
          <Button fullWidth size="small" type="submit">
            <Plus size={14} />
            <span className="ml-3">Add wallet to access list</span>
          </Button>
        </div>
      </form>
      {previews.length > 0 && (
        <div className="mt-8">
          <h5 className="text-xl mb-4">
            {pluralize(previews.length, "wallet")} added to preview list
          </h5>
          <ul className="mb-3">
            {previews.map(({ address, role }, index) => {
              const deleteWallet = () => {
                removeWallet(index);
              };

              return (
                <li key={address} className="mb-3">
                  <div className="bg-gray shadow-layer-gray flex items-center justify-between p-3">
                    <span className="block truncate text-sm">{address}</span>
                    <div className="flex items-center flex-shrink-0 ml-2">
                      <div className="flex items-center mr-2">
                        {ROLES.map(({ value, label }) => {
                          const isActive = value === role;

                          const handleClick = () => {
                            const updatedPreview: ClientPreview = {
                              address,
                              role: value,
                            };

                            const newPreviews = [...previews];
                            newPreviews[index] = updatedPreview;
                            setPreviews(newPreviews);

                            fetcher({
                              url: `${API_ENDPOINT}/previews`,
                              method: "POST",
                              body: {
                                preview: {
                                  collectionId,
                                  ...updatedPreview,
                                },
                              },
                            });
                          };

                          return (
                            <RoleButton
                              isActive={isActive}
                              onClick={handleClick}
                            >
                              {label}
                            </RoleButton>
                          );
                        })}
                      </div>
                      <div className="flex">
                        <IconButton onClick={deleteWallet}>
                          <TrashIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

function RoleButton({
  onClick,
  isActive,
  children,
}: {
  onClick: () => void;
  isActive: boolean;
  children: string;
}) {
  return (
    <button
      className={`text-sm font-secondary w-20 py-0.5 inline-flex items-center justify-center border-2 border-black cursor-pointer ${
        isActive ? "bg-primary text-black" : "bg-black text-white"
      }`}
      onClick={onClick}
    >
      {isActive && (
        <span className="flex mr-1">
          <CheckIcon size={14} />
        </span>
      )}
      <span>{children}</span>
    </button>
  );
}

export default ShareForm;
