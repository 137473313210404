import React from "react";
import { Ethereum } from "@thirdweb-dev/chains";
import { createCtx } from "../../utils";

const [useActiveChain, ActiveChainContextProvider] =
  createCtx<[number, React.Dispatch<React.SetStateAction<number>>]>();

const ActiveChainProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const value = React.useState<number>(Ethereum.chainId);

  return (
    <ActiveChainContextProvider value={value}>
      {children}
    </ActiveChainContextProvider>
  );
};

export { useActiveChain, ActiveChainProvider };
