import React from "react";
import Button from "../../../components/Button";
import Drawer from "../../../components/Drawer";
import { useCurrentCollection } from "../../../components/CollectionContext/hooks";
import PreheatingSection from "./PreheatingSection";
import ProgressSection from "./ProgressSection";
import ChevronRight from "../../../icons/ChevronRight";
import DownloadReadySection from "./DownloadReadySection";
import PinningForm from "./PinningForm";
import CollectionErrorSection from "../../../components/CollectionErrorSection";
import { calculateAmountOwed } from "lasagna-commons";
import useCustomFetch from "../../../utils/hooks/useCustomFetch";
import { API_ENDPOINT } from "../../../config";
import type { PaymentFormData } from "./types";
import PaymentSection from "./PaymentSection";

const DownloadDrawer: React.FC<{ label: string }> = ({ label }) => {
  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const [paymentFormData, setPaymentFormData] =
    React.useState<PaymentFormData | null>(null);

  const collection = useCurrentCollection();

  const [printing, setPrinting] = React.useState(() =>
    Boolean(collection.printedTokenCount > 0)
  );

  const [showPinningForm, setShowPinningForm] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const fetcher = useCustomFetch();

  const renderContent = () => {
    if (collection.collectionError) {
      return (
        <CollectionErrorSection
          collectionError={collection.collectionError}
          collection={collection}
        />
      );
    }

    if (collection.hasAllTokenZipFiles && collection.hasMetadataZipFile) {
      return <DownloadReadySection drawerOpen={open} />;
    }

    if (printing) {
      return <ProgressSection drawerOpen={open} closeDrawer={closeDrawer} />;
    }

    if (showPinningForm) {
      return <PinningForm setPrinting={setPrinting} collection={collection} />;
    }

    if (paymentFormData) {
      return (
        <PaymentSection
          paymentFormData={paymentFormData}
          collection={collection}
          tokenCount={collection.tokenCount}
          setShowPinningForm={setShowPinningForm}
          setPaymentFormData={setPaymentFormData}
        />
      );
    }

    const amountOwed = calculateAmountOwed(
      collection.tokenCount,
      collection.amountPaid
    );

    const handleSubmit =
      amountOwed > 0
        ? async (e: React.FormEvent) => {
            e.preventDefault();

            setSubmitting(true);

            const data = await fetcher<PaymentFormData>({
              url: `${API_ENDPOINT}/collections/${collection.id}/payment-intent`,
              method: "POST",
            });

            if (data) {
              setPaymentFormData(data);
            } else {
              setSubmitting(false);
            }
          }
        : async (e: React.FormEvent) => {
            e.preventDefault();
            setShowPinningForm(true);
          };

    return (
      <PreheatingSection onSubmit={handleSubmit} submitting={submitting} />
    );
  };

  return (
    <>
      <Button onClick={handleOpen} fullWidth>
        <span className="mr-2.5">{label}</span>
        <ChevronRight size="large" />
      </Button>
      <Drawer open={open} onClose={closeDrawer}>
        {renderContent()}
      </Drawer>
    </>
  );
};

export default DownloadDrawer;
