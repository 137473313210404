import { ADMIN_HTTP_HEADER } from "lasagna-commons";
import React from "react";
import { Link } from "react-router-dom";
import LockIcon from "../../icons/Lock";
import IconButton from "../Button/IconButton";
import Drawer from "../Drawer";
import TextInput from "../Form/TextInput";

window.adminUserId = window.localStorage.getItem(ADMIN_HTTP_HEADER) || "";

const AdminUserDrawer: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(() => window.adminUserId);

  React.useEffect(() => {
    window.adminUserId = value;
    window.localStorage.setItem(ADMIN_HTTP_HEADER, value);
  }, [value]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="ml-4">
        <IconButton
          onClick={handleOpen}
          className={value ? "text-primary opacity-100" : ""}
        >
          <LockIcon />
        </IconButton>
      </div>
      <Drawer open={open} onClose={handleClose}>
        <div>
          <h2>Assume User ID</h2>
          <p className="opacity-60 mb-6">
            Enter a user ID to assume that user's identity. You'll need to
            refresh the page after updating.
          </p>
          <TextInput
            name="adminUser"
            label={{ text: "User ID" }}
            value={value}
            setValue={setValue}
          />
          <div className="mt-8 text-center text-lg">
            <Link
              to="/admin"
              className="text-primary hover:text-secondary underline font-secondary"
            >
              View admin page
            </Link>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default AdminUserDrawer;
