import { useAuth } from ".";

export function useCurrentUser() {
  const { user } = useAuth();

  if (!user) {
    throw new Error("User is not authenticated");
  }

  return user;
}
