import React from "react";
import type { FetchHandlerParams } from "../../types";
import useCustomFetch from "./useCustomFetch";

export default function useUpdateStack(
  updateStack: FetchHandlerParams[] | undefined,
  callback: (updateStack: FetchHandlerParams[]) => void
) {
  const fetcher = useCustomFetch();

  const processUpdates = React.useCallback(async () => {
    if (updateStack && updateStack.length > 0) {
      const args = updateStack[0];

      const data = await fetcher(args);

      if (data) {
        callback(updateStack.slice(1));
      }
    }
  }, [updateStack, callback, fetcher]);

  React.useEffect(() => {
    processUpdates();
  }, [processUpdates]);
}
