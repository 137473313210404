import React from "react";
import AssetImageContainer from "../../../components/AssetImage/AssetImageContainer";
import AssetImage from "../../../components/AssetImage";
import IconButton from "../../../components/Button/IconButton";
import TrashIcon from "../../../icons/Trash";
import type { RecipeAssetMap } from "../types";
import { AssetWithUrls } from "lasagna-commons";

const RecipeModalFooterItem: React.FC<{
  recipeAssetMap: RecipeAssetMap;
  onDelete: () => void;
  assetKeys: string[];
}> = ({ recipeAssetMap, onDelete, assetKeys }) => {
  return (
    <li
      className={`flex items-center mr-6 border-l border-gray-light first:border-transparent`}
    >
      <div className="flex">
        {assetKeys.slice(0, 3).map((assetKey, index) => {
          const asset = recipeAssetMap[assetKey];
          return <ImageContainer asset={asset} index={index} key={assetKey} />;
        })}
      </div>
      <label className="mx-3 font-secondary text-xs tracking-tightest">
        <span className="whitespace-nowrap leading-none opacity-70 block">
          {recipeAssetMap[assetKeys[0]].layerLabel}
        </span>
        <span className="whitespace-nowrap">
          {assetKeys.length > 1
            ? `${assetKeys.length} assets`
            : recipeAssetMap[assetKeys[0]].label}
        </span>
      </label>
      <div className="flex flex-shrink-0">
        <IconButton onClick={onDelete}>
          <TrashIcon />
        </IconButton>
      </div>
    </li>
  );
};

export default RecipeModalFooterItem;

function ImageContainer({
  asset,
  index,
}: {
  asset: AssetWithUrls;
  index: number;
}) {
  return (
    <div
      className={`w-8 bg-gray-light relative ${
        index === 0 ? "" : "shadow-3xl -ml-6 border-l border-gray-light"
      }`}
      style={{ zIndex: index + 1 }}
    >
      <AssetImageContainer aspectRatio={1}>
        <AssetImage asset={asset} size="tiny" />
      </AssetImageContainer>
    </div>
  );
}
