import React, { Suspense } from "react";
import LoadingIcon from "../../components/LoadingIcon";
import PageMainContent from "../../components/PageMainContent";
import PageWrapper from "../../components/PageWrapper";

const AdminMain = React.lazy(() => import("./Main"));

const AdminPage: React.FC = () => {
  return (
    <PageWrapper>
      <PageMainContent addLayoutContainer>
        <Suspense
          fallback={
            <div className="py-4">
              <LoadingIcon />
            </div>
          }
        >
          <AdminMain />
        </Suspense>
      </PageMainContent>
    </PageWrapper>
  );
};

export default AdminPage;
