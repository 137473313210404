import React from "react";
import PageWrapper from "../../components/PageWrapper";
import NoCollections from "./NoCollections";
import LoadingIcon from "../../components/LoadingIcon";
import CollectionCarousel from "./CollectionCarousel";
import { useCollectionsEffect } from "../../utils/hooks/useFetchCollections";
import PageMainContent from "../../components/PageMainContent";

const Home: React.FC = () => {
  const [hasRendered, setHasRendered] = React.useState(false);
  const [demoDrawerOpen, setDemoDrawerOpen] = React.useState(false);
  const collectionState = useCollectionsEffect({ forceRefresh: !hasRendered });
  const loading = !collectionState;
  const hasCollections = !loading && Object.keys(collectionState).length > 0;

  React.useEffect(() => {
    setHasRendered(true);
  }, []);

  if (loading) {
    return (
      <PageWrapper>
        <PageMainContent centerContent>
          <LoadingIcon />
        </PageMainContent>
      </PageWrapper>
    );
  }

  return hasCollections && !demoDrawerOpen ? (
    <CollectionCarousel />
  ) : (
    <NoCollections
      demoDrawerOpen={demoDrawerOpen}
      setDemoDrawerOpen={setDemoDrawerOpen}
    />
  );
};

export default Home;
