import React from "react";
import { EMAIL_REGEX } from "lasagna-commons";
import Button from "../../components/Button";
import TextInput from "../../components/Form/TextInput";
import { useSnackbarDispatch } from "../../components/SnackbarContext";
import { API_ENDPOINT } from "../../config";
import { LOCAL_STORAGE_EMAIL_KEY } from "../../constants";
import ChevronRightIcon from "../../icons/ChevronRight";
import { customFetch, getErrorMessage } from "../../utils";
import { useRecaptcha } from "../../utils/hooks";

const EmailForm: React.FC<{ initialValue?: string }> = ({
  initialValue = "",
}) => {
  const [value, setValue] = React.useState(() => initialValue);
  const [submitting, setSubmitting] = React.useState(false);

  const setSnackbarMessage = useSnackbarDispatch();
  const execute = useRecaptcha();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!EMAIL_REGEX.test(value)) {
      setSnackbarMessage("Please enter a valid email address", "error");
      return;
    }

    if (execute) {
      setSubmitting(true);

      try {
        await customFetch({
          method: "POST",
          url: `${API_ENDPOINT}/auth/identify-email`,
          body: { email: value, ...(await execute()) },
        });

        window.localStorage.setItem(LOCAL_STORAGE_EMAIL_KEY, value);

        setSnackbarMessage("Success! We just sent a magic link to your email.");
      } catch (err) {
        setSnackbarMessage(`Error - ${getErrorMessage(err)}`, "error");
      }
      setSubmitting(false);
    }
  };
  return (
    <form action="#" onSubmit={handleSubmit}>
      <div className="mb-6">
        <TextInput
          value={value}
          setValue={setValue}
          label={{ text: "Email address" }}
          name="email"
          placeholder="garfield@lasagna.xyz"
        />
      </div>
      <div>
        <Button fullWidth size="large" type="submit" disabled={submitting}>
          <span className="mr-3">Send magic link</span>
          <ChevronRightIcon size="large" />
        </Button>
      </div>
    </form>
  );
};

export default EmailForm;
