import React from "react";
import { Link, useLocation } from "react-router-dom";
import NavbarUserInfo from "./NavbarUserInfo";
import Navbar from ".";
import { useProjectNavItems } from "../../utils/hooks";
import LogoLink from "./LogoLink";

interface NavItem {
  label: React.ReactNode;
  href: string;
}

const ProjectNavbar: React.FC<{
  navItems?: NavItem[];
}> = ({ navItems }) => {
  const { pathname } = useLocation();

  return (
    <Navbar>
      <ul className="flex items-center -mx-4 flex-grow">
        <li className="px-4">
          <LogoLink />
        </li>
        {navItems ? (
          navItems.map((item) => (
            <NavItemComponent {...item} key={item.href} pathname={pathname} />
          ))
        ) : (
          <DefaultNavItems pathname={pathname} />
        )}
      </ul>
      <NavbarUserInfo />
    </Navbar>
  );
};

const DefaultNavItems: React.FC<{ pathname: string }> = ({ pathname }) => {
  const items = useProjectNavItems();
  return (
    <>
      {items.map((item) => {
        return (
          <NavItemComponent {...item} key={item.href} pathname={pathname} />
        );
      })}
    </>
  );
};

const NavItemComponent: React.FC<NavItem & { pathname: string }> = ({
  label,
  href,
  pathname,
}) => {
  return (
    <li className="px-4" key={href}>
      <Link
        className={`font-secondary ${
          pathname === href ? "text-primary" : "text-white hover:opacity-80"
        }`}
        to={href}
      >
        {label}
      </Link>
    </li>
  );
};

export default ProjectNavbar;
