import { FOOTER_HEIGHT, NAV_HEIGHT } from "./layoutConstants";

// Default page constants
export const ASSET_DEFAULT_HEADER_HEIGHT = 80;
export const ASSET_DEFAULT_BOTTOM_BUFFER = 8;
export const ASSET_DEFAULT_GRID_TOTAL_HEIGHT =
  NAV_HEIGHT +
  ASSET_DEFAULT_HEADER_HEIGHT * 2 +
  FOOTER_HEIGHT +
  ASSET_DEFAULT_BOTTOM_BUFFER;

// Condensed page constants
export const ASSET_CONDENSED_LAYER_HEADER_HEIGHT = 100;
export const ASSET_CONDENSED_LAYER_FOOTER_HEIGHT = 56;
export const ASSET_CONDENSED_LAYER_WIDTH = 396;
export const ASSET_CONDENSED_BOTTOM_BUFFER = 24; // allows the shadow layer effect to be visible at the bottom
export const ASSET_CONDENSED_LIST_ITEM_HEIGHT = 96;
export const ASSET_CONDENSED_LAYER_TOTAL_HEIGHT =
  NAV_HEIGHT +
  FOOTER_HEIGHT +
  ASSET_CONDENSED_LAYER_HEADER_HEIGHT +
  ASSET_CONDENSED_LAYER_FOOTER_HEIGHT +
  ASSET_CONDENSED_BOTTOM_BUFFER;
