import React from "react";
import { Draggable } from "react-beautiful-dnd";
import ChevronRightIcon from "../../../../icons/ChevronRight";
import DragHandleIcon from "../../../../icons/DragHandle";
import PuzzlePieceIcon from "../../../../icons/PuzzlePiece";

const LayerDraggable: React.FC<{
  layerId: string;
  index: number;
  label: string;
  assetCount: number;
  setActiveLayerId: React.Dispatch<React.SetStateAction<string>>;
  activeLayerId: string;
}> = ({
  layerId,
  index,
  label,
  assetCount,
  setActiveLayerId,
  activeLayerId,
}) => {
  const handleClick = () => {
    setActiveLayerId(layerId);
  };

  const isActive = activeLayerId === layerId;

  return (
    <Draggable index={index} draggableId={layerId}>
      {({ dragHandleProps, draggableProps, innerRef }, { isDragging }) => (
        <div
          ref={innerRef}
          {...draggableProps}
          className={`flex items-center justify-between p-3 mb-3 ${getStyles(
            isActive,
            isDragging
          )}`}
          role="button"
          onClick={handleClick}
        >
          <div className="flex items-center overflow-hidden pr-2">
            <span className="flex mr-3 flex-shrink-0" {...dragHandleProps}>
              <DragHandleIcon />
            </span>
            <span className="flex-grow truncate">{label}</span>
          </div>
          <div className="flex items-center flex-shrink-0">
            <span>{assetCount}</span>
            <span className="flex ml-3 mr-4">
              <PuzzlePieceIcon />
            </span>
            <ChevronRightIcon size="large" />
          </div>
        </div>
      )}
    </Draggable>
  );
};

function getStyles(isActive: boolean, isDragging: boolean) {
  if (isActive) {
    return isDragging
      ? "bg-secondary shadow-layer-secondary text-black"
      : "bg-primary shadow-layer-primary text-black cursor-default";
  }
  return isDragging
    ? "bg-gray-light shadow-layer-gray-light"
    : "bg-gray shadow-layer-gray hover:bg-gray-light hover:shadow-layer-gray-light";
}

export default LayerDraggable;
