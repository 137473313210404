import React from "react";

const LayoutContainer: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ children, className = "" }) => {
  return <div className={`px-layout w-full ${className}`}>{children}</div>;
};

export default LayoutContainer;
