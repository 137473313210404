import React from "react";
import { useDropzone } from "react-dropzone";
import type { FileWithPath } from "react-dropzone";

const DragInput: React.FC<{
  onDrop: (files: FileWithPath[]) => void;
  getInputStyles: (draggingOver: boolean, disabled?: boolean) => string;
  onFileDialogCancel?: () => void;
  disabled?: boolean;
  type?: "file" | "directory";
  children: React.ReactNode;
}> = ({
  children,
  onDrop,
  getInputStyles,
  type = "file",
  disabled,
  onFileDialogCancel,
}) => {
  const [draggingOver, setDraggingOver] = React.useState(false);

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    useFsAccessApi: false,
    onDragEnter: () => {
      setDraggingOver(true);
    },
    onDragLeave: () => {
      setDraggingOver(false);
    },
    onDrop: (files) => {
      setDraggingOver(false);
      onDrop(files);
    },
    onFileDialogCancel,
  });

  React.useEffect(() => {
    if (type === "directory" && inputRef.current) {
      inputRef.current.setAttribute("webkitdirectory", "true");
      inputRef.current.setAttribute("directory", "true");
    }
  }, [type, inputRef]);

  return (
    <div
      {...getRootProps({
        className: getInputStyles(draggingOver, disabled),
      })}
    >
      <input {...getInputProps()} disabled={disabled} />
      {children}
    </div>
  );
};

export default DragInput;
