import React from "react";
import H6 from "../../../components/H6";
import ProjectNavbar from "../../../components/Navbar/ProjectNavbar";
import PageGrid from "../../../components/PageGrid";
import PageGridCard from "../../../components/PageGrid/PageGridCard";
import PageGridInfo from "../../../components/PageGrid/PageGridInfo";
import PageMainContent from "../../../components/PageMainContent";
import PageWrapper from "../../../components/PageWrapper";
import ExportingAssetsSection from "./ExportingAssetsSection";
import InstructionsSection from "./InstructionsSection";
import UploadSection from "./UploadSection";

const AssetUpload: React.FC = () => {
  return (
    <PageWrapper navbar={<ProjectNavbar />}>
      <PageMainContent addLayoutContainer centerContent paddingBottom={40}>
        <PageGrid>
          <PageGridInfo>
            <div>
              <H6>Assets</H6>
              <h1>Organize your artwork</h1>
              <p className="opacity-80 mb-6">
                Upload your artwork here whenever you're ready to get started on
                generating your collection. Learn more below about how to best
                prepare for uploading.
              </p>
              <div className="grid grid-cols-2 gap-4">
                <ExportingAssetsSection />
                <InstructionsSection />
              </div>
            </div>
          </PageGridInfo>
          <PageGridCard>
            <UploadSection />
          </PageGridCard>
        </PageGrid>
      </PageMainContent>
    </PageWrapper>
  );
};

export default AssetUpload;
