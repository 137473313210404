import React from "react";
import { FileWithPath } from "react-dropzone";
import DragInput from "../../components/Form/DragInput";
import { useSnackbarDispatch } from "../../components/SnackbarContext";
import TrashIcon from "../../icons/Trash";
import UploadIcon from "../../icons/Upload";

const CustomImageInput: React.FC<{
  setCustomFile: React.Dispatch<React.SetStateAction<FileWithPath | null>>;
  preview: string | null;
  setPreview: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ setCustomFile, preview, setPreview }) => {
  const setSnackbarMessage = useSnackbarDispatch();

  const handleDrop = async (files: FileWithPath[]) => {
    if (files.length !== 1) {
      setSnackbarMessage("Only one image can be uploaded", "error");
      return;
    }

    const file = files[0];

    if (!file.type.includes("image/")) {
      setSnackbarMessage("File must be an image", "error");
      return;
    }

    setCustomFile(file);
    setPreview(URL.createObjectURL(file));
  };

  if (preview) {
    const deleteFile = () => {
      setCustomFile(null);
    };
    return (
      <>
        <button
          className="hidden group-hover:block absolute top-1 right-1 z-10 bg-black text-white opacity-80 hover:opacity-100 p-0.5"
          onClick={deleteFile}
        >
          <TrashIcon />
        </button>
        <div className="absolute inset-0 flex items-center justify-center">
          <img
            className="object-center object-contain w-full h-full"
            src={preview}
            alt="Custom sample"
          />
        </div>
      </>
    );
  }

  return (
    <DragInput getInputStyles={getInputStyles} onDrop={handleDrop}>
      <div className="font-secondary text-xs flex flex-col items-center justify-center text-center h-full">
        <div className="mb-1">
          <UploadIcon />
        </div>
        <div>Upload image</div>
      </div>
    </DragInput>
  );
};

export default CustomImageInput;

function getInputStyles(draggingOver: boolean) {
  return `absolute inset-0 border border-dashed block border-white bg-white ${
    draggingOver
      ? "border-opacity-100 bg-opacity-10"
      : "bg-gray border-opacity-60 bg-opacity-0"
  }`;
}
