import React from "react";
import PageWrapper from "../../components/PageWrapper";
import { useAssetsEffect } from "../../utils/hooks/useFetchAssets";
import { useTokensEffect } from "../../utils/hooks/useFetchTokens";
import { useCollectionsEffect } from "../../utils/hooks/useFetchCollections";
import PageMainContent from "../../components/PageMainContent";
import LoadingIcon from "../../components/LoadingIcon";
import NewContract from "./NewContract";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";
import SmartContractCarousel from "./SmartContractCarousel";
import PinTokensPage from "./PinTokensPage";
import ProjectNavbar from "../../components/Navbar/ProjectNavbar";
import Button from "../../components/Button";
import ChevronRightIcon from "../../icons/ChevronRight";

const Launch: React.FC = () => {
  const collectionState = useCollectionsEffect();

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const loading = !collectionState;

  if (loading) {
    return <LoadingPage />;
  }

  return <CheckCollection />;
};

function CheckCollection() {
  const collection = useCurrentCollection();

  if (
    collection.tokenCount > 0 &&
    collection.pinnedTokenCount === collection.tokenCount
  ) {
    return <LaunchPage />;
  }

  if (collection.hasAllTokenZipFiles && collection.hasMetadataZipFile) {
    return <PinTokensPage />;
  }

  return (
    <PageWrapper navbar={<ProjectNavbar />}>
      <PageMainContent centerContent addLayoutContainer>
        <div className="flex justify-center flex-col items-center">
          <h3>Collection is not ready for launch 🧐</h3>
          <p className="opacity-60 mb-6 m-auto max-w-lg text-center">
            We appreciate your passion, but you'll need to finalize your
            collection before you can launch it.
          </p>
          <div>
            <Button
              element="link"
              href={`/collections/${collection.id}/tokens`}
              size="large"
            >
              <span className="mr-3">Go to tokens page</span>
              <ChevronRightIcon />
            </Button>
          </div>
        </div>
      </PageMainContent>
    </PageWrapper>
  );
}

function LaunchPage() {
  const [showContractForm, setShowContractForm] = React.useState(false);
  const assetState = useAssetsEffect();
  const collectionState = useCollectionsEffect();
  const tokenState = useTokensEffect({
    collectionState,
    assetState,
  });

  const collection = useCurrentCollection();

  const loading = !assetState || !tokenState;

  if (loading) {
    return <LoadingPage />;
  }

  if (
    collection.contracts &&
    collection.contracts.length > 0 &&
    !showContractForm
  ) {
    return <SmartContractCarousel setShowContractForm={setShowContractForm} />;
  }

  return (
    <NewContract
      setShowContractForm={setShowContractForm}
      showContractForm={showContractForm}
    />
  );
}

function LoadingPage() {
  return (
    <PageWrapper>
      <PageMainContent centerContent>
        <LoadingIcon />
      </PageMainContent>
    </PageWrapper>
  );
}

export default Launch;
