import React from "react";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import FooterItem from "../../components/FooterItem";
import { collectionIsReadOnly, numberWithDelimiters } from "../../utils";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";
import useTotalPossibleCombinations from "../../utils/hooks/useTotalPossibleCombinations";
import RegenerateTokensDrawer from "../../components/GenerateTokens/RegenerateTokensDrawer";
import { useRecipes } from "../../components/RecipeContext/hooks";

const RecipeFooter: React.FC = () => {
  const collection = useCurrentCollection();
  const totalPossibleCombinations = useTotalPossibleCombinations();
  const recipeState = useRecipes();

  const isReadOnly = collectionIsReadOnly(collection);

  return (
    <Footer>
      <div className="-mx-4">
        <FooterItem
          label="Estimated total combinations"
          count={numberWithDelimiters(totalPossibleCombinations)}
        />
      </div>
      {!isReadOnly && (
        <div>
          {collection.tokenCount > 0 ? (
            <RegenerateTokensDrawer
              collectionId={collection.id}
              disabled={recipeState.updateStack.length > 0}
            />
          ) : (
            <Button
              element="link"
              href={`/collections/${collection.id}/tokens`}
            >
              Generate tokens
            </Button>
          )}
        </div>
      )}
    </Footer>
  );
};

export default RecipeFooter;
