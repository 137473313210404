export default function Shuffle({ size = 16 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.00252 11.4861L1.50252 11.4836L1.49749 12.4836L1.99748 12.4861L2.00252 11.4861ZM3.58933 11.9941L3.58682 12.4941L3.58686 12.4941L3.58933 11.9941ZM5.264 11.1034L5.67987 11.381L5.68003 11.3808L5.264 11.1034ZM9.40333 4.89409L9.81936 5.17143L9.81951 5.17121L9.40333 4.89409ZM11.0747 4.00343L11.0772 3.50343L11.0762 3.50343L11.0747 4.00343ZM13.1975 4.51409L13.6975 4.5166L13.7025 3.51661L13.2025 3.5141L13.1975 4.51409ZM1.99748 12.4861L3.58682 12.4941L3.59185 11.4941L2.00252 11.4861L1.99748 12.4861ZM3.58686 12.4941C4.42767 12.4983 5.21341 12.0799 5.67987 11.381L4.84813 10.8258C4.56793 11.2456 4.09633 11.4966 3.59181 11.4941L3.58686 12.4941ZM5.68003 11.3808L9.81936 5.17143L8.9873 4.61675L4.84797 10.8261L5.68003 11.3808ZM9.81951 5.17121C10.0982 4.75271 10.5691 4.50192 11.0732 4.50342L11.0762 3.50343C10.2376 3.50093 9.45249 3.91814 8.98715 4.61697L9.81951 5.17121ZM11.0722 4.50342L13.1975 4.51409L13.2025 3.5141L11.0772 3.50343L11.0722 4.50342Z" />
      <path d="M12.3131 12.9657L11.9596 13.3193L12.6667 14.0264L13.0202 13.6728L12.3131 12.9657ZM14 11.9859L14.3536 12.3395L14.7071 11.9859L14.3536 11.6324L14 11.9859ZM13.0202 10.299L12.6667 9.94548L11.9596 10.6526L12.3131 11.0061L13.0202 10.299ZM13.0202 13.6728L14.3536 12.3395L13.6464 11.6324L12.3131 12.9657L13.0202 13.6728ZM14.3536 11.6324L13.0202 10.299L12.3131 11.0061L13.6464 12.3395L14.3536 11.6324Z" />
      <path d="M5.52306 6.04244L5.81544 6.44805L6.62665 5.86329L6.33427 5.45769L5.52306 6.04244ZM5.26933 4.8354L5.67494 4.54302L5.67484 4.54288L5.26933 4.8354ZM3.63667 4.0054L3.63409 3.5054L3.63402 3.5054L3.63667 4.0054ZM1.99735 3.51407L1.49736 3.51672L1.50265 4.51671L2.00265 4.51406L1.99735 3.51407ZM6.33427 5.45769L5.67494 4.54302L4.86373 5.12778L5.52306 6.04244L6.33427 5.45769ZM5.67484 4.54288C5.20234 3.88788 4.44235 3.50124 3.63409 3.5054L3.63924 4.50539C4.12431 4.50289 4.58033 4.73491 4.86383 5.12792L5.67484 4.54288ZM3.63402 3.5054L1.99735 3.51407L2.00265 4.51406L3.63931 4.50539L3.63402 3.5054Z" />
      <path d="M9.04152 9.94412L8.73564 9.5486L7.9446 10.1604L8.25048 10.5559L9.04152 9.94412ZM9.39667 11.2207L9.00114 11.5265L9.00115 11.5266L9.39667 11.2207ZM10.9853 11.9967L10.9869 12.4967L10.9877 12.4967L10.9853 11.9967ZM13.2024 12.486L13.7024 12.4836L13.6976 11.4836L13.1976 11.486L13.2024 12.486ZM8.25048 10.5559L9.00114 11.5265L9.79219 10.9148L9.04152 9.94412L8.25048 10.5559ZM9.00115 11.5266C9.47634 12.141 10.2105 12.4992 10.9869 12.4967L10.9837 11.4967C10.5175 11.4982 10.077 11.283 9.79218 10.9148L9.00115 11.5266ZM10.9877 12.4967L13.2024 12.486L13.1976 11.486L10.9829 11.4967L10.9877 12.4967Z" />
      <path d="M12.3131 4.99378L11.9596 5.34733L12.6667 6.05444L13.0202 5.70088L12.3131 4.99378ZM14 4.014L14.3536 4.36755L14.7071 4.014L14.3536 3.66044L14 4.014ZM13.0202 2.32711L12.6667 1.97356L11.9596 2.68066L12.3131 3.03422L13.0202 2.32711ZM13.0202 5.70088L14.3536 4.36755L13.6464 3.66044L12.3131 4.99378L13.0202 5.70088ZM14.3536 3.66044L13.0202 2.32711L12.3131 3.03422L13.6464 4.36755L14.3536 3.66044Z" />
    </svg>
  );
}
