import React from "react";
import Button from "../../../components/Button";
import Drawer from "../../../components/Drawer";
import ChevronRightIcon from "../../../icons/ChevronRight";
import EmailForm from "../EmailForm";

const EmailFormDrawer: React.FC<{ email: string }> = ({ email }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} fullWidth>
        <span className="mr-2.5">Send me another link</span>
        <ChevronRightIcon />
      </Button>
      <Drawer open={open} onClose={handleClose}>
        <div>
          <h2 className="mb-6">Enter your email</h2>
          <EmailForm initialValue={email} />
        </div>
      </Drawer>
    </>
  );
};

export default EmailFormDrawer;
