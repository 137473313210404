import React from "react";
import AssetImageStack from "../../../../components/AssetImage/AssetImageStack";
import Button from "../../../../components/Button";
import TextInput from "../../../../components/Form/TextInput";
import { TOKEN_DESCRIPTION_TOOLTIP } from "../../../../constants";
import ChevronLeftIcon from "../../../../icons/ChevronLeft";
import SaveTokenIcon from "../../../../icons/SaveToken";
import type { AssetState, TokenWithRarityRank } from "../../../../types";
import type { TokenLayerMap } from "./types";

const PreviewMode: React.FC<{
  aspectRatio: number;
  itemOrder: string[];
  tokenLayerMap: TokenLayerMap;
  layers: AssetState["layers"];
  saved: boolean;
  onClose: () => void;
  description: string;
  token: TokenWithRarityRank;
  tokenName: string;
  children: React.ReactNode;
}> = ({
  aspectRatio,
  itemOrder,
  tokenLayerMap,
  layers,
  saved,
  onClose,
  description,
  token,
  tokenName,
  children,
}) => {
  return (
    <>
      <div className="bg-gray w-1/2 flex items-center justify-center p-20">
        <div className="w-full">
          <AssetImageStack
            aspectRatio={aspectRatio}
            tokenLayers={itemOrder.map((key) => {
              return tokenLayerMap[key];
            })}
            layers={layers}
            size="large"
          />
        </div>
      </div>
      <div className="w-1/2 px-8 flex flex-col overflow-auto pb-6">
        <div className="py-4">
          <div className="flex items-center justify-between mb-2">
            <span className="text-3xl font-bold truncate">{tokenName}</span>
            {token.rarityRank && (
              <div className="flex-shrink-0 pl-2 text-right">
                <span className="block font-secondary opacity-80 text-xs">
                  Rarity rank
                </span>
                <span>#{token.rarityRank}</span>
              </div>
            )}
          </div>
          <div className="mt-6">
            <TextInput
              value={description}
              label={{
                text: "Token description",
                tooltipText: TOKEN_DESCRIPTION_TOOLTIP,
                tooltipPlacement: "right",
              }}
              name="description"
              readOnly
            />
          </div>
          {saved && (
            <div className="flex items-center text-primary mt-6">
              <SaveTokenIcon />
              <span className="ml-2">Saved as a custom token</span>
            </div>
          )}
        </div>
        {children}
        <div className="mt-8 text-center">
          <Button onClick={onClose} fullWidth variant="gray">
            <ChevronLeftIcon />
            <span className="ml-3">Go back</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default PreviewMode;
