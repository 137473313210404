import React from "react";
import cloneDeep from "lodash/cloneDeep";
import {
  AssetWithUrls,
  calculateAssetKey,
  getLayerIdFromAssetKey,
} from "lasagna-commons";
import Button from "../../../components/Button";
import LayoutContainer from "../../../components/LayoutContainer";
import FullScreenModal from "../../../components/FullScreenModal";
import CheckIcon from "../../../icons/Check";
import ChevronLeftIcon from "../../../icons/ChevronLeft";
import type {
  RecipeAssetData,
  RecipeAssetKey,
  RecipeAssetMap,
  RecipeModalState,
} from "../types";
import { useRecipeModal } from "./RecipeModalContext";
import RecipeModalGrid from "./RecipeModalGrid";
import RecipeModalHeaderLabel from "./RecipeModalHeaderLabel";
import { useRecipes } from "../../../components/RecipeContext/hooks";
import { duplicateRecipeExists } from "../utils";
import { useSnackbarDispatch } from "../../../components/SnackbarContext";
import { RECIPE_PAGE_MODAL_HEADER_HEIGHT } from "../../../constants/recipePageConstants";
import { FOOTER_HEIGHT } from "../../../constants/layoutConstants";
import RecipeModalFooterItem from "./RecipeModalFooterItem";

const RecipeModal: React.FC<{
  activeRecipe: RecipeModalState;
  recipeAssetMap: RecipeAssetMap;
}> = ({ activeRecipe, recipeAssetMap }) => {
  const [tempRecipe, setTempRecipe] = React.useState<RecipeAssetData | null>(
    null
  );

  const { recipes } = useRecipes();
  const setSnackbarMessage = useSnackbarDispatch();

  const addAsset = React.useCallback((assetKey: string) => {
    setTempRecipe((current) => {
      if (!current) {
        return null;
      }

      return {
        ...current,
        [current.recipeAssetType]: [
          ...current[current.recipeAssetType],
          assetKey,
        ],
      };
    });
  }, []);

  const removeAsset = React.useCallback((assetKeyIndex: number) => {
    setTempRecipe((current) => {
      if (!current) {
        return null;
      }

      const assetKeys = [...current[current.recipeAssetType]];
      assetKeys.splice(assetKeyIndex, 1);
      return {
        ...current,
        [current.recipeAssetType]: assetKeys,
      };
    });
  }, []);

  const removeAllAssetsForLayer = React.useCallback((layerId: string) => {
    setTempRecipe((current) => {
      if (!current) {
        return null;
      }

      const assetKeys = [...current[current.recipeAssetType]].filter(
        (assetKey) => !assetKey || getLayerIdFromAssetKey(assetKey) !== layerId
      );

      return {
        ...current,
        [current.recipeAssetType]: assetKeys,
      };
    });
  }, []);

  const addAllAssetsForLayer = React.useCallback(
    (layerId: string, assets: AssetWithUrls[]) => {
      setTempRecipe((current) => {
        if (!current) {
          return null;
        }

        const assetKeys = [...current[current.recipeAssetType]];

        assets.forEach((asset) => {
          const assetKey = calculateAssetKey({ assetId: asset.id, layerId });
          if (assetKeys.indexOf(assetKey) === -1) {
            assetKeys.push(assetKey);
          }
        });

        return {
          ...current,
          [current.recipeAssetType]: assetKeys,
        };
      });
    },
    []
  );

  const { setActiveRecipe } = useRecipeModal();

  React.useEffect(() => {
    if (activeRecipe) {
      const { recipeStateDispatch, ...newTempRecipe } = activeRecipe;
      setTempRecipe(cloneDeep(newTempRecipe));
    } else {
      setTempRecipe(null);
    }
  }, [activeRecipe]);

  const handleClose = () => {
    setActiveRecipe(null);
  };

  const handleSave = () => {
    if (activeRecipe && tempRecipe) {
      const targetAssetKeys = filterNullKeys(tempRecipe.targetAssetKeys);
      const pairedAssetKeys = filterNullKeys(tempRecipe.pairedAssetKeys);

      const duplicateRecipe = duplicateRecipeExists({
        targetAssetKeys,
        pairedAssetKeys,
        recipes,
        currentRecipeId: activeRecipe.recipeId,
      });

      if (duplicateRecipe) {
        setSnackbarMessage(
          `Recipe ${duplicateRecipe} has duplicate rules`,
          "error"
        );
      } else {
        activeRecipe.recipeStateDispatch((current) => {
          return {
            ...current,
            targetAssetKeys:
              targetAssetKeys.length > 0 ? targetAssetKeys : [null],
            pairedAssetKeys:
              pairedAssetKeys.length > 0 ? pairedAssetKeys : [null],
          };
        });
        handleClose();
      }
    }
  };

  const list = React.useMemo<
    { layerId: string; assetKeys: string[] }[] | null
  >(() => {
    if (!tempRecipe) {
      return null;
    }

    const map: { [layerId: string]: string[] } = {};

    tempRecipe[tempRecipe.recipeAssetType].forEach((assetKey) => {
      if (assetKey) {
        const layerId = getLayerIdFromAssetKey(assetKey);
        if (map[layerId]) {
          map[layerId].push(assetKey);
        } else {
          map[layerId] = [assetKey];
        }
      }
    });

    return Object.keys(map).map((layerId) => ({
      layerId,
      assetKeys: map[layerId],
    }));
  }, [tempRecipe]);

  return (
    <FullScreenModal className="flex flex-col" onClose={handleClose}>
      <LayoutContainer className="flex-shrink-0">
        <nav
          style={{ height: RECIPE_PAGE_MODAL_HEADER_HEIGHT }}
          className="flex items-center"
        >
          <div className="w-20 flex-shrink-0">
            <Button variant="gray" onClick={handleClose} size="small">
              <ChevronLeftIcon />
              <span className="ml-2">Back</span>
            </Button>
          </div>
          <div className="flex-grow-1 text-center w-full">
            {activeRecipe && (
              <RecipeModalHeaderLabel
                recipeType={activeRecipe.recipeType}
                assetKeys={
                  activeRecipe.recipeAssetType === "pairedAssetKeys"
                    ? activeRecipe.targetAssetKeys
                    : activeRecipe.pairedAssetKeys
                }
                recipeAssetMap={recipeAssetMap}
              />
            )}
          </div>
          <div className="flex-shrink-0 w-20" />
        </nav>
      </LayoutContainer>
      <div className="h-full pb-2">
        {tempRecipe && (
          <RecipeModalGrid
            activeRecipe={tempRecipe}
            addAsset={addAsset}
            removeAsset={removeAsset}
            recipeType={activeRecipe?.recipeType}
            addAllAssetsForLayer={addAllAssetsForLayer}
            removeAllAssetsForLayer={removeAllAssetsForLayer}
          />
        )}
      </div>
      <footer
        className="flex-shrink-0 flex items-center justify-between w-full border-t border-gray-dark"
        style={{ height: FOOTER_HEIGHT }}
      >
        <div className="pl-layout overflow-auto relative flex-grow">
          <ul className="flex items-center">
            {list &&
              list.map(({ layerId, assetKeys }) => {
                const handleDelete = () => {
                  removeAllAssetsForLayer(layerId);
                };

                return (
                  <RecipeModalFooterItem
                    key={layerId}
                    recipeAssetMap={recipeAssetMap}
                    assetKeys={assetKeys}
                    onDelete={handleDelete}
                  />
                );
              })}
          </ul>
        </div>
        <div className="pr-layout flex-shrink-0 pl-8 relative">
          <Button onClick={handleSave}>
            <CheckIcon />
            <span className="mx-2">Save</span>
          </Button>
        </div>
      </footer>
    </FullScreenModal>
  );
};

export default RecipeModal;

function filterNullKeys(keys: RecipeAssetKey[]): string[] {
  const newKeys: string[] = [];

  keys.forEach((key) => {
    if (key) {
      newKeys.push(key);
    }
  });

  return newKeys;
}
