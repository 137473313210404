import { RecipeType } from "lasagna-commons";
import React from "react";
import IconButton from "../../components/Button/IconButton";
import TrashIcon from "../../icons/Trash";
import AddAssetButton from "./AddAssetButton";
import RecipeModalButton from "./RecipeModal/RecipeModalButton";
import {
  RecipeAssetKey,
  RecipeAssetListItem,
  RecipeAssetType,
  RecipeStateDispatch,
} from "./types";
import { removeRecipeAssetKey } from "./utils/useRecipeForm";

const RecipeFormInputList: React.FC<{
  targetAssetKeys: RecipeAssetKey[];
  pairedAssetKeys: RecipeAssetKey[];
  recipeAssetType: RecipeAssetType;
  list: RecipeAssetListItem[];
  recipeType: RecipeType;
  recipeId: number | null;
  recipeStateDispatch: RecipeStateDispatch;
  isReadOnly: boolean;
}> = ({ recipeStateDispatch, list, ...props }) => {
  return (
    <>
      <label className="text-sm opacity-80">
        {props.recipeAssetType === "targetAssetKeys" ? "Combine" : "With"}
      </label>
      <ul>
        {list.map((item, index) => {
          const handleDeleteClick = () => {
            removeRecipeAssetKey({
              key: item
                ? { type: "layer", layerId: item.layerId }
                : { type: "null", index },
              recipeAssetType: props.recipeAssetType,
              dispatch: recipeStateDispatch,
            });
          };

          return (
            <li
              key={index}
              className={`flex items-center py-2 border-2 border-transparent ${
                index < list.length - 1 ? "border-b-black" : ""
              }`}
            >
              <div className="flex-grow overflow-hidden">
                <RecipeModalButton
                  item={item}
                  recipeStateDispatch={recipeStateDispatch}
                  {...props}
                />
              </div>
              {list.length > 1 && (
                <div className="flex-shrink-0 pl-4 flex">
                  <IconButton
                    onClick={handleDeleteClick}
                    disabled={props.isReadOnly}
                  >
                    <TrashIcon />
                  </IconButton>
                </div>
              )}
            </li>
          );
        })}
      </ul>
      {props.recipeId !== null && !props.isReadOnly && (
        <AddAssetButton
          recipeAssetType={props.recipeAssetType}
          recipeStateDispatch={recipeStateDispatch}
        />
      )}
    </>
  );
};

export default RecipeFormInputList;
