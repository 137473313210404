import React from "react";
import type { AssetWithUrls, LayerWithAssets } from "lasagna-commons";
import {
  updateAsset,
  updateLayer,
} from "../../../../../components/AssetContext/utils";
import type { AssetDispatch } from "../../../../../types";
import { useSnackbarDispatch } from "../../../../../components/SnackbarContext";

export function useToggleLayerMetadata({
  layer,
  layerId,
  assetDispatch,
}: {
  layer: LayerWithAssets;
  layerId: string;
  assetDispatch: AssetDispatch;
}) {
  const setSnackbarMessage = useSnackbarDispatch();

  const toggle = React.useCallback(() => {
    const hideMetadata = !layer.hideMetadata;

    updateLayer({
      layerId,
      layer: { hideMetadata },
      assetDispatch,
    });

    setSnackbarMessage(
      hideMetadata
        ? "Metadata will be hidden for this layer when collection is finalized"
        : "Metadata will be included for this layer when collection is finalized"
    );
  }, [layerId, assetDispatch, layer, setSnackbarMessage]);

  return toggle;
}

export function useLayerHeaderState<P extends "label" | "weight">({
  layer,
  layerId,
  updateKey,
  assetDispatch,
}: {
  layer: LayerWithAssets;
  layerId: string;
  updateKey: P;
  assetDispatch: AssetDispatch;
}) {
  const updateFunction = React.useCallback(
    (value: LayerWithAssets[P]) => {
      updateLayer({
        layerId,
        layer: { [updateKey]: value },
        assetDispatch,
      });
    },
    [layerId, updateKey, assetDispatch]
  );

  return useInputState({
    initialValue: layer[updateKey],
    updateFunction,
    updateKey,
  });
}

export function useLayerListItemState<P extends keyof AssetWithUrls>({
  asset,
  layerId,
  updateKey,
  assetDispatch,
}: {
  asset: AssetWithUrls;
  layerId: string;
  updateKey: P;
  assetDispatch: AssetDispatch;
}) {
  const updateFunction = React.useCallback(
    (value: AssetWithUrls[P]) => {
      updateAsset({
        layerId,
        asset: { [updateKey]: value },
        assetId: asset.id,
        assetDispatch,
      });
    },
    [asset.id, layerId, updateKey, assetDispatch]
  );

  return useInputState<AssetWithUrls[P]>({
    initialValue: asset[updateKey],
    updateFunction,
    updateKey,
  });
}

function useInputState<T>({
  initialValue,
  updateFunction,
}: {
  initialValue: T;
  updateFunction: (value: T) => void;
  updateKey: string;
}) {
  const [touched, setTouched] = React.useState(false);
  const [value, setValue] = React.useState(() => initialValue);
  const valueRef = React.useRef<T | null>(null);

  // useLayoutEffect to prevent visual glitches when searching assets
  React.useLayoutEffect(() => {
    setValue(initialValue);
    valueRef.current = initialValue;
  }, [initialValue]);

  const handleBlur = React.useCallback(() => {
    if (
      (value || (value as unknown as number) === 0) &&
      valueRef.current !== value
    ) {
      updateFunction(value);
      valueRef.current = value;
    }
    setTouched(true);
  }, [value, updateFunction]);

  return {
    value,
    setValue,
    handleBlur,
    error: touched && !value && value !== 0 ? `Invalid` : "",
  };
}
