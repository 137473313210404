import React from "react";
import Navbar from ".";
import ExternalLinkButton from "../ExternalLink/ExternalLinkButton";
import DiscordIcon from "../../icons/Discord";
import LogoLink from "./LogoLink";
import { DISCORD_URL } from "../../constants";

const HomeNavbar: React.FC<{
  children?: React.ReactNode;
  bgColor?: string;
}> = ({ children, bgColor }) => {
  return (
    <Navbar bgColor={bgColor}>
      <div className="flex items-center">
        <LogoLink />
        <div className="ml-10">
          <ExternalLinkButton href={DISCORD_URL} variant="discord">
            <DiscordIcon />
            <span className="ml-2">Join our Discord</span>
          </ExternalLinkButton>
        </div>
      </div>
      {children}
    </Navbar>
  );
};

export default HomeNavbar;
