import { calculateAssetKey, Recipe, RecipeAsset } from "lasagna-commons";
import type { RecipeAssetKey, RecipeAssetMap } from "../types";

export function calculateRecipeId(recipes: Recipe[]) {
  let maxId = 0;
  recipes.forEach((recipe) => {
    if (recipe.id > maxId) {
      maxId = recipe.id;
    }
  });
  return maxId + 1;
}

export function calculateRecipeAssets({
  targetAssetKeys,
  pairedAssetKeys,
  recipeAssetMap,
}: {
  targetAssetKeys: string[];
  pairedAssetKeys: string[];
  recipeAssetMap: RecipeAssetMap;
}) {
  return {
    targetAssets: targetAssetKeys.map((value) =>
      calculateAsset(value, recipeAssetMap)
    ),
    pairedAssets: pairedAssetKeys.map((value) =>
      calculateAsset(value, recipeAssetMap)
    ),
  };
}

function calculateAsset(
  recipeAssetKey: string,
  recipeAssetMap: RecipeAssetMap
): RecipeAsset {
  const { layerId, id } = recipeAssetMap[recipeAssetKey];

  return { layerId, assetId: id };
}

export function filterRecipeAssetKeys(inputKeys: RecipeAssetKey[]): {
  keys: string[];
  hasNullValues: boolean;
} {
  const keys: string[] = [];

  let hasNullValues = false;

  inputKeys.forEach((val) => {
    if (val) {
      keys.push(val);
    } else {
      hasNullValues = true;
    }
  });

  return { keys, hasNullValues };
}

export function duplicateRecipeExists({
  targetAssetKeys,
  pairedAssetKeys,
  recipes,
  currentRecipeId,
}: {
  targetAssetKeys: string[];
  pairedAssetKeys: string[];
  recipes: Recipe[];
  currentRecipeId: number | null;
}): string | undefined {
  const filteredRecipes =
    currentRecipeId === null
      ? recipes
      : recipes.filter(({ id }) => id !== currentRecipeId);

  for (const recipe of filteredRecipes) {
    for (const inputTargetKey of targetAssetKeys) {
      for (const targetAsset of recipe.targetAssets) {
        const targetAssetKey = calculateAssetKey(targetAsset);
        if (targetAssetKey === inputTargetKey) {
          for (const inputPairedKey of pairedAssetKeys) {
            for (const pairedAsset of recipe.pairedAssets) {
              const pairedAssetKey = calculateAssetKey(pairedAsset);
              if (pairedAssetKey === inputPairedKey) {
                return `${recipe.label || recipe.id}`;
              }
            }
          }
        }
      }
    }
  }
  return;
}
