export default function Lock() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33333 6.66667V4.66667C5.33333 3.194 6.52733 2 7.99999 2C9.47266 2 10.6667 3.194 10.6667 4.66667V6.66667"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3333 13.9998H4.66666C3.92999 13.9998 3.33333 13.4032 3.33333 12.6665V7.99984C3.33333 7.26317 3.92999 6.6665 4.66666 6.6665H11.3333C12.07 6.6665 12.6667 7.26317 12.6667 7.99984V12.6665C12.6667 13.4032 12.07 13.9998 11.3333 13.9998Z"
        fill="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 9.3335V11.3335"
        stroke="#131322"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
