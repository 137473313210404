import React from "react";
import LottieIcon from "./LottieIcon";
import animationData from "./LottieIcon/animations/logo.json";

const LoadingIcon: React.FC = () => {
  return (
    <div className="flex justify-center">
      <LottieIcon animationData={animationData} translateX="" />
    </div>
  );
};

export default LoadingIcon;
