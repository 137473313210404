import React from "react";

const PageGridInfo: React.FC<{
  children: React.ReactNode;
  gutter?: "left" | "right";
}> = ({ children, gutter = "right" }) => {
  return (
    <div className={`col-span-2 ${gutter === "right" ? "pr-20" : "pl-20"}`}>
      <div className="w-full">{children}</div>
    </div>
  );
};

export default PageGridInfo;
