import React from "react";
import ChevronRightIcon from "../icons/ChevronRight";
import ExternalLinkButton from "./ExternalLink/ExternalLinkButton";

const InfoCard: React.FC<{
  headerText: string;
  bodyText: string;
  fullWidth?: boolean;
  href: string;
}> = ({ headerText, bodyText, fullWidth, href }) => {
  return (
    <div
      className={`bg-gray shadow-layer-gray pt-3 pb-4 px-6 inline-flex items-center justify-between ${
        fullWidth ? "w-full" : ""
      }`}
    >
      <div className="mr-10">
        <span className="block font-secondary gradient-text py-1 text-sm">
          {headerText}
        </span>
        <p>{bodyText}</p>
      </div>
      <div>
        <ExternalLinkButton href={href}>
          <span className="mr-2">Read</span>
          <ChevronRightIcon />
        </ExternalLinkButton>
      </div>
    </div>
  );
};

export default InfoCard;
