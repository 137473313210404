import React from "react";
import NumberInput from "../../../components/Form/NumberInput";
import type { MetadataFieldProps } from "./types";

const MetadataNumberField: React.FC<MetadataFieldProps> = ({
  customTokenMetadata,
  setCustomTokenMetadata,
  metadataKey,
  label,
}) => {
  const setValue = React.useCallback(
    (value: string) => {
      setCustomTokenMetadata((current) => ({
        ...current,
        [metadataKey]: Number(value.replace(/,/g, "")),
      }));
    },
    [setCustomTokenMetadata, metadataKey]
  );

  return (
    <NumberInput
      value={customTokenMetadata[metadataKey]}
      setValue={setValue}
      name={metadataKey}
      label={{ text: label }}
      min={0}
      max={10000}
      decimalScale={3}
    />
  );
};

export default MetadataNumberField;
