import React from "react";
import PageWrapper from "../../components/PageWrapper";
import BeforeTokenGeneration from "./BeforeTokenGeneration";
import AfterTokenGeneration from "./AfterTokenGeneration";
import { useAssetsEffect } from "../../utils/hooks/useFetchAssets";
import { useTokensEffect } from "../../utils/hooks/useFetchTokens";
import { useCollectionsEffect } from "../../utils/hooks/useFetchCollections";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";
import { useRecipesEffect } from "../../utils/hooks/useFetchRecipes";
import PageMainContent from "../../components/PageMainContent";
import LoadingSkeleton from "./LoadingSkeleton";
import type { TokenState } from "../../types";
import LoadingIcon from "../../components/LoadingIcon";
import { getCollectionId } from "../../utils";

const Tokens: React.FC = () => {
  const [checkForThumbnails, setCheckForThumbnails] = React.useState(false);
  const collectionState = useCollectionsEffect();
  const assetState = useAssetsEffect({
    checkForThumbnails,
  });
  const recipeState = useRecipesEffect();
  const tokenState = useTokensEffect({ collectionState, assetState });
  const collectionId = getCollectionId();

  const loading =
    !collectionState || !assetState || !recipeState || !tokenState;

  if (loading) {
    return (
      <TokensLoadingPage
        showSkeleton={Boolean(
          collectionState &&
            collectionState[collectionId].tokenCount > 0 &&
            !collectionState[collectionId].mustRegenerate
        )}
      />
    );
  }

  return (
    <TokensPage
      tokenState={tokenState}
      setCheckForThumbnails={setCheckForThumbnails}
    />
  );
};

const TokensPage: React.FC<{
  tokenState: TokenState;
  setCheckForThumbnails: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ tokenState, setCheckForThumbnails }) => {
  const collection = useCurrentCollection();

  React.useEffect(() => {
    if (
      !tokenState.generatingOnTokensPage &&
      tokenState.tokens.length > 0 &&
      !collection.mustRegenerate
    ) {
      setCheckForThumbnails(true);
    }
  }, [setCheckForThumbnails, collection, tokenState]);

  if (tokenState.generatingOnTokensPage) {
    return <TokensLoadingPage showSkeleton />;
  }

  if (
    (tokenState.tokens.length === 0 || collection.mustRegenerate) &&
    tokenState.generatingOnTokensPage !== "beforeGen"
  ) {
    return <BeforeTokenGeneration />;
  }

  return <AfterTokenGeneration />;
};

function TokensLoadingPage({ showSkeleton }: { showSkeleton: boolean }) {
  return (
    <PageWrapper>
      <PageMainContent addLayoutContainer centerContent={!showSkeleton}>
        {showSkeleton ? <LoadingSkeleton /> : <LoadingIcon />}
      </PageMainContent>
    </PageWrapper>
  );
}

export default Tokens;
