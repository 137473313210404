import { calculateAssetKey } from "lasagna-commons";
import React from "react";
import { useSearchParams } from "react-router-dom";
import SimilarTokensItem from "./SimilarTokensItem";
import type { SimilarTokensProps } from "./types";
import { TokenWithRarityRank } from "../../../../../types";

const SimilarTokensList: React.FC<SimilarTokensProps> = ({
  aspectRatio,
  tempCollectionState,
  layers,
  currentTokenId,
  tokens,
}) => {
  const setSearchParams = useSearchParams()[1];

  const similarTokensArray = React.useMemo(() => {
    const assetKeySet = new Set(
      tempCollectionState.tokenLayers
        .filter(({ visible }) => visible)
        .map((tokenLayer) => calculateAssetKey(tokenLayer))
    );

    const filteredTokens: (TokenWithRarityRank & { sameTraitCount: number })[] =
      [];

    for (const token of tokens) {
      if (token.id !== currentTokenId) {
        const sameTraitCount = token.tokenLayers.reduce((a, b) => {
          const assetKey = calculateAssetKey(b);
          if (b.visible && assetKeySet.has(assetKey)) {
            a++;
          }
          return a;
        }, 0);

        if (sameTraitCount >= token.tokenLayers.length - 2) {
          filteredTokens.push({ ...token, sameTraitCount });
        }
      }
    }

    return filteredTokens
      .sort((a, b) => (a.sameTraitCount > b.sameTraitCount ? -1 : 1))
      .slice(0, 10);
  }, [tempCollectionState, tokens, currentTokenId]);

  if (similarTokensArray.length === 0) {
    return null;
  }

  return (
    <div className="py-6 overflow-hidden">
      <h5 className="text-xl mb-3">Similar tokens in your collection</h5>
      <div className="flex overflow-auto pb-2">
        {similarTokensArray.map((token) => (
          <SimilarTokensItem
            token={token}
            key={token.id}
            aspectRatio={aspectRatio}
            setSearchParams={setSearchParams}
            layers={layers}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(SimilarTokensList);
