export default function DragHandle() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99999 7.98999L8.00999 7.99999L7.99999 8.00999L7.98999 7.99999L7.99999 7.98999Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99999 1.98999L8.00999 1.99999L7.99999 2.00999L7.98999 1.99999L7.99999 1.98999Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99999 13.99L8.00999 14L7.99999 14.01L7.98999 14L7.99999 13.99Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 7.98999L14.01 7.99999L14 8.00999L13.99 7.99999L14 7.98999Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 1.98999L14.01 1.99999L14 2.00999L13.99 1.99999L14 1.98999Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 13.99L14.01 14L14 14.01L13.99 14L14 13.99Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.99999 7.98999L2.00999 7.99999L1.99999 8.00999L1.98999 7.99999L1.99999 7.98999Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.99999 1.98999L2.00999 1.99999L1.99999 2.00999L1.98999 1.99999L1.99999 1.98999Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.99999 13.99L2.00999 14L1.99999 14.01L1.98999 14L1.99999 13.99Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
