export default function Drop() {
  return (
    <svg
      width="60"
      height="75"
      viewBox="0 0 64 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60 50C60 66.5685 46.5685 80 30 80C13.4315 80 0 66.5685 0 50C0 27.4315 30 0 30 0C30 0 60 27.4315 60 50Z"
        fill="url(#paint0_linear_2345_315997)"
      />
      <path
        d="M63 27.5C63 36.6127 55.8366 44 47 44C38.1634 44 31 36.6127 31 27.5C31 19.0873 47 0 47 0C47 0 63 19.0873 63 27.5Z"
        fill="#FDB403"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.0981 39.3855C55.2213 42.2429 51.3096 44.0001 47 44.0001C38.1634 44.0001 31 36.6128 31 27.5001C31 23.1191 35.3389 15.8435 39.4977 9.87598C46.1283 17.4361 54.4241 28.4006 58.0981 39.3855Z"
        fill="#FF7600"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2345_315997"
          x1="5.2355e-06"
          y1="80"
          x2="31.3725"
          y2="-9.98114"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC8100" />
          <stop offset="1" stopColor="#FDB403" />
        </linearGradient>
      </defs>
    </svg>
  );
}
