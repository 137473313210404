import React from "react";
import type { Placement } from "@popperjs/core";
import InfoIcon from "../../icons/Info";
import Tooltip from "./Tooltip";

const InfoTooltip: React.FC<{
  iconOpacity?: "opacity-100" | "opacity-70" | "opacity-60";
  contentBackgroundColor?: "bg-gray-light" | "bg-gray" | "bg-black";
  title: React.ReactNode;
  placement?: Placement;
}> = ({
  iconOpacity = "opacity-70",
  contentBackgroundColor = "bg-gray-light",
  title,
  placement,
}) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      className={`rounded-full inline-flex items-center justify-center ${iconOpacity}`}
      tooltipClassName={`shadow-md max-w-xs px-3 py-2 text-xs font-secondary text-white ${contentBackgroundColor} left-0`}
      offset={4}
    >
      <InfoIcon />
    </Tooltip>
  );
};

export default InfoTooltip;
