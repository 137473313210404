export default function SmallGrid() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="4" height="4" />
      <rect x="8" width="4" height="4" />
      <rect x="16" width="4" height="4" />
      <rect y="8" width="4" height="4" />
      <rect x="8" y="8" width="4" height="4" />
      <rect x="16" y="8" width="4" height="4" />
      <rect y="16" width="4" height="4" />
      <rect x="8" y="16" width="4" height="4" />
      <rect x="16" y="16" width="4" height="4" />
    </svg>
  );
}
