import React from "react";
import { AssetWithUrls, calculateAssetKey } from "lasagna-commons";
import AssetImageContainer from "../../../components/AssetImage/AssetImageContainer";
import AssetImage from "../../../components/AssetImage";
import { RecipeAssetKey } from "../types";
import Checkbox from "../../../components/Form/Checkbox";
import { RECIPE_PAGE_LIST_ITEM_HEIGHT } from "../../../constants/recipePageConstants";

interface RecipeModalListItemProps {
  asset: AssetWithUrls;
  layerId: string;
  currentAssetKeys: RecipeAssetKey[];
  addAsset: (assetKey: string) => void;
  removeAsset: (index: number) => void;
  disabled: boolean;
}

const RecipeModalListItem: React.FC<RecipeModalListItemProps> = ({
  asset,
  layerId,
  currentAssetKeys,
  removeAsset,
  addAsset,
  disabled,
}) => {
  const assetKey = calculateAssetKey({ layerId, assetId: asset.id });

  const assetKeyIndex = currentAssetKeys.indexOf(assetKey);

  const selected = assetKeyIndex !== -1;

  const handleClick = selected
    ? () => removeAsset(assetKeyIndex)
    : () => addAsset(assetKey);

  return (
    <button
      className={`w-full flex text-left items-center px-2 border-2 border-transparent border-b-black disabled:pointer-events-none disabled:opacity-30 ${
        selected
          ? "bg-primary text-black"
          : "bg-gray text-white hover:bg-gray-light"
      }`}
      style={{ height: RECIPE_PAGE_LIST_ITEM_HEIGHT }}
      onClick={handleClick}
      disabled={!selected && disabled}
    >
      <div className="w-20 flex-shrink-0 bg-gray-light border border-gray">
        <AssetImageContainer aspectRatio={1}>
          <AssetImage asset={asset} size="tiny" />
        </AssetImageContainer>
      </div>
      <div className="flex-grow px-3 overflow-hidden">
        <span className="block truncate">{asset.label}</span>
      </div>
      <div className="flex-shrink-0 flex items-center px-2">
        <Checkbox checked={selected} variant="secondary" />
      </div>
    </button>
  );
};

export default RecipeModalListItem;
