export default function Eye({ size = 16 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={(14 / 16) * size}
      viewBox="0 0 16 14"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.76834 5.23337C10.7442 6.2092 10.7442 7.79337 9.76834 8.77087C8.7925 9.7467 7.20834 9.7467 6.23084 8.77087C5.255 7.79504 5.255 6.21087 6.23084 5.23337C7.20834 4.25587 8.79167 4.25587 9.76834 5.23337Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 7.00008C0.5 6.45092 0.626667 5.90758 0.871667 5.40675C2.13417 2.82592 4.92417 1.16675 8 1.16675C11.0758 1.16675 13.8658 2.82592 15.1283 5.40675C15.3733 5.90758 15.5 6.45092 15.5 7.00008C15.5 7.54925 15.3733 8.09258 15.1283 8.59342C13.8658 11.1742 11.0758 12.8334 8 12.8334C4.92417 12.8334 2.13417 11.1742 0.871667 8.59342C0.626667 8.09258 0.5 7.54925 0.5 7.00008Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
