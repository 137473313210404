import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import type { DropResult } from "react-beautiful-dnd";
import DraggableModalItem from "./DraggableModalItem";
import type { TokenLayerMap, TempCollectionState } from "./types";
import { TempCollectionAction, UPDATE_LAYER_ORDER } from "./hooks";
import { AssetState } from "../../../../types";

const EditModalLayers: React.FC<{
  itemOrder: string[];
  setItemOrder: React.Dispatch<React.SetStateAction<string[]>>;
  tokenLayerMap: TokenLayerMap;
  tempCollectionState: TempCollectionState;
  tempCollectionDispatch: React.Dispatch<TempCollectionAction>;
  aspectRatio: number;
  layers: AssetState["layers"];
  searchKey: string | null;
  setSearchKey: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({
  aspectRatio,
  tempCollectionState,
  tempCollectionDispatch,
  tokenLayerMap,
  itemOrder,
  setItemOrder,
  layers,
  searchKey,
  setSearchKey,
}) => {
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newOrder = [...itemOrder];
    const [removed] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, removed);
    setItemOrder(newOrder);
    tempCollectionDispatch({
      type: UPDATE_LAYER_ORDER,
      payload: { tokenLayers: newOrder.map((key) => tokenLayerMap[key]) },
    });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="list">
        {({ innerRef, droppableProps, placeholder }) => (
          <div ref={innerRef} {...droppableProps}>
            {itemOrder.map((key, index) => {
              return (
                <DraggableModalItem
                  key={key}
                  index={index}
                  tokenLayer={tokenLayerMap[key]}
                  tempCollectionState={tempCollectionState}
                  tempCollectionDispatch={tempCollectionDispatch}
                  aspectRatio={aspectRatio}
                  layers={layers}
                  searchKey={searchKey}
                  setSearchKey={setSearchKey}
                />
              );
            })}
            {placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default EditModalLayers;
