import React from "react";
import ProjectNavbar from "../../../components/Navbar/ProjectNavbar";
import PageWrapper from "../../../components/PageWrapper";
import AssetFooter from "../AssetFooter";
import AssetEditCollapsedView from "./AssetEditCollapsedView";
import AssetEditDefaultView from "./AssetEditDefaultView";

const AssetEdit: React.FC = () => {
  const [showDefaultView, setShowDefaultView] = React.useState(true);

  return (
    <PageWrapper
      navbar={<ProjectNavbar />}
      footer={
        <AssetFooter
          showDefaultView={showDefaultView}
          setShowDefaultView={setShowDefaultView}
        />
      }
    >
      {showDefaultView ? <AssetEditDefaultView /> : <AssetEditCollapsedView />}
    </PageWrapper>
  );
};

export default AssetEdit;
