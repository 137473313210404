export default function CodeBrackets() {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.5 4L1.5 8L5.5 12" stroke="white" />
      <path d="M7 14L11 2" stroke="white" />
      <path d="M12.5 4L16.5 8L12.5 12" stroke="white" />
    </svg>
  );
}
