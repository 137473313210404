import React from "react";
import { FOOTER_HEIGHT, NAV_HEIGHT } from "../constants/layoutConstants";

const PageMainContent: React.FC<{
  children: React.ReactNode;
  centerContent?: boolean;
  addLayoutContainer?: boolean;
  paddingBottom?: number;
}> = ({
  children,
  centerContent,
  addLayoutContainer,
  paddingBottom = FOOTER_HEIGHT,
}) => {
  return (
    <main
      style={{ paddingTop: NAV_HEIGHT, paddingBottom }}
      className={`min-h-screen ${
        centerContent ? "flex flex-col justify-center" : ""
      } ${addLayoutContainer ? "px-layout" : ""}`}
    >
      {children}
    </main>
  );
};

export default PageMainContent;
