import React from "react";
import InputBase from "./InputBase";
import type { InputBaseProps } from "./types";

const NumberInput: React.FC<
  InputBaseProps & { min: number; max: number; decimalScale?: number }
> = (props) => {
  return <InputBase {...props} componentType="number" />;
};

export default NumberInput;
