import React from "react";
import Card from "../../../../components/Card";
import FolderOpen from "../../../../icons/FolderOpen";
import FolderItem from "./FolderItem";
import ImageItem from "./ImageItem";

const InstructionsSection: React.FC = () => {
  return (
    <Card
      headerIcon={<FolderOpen />}
      headerText="Folder structure"
      bodyText="Create folders for each layer in your project, then put the assets for every layer in their respective folder."
      button={{
        linkText: "Learn more",
        linkHref: "https://lasagna.xyz/guides/add-assets",
        type: "link",
      }}
    >
      <>
        <div className="h-48 flex items-center px-6">
          <div className="text-xs font-secondary">
            <FolderItem label="My NFT project" />
            <ul className="ml-7">
              <li>
                <FolderItem label="Background" />
                <ul className="ml-12">
                  <li>
                    <ImageItem label="purple.png" />
                  </li>
                  <li>
                    <ImageItem label="green.png" />
                  </li>
                </ul>
              </li>
              <li>
                <FolderItem label="Body" />
                <ul className="ml-12">
                  <li>
                    <ImageItem label="human.png" />
                  </li>
                  <li>
                    <ImageItem label="robot.png" />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="px-2">
          <span className="h-0.5 bg-black block" />
        </div>
      </>
    </Card>
  );
};

export default InstructionsSection;
