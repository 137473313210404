import React from "react";
import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import FontFaceObserver from "fontfaceobserver";
import { useAddress } from "@thirdweb-dev/react";
import { AssetProvider } from "../AssetContext";
import TokenPage from "../../pages/Tokens";
import { TokenProvider } from "../TokenContext";
import LoadingIcon from "../LoadingIcon";
import { useAuth } from "../AuthContext";
import LoginPage from "../../pages/Login";
import HomePage from "../../pages/Home";
import LaunchPage from "../../pages/Launch";
import { CollectionProvider } from "../CollectionContext";
import AssetPage from "../../pages/Assets";
import CollectionHome from "../../pages/Collections";
import useAuthRefresh from "../../utils/hooks/useAuthRefresh";
import PageWrapper from "../PageWrapper";
import { REDIRECT_TO_QUERY_PARAM } from "../../constants";
import PageNotFound from "../../pages/PageNotFound";
import RecipesPage from "../../pages/Recipes";
import { RecipeProvider } from "../RecipeContext";
import PageMainContent from "../PageMainContent";
import AdminPage from "../../pages/Admin";
import EmailVerificationPage from "../../pages/Login/EmailVerificationPage";
import Redirect from "../../pages/Login/EmailVerificationPage/Redirect";

const Main: React.FC = () => {
  const { fontsLoaded, authenticating, delayFinished } = useAppLoader();
  const { user } = useAuth();
  const address = useAddress();

  if (fontsLoaded && !authenticating && delayFinished) {
    if (user && ((user.address && address) || !user.address)) {
      return (
        <RedirectWrapper>
          <CollectionProvider>
            <AssetProvider>
              <RecipeProvider>
                <TokenProvider>
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route
                      path="/collections/:collectionId"
                      element={<CollectionHome />}
                    />
                    <Route
                      path="/collections/:collectionId/assets"
                      element={<AssetPage />}
                    />
                    <Route
                      path="/collections/:collectionId/recipes"
                      element={<RecipesPage />}
                    />
                    <Route
                      path="/collections/:collectionId/tokens"
                      element={<TokenPage />}
                    />
                    {user.address && (
                      <Route
                        path="/collections/:collectionId/launch"
                        element={<LaunchPage />}
                      />
                    )}
                    <Route
                      path="/email-verification/:userId/:nonce"
                      element={<Redirect />}
                    />
                    {user.isAdmin && (
                      <Route path="/admin" element={<AdminPage />} />
                    )}
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </TokenProvider>
              </RecipeProvider>
            </AssetProvider>
          </CollectionProvider>
        </RedirectWrapper>
      );
    }
    return (
      <Routes>
        <Route
          path="/email-verification/:userId/:nonce"
          element={<EmailVerificationPage />}
        />
        <Route path="*" element={<LoginPage />} />
      </Routes>
    );
  }
  return (
    <PageWrapper>
      <PageMainContent centerContent>
        <div className="relative">
          <LoadingIcon />
          {fontsLoaded && delayFinished && (
            <div className="text-center absolute left-0 right-0">
              Authenticating...please wait.
            </div>
          )}
        </div>
      </PageMainContent>
    </PageWrapper>
  );
};

const RedirectWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const redirectTo = params.get(REDIRECT_TO_QUERY_PARAM);

  React.useEffect(() => {
    if (redirectTo) {
      navigate(redirectTo);
    }
  }, [navigate, redirectTo]);

  return <>{children}</>;
};

const fonts = [
  { name: "Mabry", weight: "normal" },
  { name: "Mabry", weight: "bold" },
  { name: "Mabry Mono", weight: "normal" },
];

const fontFaceObservers = fonts.map(
  ({ name, weight }) => new FontFaceObserver(name, { weight })
);

function useAppLoader() {
  const [authenticating, setAuthenticating] = React.useState(true);
  const [fontsLoaded, setFontsLoaded] = React.useState(false);
  const [delayFinished, setDelayFinished] = React.useState(false);

  const authRefresh = useAuthRefresh();

  const authCallback = React.useCallback(async () => {
    await authRefresh({ failSilently: true });
    setAuthenticating(false);
  }, [authRefresh]);

  React.useEffect(() => {
    authCallback();
  }, [authCallback]);

  React.useEffect(() => {
    const timerId = window.setTimeout(() => {
      setDelayFinished(true);
    }, 1000);

    return () => {
      window.clearTimeout(timerId);
    };
  }, []);

  React.useLayoutEffect(() => {
    if (
      window.navigator.userAgent.indexOf("Mac") !== -1 &&
      !window.document.body.className.includes(MAC_CLASSNAME)
    ) {
      window.document.body.className += ` ${MAC_CLASSNAME}`; // used for hiding scrollbars on macs (it looks better)
    }

    Promise.all(fontFaceObservers.map((observer) => observer.load())).finally(
      () => setFontsLoaded(true)
    );
  }, []);

  return {
    fontsLoaded,
    authenticating,
    delayFinished,
  };
}

export default Main;

const MAC_CLASSNAME = "is-mac";
