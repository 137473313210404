import React from "react";
import CloseIcon from "../../icons/Close";
import SearchIcon from "../../icons/Search";
import IconButton from "../Button/IconButton";
import TextInput from "./TextInput";

interface SearchInputProps {
  placeholder?: string;
  name: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  isDark?: boolean;
}

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  name,
  value,
  setValue,
  isDark,
}) => {
  const clearValue = () => {
    setValue("");
  };

  return (
    <TextInput
      placeholder={placeholder}
      name={name}
      setValue={setValue}
      value={value}
      endAdornment={
        value ? (
          <IconButton
            onClick={clearValue}
            className={isDark ? "text-black opacity-100" : ""}
          >
            <CloseIcon size={22} />
          </IconButton>
        ) : (
          <span className={`${isDark ? "" : "opacity-50"} flex items-center`}>
            <SearchIcon />
          </span>
        )
      }
      isDark={isDark}
    />
  );
};

export default SearchInput;
