import React from "react";
import Portal from "./Portal";
import { MIN_SCREEN_WIDTH } from "../constants";

const FullScreenModal: React.FC<{
  className?: string;
  children: React.ReactNode;
  onClose: () => void;
}> = ({ children, className = "", onClose }) => {
  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  React.useEffect(() => {
    const htmlElement = document.getElementsByTagName("html")[0];
    htmlElement.style.overflow = "hidden";
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      htmlElement.style.overflow = null as unknown as string;
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Portal mounted={true}>
      <div
        className="fixed inset-0 z-30"
        role="presentation"
        style={{
          minWidth: MIN_SCREEN_WIDTH,
        }}
      >
        <div className={`h-screen w-full bg-black ${className}`}>
          {children}
        </div>
      </div>
    </Portal>
  );
};

export default FullScreenModal;
