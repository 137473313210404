export default function PuzzlePiece() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <mask id="path-1-inside-1_1791_16419" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H16V4C18.2091 4 20 5.79086 20 8C20 10.2091 18.2091 12 16 12V16H12C12 18.2091 10.2091 20 8 20C5.79086 20 4 18.2091 4 16H0V0Z"
        />
      </mask>
      <path
        d="M16 0H17.5V-1.5H16V0ZM0 0V-1.5H-1.5V0H0ZM16 4H14.5V5.5H16V4ZM16 12V10.5H14.5V12H16ZM16 16V17.5H17.5V16H16ZM12 16V14.5H10.5V16H12ZM4 16H5.5V14.5H4V16ZM0 16H-1.5V17.5H0V16ZM16 -1.5H0V1.5H16V-1.5ZM17.5 4V0H14.5V4H17.5ZM21.5 8C21.5 4.96243 19.0376 2.5 16 2.5V5.5C17.3807 5.5 18.5 6.61929 18.5 8H21.5ZM16 13.5C19.0376 13.5 21.5 11.0376 21.5 8H18.5C18.5 9.38071 17.3807 10.5 16 10.5V13.5ZM17.5 16V12H14.5V16H17.5ZM12 17.5H16V14.5H12V17.5ZM10.5 16C10.5 17.3807 9.38071 18.5 8 18.5V21.5C11.0376 21.5 13.5 19.0376 13.5 16H10.5ZM8 18.5C6.61929 18.5 5.5 17.3807 5.5 16H2.5C2.5 19.0376 4.96243 21.5 8 21.5V18.5ZM0 17.5H4V14.5H0V17.5ZM-1.5 0V16H1.5V0H-1.5Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_1791_16419)"
      />
    </svg>
  );
}
