import React from "react";
import { calculateAssetKey } from "lasagna-commons";
import CheckboxInput from "../../../components/Form/CheckboxInput";
import { useTokens } from "../../../components/TokenContext/hooks";
import ChevronRightIcon from "../../../icons/ChevronRight";
import {
  numberWithDelimiters,
  searchFilter,
  sortByLabel,
} from "../../../utils";
import { TokenFilterProps } from "../types";
import Checkbox from "../../../components/Form/Checkbox";
import SearchInput from "../../../components/Form/SearchInput";
import CheckIcon from "../../../icons/Check";
import Tooltip from "../../../components/Popper/Tooltip";

const FilterContainer: React.FC<
  TokenFilterProps & {
    layerLabel: string;
    layerId: string;
    assets: { id: string; label: string }[];
  }
> = ({ layerLabel, layerId, assets, tokenFilters, setTokenFilters }) => {
  const [filterSelected, setFilterSelected] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [collapsed, setCollapsed] = React.useState(false);
  const listRef = React.useRef<HTMLDivElement>(null);

  const filteredAssets = React.useMemo(() => {
    const sorted = assets.sort(sortByLabel);

    if (searchValue && filterSelected) {
      return sorted.filter(
        ({ label, id }) =>
          searchFilter(label, searchValue) && tokenFilters[layerId][id]
      );
    }

    if (searchValue) {
      return sorted.filter(({ label }) => searchFilter(label, searchValue));
    }

    if (filterSelected) {
      return sorted.filter(({ id }) => tokenFilters[layerId][id]);
    }

    return sorted;
  }, [assets, searchValue, tokenFilters, filterSelected, layerId]);

  const {
    tokens,
    meta: { assetCounts },
  } = useTokens();

  const toggleCollapsed = () => {
    setCollapsed((val) => !val);
    if (collapsed) {
      setFilterSelected(false);
    }
  };

  const toggleFilter = () => {
    setFilterSelected((val) => !val);
  };

  const totalFilters = Object.keys(tokenFilters[layerId]).length;

  const allSelected = totalFilters === assets.length;

  const handleClick = () => {
    setTokenFilters((current) => {
      return {
        ...current,
        [layerId]: setAllFilters(assets, !allSelected),
      };
    });
  };

  return (
    <div className="bg-gray shadow-layer-gray p-1 overflow-hidden mb-4 text-sm">
      <div className="flex items-center">
        <div className="flex-shrink-0 pl-2 pr-1">
          <button className="flex" onClick={handleClick}>
            <Checkbox checked={allSelected} />
          </button>
        </div>
        <div className="flex-grow overflow-hidden">
          <button
            className="flex items-center justify-between p-2 w-full font-secondary text-left"
            onClick={toggleCollapsed}
          >
            <span className="truncate flex-grow">{layerLabel}</span>
            <span className="flex items-center flex-shrink-0">
              {totalFilters > 0 && (
                <span
                  className={`mr-3 ml-2 px-1.5 py-0.5 flex items-center justify-center text-xs text-black bg-primary`}
                >
                  {totalFilters}
                </span>
              )}
              <span
                className={`flex flex-shrink-0 transform transition-transform ${
                  collapsed ? "rotate-90" : ""
                }`}
              >
                <ChevronRightIcon />
              </span>
            </span>
          </button>
        </div>
      </div>
      <div className={`bg-black ${collapsed ? "block" : "hidden"}`}>
        <div className="p-2 max-h-56 overflow-auto" ref={listRef}>
          {assets.length > 7 && (
            <div className="mb-2 flex items-center">
              {totalFilters > 0 && (
                <div className="flex-shrink-0 flex mr-3">
                  <Tooltip title="Filter for selected traits">
                    <button
                      className={`w-5 h-5 border-2 ${
                        filterSelected
                          ? "border-primary text-primary"
                          : "border-white opacity-60 hover:opacity-100"
                      }`}
                      onClick={toggleFilter}
                    >
                      <CheckIcon />
                    </button>
                  </Tooltip>
                </div>
              )}
              <div className="flex-grow">
                <SearchInput
                  value={searchValue}
                  setValue={setSearchValue}
                  name={`search-${layerId}`}
                  placeholder="Search traits"
                />
              </div>
            </div>
          )}
          <ul>
            {filteredAssets.map(({ id: assetId, label }) => {
              const checked = Boolean(tokenFilters[layerId][assetId]);
              const handleChange = () => {
                setTokenFilters((current) => {
                  const assetIds = { ...current[layerId] };
                  if (checked) {
                    delete assetIds[assetId];
                  } else {
                    assetIds[assetId] = true;
                  }
                  return {
                    ...current,
                    [layerId]: assetIds,
                  };
                });
              };

              const count = assetCounts[layerId][assetId] || 0;

              return (
                <li key={assetId} className="py-1 group">
                  <CheckboxInput
                    name={calculateAssetKey({ layerId, assetId })}
                    checked={checked}
                    onChange={handleChange}
                  >
                    <span className="flex flex-grow items-center justify-between">
                      <span className="truncate flex-grow pr-2">{label}</span>
                      <span className="block flex-shrink-0 pr-1 group-hover:hidden">
                        {numberWithDelimiters(count)}
                      </span>
                      <span className="hidden flex-shrink-0 pr-1 group-hover:block">
                        {((count / tokens.length) * 100).toFixed(1)}
                        <span className="pl-0.5">%</span>
                      </span>
                    </span>
                  </CheckboxInput>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FilterContainer;

function setAllFilters(
  assets: { id: string; label: string }[],
  value: boolean
) {
  if (!value) {
    return {};
  }

  return assets.reduce<{ [key: string]: boolean }>((a, b) => {
    a[b.id] = true;
    return a;
  }, {});
}
