import React, { ChangeEvent, ReactNode } from "react";
import type { FileWithPath } from "react-dropzone";

const FileInput: React.FC<{
  type: "file" | "directory";
  children: ReactNode;
  onFilesAdded: (files: FileWithPath[]) => void;
  disabled?: boolean;
  multiple?: boolean;
}> = ({ type, children, onFilesAdded, disabled, multiple }) => {
  // For some reason it doesn't do anything after you upload files one time...
  // forcing the input to re-render seems to fix it ¯\_(ツ)_/¯

  const [forceRerender, setForceRerender] = React.useState(false);

  React.useEffect(() => {
    if (forceRerender) {
      setForceRerender(false);
    }
  }, [forceRerender]);

  const inputProps = React.useMemo(() => {
    if (type === "directory") {
      return {
        webkitdirectory: "true",
        directory: "true",
      };
    }

    return {};
  }, [type]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const filesWithPath: FileWithPath[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = Object.assign(files[i], {
          path: files[i].webkitRelativePath || `/${files[i].name}`,
        });

        filesWithPath.push(file);
      }

      onFilesAdded(filesWithPath);
      setForceRerender(true);

      return;
    }
  };

  return (
    <label className="cursor-pointer">
      {children}
      {!forceRerender && (
        <input
          type="file"
          {...inputProps}
          onChange={onChange}
          hidden
          disabled={disabled}
          multiple={multiple}
        />
      )}
    </label>
  );
};

export default FileInput;
