import type { FileWithPath } from "react-dropzone";
import type { Dispatch, SetStateAction } from "react";
import type {
  AssetErrorMessage,
  InitialClientAssetWithLayerId,
} from "../../../../types";
import { sortByLabel, validateUploadedFile } from "../../../../utils";
import { AssetMeta, LayerInput, MAX_ASSET_COUNT } from "lasagna-commons";
import { AssetUploadError } from "../../../../utils/AssetUploadError";
import type { ProgressProps } from "../../../../components/Progress/types";

export async function processInitialFiles(
  files: FileWithPath[],
  setProcessedFileProgress: Dispatch<SetStateAction<ProgressProps>>
): Promise<{
  assets: InitialClientAssetWithLayerId[];
  layers: LayerInput[];
  assetMeta: AssetMeta;
  errors: AssetErrorMessage[];
}> {
  const assets: InitialClientAssetWithLayerId[] = [];
  const layers: Omit<LayerInput, "layerOrder">[] = [];
  const labelKeyMap: { [key: string]: string } = {};
  let initialAssetMeta: AssetMeta | null = null;

  if (files.length > MAX_ASSET_COUNT) {
    throw new AssetUploadError([
      {
        name: undefined,
        reason: `You have attempted to load ${files.length} assets - you can only load up to ${MAX_ASSET_COUNT} assets.`,
      },
    ]);
  }

  const errors: AssetErrorMessage[] = [];

  let fileCount = 0;

  for (const file of files) {
    const { pathArray, asset, assetError, assetMeta } =
      await validateUploadedFile({
        file,
        assetMeta: initialAssetMeta,
        deriveAssetId: fileCount++,
        directoryValidation: {
          layerCount: 3,
          errorMessage: "file must be in a folder within a parent directory",
        },
      });

    if (!initialAssetMeta && assetMeta) {
      // @ts-expect-error - for some reason TS doesn't like the circular reference here
      const clonedAssetMeta = { ...assetMeta };
      initialAssetMeta = clonedAssetMeta;
    }

    if (assetError) {
      errors.push(assetError);
    } else if (asset) {
      const layerName = pathArray[1];

      // Since the user will be able to edit the name of the layer,
      // we just want to assign the object key as an uneditable index
      let layerId = labelKeyMap[layerName];

      if (!layerId) {
        const length = Object.keys(labelKeyMap).length;
        layerId = length.toString();
        labelKeyMap[layerName] = layerId;
        layers.push({ id: layerId, label: layerName });
      }

      assets.push({ ...asset, layerId });

      setProcessedFileProgress(({ numerator, denominator }) => ({
        numerator: numerator + 1,
        denominator,
      }));
    }
  }

  if (!initialAssetMeta) {
    if (errors.length) {
      throw new AssetUploadError(errors);
    }

    throw new Error(
      "No compatible files were found, or your folder structure is incorrect."
    );
  }

  return {
    assets,
    assetMeta: initialAssetMeta,
    layers: layers
      .sort(sortByLabel)
      .map((layer, index) => ({ ...layer, layerOrder: index })),
    errors,
  };
}
