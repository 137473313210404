import React from "react";
import IconButton from "../../../components/Button/IconButton";
import NumberInput from "../../../components/Form/NumberInput";
import TextInput from "../../../components/Form/TextInput";
import PlusIcon from "../../../icons/Plus";
import TrashIcon from "../../../icons/Trash";
import type { MetadataFieldProps } from "./types";

const MetadataListField: React.FC<MetadataFieldProps> = ({
  customTokenMetadata,
  setCustomTokenMetadata,
  metadataKey,
}) => {
  const values = customTokenMetadata[metadataKey];

  const addRow = () => {
    setCustomTokenMetadata((current) => {
      const newFields = { ...current };
      newFields[metadataKey].push({ address: "", share: 0 });
      return newFields;
    });
  };

  return (
    <div>
      {values.map(
        (value: { address: string; share: number }, index: number) => {
          const deleteRow = () => {
            setCustomTokenMetadata((current) => {
              const newFields = { ...current };
              newFields[metadataKey].splice(index, 1);
              return newFields;
            });
          };
          return (
            <div className="flex items-center w-full mb-3" key={index}>
              <div className="flex-grow">
                <TextInput
                  value={value.address}
                  setValue={(val: string) => {
                    setCustomTokenMetadata((current) => {
                      const newFields = { ...current };
                      newFields[metadataKey][index].address = val;
                      return newFields;
                    });
                  }}
                  name={`address-${index}`}
                  label={{ text: "Address" }}
                />
              </div>
              <div className="w-32 px-3 flex-shrink-0">
                <NumberInput
                  value={value.share}
                  setValue={(val: string) => {
                    setCustomTokenMetadata((current) => {
                      const newFields = { ...current };
                      newFields[metadataKey][index].share = Number(
                        val.replace(/,/g, "")
                      );
                      return newFields;
                    });
                  }}
                  name={`share-${index}`}
                  label={{ text: "Share" }}
                  min={0}
                  max={100}
                  decimalScale={2}
                />
              </div>
              <div className="w-7 flex-shrink-0">
                <IconButton onClick={deleteRow} title="Remove row">
                  <TrashIcon size={22} />
                </IconButton>
              </div>
            </div>
          );
        }
      )}
      <div className="mt-4">
        <button
          className="border border-white px-2 py-1 font-secondary inline-flex items-center text-sm bg-white bg-opacity-0 hover:bg-opacity-10"
          type="button"
          onClick={addRow}
        >
          <PlusIcon size={12} />
          <span className="ml-2">Add creator</span>
        </button>
      </div>
    </div>
  );
};

export default MetadataListField;
