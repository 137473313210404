import React from "react";
import type { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { Collection, MAX_COLLECTION_SIZE, wait } from "lasagna-commons";
import Button from "../Button";
import NumberInput from "../Form/NumberInput";
import { pluralize } from "../../utils";
import { API_ENDPOINT } from "../../config";
import { useCollectionDispatch } from "../CollectionContext";
import useCustomFetch from "../../utils/hooks/useCustomFetch";
import useFetchTokens from "../../utils/hooks/useFetchTokens";
import RotateIcon from "../../icons/Rotate";
import { useSnackbarDispatch } from "../SnackbarContext";
import { useCurrentCollection } from "../CollectionContext/hooks";
import { useAssets } from "../AssetContext/hooks";
import { useTokenDispatch } from "../TokenContext";
import useTotalPossibleCombinations from "../../utils/hooks/useTotalPossibleCombinations";
import WarningMessage from "../WarningMessage";
import type { TokenSubmissionStatus } from "./hooks";
import LottieIcon from "../LottieIcon";
import generateAnimationData from "../LottieIcon/animations/generate-tokens.json";
import regenerateAnimationData from "../LottieIcon/animations/regenerate-tokens.json";

const ERROR_MESSAGE = "Tokens could not be generated";

const GenerateTokensForm: React.FC<{
  setStatus: Dispatch<SetStateAction<TokenSubmissionStatus>>;
  isRegenerateForm?: boolean;
  tokensPage?: "beforeGen" | "afterGen";
}> = ({ setStatus, isRegenerateForm, tokensPage }) => {
  const collection = useCurrentCollection();
  const assetState = useAssets();
  const maxCollectionSize = Math.min(
    MAX_COLLECTION_SIZE,
    useTotalPossibleCombinations()
  );
  const updatedCollection = React.useRef<Collection>();

  const [collectionSize, setCollectionSize] = React.useState<string>(() => {
    return collection.tokenCount
      ? `${Math.min(maxCollectionSize, collection.tokenCount)}`
      : "";
  });

  React.useEffect(() => {
    if (collection.tokenCount) {
      setCollectionSize(
        `${Math.min(maxCollectionSize, collection.tokenCount)}`
      );
    }
  }, [collection.tokenCount, maxCollectionSize]);

  const collectionDispatch = useCollectionDispatch();
  const fetcher = useCustomFetch();
  const fetchTokens = useFetchTokens();
  const setSnackbarMessage = useSnackbarDispatch();
  const tokenDispatch = useTokenDispatch();
  const collectionId = collection.id;

  const updatesPending = assetState.updateStack.length > 0;

  const { heading, subheading, note, buttonLabel } = React.useMemo(() => {
    if (isRegenerateForm) {
      return {
        heading: "Regenerate tokens",
        subheading: `Update your tokens based on your asset and recipe changes. You can generate up to ${pluralize(
          maxCollectionSize,
          "token"
        )}.`,
        note: (
          <WarningMessage>
            Regenerating your tokens will overwrite all previously generated
            tokens and replace them with new results based on your updated
            configurations. This process cannot be reversed...gone means gone!
          </WarningMessage>
        ),
        buttonLabel: (
          <>
            <RotateIcon />
            <span className="ml-3">Regenerate tokens</span>
          </>
        ),
      };
    }
    return {
      heading: "Generate your tokens",
      subheading: `You can generate up to ${pluralize(
        maxCollectionSize,
        "token"
      )}.`,
      note: null,
      buttonLabel: "Generate tokens",
    };
  }, [isRegenerateForm, maxCollectionSize]);

  const handleSubmit = async (event: React.FormEvent) => {
    try {
      event.preventDefault();

      const initialCollectionSize = parseInt(collectionSize.replace(/,/g, ""));

      updatedCollection.current = {
        ...collection,
        hasStartedGeneratingTokens: true,
        hasFinishedGeneratingTokens: false,
        tokenGenerationProgress: 0,
        tokenGenerationError: "",
        tokenCount: initialCollectionSize,
      };

      collectionDispatch((current) => {
        if (!current || !updatedCollection.current) {
          return;
        }

        return {
          ...current,
          [collectionId]: updatedCollection.current,
        };
      });

      setStatus("generating");

      const data = await fetcher<{
        success: boolean;
      }>({
        url: `${API_ENDPOINT}/collections/${collectionId}/tokens`,
        method: "POST",
        body: {
          collectionSize: initialCollectionSize,
        },
      });

      if (!data) {
        throw new Error(ERROR_MESSAGE);
      }

      while (!updatedCollection.current.hasFinishedGeneratingTokens) {
        const data = await fetcher<{ collection: Collection }>({
          url: `${API_ENDPOINT}/collections/${collection.id}`,
          method: "GET",
        });

        if (!data || data.collection.tokenGenerationError) {
          throw new Error(ERROR_MESSAGE);
        }

        if (tokensPage === "beforeGen") {
          data.collection.mustRegenerate = Boolean(
            !data.collection.hasFinishedGeneratingTokens
          ); // Prevents errors when regenerating from the tokens page
        }

        updatedCollection.current = data.collection;

        collectionDispatch((current) => {
          if (!current) {
            return;
          }

          return {
            ...current,
            [collection.id]: data.collection,
          };
        });

        if (!updatedCollection.current.hasFinishedGeneratingTokens) {
          await wait(3000);
        }
      }

      tokenDispatch({
        collectionId,
        tokens: [],
        meta: { assetCounts: {}, tokenSet: new Set() },
        updateStack: [],
        generatingOnTokensPage: tokensPage,
      });

      setStatus("fetching");

      await fetchTokens({
        currentCollection: updatedCollection.current,
        assetState,
      });

      if (initialCollectionSize !== updatedCollection.current.tokenCount) {
        setSnackbarMessage(
          `Due to recipe restrictions, we could only generate ${updatedCollection.current.tokenCount} tokens`
        );
      }
    } catch {
      setStatus(null);

      collectionDispatch((current) => {
        if (!current) {
          return;
        }

        return {
          ...current,
          [collectionId]: {
            ...current[collectionId],
            tokenGenerationError: ERROR_MESSAGE,
          },
        };
      });
    }
  };

  if (maxCollectionSize === 0) {
    return (
      <div>
        <h2 className="mb-4">No token combinations are available 😔</h2>
        <p className="opacity-60">
          You'll need to make sure all of your layers have at least one valid
          asset before you can create any tokens.
        </p>
        {!isRegenerateForm && (
          <div className="mt-2">
            <Link
              to={`/collections/${collectionId}/assets`}
              className="text-primary underline"
            >
              View assets
            </Link>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <LottieIcon
        animationData={
          isRegenerateForm ? regenerateAnimationData : generateAnimationData
        }
      />
      <h2>{heading}</h2>
      <p className="opacity-60">{subheading}</p>
      {collection.tokenGenerationError && (
        <p className="text-red mt-1">
          {collection.tokenGenerationError} - you might need to try again.
        </p>
      )}
      <form action="#" onSubmit={handleSubmit} className="mt-8">
        <div className="mb-6">
          <NumberInput
            name="collectionSize"
            setValue={setCollectionSize}
            value={collectionSize}
            label={{ text: "How many tokens?" }}
            placeholder="Enter your collection size"
            min={1}
            max={maxCollectionSize}
          />
        </div>
        {note}
        <Button
          fullWidth
          disabled={!collectionSize || updatesPending}
          type="submit"
        >
          {updatesPending ? "Saving assets...please wait" : buttonLabel}
        </Button>
      </form>
    </>
  );
};

export default GenerateTokensForm;
