export default function ChevronLeft({
  size = "normal",
}: {
  size?: "normal" | "large";
}) {
  const { width, height } =
    size === "normal" ? { width: 8, height: 12 } : { width: 12, height: 16 };

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.46651 1.47061L5.99589 0.939342L7.05844 1.99812L6.52905 2.52939L5.46651 1.47061ZM2 6.012L1.47047 6.54313L0.939486 6.01374L1.46873 5.48261L2 6.012ZM6.52953 9.46887L7.06066 9.99841L6.00159 11.0607L5.47047 10.5311L6.52953 9.46887ZM6.52905 2.52939L2.53127 6.54139L1.46873 5.48261L5.46651 1.47061L6.52905 2.52939ZM2.52953 5.48087L6.52953 9.46887L5.47047 10.5311L1.47047 6.54313L2.52953 5.48087Z" />
    </svg>
  );
}
