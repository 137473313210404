import React from "react";
import FilterContainer from "./FilterContainer";
import type { TokenFilterProps } from "../types";
import { sortLayers } from "lasagna-commons";
import { useAssets } from "../../../components/AssetContext/hooks";
import SaveTokenIcon from "../../../icons/SaveToken";
import Checkbox from "../../../components/Form/Checkbox";
import {
  ASIDE_WIDTH,
  FOOTER_HEIGHT,
  NAV_HEIGHT,
} from "../../../constants/layoutConstants";
import { TOKEN_PAGE_HEADER_HEIGHT } from "../../../constants/tokenPageConstants";

const TokenFilters: React.FC<
  TokenFilterProps & {
    savedTokenFilter: boolean;
    setSavedTokenFilter: React.Dispatch<React.SetStateAction<boolean>>;
    isReadOnly: boolean;
  }
> = ({
  tokenFilters,
  setTokenFilters,
  savedTokenFilter,
  setSavedTokenFilter,
  isReadOnly,
}) => {
  const { layers } = useAssets();
  const toggleTokenFilter = () => {
    setSavedTokenFilter((val) => !val);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [tokenFilters]);

  return (
    <>
      <aside
        style={{
          width: ASIDE_WIDTH,
          top: NAV_HEIGHT + TOKEN_PAGE_HEADER_HEIGHT,
          bottom: isReadOnly ? 0 : FOOTER_HEIGHT,
        }}
        className="fixed overflow-hidden pr-4 -ml-1"
      >
        <div className="overflow-auto h-full pr-2 pl-1">
          <div className="mb-4">
            <button
              onClick={toggleTokenFilter}
              className={`cursor-pointer flex items-center justify-between w-full text-sm py-2 px-3 ${
                savedTokenFilter
                  ? "bg-primary text-black shadow-layer-primary"
                  : "bg-gray shadow-layer-gray hover:bg-gray-light hover:shadow-layer-gray-light"
              }`}
            >
              <span className="inline-flex items-center">
                <SaveTokenIcon />
                <span className="ml-2">Custom tokens only</span>
              </span>
              <span>
                <Checkbox checked={savedTokenFilter} variant="secondary" />
              </span>
            </button>
          </div>
          {Object.entries(layers)
            .sort((a, b) => sortLayers(a[1], b[1]))
            .map(([layerId, layer]) => {
              const assets = Object.values(layer.assets).map(
                ({ id, label }) => ({
                  id,
                  label,
                })
              );

              return (
                <FilterContainer
                  key={layerId}
                  tokenFilters={tokenFilters}
                  setTokenFilters={setTokenFilters}
                  layerId={layerId}
                  layerLabel={layer.label}
                  assets={assets}
                />
              );
            })}
        </div>
      </aside>
      <div className="flex-shrink-0" style={{ width: ASIDE_WIDTH }} />
    </>
  );
};

export default TokenFilters;
