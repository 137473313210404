import React from "react";
import cloneDeep from "lodash/cloneDeep";
import type { FileWithPath } from "react-dropzone";
import { useCurrentCollection } from "../../../../components/CollectionContext/hooks";
import useAssetUpload from "../../../../utils/hooks/useAssetUpload";
import { AssetUploadError } from "../../../../utils/AssetUploadError";
import { AssetMeta } from "lasagna-commons";
import { AssetErrorMessage, InitialClientAsset } from "../../../../types";
import { validateUploadedFile } from "../../../../utils";
import FileInput from "../../../../components/Form/FileInput";
import RotateIcon from "../../../../icons/Rotate";
import { menuButtonStyles } from "./AssetGridItemMenu";

const AssetGridItemFileInput: React.FC<{
  layerId: string;
  assetId: string;
  assetLabel: string;
  setMouseIsOver: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ layerId, assetId, assetLabel, setMouseIsOver }) => {
  const collection = useCurrentCollection();

  const { setLoading, setSnackbarMessage, assetDispatch, updateAsset, modals } =
    useAssetUpload();

  const handleFilesAdded = async (files: FileWithPath[]) => {
    setLoading(true);

    try {
      if (!collection.assetMeta) {
        throw new Error("Asset meta is required");
      }

      const { asset } = await processAsset({
        files,
        assetId,
        assetMeta: collection.assetMeta,
      });

      await updateAsset(asset, layerId);

      assetDispatch((current) => {
        if (!current) {
          return;
        }
        const assetState = cloneDeep(current);

        assetState.layers[layerId].assets[asset.id].urls = {
          default: asset.previewUrl,
        };

        return assetState;
      });

      setSnackbarMessage(`Asset "${assetLabel}" replaced successfully`);
    } catch (err) {
      if (err instanceof AssetUploadError) {
        const errors = err.messages;
        assetDispatch((current) => {
          if (!current) {
            return;
          }
          return {
            ...current,
            errors,
          };
        });
      } else {
        setSnackbarMessage("An error occurred uploading your files", "error");
      }
    }

    setMouseIsOver(false);

    setLoading(false);
  };

  return (
    <>
      <FileInput onFilesAdded={handleFilesAdded} type="file">
        <span className={menuButtonStyles}>
          <RotateIcon />
        </span>
      </FileInput>
      {modals}
    </>
  );
};

export default AssetGridItemFileInput;

async function processAsset({
  files,
  assetId,
  assetMeta,
}: {
  files: FileWithPath[];
  assetId: string;
  assetMeta: AssetMeta;
}): Promise<{
  asset: InitialClientAsset;
}> {
  if (files.length > 1) {
    throw new AssetUploadError([
      {
        name: "",
        reason:
          "You selected multiple files. Only one file can be used to replace an asset",
      },
    ]);
  }

  const file = files[0];

  let error: AssetErrorMessage | null = null;

  const { asset, assetError } = await validateUploadedFile({
    file,
    assetMeta,
    deriveAssetId: () => {
      return assetId;
    },
  });

  error = error || assetError;

  if (error) {
    throw new AssetUploadError([error]);
  }

  if (!asset) {
    throw new AssetUploadError([
      { name: file.name, reason: "File could not be uploaded" },
    ]);
  }

  return { asset };
}
