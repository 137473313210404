import { useCallback } from "react";
import { customFetch } from "..";
import { useAuthDispatch } from "../../components/AuthContext";
import type { AuthUser } from "../../components/AuthContext/types";
import { useSnackbarDispatch } from "../../components/SnackbarContext";
import { API_ENDPOINT } from "../../config";

export default function useAuthRefresh() {
  const authDispatch = useAuthDispatch();
  const setSnackbarMessage = useSnackbarDispatch();

  return useCallback(
    async (
      { failSilently }: { failSilently?: boolean } = { failSilently: false }
    ) => {
      try {
        const data = await customFetch<AuthUser>({
          url: `${API_ENDPOINT}/auth/refresh`,
          method: "POST",
        });

        authDispatch((current) => {
          return {
            ...current,
            user: data,
          };
        });

        return data.jwt;
      } catch {
        authDispatch((current) => ({ ...current, user: null }));

        if (!failSilently) {
          setSnackbarMessage(
            "Your session is expired - please sign in again",
            "error"
          );
        }
      }
    },
    [authDispatch, setSnackbarMessage]
  );
}
