import {
  ASIDE_WIDTH,
  FOOTER_HEIGHT,
  NAV_HEIGHT,
} from "../../../constants/layoutConstants";
import { TOKEN_PAGE_HEADER_HEIGHT } from "../../../constants/tokenPageConstants";

export default function LoadingSidePanel() {
  return (
    <>
      <div
        style={{
          width: ASIDE_WIDTH,
          top: NAV_HEIGHT + TOKEN_PAGE_HEADER_HEIGHT,
          bottom: FOOTER_HEIGHT,
        }}
        className="fixed overflow-hidden pr-4 -ml-1"
      >
        {Array.from({ length: 5 }).map((v, i) => (
          <div key={i} className="bg-gray-light animate-pulse h-11 mb-4" />
        ))}
      </div>
      <div className="flex-shrink-0" style={{ width: ASIDE_WIDTH }} />
    </>
  );
}
