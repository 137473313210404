import React from "react";

const PageGridCard: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div
      className={`p-8 flex flex-col justify-center shadow-layer-gray bg-gray text-white`}
    >
      <div className="w-full">{children}</div>
    </div>
  );
};

export default PageGridCard;
