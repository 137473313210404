import React from "react";
import InputBase from "./InputBase";
import type { InputBaseProps } from "./types";

type TextInputType =
  | (InputBaseProps & { componentType?: "text" })
  | (InputBaseProps & {
      componentType: "textarea";
      maxRows?: number;
      initialRows?: number;
    });

const TextInput: React.FC<TextInputType> = ({
  componentType = "text",
  ...props
}) => {
  return <InputBase {...props} componentType={componentType} />;
};

export default TextInput;
