import React from "react";

const InputBottomBorder: React.FC<{
  error?: string;
  focused: boolean;
  isDark?: boolean;
  readOnly?: boolean;
}> = ({ error, focused, isDark, readOnly }) => {
  const { focusedStyles, unfocusedStyles } = isDark
    ? {
        focusedStyles: "border-b-2 border-black",
        unfocusedStyles: "border-b border-black",
      }
    : {
        focusedStyles:
          "border-b-2 border-white border-opacity-60 group-hover:border-opacity-60",
        unfocusedStyles:
          "border-b border-white border-opacity-30 group-hover:border-opacity-60",
      };
  return (
    <>
      <span
        className={`absolute z-0 bottom-0 left-0 right-0 ${
          readOnly ? "group-hover:border-opacity-30" : "group-hover:border-b-2"
        } ${focused ? focusedStyles : unfocusedStyles}`}
      />
      <span
        className={`absolute z-0 bottom-0 left-0 right-0 border-b-2 ${
          error ? "border-red" : isDark ? "border-black" : "border-primary"
        } transform transition-transform ${focused ? "scale-1" : "scale-0"}`}
      />
    </>
  );
};

export default InputBottomBorder;
