import React from "react";
import Button from "../../../components/Button";
import ChevronRightIcon from "../../../icons/ChevronRight";
import ImageIcon from "../../../icons/Image";
import CodeBracketsIcon from "../../../icons/CodeBrackets";
import PreheatingCard from "./PreheatingCard";
import LottieIcon from "../../../components/LottieIcon";
import animationData from "../../../components/LottieIcon/animations/preheating.json";

const PreheatingSection: React.FC<{
  submitting: boolean;
  onSubmit: (e: React.FormEvent) => Promise<void>;
}> = ({ submitting, onSubmit }) => {
  return (
    <>
      <LottieIcon animationData={animationData} />
      <h2 className="mb-4">Preheating the oven</h2>
      <p className="mb-6">
        The big moment! We'll assemble a complete package ready to go for you to
        launch your project.
      </p>
      <div className="bg-gray px-6 pt-4 pb-6 shadow-layer-gray mb-8">
        <div className="flex items-center">
          <div className="w-1/2">
            <PreheatingCard
              headerText="Images"
              bodyText="You'll get all generated tokens as seen on your tokens page"
              icon={<ImageIcon />}
              href="https://lasagna.xyz/guides/finalize-collection"
            />
          </div>
          <div className="w-1/2 border-l-2 border-black -ml-1 pl-4">
            <PreheatingCard
              headerText="Metadata"
              bodyText="You'll also get metadata files that includes info on traits and more"
              icon={<CodeBracketsIcon />}
              href="https://lasagna.xyz/guides/token-metadata"
            />
          </div>
        </div>
      </div>
      <div>
        <form action="#" onSubmit={onSubmit}>
          <div>
            <Button fullWidth disabled={submitting} type="submit">
              {submitting ? (
                "Setting stuff up..."
              ) : (
                <>
                  <span className="mr-3">Finalize my collection</span>
                  <ChevronRightIcon />
                </>
              )}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PreheatingSection;
