import React from "react";
import type { TokenLayer } from "lasagna-commons";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AssetImageStack from "../../../../components/AssetImage/AssetImageStack";
import PageMainContent from "../../../../components/PageMainContent";
import { useAssetViewProps } from "../../hooks";
import LayerDraggable from "./LayerDraggable";
import LayerContainer from "./LayerContainer";
import {
  ASIDE_WIDTH,
  FOOTER_HEIGHT,
  NAV_HEIGHT,
} from "../../../../constants/layoutConstants";
import WarningDrawer from "../../WarningDrawer";
import { useViewport } from "../../../../components/ViewportContext";
import PlusIcon from "../../../../icons/Plus";
import AddLayer from "../../AddLayer";
import EyeIcon from "../../../../icons/Eye";
import EyeSlashIcon from "../../../../icons/EyeSlash";
import { calculateButtonStyles } from "../../../../components/Button/utils";

const buttonStyles = calculateButtonStyles({
  variant: "primary",
  fullWidth: true,
});

const AssetEditDefaultView: React.FC = () => {
  const {
    order,
    layers,
    collection,
    handleDragEnd,
    setOrder,
    isDeleting,
    setIsDeleting,
    setUpdateWarning,
    updateWarning,
    originalSetUpdateWarning,
    warningDrawerOpen,
    setWarningDrawerOpen,
    isReadOnly,
  } = useAssetViewProps();

  const [showPreview, setShowPreview] = React.useState(true);

  const togglePreview = () => {
    setShowPreview((val) => !val);
  };

  const [activeLayerId, setActiveLayerId] = React.useState(() => order[0]);

  React.useEffect(() => {
    if (!layers[activeLayerId]) {
      setActiveLayerId(Object.keys(layers)[0]);
    }
  }, [layers, activeLayerId]);

  const tokenLayers = React.useMemo<TokenLayer[]>(() => {
    return Object.keys(layers)
      .filter((layerId) => Object.keys(layers[layerId].assets).length > 0)
      .sort((a, b) => (layers[a].layerOrder > layers[b].layerOrder ? 1 : -1))
      .map((layerId) => {
        return {
          layerId,
          assetId:
            layers[layerId].assets[Object.keys(layers[layerId].assets)[0]].id,
          visible: true,
        };
      });
  }, [layers]);

  const viewport = useViewport();

  const aspectRatio = collection.assetMeta?.aspectRatio || 1;

  return (
    <>
      <PageMainContent addLayoutContainer>
        <div className="flex">
          <aside
            className="flex-shrink-0 mr-4 flex flex-col"
            style={{
              width: ASIDE_WIDTH,
              height: viewport.height - NAV_HEIGHT - FOOTER_HEIGHT,
            }}
          >
            <div className="pb-2 px-1 bg-black relative">
              {showPreview ? (
                <AssetImageStack
                  layers={layers}
                  aspectRatio={aspectRatio}
                  tokenLayers={tokenLayers}
                  size="small"
                />
              ) : (
                <span className="block h-4" />
              )}
              <button
                className={`absolute left-1 right-1 py-0.5 px-1.5 z-20 font-secondary inline-flex items-center justify-center ${
                  showPreview
                    ? "bg-gray opacity-70 hover:opacity-100 bottom-2"
                    : "bg-white text-black hover:bg-primary top-0"
                }`}
                style={{ fontSize: 10 }}
                onClick={togglePreview}
              >
                {showPreview ? (
                  <>
                    <EyeSlashIcon size={14} />
                    <span className="ml-1.5">Hide preview</span>
                  </>
                ) : (
                  <>
                    <EyeIcon size={12} />
                    <span className="ml-1.5">Show preview</span>
                  </>
                )}
              </button>
            </div>
            <div className="px-1 py-2 overflow-auto">
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="list">
                  {({ innerRef, droppableProps, placeholder }) => (
                    <div ref={innerRef} {...droppableProps}>
                      {order.map((layerId, index) => (
                        <LayerDraggable
                          key={layerId}
                          layerId={layerId}
                          index={index}
                          label={layers[layerId].label}
                          assetCount={
                            Object.keys(layers[layerId].assets).length
                          }
                          setActiveLayerId={setActiveLayerId}
                          activeLayerId={activeLayerId}
                        />
                      ))}
                      {placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {!isReadOnly && (
                <div className="mt-6 mb-1">
                  <AddLayer setOrder={setOrder}>
                    <span className={buttonStyles.containerClassName}>
                      <span className={buttonStyles.contentClassName}>
                        <PlusIcon />
                        <span className="ml-3">Add another layer</span>
                      </span>
                      <span className={buttonStyles.backgroundClassName} />
                    </span>
                  </AddLayer>
                </div>
              )}
            </div>
          </aside>
          {layers[activeLayerId] && (
            <div className="flex-grow">
              <LayerContainer
                layerId={activeLayerId}
                layers={layers}
                order={order}
                setOrder={setOrder}
                isDeleting={isDeleting}
                setIsDeleting={setIsDeleting}
                setUpdateWarning={setUpdateWarning}
                aspectRatio={aspectRatio}
                viewport={viewport}
                isReadOnly={isReadOnly}
              />
            </div>
          )}
        </div>
      </PageMainContent>
      <WarningDrawer
        updateWarning={updateWarning}
        setUpdateWarning={originalSetUpdateWarning}
        open={warningDrawerOpen}
        setOpen={setWarningDrawerOpen}
      />
    </>
  );
};

export default AssetEditDefaultView;
