import React from "react";
import { createBasicCtx, createCtx } from "../../utils";
import type {
  AssetDispatch,
  AssetState,
  FetchHandlerParams,
} from "../../types";
import useUpdateStack from "../../utils/hooks/useUpdateStack";
import AssetErrorDrawer from "./AssetErrorDrawer";

const [useAssetsInit, AssetContextProvider] = createBasicCtx<AssetState>();
const [useAssetDispatch, AssetDispatchContextProvider] =
  createCtx<AssetDispatch>();

const AssetProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [assetState, setAssetState] = React.useState<AssetState>();

  const callback = React.useCallback((updateStack: FetchHandlerParams[]) => {
    setAssetState((current) => {
      if (!current) {
        return;
      }
      return { ...current, updateStack };
    });
  }, []);

  useUpdateStack(assetState?.updateStack, callback);

  return (
    <AssetDispatchContextProvider value={setAssetState}>
      <AssetContextProvider value={assetState}>
        {children}
        <AssetErrorDrawer />
      </AssetContextProvider>
    </AssetDispatchContextProvider>
  );
};

export { useAssetsInit, useAssetDispatch, AssetProvider };
