import { EMAIL_REGEX } from "lasagna-commons";
import React from "react";
import Button from "../../../components/Button";
import TextInput from "../../../components/Form/TextInput";
import { useSnackbarDispatch } from "../../../components/SnackbarContext";

const UpdateEmailForm: React.FC<{
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}> = ({ setEmail }) => {
  const [value, setValue] = React.useState("");

  const setSnackbarMessage = useSnackbarDispatch();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!EMAIL_REGEX.test(value)) {
      setSnackbarMessage("Please enter a valid email address", "error");
      return;
    }
    setEmail(value);
  };

  return (
    <div className="max-w-screen-xs m-auto">
      <form action="#" onSubmit={handleSubmit}>
        <h3>Verify your email</h3>
        <div className="my-6">
          <TextInput
            value={value}
            setValue={setValue}
            label={{ text: "Email address" }}
            name="email"
            placeholder="garfield@lasagna.xyz"
          />
        </div>
        <div>
          <Button fullWidth type="submit">
            Verify email
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UpdateEmailForm;
