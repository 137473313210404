import { useMemo, useEffect, useCallback, useState, useRef } from "react";
import type { FileWithPath } from "react-dropzone";
import {
  AssetInput,
  AssetInputWithPresignedUrl,
  LayerInput,
  Layer,
  AssetMeta,
  calculateAssetKey,
} from "lasagna-commons";
import { API_ENDPOINT } from "../../config";
import type {
  AssetState,
  InitialClientAsset,
  InitialClientAssetWithLayerId,
  S3AssetInput,
} from "../../types";
import { useSnackbarDispatch } from "../../components/SnackbarContext";
import { useAssetDispatch } from "../../components/AssetContext";
import { useAssets } from "../../components/AssetContext/hooks";
import Drawer from "../../components/Drawer";
import ProgressBar from "../../components/Progress/ProgressBar";
import ProgressItem from "../../components/Progress/ProgressItem";
import type {
  ProgressItemStage,
  ProgressProps,
} from "../../components/Progress/types";
import Divider from "../../components/Divider";
import LottieIcon from "../../components/LottieIcon";
import uploadAnimationData from "../../components/LottieIcon/animations/upload-asset.json";
import processingAnimationData from "../../components/LottieIcon/animations/zip-collection.json";
import useCustomFetch from "./useCustomFetch";
import { getCollectionId } from "..";

export default function useAssetUpload() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processedFileProgress, setProcessedFileProgress] = useState({
    numerator: 0,
    denominator: 0,
  });
  const hasLoaded = useRef(false);
  const setSnackbarMessage = useSnackbarDispatch();
  const assetDispatch = useAssetDispatch();
  const { layers } = useAssets();
  const { run, updateAsset, progress, setProgress } = useUploadToS3();

  useEffect(() => {
    if (loading) {
      setOpen(true);
      hasLoaded.current = true;
    } else if (hasLoaded.current) {
      const timeoutId = window.setTimeout(() => {
        setOpen(false);
        setProgress({ numerator: 0, denominator: 1 });
      }, 1000);

      return () => {
        window.clearTimeout(timeoutId);
      };
    }
  }, [loading, setProgress]);

  return {
    setSnackbarMessage,
    assetDispatch,
    layers,
    setLoading,
    setProcessedFileProgress,
    run,
    updateAsset,
    modals: (
      <ProgressDrawer
        open={open}
        uploadProgress={progress}
        processedFileProgress={processedFileProgress}
      />
    ),
  };
}

const SHOW_PROCESSED_FILE_MINIMUM = 25; // No need to have a jarring loading experience if it will be finished quickly

function ProgressDrawer({
  open,
  uploadProgress,
  processedFileProgress,
}: {
  open: boolean;
  uploadProgress: ProgressProps;
  processedFileProgress?: ProgressProps;
}) {
  return (
    <Drawer open={open}>
      {processedFileProgress &&
      processedFileProgress.denominator > SHOW_PROCESSED_FILE_MINIMUM ? (
        <ProgressStages
          uploadProgress={uploadProgress}
          processedFileProgress={processedFileProgress}
        />
      ) : (
        <>
          <LottieIcon animationData={uploadAnimationData} />
          <h2 className="animate-pulse">Uploading your assets</h2>
          <ProgressBar
            {...uploadProgress}
            initialMessage="Processing files..."
          />
        </>
      )}
    </Drawer>
  );
}

function ProgressStages({
  uploadProgress,
  processedFileProgress,
}: {
  uploadProgress: ProgressProps;
  processedFileProgress: ProgressProps;
}) {
  const stages = useMemo<{
    process: ProgressItemStage;
    upload: ProgressItemStage;
  }>(() => {
    if (processedFileProgress.denominator === processedFileProgress.numerator) {
      return {
        process: "finished",
        upload:
          uploadProgress.numerator === uploadProgress.denominator
            ? "finished"
            : "inProgress",
      };
    }

    return {
      process: "inProgress",
      upload: "idle",
    };
  }, [processedFileProgress, uploadProgress]);

  return (
    <>
      <ProgressItem
        step={1}
        animationData={processingAnimationData}
        headings={{
          idle: "Process your files",
          inProgress: "Processing your files",
          finished: "Your files are processed",
        }}
        description={{
          top: "We're analyzing your images and preparing for upload.",
          bottom: "This could take a few seconds.",
        }}
        stage={stages.process}
        progress={processedFileProgress}
        disableTransition
      />
      <Divider className="my-8" />
      <ProgressItem
        step={2}
        animationData={uploadAnimationData}
        headings={{
          idle: "Upload your files",
          inProgress: "Uploading your files",
          finished: "Your files are uploaded",
        }}
        description={{
          top: "We're uploading your images to our server.",
          bottom: "This could take a few seconds.",
        }}
        stage={stages.upload}
        progress={uploadProgress}
        disableTransition
      />
    </>
  );
}

function useUploadToS3() {
  const [progress, setProgress] = useState<ProgressProps>({
    numerator: 0,
    denominator: 1,
  });
  const [isRunning, setIsRunning] = useState(false);
  const uploadErrors = useRef<S3AssetInput[]>([]);
  const collectionId = getCollectionId();
  const fetcher = useCustomFetch();

  const upload = useCallback(
    async (asset: S3AssetInput, totalAssets: number) => {
      setProgress({ numerator: 0, denominator: totalAssets });
      const buffer = await readFile(asset.file);
      const body = new Blob([buffer], { type: asset.file.type });

      const res = await fetch(asset.presignedUrl, { method: "PUT", body });

      const success = res.status >= 200 && res.status < 300;

      if (success) {
        setProgress(({ numerator, denominator }) => ({
          numerator: numerator + 1,
          denominator,
        }));
      } else {
        uploadErrors.current.push(asset);
      }
      return { success };
    },
    []
  );

  const updateAsset = useCallback(
    async (clientAsset: InitialClientAsset, layerId: string) => {
      setIsRunning(true);

      const data = await fetcher<{
        presignedUrl: string;
      }>({
        url: `${API_ENDPOINT}/collections/${collectionId}/assets/replace`,
        method: "POST",
        body: {
          assetId: clientAsset.id,
          layerId,
          extension: clientAsset.extension,
        },
      });

      if (!data) {
        throw new Error("Asset could not be replaced");
      }

      await upload(
        { presignedUrl: data.presignedUrl, file: clientAsset.file },
        1
      );

      setIsRunning(false);
    },
    [setIsRunning, upload, collectionId, fetcher]
  );

  const run = useCallback(
    async (
      clientAssets: InitialClientAssetWithLayerId[],
      inputLayers: LayerInput[],
      assetMeta?: AssetMeta
    ) => {
      setIsRunning(true);

      const assetsToSubmit: AssetInput[] = [];
      const clientAssetMap: ClientAssetMap = {};

      clientAssets.forEach(
        ({ layerId, id, file, label, previewUrl, extension }) => {
          assetsToSubmit.push({ layerId, id, label, extension });
          clientAssetMap[calculateAssetKey({ assetId: id, layerId })] = {
            file,
            previewUrl,
          };
        }
      );

      const data = await fetcher<{
        presignedAssets: AssetInputWithPresignedUrl[];
        layers: Layer[];
      }>({
        url: `${API_ENDPOINT}/collections/${collectionId}/assets`,
        method: "POST",
        body: {
          assets: assetsToSubmit,
          layers: inputLayers,
          assetMeta,
        },
      });

      if (!data) {
        throw new Error("Assets could not be added");
      }

      const { presignedAssets, layers } = data;

      await Promise.all(
        presignedAssets.map(({ layerId, presignedUrl, id }) => {
          const uploadAsset = {
            presignedUrl,
            file: clientAssetMap[calculateAssetKey({ assetId: id, layerId })]
              .file,
          };
          return upload(uploadAsset, presignedAssets.length);
        })
      );

      setIsRunning(false);

      const layerMap = layers.reduce<{ [key: string]: Layer }>((a, b) => {
        a[b.id] = b;
        return a;
      }, {});

      const newLayers = presignedAssets.reduce<AssetState["layers"]>(
        (a, { label, layerId, id, rarity }) => {
          const defaultUrl =
            clientAssetMap[calculateAssetKey({ assetId: id, layerId })]
              .previewUrl;
          if (a[layerId]) {
            a[layerId].assets[id] = {
              id,
              label,
              rarity,
              urls: { default: defaultUrl },
            };
          } else {
            a[layerId] = {
              ...layerMap[layerId],
              assets: {
                [id]: { label, rarity, id, urls: { default: defaultUrl } },
              },
            };
          }
          return a;
        },
        {}
      );

      return { newLayers };
    },
    [collectionId, fetcher, upload]
  );

  return {
    run,
    updateAsset,
    progress,
    isRunning,
    setProgress,
  };
}

async function readFile(file: FileWithPath) {
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        resolve(e.target.result);
      } else {
        reject("File could not be read");
      }
    };
    reader.readAsArrayBuffer(file);
  });
}

interface ClientAssetMap {
  [key: string]: { file: FileWithPath; previewUrl: string };
}
