import React, { useState } from "react";
import Button from "../../../components/Button";
import { useCollectionDispatch } from "../../../components/CollectionContext";
import { useSnackbarDispatch } from "../../../components/SnackbarContext";
import WarningMessage from "../../../components/WarningMessage";
import { API_ENDPOINT } from "../../../config";
import CloseIcon from "../../../icons/Close";
import RotateIcon from "../../../icons/Rotate";
import { getCollectionId } from "../../../utils";
import useCustomFetch from "../../../utils/hooks/useCustomFetch";

const ResetMetadataButton: React.FC = () => {
  const [confirm, setConfirm] = React.useState(false);
  const [submitting, setSubmitting] = useState(false);

  const collectionId = getCollectionId();
  const fetcher = useCustomFetch();
  const collectionDispatch = useCollectionDispatch();
  const setSnackbarMessage = useSnackbarDispatch();

  React.useEffect(() => {
    // Too lazy to pass a ref...just going to get the drawer ID
    if (confirm) {
      const el = document.getElementById("drawer-body");
      if (el) {
        el.scrollTo({ top: window.innerHeight });
      }
    }
  }, [confirm]);

  const toggleConfirm = () => {
    if (!submitting) {
      setConfirm((val) => !val);
    }
  };

  const submit = async () => {
    setSubmitting(true);

    try {
      const data = await fetcher<{ success: boolean }>({
        url: `${API_ENDPOINT}/collections/${collectionId}/reset-metadata`,
        method: "POST",
      });

      if (data) {
        collectionDispatch((current) => {
          if (!current) {
            return;
          }

          return {
            ...current,
            [collectionId]: {
              ...current[collectionId],
              tokenZipFileProgress: 0,
              hasAllTokenZipFiles: false,
              hasMetadataZipFile: false,
            },
          };
        });
      }
    } catch {
      setSnackbarMessage("An error occurred", "error");
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="flex items-center">
        <Button onClick={toggleConfirm} fullWidth variant="gray" size="small">
          <RotateIcon />
          <span className="ml-3">Re-generate metadata</span>
        </Button>
      </div>
      {confirm && (
        <div>
          <WarningMessage>
            This will remove your previously generated metadata files and
            replace them with new ones to reflect any configuration changes
            you've made. Are you sure you wish to continue?
          </WarningMessage>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <button
                className="text-sm font-secondary flex w-full items-center justify-center border border-white py-1.5 bg-white bg-opacity-0 hover:bg-opacity-10 disabled:opacity-50 disabled:pointer-events-none"
                onClick={toggleConfirm}
                disabled={submitting}
              >
                <CloseIcon size={18} />
                <span className="ml-3">No, cancel</span>
              </button>
            </div>
            <div>
              <button
                className="text-sm font-secondary flex w-full items-center justify-center border border-secondary text-secondary py-1.5 bg-secondary bg-opacity-0 hover:bg-opacity-10 disabled:opacity-50 disabled:pointer-events-none"
                disabled={submitting}
                onClick={submit}
              >
                <RotateIcon />
                <span className="ml-3">
                  {submitting ? "Submitting..." : "Yes, re-generate"}
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetMetadataButton;
