import { calculateAssetKey, Recipe } from "lasagna-commons";
import { RecipeDispatch } from "../../types";

export function modifyAssociatedRecipes(
  assetKeys: { assetId: string; layerId: string }[],
  recipeDispatch: RecipeDispatch
) {
  const assetKeySet = new Set(
    assetKeys.map((assetKey) => calculateAssetKey(assetKey))
  );

  recipeDispatch((current) => {
    if (!current) {
      return;
    }
    const updatedRecipes: Recipe[] = [];

    current.recipes.forEach((recipe) => {
      if (
        recipe.targetAssets.find((targetAsset) =>
          assetKeySet.has(calculateAssetKey(targetAsset))
        ) ||
        recipe.pairedAssets.find((pairedAsset) =>
          assetKeySet.has(calculateAssetKey(pairedAsset))
        )
      ) {
        const targetAssets = recipe.targetAssets.filter(
          (targetAsset) => !assetKeySet.has(calculateAssetKey(targetAsset))
        );
        const pairedAssets = recipe.pairedAssets.filter(
          (pairedAsset) => !assetKeySet.has(calculateAssetKey(pairedAsset))
        );

        if (targetAssets.length > 0 && pairedAssets.length > 0) {
          updatedRecipes.push({
            id: recipe.id,
            label: recipe.label,
            pairedAssets,
            targetAssets,
            recipeType: recipe.recipeType,
          });
        }
      } else {
        updatedRecipes.push(recipe);
      }
    });

    return {
      ...current,
      recipes: updatedRecipes,
    };
  });
}
