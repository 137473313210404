import {
  ChainId,
  useAddress,
  useChainId,
  useSwitchChain,
  useSDK,
  useStorageUpload,
} from "@thirdweb-dev/react";
import type { CollectionContract } from "lasagna-commons";
import React from "react";
import Button from "../../components/Button";
import { useCollectionDispatch } from "../../components/CollectionContext";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";
import NumberInput from "../../components/Form/NumberInput";
import TextInput from "../../components/Form/TextInput";
import { useSnackbarDispatch } from "../../components/SnackbarContext";
import { useTokens } from "../../components/TokenContext/hooks";
import { API_ENDPOINT } from "../../config";
import useCustomFetch from "../../utils/hooks/useCustomFetch";
import ImageSelect from "./ImageSelect";
import NetworkSelect from "./NetworkSelect";
import CustomImageInput from "./CustomImageInput";
import type { FileWithPath } from "react-dropzone";
import DropIcon from "../../icons/Drop";
import CheckIcon from "../../icons/Check";
import ChevronRightIcon from "../../icons/ChevronRight";
import ExternalLink from "../../components/ExternalLink";
import ExternalLinkButton from "../../components/ExternalLink/ExternalLinkButton";
import uploadAnimationData from "../../components/LottieIcon/animations/upload-asset.json";
import InfoTooltip from "../../components/Popper/InfoTooltip";
import LottieIcon from "../../components/LottieIcon";
import Drawer from "../../components/Drawer";
import { useActiveChain } from "../../components/WalletProvider/ActiveChainProvider";

type ThumbnailOption = "token" | "custom";

const thumbnailOptions: ThumbnailOption[] = ["token", "custom"];

const SmartContractForm: React.FC<{
  setShowContractForm: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setShowContractForm }) => {
  const address = useAddress();
  const collection = useCurrentCollection();
  const { tokens } = useTokens();
  const sdk = useSDK();
  const setSnackbarMessage = useSnackbarDispatch();
  const chainId = useChainId();
  const switchChain = useSwitchChain();
  const fetcher = useCustomFetch();
  const collectionDispatch = useCollectionDispatch();
  const { mutateAsync: upload } = useStorageUpload();

  const [submitting, setSubmitting] = React.useState(false);
  const [thumbnailOption, setThumbnailOption] =
    React.useState<ThumbnailOption>("token");
  const [selectedChain, setSelectedChain] = React.useState(
    () => chainId || ChainId.Mainnet
  );
  const [name, setName] = React.useState(() => collection.name);
  const [recipientAddress, setRecipientAddress] = React.useState(
    () => address || ""
  );
  const [royaltiesAddress, setRoyaltiesAddress] = React.useState(
    () => address || ""
  );
  const [royaltiesPercentage, setRoyaltiesPercentage] = React.useState("0.00");
  const [description, setDescription] = React.useState(
    () => collection.description
  );
  const [symbol, setSymbol] = React.useState("");
  const [sampleToken, setSampleToken] = React.useState(() => tokens[0]);
  const [customFile, setCustomFile] = React.useState<FileWithPath | null>(null);
  const [preview, setPreview] = React.useState<string | null>(null);
  const [activeChain, setActiveChain] = useActiveChain();

  React.useEffect(() => {
    if (chainId !== selectedChain) {
      switchChain(selectedChain);
    }
  }, [switchChain, chainId, selectedChain]);

  React.useEffect(() => {
    if (chainId !== undefined && activeChain !== chainId) {
      setActiveChain(chainId);
    }
  }, [activeChain, setActiveChain, chainId]);

  const chainMismatch = selectedChain !== chainId;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!sdk) {
      return;
    }

    if (chainMismatch) {
      setSnackbarMessage(
        "You must be on the selected chain - please update your wallet",
        "error"
      );
      return;
    }

    if (!name) {
      setSnackbarMessage("Name is a required field", "error");
      return;
    }

    if (!recipientAddress) {
      setSnackbarMessage(
        "Primary sales recipient is a required field",
        "error"
      );
      return;
    }

    if (!royaltiesAddress) {
      setSnackbarMessage(
        "Royalty recipient address is a required field",
        "error"
      );
      return;
    }

    try {
      setSubmitting(true);

      const sellerFeeBasisPoints = Number(royaltiesPercentage) * 100;
      let imageUrl: string | undefined = undefined;

      if (thumbnailOption === "token") {
        const imageData = await fetcher<{ ipfsUrl: string }>({
          url: `${API_ENDPOINT}/collections/${collection.id}/contracts/image`,
          method: "POST",
          body: { tokenId: sampleToken.id },
        });

        if (!imageData) {
          throw new Error("Image could not be processed");
        }

        imageUrl = imageData.ipfsUrl;
      } else if (customFile) {
        const uploadUris = await upload({ data: [customFile] });
        imageUrl = uploadUris[0];
      }

      const contractAddress = await sdk.deployer.deployNFTDrop({
        name,
        description,
        primary_sale_recipient: recipientAddress,
        image: imageUrl,
        fee_recipient: royaltiesAddress,
        seller_fee_basis_points: sellerFeeBasisPoints,
      });

      const data = await fetcher<{ contracts: CollectionContract[] }>({
        url: `${API_ENDPOINT}/collections/${collection.id}/contracts`,
        method: "POST",
        body: {
          contract: {
            address: contractAddress,
            imageUrl,
            chainId,
          },
        },
      });

      if (data) {
        collectionDispatch((current) => {
          if (!current) {
            return;
          }
          return {
            ...current,
            [collection.id]: {
              ...current[collection.id],
              contracts: data.contracts,
            },
          };
        });
        setSnackbarMessage("Contract deployed successfully");
        setShowContractForm(false);
      }
    } catch (err) {
      const errorMessage =
        err instanceof Error &&
        err.message.includes("execution reverted: implementation not approved")
          ? "Implementation not approved - perhaps you don't have enough funds for gas fees?"
          : "Contract could not be deployed";
      setSubmitting(false);
      setSnackbarMessage(errorMessage, "error");
    }
  };

  const handleBlur = () => {
    if (!royaltiesPercentage) {
      setRoyaltiesPercentage("0.00");
    }
  };

  return (
    <>
      <form action="#" onSubmit={handleSubmit}>
        <FormSection>
          <div className="flex items-center justify-between">
            <div className="flex items-center flex-grow">
              <DropIcon />
              <div className="ml-4">
                <div className="flex items-center">
                  <h3>NFT Drop</h3>
                  <div className="ml-4 mb-2 flex items-center bg-gradient-to-b from-secondary to-primary p-1 rounded-full">
                    <span className="flex items-center justify-center w-4 h-4 rounded-full bg-black text-primary">
                      <CheckIcon size={12} />
                    </span>
                    <span className="mx-1 leading-none font-secondary text-black text-xs">
                      audited
                    </span>
                  </div>
                </div>
                <p className="text-sm">
                  Release a collection of unique ERC721A NFTs for a set price
                </p>
              </div>
            </div>
            <div className="flex-shrink-0">
              <ExternalLinkButton href="https://thirdweb.com/thirdweb.eth/DropERC721">
                <span className="mr-2">Learn more</span>
                <ChevronRightIcon />
              </ExternalLinkButton>
            </div>
          </div>
        </FormSection>
        <FormSection>
          <h3>Network / Chain</h3>
          <p className="mb-1">
            Select a network to deploy this contract on. We recommend starting
            with a testnet.
          </p>
          <div className="mb-5 text-sm">
            <ExternalLink href="https://ethereum.org/en/developers/docs/networks/">
              Learn more about the different Ethereum networks
            </ExternalLink>
          </div>
          <div>
            <label className="font-secondary mb-2 text-sm opacity-60">
              Network/Chain
            </label>
            <div>
              <NetworkSelect
                chainMismatch={chainMismatch}
                selectedChain={selectedChain}
                setSelectedChain={setSelectedChain}
              />
            </div>
          </div>
        </FormSection>
        <FormSection>
          <h3>Contract Metadata</h3>
          <p className="mb-5">
            Settings to organize and distinguish your different contracts.
          </p>
          <div className="flex items-start">
            <div className="flex-shrink-0 mr-6">
              <label className="flex items-center mb-2">
                <span className="mr-3 font-secondary opacity-60 text-sm">
                  Thumbnail
                </span>
                <InfoTooltip
                  title="This is an optional image that will be displayed with your contract. It will be the featured image on your mint page. You can upload your own image, or use a pre-existing token from your collection."
                  placement="right"
                />
              </label>
              <div className="w-40 aspect-square bg-black mb-2 flex items-center relative group">
                {thumbnailOption === "custom" ? (
                  <CustomImageInput
                    setCustomFile={setCustomFile}
                    preview={preview}
                    setPreview={setPreview}
                  />
                ) : (
                  <ImageSelect
                    sampleToken={sampleToken}
                    setSampleToken={setSampleToken}
                  />
                )}
              </div>
              <div className="flex items-center border border-black">
                {thumbnailOptions.map((option) => {
                  const handleClick = () => {
                    setThumbnailOption(option);
                  };

                  const selected = option === thumbnailOption;
                  return (
                    <button
                      className={`${
                        selected
                          ? "bg-gray-dark text-primary"
                          : "bg-gray-light text-white hover:bg-gray-dark"
                      } w-20 flex py-1 items-center justify-center font-secondary text-xs uppercase`}
                      type="button"
                      onClick={handleClick}
                      key={option}
                    >
                      {selected && (
                        <span className="flex mr-1">
                          <CheckIcon size={14} />
                        </span>
                      )}
                      <span>{option}</span>
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="flex-grow">
              <div className="grid grid-cols-5 gap-4 mb-5">
                <div className="col-span-3">
                  <TextInput
                    name="name"
                    label={{ text: "Name" }}
                    value={name}
                    setValue={setName}
                  />
                </div>
                <div className="col-span-2">
                  <TextInput
                    name="symbol"
                    label={{
                      text: "Symbol",
                      tooltipText:
                        "A short abbreviation for your collection (e.g. if it's named Normiez, perhaps the symbol should be NMZ)",
                      tooltipPlacement: "right",
                    }}
                    value={symbol}
                    setValue={setSymbol}
                  />
                </div>
              </div>
              <div className="mb-3">
                <TextInput
                  name="description"
                  label={{
                    text: "Description",
                  }}
                  value={description}
                  setValue={setDescription}
                  componentType="textarea"
                  maxRows={5}
                  initialRows={3}
                />
              </div>
            </div>
          </div>
        </FormSection>
        <FormSection>
          <h3>Payout Settings</h3>
          <p className="mb-8">
            Determine which wallet address should receive funds generated by
            this smart contact.
          </p>
          <h5 className="text-lg font-bold mb-1">Primary Sales</h5>
          <p className="mb-5">
            Determine the address that should receive the revenue from initial
            sales of the assets.
          </p>
          <div className="mb-3">
            <TextInput
              name="primarySalesRecipient"
              label={{
                text: "Primary sales recipient address",
                tooltipText:
                  "Determine the address that should receive the revenue from initial sales of the assets...this is presumably your wallet.",
                tooltipPlacement: "right",
              }}
              value={recipientAddress}
              setValue={setRecipientAddress}
            />
          </div>
          <span className="mt-12 mb-8 bg-black block h-0.5" />
          <h5 className="text-lg font-bold mb-1">Royalties</h5>
          <p className="mb-5">
            Determine the address that should receive the revenue from royalties
            earned from secondary sales of the assets.
          </p>
          <div className="grid grid-cols-5 gap-4 mb-6">
            <div className="col-span-4">
              <TextInput
                name="royaltiesAddress"
                label={{
                  text: "Recipient address",
                  tooltipText:
                    "Determine the address that should receive the revenue from royalties earned from secondary sales of the assets.",
                  tooltipPlacement: "right",
                }}
                value={royaltiesAddress}
                setValue={setRoyaltiesAddress}
              />
            </div>
            <div>
              <NumberInput
                name="royaltyPercentage"
                label={{
                  text: "Percentage",
                  tooltipText:
                    "Determine the percentage of revenue from royalties earned from secondary sales of the assets.",
                  tooltipPlacement: "right",
                }}
                value={royaltiesPercentage}
                setValue={setRoyaltiesPercentage}
                endAdornment={<span className="opacity-60">%</span>}
                min={0}
                max={100}
                decimalScale={2}
                onBlur={handleBlur}
              />
            </div>
          </div>
        </FormSection>
        <p className="text-sm font-secondary opacity-60 mb-5">
          Submitting will trigger a transaction to deploy your contract to the
          Ethereum blockchain. After this, you'll need to manage your contract
          from the thirdweb dashboard.
        </p>
        <div>
          <Button type="submit" fullWidth disabled={submitting}>
            {submitting ? (
              <>Deploying contract...</>
            ) : (
              <>
                <span className="mr-3">Deploy contract</span>
                <ChevronRightIcon />
              </>
            )}
          </Button>
        </div>
      </form>
      <Drawer open={submitting}>
        <LottieIcon animationData={uploadAnimationData} />
        <div className="animate-pulse">
          <h2>Deploying your contract</h2>
          <p>
            Sign off on the transaction with your wallet, then please wait while
            we complete your contract deployment.
          </p>
        </div>
      </Drawer>
    </>
  );
};

function FormSection({ children }: { children: React.ReactNode }) {
  return (
    <section className="bg-gray shadow-layer-gray p-6 mb-8">{children}</section>
  );
}

export default SmartContractForm;
