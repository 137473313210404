import { useCallback, useRef } from "react";
import { matchPath } from "react-router";
import { PREVIEW_ID_QUERY_PARAM } from "lasagna-commons";
import { customFetch, getErrorMessage } from "../index";
import { useCurrentUser } from "../../components/AuthContext/hooks";
import { useSnackbarDispatch } from "../../components/SnackbarContext";
import type { FetchHandlerParams } from "../../types";
import useAuthRefresh from "./useAuthRefresh";
import { useCollectionsInit } from "../../components/CollectionContext";
import { NoCollectionError } from "../../constants";

export default function useCustomFetch() {
  const user = useCurrentUser();
  const setSnackbarMessage = useSnackbarDispatch();
  const authRefresh = useAuthRefresh();

  // Using a ref to avoid re-renders, but the user JWT object will still get updated just fine since it references the user object
  const jwtRef = useRef<{ jwt: string | undefined; jwtExpiresAt: number }>({
    jwt: user.jwt,
    jwtExpiresAt: user.jwtExpiresAt,
  });

  const collections = useCollectionsInit();
  const match = matchPath(
    "/collections/:collectionId/*",
    window.location.pathname
  );
  const collectionId = match?.params.collectionId;

  if (collectionId && collections && !collections[collectionId]) {
    throw new NoCollectionError();
  }

  const previewId =
    collections && collectionId && collections[collectionId].preview?.id;

  return useCallback(
    async function <T>(initialParams: Omit<FetchHandlerParams, "jwt">) {
      try {
        if (Date.now() > jwtRef.current.jwtExpiresAt) {
          jwtRef.current.jwt = await authRefresh();
        }

        const query = initialParams.query || {};

        if (previewId) {
          query[PREVIEW_ID_QUERY_PARAM] = previewId;
        }

        if (jwtRef.current.jwt) {
          return await customFetch<T>({
            ...initialParams,
            jwt: jwtRef.current.jwt,
            query,
          });
        }
      } catch (err) {
        setSnackbarMessage(`Error - ${getErrorMessage(err)}`, "error");
      }
    },
    [authRefresh, setSnackbarMessage, previewId]
  );
}
