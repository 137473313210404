import React from "react";
import Button from "../Button";
import SkipArrowIcon from "../../icons/SkipArrow";
import RotateIcon from "../../icons/Rotate";
import type { PinningRequestArgs } from "../../pages/Tokens/DownloadDrawer/types";

const PinningErrorSection: React.FC<{
  submitCallback: (args: PinningRequestArgs) => Promise<void>;
  submitting: boolean;
  disablePinningRetry?: boolean;
}> = ({ submitCallback, submitting, disablePinningRetry }) => {
  const retryPinning = async () => {
    await submitCallback({
      skipPinning: false,
    });
  };

  const retryWithoutPinning = async () => {
    await submitCallback({
      skipPinning: true,
    });
  };

  return (
    <div>
      <div>
        <Button fullWidth disabled={submitting} onClick={retryPinning}>
          <RotateIcon />
          <span className="ml-2.5">Retry pinning</span>
        </Button>
      </div>
      {!disablePinningRetry && (
        <>
          <div className="flex items-center my-6">
            <span className="h-px bg-white flex-grow" />
            <span className="px-2">OR</span>
            <span className="h-px bg-white flex-grow" />
          </div>
          <div>
            <Button
              variant="gray"
              fullWidth
              onClick={retryWithoutPinning}
              disabled={submitting}
            >
              <SkipArrowIcon />
              <span className="ml-3">Try again without pinning</span>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PinningErrorSection;
