import React from "react";
import { animated } from "react-spring";
import { usePortalAnimation } from "../utils/hooks";
import Portal from "./Portal";
import { useViewport } from "./ViewportContext";

interface DrawerProps {
  onClose?: () => void;
  onExited?: () => void;
  open: boolean;
  children: React.ReactNode;
  width?: "normal" | "wide";
}

const Drawer: React.FC<DrawerProps> = ({
  children,
  open,
  onClose,
  onExited,
  width = "normal",
}) => {
  const { height } = useViewport();
  const { opacity, translate, mounted } = usePortalAnimation(open, onExited);

  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      if (document.body.scrollHeight > height) {
        document.body.style.paddingRight = "12px"; // account for the width of the window scrollbar
      }
    } else {
      document.body.style.overflow = null as unknown as string;
      document.body.style.paddingRight = null as unknown as string;
    }
  }, [open, height]);

  return (
    <Portal mounted={mounted}>
      <animated.div
        className="fixed inset-0 z-30 bg-black bg-opacity-60 cursor-default"
        role="button"
        onClick={onClose}
        tabIndex={0}
        style={{ opacity }}
      />
      <animated.div
        className="bg-gray-dark z-40 shadow-3xl fixed top-0 right-0 bottom-0 overflow-hidden"
        style={{ transform: translate.to((val) => `translateX(${val}%)`) }}
      >
        <div
          className={`flex flex-col justify-center h-full ${
            width === "normal" ? "w-drawer" : "w-drawer-wide"
          }`}
        >
          <div className="py-12 px-16 overflow-auto" id="drawer-body">
            {children}
          </div>
        </div>
      </animated.div>
    </Portal>
  );
};

export default Drawer;
