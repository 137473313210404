import { EMAIL_REGEX } from "lasagna-commons";
import React from "react";
import Button from "../../../components/Button";
import { useCollectionDispatch } from "../../../components/CollectionContext";
import { useCurrentCollection } from "../../../components/CollectionContext/hooks";
import TextInput from "../../../components/Form/TextInput";
import { useSnackbarDispatch } from "../../../components/SnackbarContext";
import { API_ENDPOINT } from "../../../config";
import BellIcon from "../../../icons/Bell";
import ChevronLeftIcon from "../../../icons/ChevronLeft";
import useCustomFetch from "../../../utils/hooks/useCustomFetch";

const containerStyles =
  "flex absolute left-0 bottom-0 right-0 px-12 border-t border-black bg-gray-dark z-10 h-24";

const ProgressFooter: React.FC<{ closeDrawer: () => void }> = ({
  closeDrawer,
}) => {
  const collection = useCurrentCollection();

  const [email, setEmail] = React.useState(
    () => collection.notificationEmail || ""
  );
  const [showForm, setShowForm] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const setSnackbarMessage = useSnackbarDispatch();
  const collectionDispatch = useCollectionDispatch();
  const fetcher = useCustomFetch();

  const toggleShowForm = () => {
    setShowForm((val) => !val);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (email && !EMAIL_REGEX.test(email)) {
      setSnackbarMessage("Invalid email address", "error");
      return;
    }

    setSubmitting(true);

    const data = await fetcher({
      url: `${API_ENDPOINT}/collections/${collection.id}/notification-email`,
      method: "PUT",
      body: { email },
    });

    if (data) {
      setShowForm(false);
      collectionDispatch((current) => {
        if (!current) {
          return;
        }
        return {
          ...current,
          [collection.id]: {
            ...current[collection.id],
            notificationEmail: email,
          },
        };
      });
      setSnackbarMessage(
        email
          ? "Notifications enabled - email address saved"
          : "Notifications have been disabled"
      );
    }

    setSubmitting(false);
  };

  if (showForm) {
    return (
      <form
        className={`${containerStyles} items-end pb-7`}
        action="#"
        onSubmit={handleSubmit}
      >
        <div className="flex-grow">
          <TextInput
            value={email}
            setValue={setEmail}
            name="email"
            label={{ text: "Email address" }}
          />
        </div>
        <div className="flex-shrink-0 ml-6 flex items-center">
          <div className="mr-4">
            <Button
              variant="gray"
              size="small"
              onClick={toggleShowForm}
              disabled={submitting}
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button size="small" type="submit" disabled={submitting}>
              Save
            </Button>
          </div>
        </div>
      </form>
    );
  }

  return (
    <div className={`${containerStyles} items-center justify-between`}>
      <div className="flex items-center">
        <div>
          <Button variant="gray" onClick={closeDrawer} size="small">
            <ChevronLeftIcon />
            <span className="ml-2">Hide</span>
          </Button>
        </div>
        <div className="opacity-60 ml-3 text-xs w-48">
          Don't worry, we'll continue to make progress in the background.
        </div>
      </div>
      <div className="flex-grow-0">
        <Button onClick={toggleShowForm} size="small">
          <BellIcon />
          <span className="ml-2 truncate block" style={{ maxWidth: 170 }}>
            {email || "Notify me when done"}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default ProgressFooter;
