import { useCallback, useEffect, useRef } from "react";
import {
  useCollectionDispatch,
  useCollectionsInit,
} from "../../components/CollectionContext";
import { API_ENDPOINT } from "../../config";
import type { CollectionState } from "../../types";
import useCustomFetch from "./useCustomFetch";

export function useFetchCollections() {
  const collectionDispatch = useCollectionDispatch();

  const loading = useRef(false);

  const fetcher = useCustomFetch();

  return useCallback(async () => {
    if (!loading.current) {
      loading.current = true;
      const data = await fetcher<{
        collections: CollectionState;
      }>({
        url: `${API_ENDPOINT}/collections`,
        method: "GET",
      });

      if (data) {
        collectionDispatch(data.collections);
      }

      loading.current = false;
    }
  }, [fetcher, collectionDispatch]);
}

export function useCollectionsEffect(
  { forceRefresh }: { forceRefresh?: boolean } = { forceRefresh: false }
) {
  const fetchCollections = useFetchCollections();
  const collectionState = useCollectionsInit();

  useEffect(() => {
    if (!collectionState || forceRefresh) {
      fetchCollections();
    }
  }, [fetchCollections, collectionState, forceRefresh]);

  return collectionState;
}
