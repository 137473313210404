import React from "react";
import ProgressBar from "../../../components/Progress/ProgressBar";
import { ASIDE_WIDTH, NAV_HEIGHT } from "../../../constants/layoutConstants";
import { TOKEN_PAGE_HEADER_HEIGHT } from "../../../constants/tokenPageConstants";
import LargeGridIcon from "../../../icons/LargeGrid";
import SmallGridIcon from "../../../icons/SmallGrid";
import { numberWithDelimiters, pluralizeWord } from "../../../utils";
import SharePreviewDrawer from "../SharePreviewDrawer";
import type { GridStyleOption, SortTypeOption } from "../types";
import SortButton from "./SortButton";
import ShuffleTokensDrawer from "../ShuffleTokensDrawer";

interface TokenHeaderProps {
  gridStyle: GridStyleOption;
  setGridStyle: React.Dispatch<React.SetStateAction<GridStyleOption>>;
  sortType: SortTypeOption;
  setSortType: React.Dispatch<React.SetStateAction<SortTypeOption>>;
  totalTokenCount: number;
  filteredTokenCount: number;
  isLoading: boolean;
  tokensLoaded: number;
  tokenCount: number;
  isPreview: boolean;
  printedTokenCount: number;
}

const gridStyleOptions: { type: GridStyleOption; icon: React.ReactNode }[] = [
  { type: "large", icon: <LargeGridIcon /> },
  { type: "small", icon: <SmallGridIcon /> },
];

const TokenHeader: React.FC<TokenHeaderProps> = ({
  gridStyle,
  setGridStyle,
  sortType,
  setSortType,
  totalTokenCount,
  printedTokenCount,
  filteredTokenCount,
  isLoading,
  tokensLoaded,
  tokenCount,
  isPreview,
}) => {
  return (
    <>
      <div
        className="px-layout bg-black flex items-center fixed left-0 right-0 z-20 pb-2"
        style={{ height: TOKEN_PAGE_HEADER_HEIGHT, top: NAV_HEIGHT }}
      >
        <div className="flex-shrink-0" style={{ width: ASIDE_WIDTH }}>
          {isLoading ? (
            <div className="w-64 flex items-center">
              <div className="flex-grow">
                <ProgressBar
                  numerator={tokensLoaded}
                  denominator={tokenCount}
                  showMessage={false}
                  transitionDuration="duration-150"
                />
              </div>
              <span className="text-sm animate-pulse">Loading tokens...</span>
            </div>
          ) : (
            <span className="opacity-80 text-sm">
              Showing {numberWithDelimiters(filteredTokenCount)} of{" "}
              {numberWithDelimiters(totalTokenCount)}{" "}
              {pluralizeWord(totalTokenCount, "token")}
            </span>
          )}
        </div>
        <div className="flex flex-grow items-center justify-between">
          <div className="flex items-center">
            {gridStyleOptions.map(({ type, icon }) => {
              const handleClick = () => {
                setGridStyle(type);
              };
              return (
                <span className="mr-4" key={type}>
                  <button
                    className={`flex ${
                      gridStyle === type
                        ? "text-primary"
                        : "text-white opacity-80 hover:opacity-100"
                    }`}
                    onClick={handleClick}
                  >
                    {icon}
                  </button>
                </span>
              );
            })}
            {!isLoading && (
              <SortButton sortType={sortType} setSortType={setSortType} />
            )}
          </div>
          {!isPreview && (
            <div className="flex items-center">
              {printedTokenCount === 0 && (
                <div className="mr-6">
                  <ShuffleTokensDrawer />
                </div>
              )}
              <SharePreviewDrawer />
            </div>
          )}
        </div>
      </div>
      <div style={{ height: TOKEN_PAGE_HEADER_HEIGHT }} />
    </>
  );
};

export default TokenHeader;
