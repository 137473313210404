import { useTokensInit } from ".";

export function useTokens() {
  const tokenState = useTokensInit();

  if (!tokenState) {
    throw new Error("Tokens have not been fetched yet");
  }

  return tokenState;
}
