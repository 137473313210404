import { AssetMeta } from "lasagna-commons";
import { useMemo } from "react";
import { useCollectionsInit } from ".";
import type { AssetState } from "../../types";
import { getCollectionId } from "../../utils";

export function useCurrentCollection() {
  const collections = useCollectionsInit();
  const collectionId = getCollectionId();

  if (!collections) {
    throw new Error("Collections have not been fetched");
  }

  return collections[collectionId];
}

export function useAssetMetadata(): AssetMeta {
  const { assetMeta } = useCurrentCollection();

  return assetMeta || { imageHeight: 0, imageWidth: 0, aspectRatio: 1 };
}

export function useAssetTraitData(layers: AssetState["layers"]) {
  return useMemo<{
    totalLayers: number;
    totalTraits: number;
  }>(() => {
    const totalTraits = Object.values(layers).reduce((a, { assets }) => {
      return a + Object.keys(assets).length;
    }, 0);

    return {
      totalLayers: Object.keys(layers).length,
      totalTraits,
    };
  }, [layers]);
}
