export default function Guides() {
  return (
    <svg
      width="16.2"
      height="18"
      viewBox="0 0 18 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.75 13.125V1.875C17.75 0.839453 16.9102 0 15.875 0H4C1.92891 0 0.25 1.67891 0.25 3.75V16.25C0.25 18.3211 1.92891 20 4 20H16.5C17.1902 20 17.75 19.4402 17.75 18.7852C17.75 18.3273 17.4919 17.9445 17.125 17.7266V14.5484C17.5078 14.1719 17.75 13.6797 17.75 13.125ZM15.25 17.5H4C3.30977 17.5 2.75 16.9402 2.75 16.25C2.75 15.5598 3.30977 15 4 15H15.25V17.5Z" />
    </svg>
  );
}
