import {
  Token,
  AssetCounts,
  TOKENS_PER_PAGE,
  generateTokenSetKey,
  Collection,
  getTotalPages,
  getTokenRangeFromPage,
  wait,
} from "lasagna-commons";
import { useRef, useCallback, useEffect } from "react";
import { calculateRarityScore, getCollectionId } from "..";
import { useSnackbarDispatch } from "../../components/SnackbarContext";
import { useTokenDispatch, useTokensInit } from "../../components/TokenContext";
import { API_ENDPOINT } from "../../config";
import { AssetState, CollectionState } from "../../types";
import useCustomFetch from "./useCustomFetch";

export default function useFetchTokens() {
  const collectionId = getCollectionId();
  const tokenDispatch = useTokenDispatch();
  const fetcher = useCustomFetch();
  const setSnackbarMessage = useSnackbarDispatch();
  const loading = useRef(false);

  return useCallback(
    async ({
      currentCollection,
      assetState,
      getIpfsUrl,
    }: {
      currentCollection: Collection & { sampleUrl?: string };
      assetState: AssetState;
      getIpfsUrl?: boolean;
    }) => {
      if (!loading.current) {
        loading.current = true;
        const newTokens: Token[] = [];

        const assetCounts = Object.keys(assetState.layers).reduce<AssetCounts>(
          (a, layerId) => {
            a[layerId] = {};
            Object.keys(assetState.layers[layerId].assets).forEach(
              (assetId) => {
                a[layerId][assetId] = 0;
              }
            );
            return a;
          },
          {}
        );

        const pages = getTotalPages(
          currentCollection.tokenCount,
          TOKENS_PER_PAGE
        );

        const fetchTokenPage = async (pageNumber: number) => {
          const { startId, endId } = getTokenRangeFromPage({
            pageNumber,
            tokensPerPage: TOKENS_PER_PAGE,
            tokenCount: currentCollection.tokenCount,
            tokenIdStartsAt: currentCollection.tokenIdStartsAt,
          });

          const query: { page: number; ipfs?: string } = { page: pageNumber };

          if (getIpfsUrl) {
            query.ipfs = "true";
          }

          const expectedTokenCount = endId - startId + 1;

          let retryCount = 0;

          while (retryCount < 3) {
            const response = await fetcher<{
              tokens: Token[];
              assetCounts: AssetCounts;
            }>({
              url: `${API_ENDPOINT}/collections/${collectionId}/tokens`,
              method: "GET",
              query,
            });

            if (!response) {
              return;
            }

            if (response.tokens.length === expectedTokenCount) {
              return response;
            }

            await wait(5000); // If there aren't enough tokens, we probably need to let DynamoDB rest a little...

            retryCount++;
          }
        };

        let page = 0;

        while (page < pages) {
          const response = await fetchTokenPage(page);

          if (!response) {
            setSnackbarMessage(
              "Some tokens could not be fetched - refresh to try again",
              "error"
            );

            loading.current = false;

            return;
          }

          newTokens.push(...response.tokens);

          if (pages > 1) {
            tokenDispatch((current) => {
              const updates = {
                meta: {
                  assetCounts: {},
                  tokenSet: new Set(
                    newTokens.map(({ tokenLayers }) =>
                      generateTokenSetKey(tokenLayers)
                    )
                  ),
                },
                updateStack: [],
                collectionId,
              };

              if (!current) {
                return {
                  ...updates,
                  tokens: newTokens,
                };
              }

              return {
                ...updates,
                tokens: [...newTokens],
              };
            });
          }

          if (page < pages - 1) {
            wait(500); // Give DynamoDB some breathing room
          }

          page++;
        }

        // Calculate asset counts and also add in any "new layers".
        // If a user adds a layer after tokens have already been generated, none of the tokens will have this layer
        // so we can just add it client-side (we also add this new layer in the AddLayer component via tokenDispatch updates).

        newTokens.forEach((token) => {
          const tokenLayerMap: { [layerId: string]: true } = {};

          token.tokenLayers.forEach(({ layerId, assetId, visible }) => {
            tokenLayerMap[layerId] = true;

            if (visible) {
              assetCounts[layerId][assetId]++;
            }
          });

          // Sort first to ensure the missing layer gets added in the right spot
          Object.keys(assetCounts)
            .sort((a, b) =>
              assetState.layers[a].layerOrder > assetState.layers[b].layerOrder
                ? 1
                : -1
            )
            .forEach((layerId, index) => {
              if (!tokenLayerMap[layerId]) {
                // Add the first asset for the new layer to every token, but hide it
                token.tokenLayers.splice(index, 0, {
                  layerId,
                  assetId: Object.keys(assetCounts[layerId])[0],
                  visible: false,
                });
              }
            });
        });

        tokenDispatch({
          tokens: newTokens
            .sort((a, b) => (a.id > b.id ? 1 : -1))
            .map((token) => ({
              ...token,
              rarityScore: calculateRarityScore({
                token,
                assetCounts,
                tokenCount: newTokens.length,
              }),
            })),
          collectionId,
          meta: {
            assetCounts,
            tokenSet: new Set(
              newTokens.map(({ tokenLayers }) =>
                generateTokenSetKey(tokenLayers)
              )
            ),
          },
          updateStack: [],
        });

        loading.current = false;
      }
    },
    [tokenDispatch, collectionId, fetcher, setSnackbarMessage]
  );
}

export function useTokensEffect({
  collectionState,
  assetState,
  getIpfsUrl,
}: FetchTokenArgs & { getIpfsUrl?: boolean }) {
  const collectionId = getCollectionId();
  const fetchTokens = useFetchTokens();
  const tokenState = useTokensInit();

  const tokens =
    tokenState &&
    tokenState.collectionId === collectionId &&
    ((getIpfsUrl && tokenState.tokens[0].ipfsUrl) || !getIpfsUrl)
      ? tokenState
      : undefined;

  const currentCollection = collectionState && collectionState[collectionId];

  useEffect(() => {
    if (!tokens && currentCollection && assetState) {
      fetchTokens({
        currentCollection,
        assetState,
        getIpfsUrl,
      });
    }
  }, [fetchTokens, tokens, currentCollection, assetState, getIpfsUrl]);

  return tokens;
}

interface FetchTokenArgs {
  collectionState: CollectionState | undefined;
  assetState: AssetState | undefined;
}
