import React from "react";
import type { AssetWithUrls } from "lasagna-commons";
import AssetImage from "../../../../components/AssetImage";
import AssetImageContainer from "../../../../components/AssetImage/AssetImageContainer";

const AssetSearchImage: React.FC<{
  asset: AssetWithUrls;
  aspectRatio: number;
  isCurrent?: boolean;
  labelText: React.ReactNode;
  footerText: React.ReactNode;
}> = ({ aspectRatio, asset, footerText, labelText, isCurrent }) => {
  return (
    <>
      <div className="relative">
        <AssetImageContainer aspectRatio={aspectRatio}>
          <AssetImage asset={asset} size="tiny" />
        </AssetImageContainer>
        {isCurrent && (
          <div className="absolute inset-0 border-2 border-primary flex items-end">
            <span className="block w-full text-center bg-primary text-black text-xs font-secondary">
              Current
            </span>
          </div>
        )}
      </div>
      <div className="text-center overflow-hidden">
        {labelText}
        <span className="block truncate opacity-60 text-xs font-secondary">
          {footerText}
        </span>
      </div>
    </>
  );
};

export default AssetSearchImage;
