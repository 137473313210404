import React from "react";
import { AssetWithUrls, calculateAssetKey } from "lasagna-commons";
import AssetImageContainer from "./AssetImageContainer";
import AssetImageWithFallback from "./AssetImageWithFallback";
import { AssetImageStackProps } from "./types";

const AssetImageStackWithFallback: React.FC<
  AssetImageStackProps & { fallbackSize: keyof AssetWithUrls["urls"] }
> = ({ aspectRatio, tokenLayers, layers, size, fallbackSize, bgClassName }) => {
  return (
    <AssetImageContainer aspectRatio={aspectRatio} bgClassName={bgClassName}>
      {tokenLayers
        .filter(({ visible }) => visible)
        .map(({ assetId, layerId }, index) => {
          return (
            <AssetImageWithFallback
              key={calculateAssetKey({ layerId, assetId })}
              asset={layers[layerId].assets[assetId]}
              size={size}
              zIndex={index}
              fallbackSize={fallbackSize}
            />
          );
        })}
    </AssetImageContainer>
  );
};

export default React.memo(AssetImageStackWithFallback);
