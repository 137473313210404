import React from "react";

export default function AssetIgnoredMessage({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="absolute z-10 inset-0 flex items-center justify-center">
      <span className="bg-black bg-opacity-50 text-xs overflow-hidden truncate w-full text-center py-0.5">
        {children}
      </span>
    </div>
  );
}
