import React from "react";
import type { Collection, CollectionError } from "lasagna-commons";
import useCustomFetch from "../../utils/hooks/useCustomFetch";
import { API_ENDPOINT } from "../../config";
import { useCollectionDispatch } from "../CollectionContext";
import PinningErrorSection from "./PinningErrorSection";
import Button from "../Button";
import RotateIcon from "../../icons/Rotate";
import type { PinningRequestArgs } from "../../pages/Tokens/DownloadDrawer/types";
import { DISCORD_URL } from "../../constants";

const CollectionErrorSection: React.FC<{
  collectionError: CollectionError;
  collection: Collection;
  disablePinningRetry?: boolean;
}> = ({ collectionError, collection, disablePinningRetry }) => {
  const [submitting, setSubmitting] = React.useState(false);

  const fetcher = useCustomFetch();
  const collectionDispatch = useCollectionDispatch();

  const submitCallback = React.useCallback(
    async ({ skipPinning }: PinningRequestArgs) => {
      setSubmitting(true);

      const data = await fetcher({
        url: `${API_ENDPOINT}/collections/${collection.id}/tokens/retry`,
        method: "POST",
        body: {
          skipPinning,
        },
      });

      if (data) {
        collectionDispatch((current) => {
          if (!current) {
            return;
          }
          return {
            ...current,
            [collection.id]: {
              ...current[collection.id],
              collectionError: null,
              pinTokens: !skipPinning,
            },
          };
        });
      } else {
        setSubmitting(false);
      }
    },
    [collectionDispatch, collection.id, fetcher]
  );

  const retryProcessing = async () => {
    submitCallback({
      skipPinning: !collection.pinTokens,
    });
  };

  return (
    <>
      <div className="w-24 h-24 bg-primary text-black flex items-center justify-center mb-6 text-6xl">
        !
      </div>
      <h4 className="mb-4">
        An error occurred while {collectionError.stage} your tokens
      </h4>
      <div className="text-red mb-4">
        <p className="mb-1">
          <strong>Error messsage:</strong> {collectionError.message}
        </p>
        <p>
          <strong>Token ID:</strong> {collectionError.tokenId}
        </p>
      </div>
      {collectionError.stage === "pinning" ? (
        <PinningErrorSection
          submitCallback={submitCallback}
          submitting={submitting}
          disablePinningRetry={disablePinningRetry}
        />
      ) : (
        <div className="mt-6">
          <p className="mb-6">
            If this issue persists, you may need to modify your collection or
            let us know in our{" "}
            <a
              href={DISCORD_URL}
              target="blank"
              rel="noopener noreferrer"
              className="text-primary underline"
            >
              Discord channel
            </a>
            .
          </p>
          <div>
            <Button fullWidth onClick={retryProcessing} disabled={submitting}>
              <RotateIcon />
              <span className="ml-2">
                Try {collectionError.stage} my collection again
              </span>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CollectionErrorSection;
