import React from "react";
import type { ButtonSize, ButtonVariant } from "../Button";
import { calculateButtonStyles } from "../Button/utils";

const ExternalLinkButton: React.FC<{
  href: string | undefined;
  children: React.ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  disabled?: boolean;
}> = ({
  href,
  children,
  variant = "primary",
  fullWidth,
  disabled,
  size = "small",
}) => {
  const { containerClassName, contentClassName, backgroundClassName } =
    calculateButtonStyles({ variant, size, fullWidth });

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`${containerClassName} group ${
        disabled ? "pointer-events-none opacity-50" : ""
      }`}
    >
      <span className={contentClassName}>{children}</span>
      <span className={backgroundClassName} />
    </a>
  );
};

export default ExternalLinkButton;
