import React from "react";
import { useNavigate } from "react-router-dom";
import type { Collection } from "lasagna-commons";
import Button from "../../components/Button";
import TextInput from "../../components/Form/TextInput";
import { useSnackbarDispatch } from "../../components/SnackbarContext";
import { API_ENDPOINT } from "../../config";
import { useRecaptcha } from "../../utils/hooks";
import { useCollectionDispatch } from "../../components/CollectionContext";
import useCustomFetch from "../../utils/hooks/useCustomFetch";
import PlusIcon from "../../icons/Plus";
import LottieIcon from "../LottieIcon";
import animationData from "../LottieIcon/animations/new-collection.json";

const NewCollection: React.FC = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const navigate = useNavigate();
  const setSnackbarMessage = useSnackbarDispatch();
  const execute = useRecaptcha();
  const collectionDispatch = useCollectionDispatch();
  const fetcher = useCustomFetch();

  const handleSubmit = React.useMemo(() => {
    if (execute) {
      return async (e: React.FormEvent) => {
        e.preventDefault();

        if (!inputValue) {
          setSnackbarMessage("Collection name cannot be blank", "error");
          return;
        }

        setSubmitting(true);

        const data = await fetcher<{ collection: Collection }>({
          url: `${API_ENDPOINT}/collections`,
          method: "POST",
          body: {
            collection: {
              name: inputValue,
            },
            ...(await execute()),
          },
        });

        if (data) {
          collectionDispatch((current) => ({
            ...current,
            [data.collection.id]: data.collection,
          }));
          setSnackbarMessage("Collection created - let's get started!");
          navigate(`/collections/${data.collection.id}/assets`);
        }

        setSubmitting(false);
      };
    }
    return undefined;
  }, [
    execute,
    inputValue,
    collectionDispatch,
    fetcher,
    navigate,
    setSnackbarMessage,
  ]);

  return (
    <>
      <LottieIcon animationData={animationData} translateX="-translate-x-9" />
      <h2>New collection</h2>
      <p className="mb-8">
        Generate up to 20,000 tokens. Start for free, pay when your collection
        is done.
      </p>
      <form action="#" onSubmit={handleSubmit}>
        <div className="mb-6">
          <TextInput
            name="name"
            setValue={setInputValue}
            value={inputValue}
            label={{
              text: "Collection name",
              tooltipText:
                "This is the public name of your collection - you will not be able to change it later.",
              tooltipPlacement: "top",
              tooltipContentBackgroundColor: "bg-black",
            }}
            placeholder="Enter a collection name"
          />
        </div>
        <Button fullWidth disabled={submitting || !handleSubmit} type="submit">
          <PlusIcon />
          <span className="ml-3">Start a new collection</span>
        </Button>
      </form>
    </>
  );
};

export default NewCollection;
