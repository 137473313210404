export default function ChevronDown() {
  return (
    <svg
      width="12"
      viewBox="0 0 10 6"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 1.00222L4.988 5L1 1" strokeLinecap="square" />
    </svg>
  );
}
