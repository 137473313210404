import { useAssetsInit } from ".";
import type { AssetState } from "../../types";

export function useAssets(): AssetState {
  const assetState = useAssetsInit();

  if (!assetState) {
    throw new Error("Assets have not been fetched yet");
  }

  return assetState;
}
