import { useContract } from "@thirdweb-dev/react";
import React from "react";
import Button from "../../components/Button";
import { useCollectionDispatch } from "../../components/CollectionContext";
import Drawer from "../../components/Drawer";
import LottieIcon from "../../components/LottieIcon";
import ProgressBar from "../../components/Progress/ProgressBar";
import { useSnackbarDispatch } from "../../components/SnackbarContext";
import { API_ENDPOINT } from "../../config";
import { getCollectionId } from "../../utils";
import useCalculateTokenMetadata from "../../utils/hooks/useCalculateTokenMetadata";
import useCustomFetch from "../../utils/hooks/useCustomFetch";
import uploadAnimationData from "../../components/LottieIcon/animations/upload-asset.json";
import ChevronRightIcon from "../../icons/ChevronRight";
import { useAssetsEffect } from "../../utils/hooks/useFetchAssets";
import { useCollectionsEffect } from "../../utils/hooks/useFetchCollections";
import { useTokensEffect } from "../../utils/hooks/useFetchTokens";

const UploadTokensButton: React.FC<{ contractAddress: string }> = ({
  contractAddress,
}) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  return (
    <>
      <div>
        <Button fullWidth onClick={openDrawer} disabled={drawerOpen}>
          {drawerOpen ? (
            <>Uploading tokens...</>
          ) : (
            <>
              <span className="mr-3">Upload tokens</span>
              <ChevronRightIcon />
            </>
          )}
        </Button>
      </div>
      <Drawer open={drawerOpen}>
        <LottieIcon animationData={uploadAnimationData} />
        <h2 className="animate-pulse">Uploading your tokens</h2>
        {drawerOpen && (
          <UploadTokensDrawerContent
            setDrawerOpen={setDrawerOpen}
            contractAddress={contractAddress}
          />
        )}
      </Drawer>
    </>
  );
};

function UploadTokensDrawerContent({
  setDrawerOpen,
  contractAddress,
}: {
  contractAddress: string;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const assetState = useAssetsEffect();
  const collectionState = useCollectionsEffect();
  const tokenState = useTokensEffect({
    collectionState,
    assetState,
    getIpfsUrl: true,
  });

  const { contract } = useContract(contractAddress, "nft-drop");
  const setSnackbarMessage = useSnackbarDispatch();
  const calculateMetadata = useCalculateTokenMetadata();
  const fetcher = useCustomFetch();
  const collectionId = getCollectionId();
  const collectionDispatch = useCollectionDispatch();
  const collection = collectionState ? collectionState[collectionId] : null;

  const uploadTokens = React.useCallback(async () => {
    if (
      !tokenState ||
      !collection ||
      collection.tokenCount !== tokenState.tokens.length
    ) {
      return;
    }

    if (!contract) {
      setSnackbarMessage("Contract no longer exists", "error");
      return;
    }

    try {
      const metadatas = tokenState.tokens.map(
        ({ id, tokenLayers, description, ipfsUrl }) => {
          if (!ipfsUrl) {
            throw new Error("Missing IPFS URL");
          }

          return {
            ...calculateMetadata({ id, description, tokenLayers }),
            image: ipfsUrl,
          };
        }
      );

      await contract.createBatch(metadatas);

      await fetcher({
        url: `${API_ENDPOINT}/collections/${collection.id}/contracts`,
        method: "PUT",
        body: { contractAddress },
      });

      setSnackbarMessage("Tokens uploaded to contract successfully");

      collectionDispatch((current) => {
        if (!current) {
          return;
        }
        const contracts = current[collection.id].contracts || [];
        const newContracts = [...contracts];
        const contractIndex = contracts.findIndex(
          ({ address }) => contractAddress === address
        );
        newContracts[contractIndex].tokensUploaded = true;

        return {
          ...current,
          [collection.id]: {
            ...current[collection.id],
            contracts: newContracts,
          },
        };
      });
    } catch {
      setSnackbarMessage("Tokens could not be uploaded", "error");
    }

    setDrawerOpen(false);
  }, [
    setDrawerOpen,
    setSnackbarMessage,
    collectionDispatch,
    fetcher,
    contractAddress,
    tokenState,
    calculateMetadata,
    collection,
    contract,
  ]);

  React.useEffect(() => {
    uploadTokens();
  }, [uploadTokens]);

  if (!collection) {
    return null;
  }

  return (
    <div>
      {collection.tokenCount === tokenState?.tokens.length ? (
        <div className="animate-pulse">
          Tokens are ready. After signing off on the transaction with your
          wallet, please wait while we assign your token data to your contract.
          This might take a while for larger collections.
        </div>
      ) : (
        <ProgressBar
          numerator={collection.pinnedTokenCount}
          denominator={collection.tokenCount}
          initialMessage="Fetching tokens..."
        />
      )}
    </div>
  );
}

export default UploadTokensButton;
