export default function ImagePackage() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M27.001 27.375L42.001 18.9375V35.8125L27.001 44.25"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.00039 12.1875L5.96289 32.415L27.0004 44.25V27.375L18.0004 22.3125L16.4816 18.0825L6.00039 12.1875Z"
          fill="black"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.001 18.9375L33.001 13.875L31.4822 9.645L21.001 3.75"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00098 30.75L13.501 23.1562L19.501 36.6562L9.00098 30.75Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.0806 30.8428C22.6538 30.494 22.6342 29.4152 22.0367 28.4333C21.4392 27.4513 20.4901 26.9381 19.9169 27.2869C19.3436 27.6357 19.3633 28.7145 19.9608 29.6964C20.5583 30.6784 21.5074 31.1916 22.0806 30.8428Z"
          fill="currentColor"
        />
        <path
          d="M22.0806 30.8428C22.6538 30.494 22.6342 29.4152 22.0367 28.4333C21.4392 27.4513 20.4901 26.9381 19.9169 27.2869C19.3436 27.6357 19.3633 28.7145 19.9608 29.6964C20.5583 30.6784 21.5074 31.1916 22.0806 30.8428Z"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.001 3.75L6.00098 12.1875"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.001 12.1875L27.001 20.625V24"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0527 10.4702L30.0002 18.9377V22.3127"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.001 8.8125L33.001 17.25V20.625"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
