import React from "react";
import { API_ENDPOINT } from "../../../config";
import { getCollectionId } from "../../../utils";
import useCustomFetch from "../../../utils/hooks/useCustomFetch";
import LottieIcon from "../../../components/LottieIcon";
import animationData from "../../../components/LottieIcon/animations/regenerate-tokens.json";
import { useSnackbarDispatch } from "../../../components/SnackbarContext";
import Button from "../../../components/Button";
import ShuffleIcon from "../../../icons/Shuffle";
import { useTokenDispatch } from "../../../components/TokenContext";
import LoadingIcon from "../../../components/LoadingIcon";

const ShuffleForm: React.FC = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const collectionId = getCollectionId();
  const fetcher = useCustomFetch();
  const setSnackbarMessage = useSnackbarDispatch();
  const tokenDispatch = useTokenDispatch();

  const shuffle = React.useCallback(async () => {
    setSubmitting(true);
    const data = await fetcher<{ success: true }>({
      url: `${API_ENDPOINT}/collections/${collectionId}/tokens/shuffle`,
      method: "POST",
    });

    if (data) {
      setSnackbarMessage("Tokens shuffled successfully");
      tokenDispatch(undefined);
    }
    setSubmitting(false);
  }, [fetcher, collectionId, setSnackbarMessage, tokenDispatch]);

  if (submitting) {
    return (
      <div className="text-center">
        <LoadingIcon />
        <p>Shuffling tokens...this may take a moment</p>
      </div>
    );
  }

  return (
    <div>
      <LottieIcon animationData={animationData} />
      <h2>Shuffle tokens</h2>
      <p className="opacity-60 mb-6">
        Randomly shuffle your tokens. This will retain all of your existing
        tokens, but the token IDs will be swapped. This action cannot be
        reversed.
      </p>
      <div>
        <Button onClick={shuffle} disabled={submitting}>
          <ShuffleIcon />
          <span className="ml-2">Commence shuffle</span>
        </Button>
      </div>
    </div>
  );
};

export default ShuffleForm;
