import React from "react";
import ExternalLink from "./ExternalLink";

const Card: React.FC<{
  headerIcon: React.ReactNode;
  headerText: string;
  bodyText: string;
  button:
    | { type: "link"; linkText: string; linkHref: string }
    | { type: "button"; component: React.ReactNode };
  children?: React.ReactNode;
}> = ({ headerIcon, headerText, bodyText, button, children }) => {
  return (
    <div className="bg-gray shadow-layer-gray flex flex-col">
      {children && (
        <div className="flex-grow flex flex-col justify-center">{children}</div>
      )}
      <div className="px-6 py-4 flex flex-col justify-between h-full">
        <div>
          <div className="flex items-center font-secondary opacity-80 mb-2">
            {headerIcon}
            <span className="ml-2 text-lg">{headerText}</span>
          </div>
          <p className="opacity-60 mb-4">{bodyText}</p>
        </div>
        <div>
          {button.type === "link" ? (
            <ExternalLink href={button.linkHref}>
              {button.linkText}
            </ExternalLink>
          ) : (
            button.component
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
