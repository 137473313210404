import { useCallback, useEffect, useRef } from "react";
import type { Recipe } from "lasagna-commons";
import {
  useRecipeDispatch,
  useRecipesInit,
} from "../../components/RecipeContext";
import { API_ENDPOINT } from "../../config";
import useCustomFetch from "./useCustomFetch";
import { useCollectionsInit } from "../../components/CollectionContext";
import { getCollectionId } from "..";

export default function useFetchRecipes() {
  const collectionId = getCollectionId();
  const recipeDispatch = useRecipeDispatch();

  const fetcher = useCustomFetch();

  const loading = useRef(false);

  return useCallback(async () => {
    if (!loading.current) {
      loading.current = true;
      const data = await fetcher<{
        recipes: Recipe[];
      }>({
        url: `${API_ENDPOINT}/collections/${collectionId}/recipes`,
        method: "GET",
      });
      if (data) {
        recipeDispatch({
          recipes: data.recipes,
          collectionId,
          updateStack: [],
        });
      }
      loading.current = false;
    }
  }, [recipeDispatch, collectionId, fetcher]);
}

export function useRecipesEffect() {
  const collectionId = getCollectionId();
  const collections = useCollectionsInit();
  const fetchRecipes = useFetchRecipes();
  const recipeState = useRecipesInit();

  const recipes =
    recipeState && recipeState.collectionId === collectionId
      ? recipeState
      : undefined;

  useEffect(() => {
    if (!recipes && collections) {
      fetchRecipes();
    }
  }, [fetchRecipes, recipes, collections]);

  return recipes;
}
