import React from "react";
import { useNavigate } from "react-router";
import type { Collection } from "lasagna-commons";
import Button from "./Button";
import Drawer from "./Drawer";
import LottieIcon from "./LottieIcon";
import newCollectionIcon from "./LottieIcon/animations/new-collection.json";
import generateTokensIcon from "./LottieIcon/animations/generate-tokens.json";
import { useSnackbarDispatch } from "./SnackbarContext";
import { API_ENDPOINT } from "../config";
import useCustomFetch from "../utils/hooks/useCustomFetch";
import { useRecaptcha } from "../utils/hooks";
import { useCollectionDispatch, useCollectionsInit } from "./CollectionContext";

const DemoDrawer: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttonLabel: React.ReactNode;
  buttonClassName: string;
}> = ({ open, setOpen, buttonLabel, buttonClassName }) => {
  const [submitting, setSubmitting] = React.useState(false);
  const collectionId = React.useRef("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetcher = useCustomFetch();
  const navigate = useNavigate();
  const setSnackbarMessage = useSnackbarDispatch();
  const execute = useRecaptcha();
  const collectionState = useCollectionsInit();
  const collectionDispatch = useCollectionDispatch();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!execute) {
      return;
    }

    setSubmitting(true);

    const data = await fetcher<{ collection: Collection }>({
      url: `${API_ENDPOINT}/demo-collection`,
      method: "POST",
      body: { ...(await execute()) },
    });

    if (data) {
      collectionId.current = data.collection.id;

      collectionDispatch((current) => {
        if (!current) {
          return { [data.collection.id]: data.collection };
        }
        return {
          ...current,
          [data.collection.id]: data.collection,
        };
      });
      setSnackbarMessage("Demo collection created!");
    } else {
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (collectionState && collectionState[collectionId.current]) {
      navigate(`/collections/${collectionId.current}/tokens`);
    }
  }, [collectionState, navigate]);

  return (
    <>
      <button onClick={handleOpen} className={buttonClassName}>
        {buttonLabel}
      </button>
      <Drawer open={open} onClose={handleClose}>
        {submitting ? (
          <div className="text-center">
            <h2 className="mb-8 animate-pulse">Generating your tokens</h2>
            <div className="flex justify-center mb-2">
              <LottieIcon animationData={generateTokensIcon} translateX="" />
            </div>
            <p className="animate-pulse">
              Please wait while we build your collection...
            </p>
          </div>
        ) : (
          <div>
            <LottieIcon
              animationData={newCollectionIcon}
              translateX="-translate-x-9"
            />
            <h2 className="mb-4">It's demo time</h2>
            <p className="mb-8">
              We'll create a demo collection for you with some pre-made traits
              so you can get a feel for how lasagna works...let's get this thing
              started.
            </p>
            <form onSubmit={handleSubmit} action="#">
              <Button type="submit" fullWidth size="large" disabled={!execute}>
                Create a demo collection
              </Button>
            </form>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default DemoDrawer;
