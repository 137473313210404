import React from "react";
import { useDisconnect } from "@thirdweb-dev/react";
import { API_ENDPOINT } from "../../config";
import LogOutIcon from "../../icons/LogOut";
import { useTargetElement } from "../../utils/hooks";
import { customFetch } from "../../utils";
import { useAuthDispatch } from "../AuthContext";
import { useCurrentUser } from "../AuthContext/hooks";
import Button from "../Button";
import { useNavigate } from "react-router";
import AdminUserDrawer from "./AdminUserDrawer";
import Menu from "../Popper/Menu";

const NavbarUserInfo: React.FC = () => {
  const { targetElement, open, close } = useTargetElement();
  const user = useCurrentUser();
  const authDispatch = useAuthDispatch();
  const navigate = useNavigate();
  const disconnect = useDisconnect();

  const logOut = async () => {
    await customFetch({ url: `${API_ENDPOINT}/auth/logout`, method: "POST" });
    disconnect();
    authDispatch({ user: null });
    navigate("/");
  };

  const displayId = generateDisplayId(user.email, user.address);

  return (
    <div className="hidden lg:flex items-center">
      <div className="relative">
        <Button variant="gray" size="small" onClick={open}>
          <span>{displayId}</span>
        </Button>
        <Menu targetElement={targetElement} onClose={close}>
          <button
            className="py-2 px-4 bg-gray hover:bg-gray-light transition-colors w-full flex items-center justify-center font-secondary text-sm"
            onClick={logOut}
          >
            <LogOutIcon />
            <span className="ml-2">Log out</span>
          </button>
        </Menu>
      </div>
      {user.isAdmin && <AdminUserDrawer />}
    </div>
  );
};

function generateDisplayId(email: string, address: string) {
  if (address) {
    return `${address.slice(0, 5)}...${address.slice(-5)}`;
  }
  if (email.length > 30) {
    return email.slice(0, 30) + "...";
  }
  return email;
}

export default NavbarUserInfo;
