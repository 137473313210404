import React from "react";
import PageWrapper from "../../components/PageWrapper";
import { useCollectionsEffect } from "../../utils/hooks/useFetchCollections";
import LoadingIcon from "../../components/LoadingIcon";
import { useAssetsEffect } from "../../utils/hooks/useFetchAssets";
import { useRecipesEffect } from "../../utils/hooks/useFetchRecipes";
import RecipesMain from "./RecipesMain";
import PageMainContent from "../../components/PageMainContent";

const RecipesRoot: React.FC = () => {
  const collections = useCollectionsEffect();
  const assets = useAssetsEffect();
  const recipeState = useRecipesEffect();

  const loading = !collections || !assets || !recipeState;

  if (loading) {
    return (
      <PageWrapper>
        <PageMainContent centerContent>
          <LoadingIcon />
        </PageMainContent>
      </PageWrapper>
    );
  }

  return <RecipesMain hasRecipes={recipeState.recipes.length > 0} />;
};

export default RecipesRoot;
