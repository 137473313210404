export default function ArrowUp() {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.75635 11.9933V12.7433H5.25635V11.9933H6.75635ZM5.25635 2.67335V1.92335H6.75635V2.67335H5.25635ZM5.25635 11.9933V2.67335H6.75635V11.9933H5.25635Z" />
      <path d="M2.54542 6.52574L2.01505 7.05602L0.954477 5.99527L1.48485 5.46499L2.54542 6.52574ZM5.9998 2.01136L5.46952 1.48099L5.9998 0.950792L6.53009 1.48099L5.9998 2.01136ZM10.5148 5.46499L11.0451 5.99527L9.98456 7.05602L9.45419 6.52574L10.5148 5.46499ZM1.48485 5.46499L5.46952 1.48099L6.53009 2.54174L2.54542 6.52574L1.48485 5.46499ZM6.53009 1.48099L10.5148 5.46499L9.45419 6.52574L5.46952 2.54174L6.53009 1.48099Z" />
    </svg>
  );
}
