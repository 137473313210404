import { useMemo, useState } from "react";
import {
  calculateAssetKey,
  getLayerIdFromAssetKey,
  parseAssetKey,
  Recipe,
  RecipeType,
} from "lasagna-commons";
import {
  RecipeAssetKey,
  RecipeAssetListItem,
  RecipeAssetType,
  RecipeStateDispatch,
} from "../types";

export default function useRecipeForm(initialRecipe?: Recipe) {
  const [recipeState, recipeStateDispatch] = useState<{
    recipeType: RecipeType;
    targetAssetKeys: RecipeAssetKey[];
    pairedAssetKeys: RecipeAssetKey[];
  }>(() =>
    initialRecipe
      ? {
          recipeType: initialRecipe.recipeType,
          targetAssetKeys: initialRecipe.targetAssets.map(calculateAssetKey),
          pairedAssetKeys: initialRecipe.pairedAssets.map(calculateAssetKey),
        }
      : {
          recipeType: "never",
          targetAssetKeys: [null],
          pairedAssetKeys: [null],
        }
  );

  const lists = useMemo(() => {
    return {
      targetAssetList: getList(recipeState.targetAssetKeys),
      pairedAssetList: getList(recipeState.pairedAssetKeys),
    };
  }, [recipeState]);

  return { recipeState, recipeStateDispatch, lists };
}

function getList(keys: RecipeAssetKey[]): RecipeAssetListItem[] {
  const arr: null[] = [];

  const map: { [layerId: string]: string[] } = {};

  keys.forEach((key) => {
    if (key) {
      const { layerId, assetId } = parseAssetKey(key);

      if (map[layerId]) {
        map[layerId].push(assetId);
      } else {
        map[layerId] = [assetId];
      }
    } else {
      arr.push(null);
    }
  });

  return Object.keys(map)
    .map<RecipeAssetListItem>((layerId) => ({
      layerId,
      assetIds: map[layerId],
    }))
    .concat(...arr);
}

export function updateRecipeType(
  recipeType: RecipeType,
  dispatch: RecipeStateDispatch
) {
  dispatch((current) => ({ ...current, recipeType }));
}

export function updateAllRecipeKeys({
  targetAssetKeys,
  pairedAssetKeys,
  dispatch,
}: {
  targetAssetKeys: RecipeAssetKey[];
  pairedAssetKeys: RecipeAssetKey[];
  dispatch: RecipeStateDispatch;
}) {
  dispatch((current) => ({
    recipeType: current.recipeType,
    targetAssetKeys,
    pairedAssetKeys,
  }));
}

export function addRecipeAssetKey(
  recipeAssetType: RecipeAssetType,
  dispatch: RecipeStateDispatch
) {
  dispatch((current) => {
    const newAssetKeys = [...current[recipeAssetType], null];
    return {
      ...current,
      [recipeAssetType]: newAssetKeys,
    };
  });
}

export function removeRecipeAssetKey({
  recipeAssetType,
  dispatch,
  key,
}: {
  recipeAssetType: RecipeAssetType;
  dispatch: RecipeStateDispatch;
  key: { type: "layer"; layerId: string } | { type: "null"; index: number };
}) {
  if (key.type === "layer") {
    dispatch((current) => {
      const newAssetKeys = current[recipeAssetType].filter((assetKey) => {
        return assetKey
          ? key.layerId !== getLayerIdFromAssetKey(assetKey)
          : true;
      });

      return {
        ...current,
        [recipeAssetType]: newAssetKeys,
      };
    });
  } else {
    dispatch((current) => {
      const newAssetKeys = [...current[recipeAssetType]];
      newAssetKeys.splice(key.index, 1);
      return {
        ...current,
        [recipeAssetType]: newAssetKeys,
      };
    });
  }
}
