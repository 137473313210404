import { UserCoupon } from "lasagna-commons";
import React from "react";
import { useAuthDispatch } from "../../../../components/AuthContext";
import Button from "../../../../components/Button";
import { useCollectionDispatch } from "../../../../components/CollectionContext";
import TextInput from "../../../../components/Form/TextInput";
import { useSnackbarDispatch } from "../../../../components/SnackbarContext";
import { API_ENDPOINT } from "../../../../config";
import CheckIcon from "../../../../icons/Check";
import CloseIcon from "../../../../icons/Close";
import PlusIcon from "../../../../icons/Plus";
import useCustomFetch from "../../../../utils/hooks/useCustomFetch";
import type { PaymentFormData } from "../types";

const CouponForm: React.FC<{
  collectionId: string;
  setPaymentFormData: React.Dispatch<
    React.SetStateAction<PaymentFormData | null>
  >;
  setShowPinningForm: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ collectionId, setPaymentFormData, setShowPinningForm }) => {
  const [showCouponForm, setShowCouponForm] = React.useState(false);

  const showForm = () => {
    setShowCouponForm(true);
  };

  return (
    <div className="mt-10 py-4 border-t border-white border-opacity-20">
      {showCouponForm ? (
        <CouponFormElement
          collectionId={collectionId}
          setShowCouponForm={setShowCouponForm}
          setPaymentFormData={setPaymentFormData}
          setShowPinningForm={setShowPinningForm}
        />
      ) : (
        <button
          className="inline-flex items-center font-secondary transition-colors opacity-50 hover:opacity-80 "
          onClick={showForm}
        >
          <PlusIcon />
          <span className="ml-2">Apply coupon</span>
        </button>
      )}
    </div>
  );
};

function CouponFormElement({
  collectionId,
  setShowCouponForm,
  setPaymentFormData,
  setShowPinningForm,
}: {
  collectionId: string;
  setShowCouponForm: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentFormData: React.Dispatch<
    React.SetStateAction<PaymentFormData | null>
  >;
  setShowPinningForm: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [value, setValue] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const fetcher = useCustomFetch();
  const collectionDispatch = useCollectionDispatch();
  const setSnackbarMessage = useSnackbarDispatch();
  const authDispatch = useAuthDispatch();

  const hideForm = () => {
    setShowCouponForm(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!value) {
      setSnackbarMessage("Invalid coupon code", "error");
      return;
    }

    setSubmitting(true);

    const data = await fetcher<{
      amountOwed: number;
      amountPaid: number;
      coupon: UserCoupon;
    }>({
      method: "POST",
      url: `${API_ENDPOINT}/collections/${collectionId}/apply-coupon`,
      body: {
        coupon: value,
      },
    });

    if (data) {
      authDispatch((current) => {
        if (!current.user) {
          return { user: null };
        }

        return {
          ...current,
          user: {
            ...current.user,
            coupon: data.coupon,
          },
        };
      });

      collectionDispatch((current) => {
        if (!current) {
          return;
        }
        return {
          ...current,
          [collectionId]: {
            ...current[collectionId],
            amountPaid: data.amountPaid,
          },
        };
      });

      setSnackbarMessage("Coupon applied successfully");

      if (data.amountOwed === 0) {
        setPaymentFormData(null);
        setShowPinningForm(true);
      } else {
        setPaymentFormData(data);
        setSubmitting(false);
        setShowCouponForm(false);
        setValue("");
      }
    } else {
      setSubmitting(false);
    }
  };

  return (
    <form action="#" className="flex items-end" onSubmit={handleSubmit}>
      <div className="flex-grow">
        <TextInput
          value={value}
          setValue={setValue}
          name="coupon"
          placeholder="Enter coupon code"
          label={{ text: "Coupon" }}
        />
      </div>
      <div className="flex-shrink-0 flex items-center pl-4">
        <div className="mr-4">
          <Button
            onClick={hideForm}
            size="small"
            variant="gray"
            disabled={submitting}
          >
            <CloseIcon size={16} />
            <span className="ml-2">Cancel</span>
          </Button>
        </div>
        <div>
          <Button type="submit" size="small" disabled={submitting}>
            <CheckIcon size={16} />
            <span className="ml-2">Apply</span>
          </Button>
        </div>
      </div>
    </form>
  );
}

export default CouponForm;
