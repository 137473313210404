import React from "react";
import { useDisconnect, useSDK } from "@thirdweb-dev/react";
import { generateSignatureMessage, UserNonce } from "lasagna-commons";
import { useAuthDispatch } from "../../../components/AuthContext";
import type { AuthUser } from "../../../components/AuthContext/types";
import LoadingIcon from "../../../components/LoadingIcon";
import { useSnackbarDispatch } from "../../../components/SnackbarContext";
import { API_ENDPOINT } from "../../../config";
import { customFetch } from "../../../utils";

const SignMessage: React.FC<{ address: string }> = ({ address }) => {
  const [state, setState] = React.useState<{
    userData: UserData | null;
    status: "fetching" | "signing";
    error: string | null;
  }>({ userData: null, status: "fetching", error: null });

  const sdk = useSDK();
  const setSnackbarMessage = useSnackbarDispatch();
  const authDispatch = useAuthDispatch();
  const disconnect = useDisconnect();

  const signCallback = React.useCallback(
    async (userData: UserData) => {
      if (!sdk) {
        return;
      }

      try {
        const message = generateSignatureMessage({
          address,
          nonce: userData.nonce,
        });

        const signature = await sdk.wallet.sign(message);

        const user = await customFetch<AuthUser>({
          method: "POST",
          url: `${API_ENDPOINT}/auth/login-wallet`,
          body: { userId: userData.userId, signature },
        });

        authDispatch({ user });

        setSnackbarMessage("Logged in successfully");
      } catch {
        setState((current) => ({
          ...current,
          error: "Message was not signed",
        }));
      }
    },
    [sdk, address, authDispatch, setSnackbarMessage]
  );

  const fetchNonce = React.useCallback(async () => {
    try {
      const data = await customFetch<UserData>({
        method: "POST",
        url: `${API_ENDPOINT}/auth/identify-wallet`,
        body: { address },
      });

      setState({ status: "signing", userData: data, error: null });
    } catch {
      setState((current) => ({
        ...current,
        error: "Account could not be fetched",
      }));
    }
  }, [address]);

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    fetchNonce();
  }, [fetchNonce]);

  React.useEffect(() => {
    if (state.error) {
      setSnackbarMessage(state.error, "error");
      disconnect();
      return;
    }

    if (state.userData) {
      signCallback(state.userData);
    }
  }, [state, signCallback, disconnect, setSnackbarMessage]);

  return (
    <div>
      <LoadingIcon />
      <div className="text-center">
        <h6 className="text-xl font-bold mb-1">Connecting...</h6>
        <p>
          {state.status === "signing"
            ? "Please sign the message with your wallet to finish logging in."
            : "Please wait while we verify your account."}
        </p>
      </div>
    </div>
  );
};

export default SignMessage;

interface UserData {
  nonce: UserNonce;
  userId: string;
}
