import React from "react";
import ReactDOM from "react-dom";

function useDivRef() {
  const ref = React.useRef<HTMLDivElement>();
  if (!ref.current) {
    ref.current = document.createElement("div");
  }
  return ref.current;
}

const Portal: React.FC<{ mounted: boolean; children: React.ReactNode }> = ({
  mounted,
  children,
}) => {
  const portal = useDivRef();

  React.useEffect(() => {
    if (mounted && portal) {
      document.body.appendChild(portal);
      return () => {
        document.body.removeChild(portal);
      };
    }
  }, [mounted, portal]);

  if (!portal) {
    return null;
  }

  return ReactDOM.createPortal(children, portal);
};

export default Portal;
