import React from "react";
import PageGrid from "../../components/PageGrid";
import PageWrapper from "../../components/PageWrapper";
import ProjectNavbar from "../../components/Navbar/ProjectNavbar";
import InfoCard from "../../components/InfoCard";
import H6 from "../../components/H6";
import GenerateTokensForm from "../../components/GenerateTokens/GenerateTokensForm";
import NoAssetsYet from "../../components/NoAssetsYet";
import PageGridInfo from "../../components/PageGrid/PageGridInfo";
import PageGridCard from "../../components/PageGrid/PageGridCard";
import { useAssets } from "../../components/AssetContext/hooks";
import PageMainContent from "../../components/PageMainContent";
import Drawer from "../../components/Drawer";
import GeneratingModalContent from "../../components/GenerateTokens/GeneratingModalContent";
import { useTokenSubmissionStatus } from "../../components/GenerateTokens/hooks";
import animationData from "../../components/LottieIcon/animations/generate-tokens.json";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";
import { collectionIsReadOnly } from "../../utils";

const BeforeTokenGeneration: React.FC = () => {
  const { layers } = useAssets();
  const { submitting, setStatus } = useTokenSubmissionStatus();
  const collection = useCurrentCollection();

  const isReadOnly = collectionIsReadOnly(collection);

  return (
    <>
      <PageWrapper navbar={<ProjectNavbar />}>
        <PageMainContent addLayoutContainer centerContent>
          <PageGrid>
            <PageGridInfo>
              <div>
                <H6>Tokens</H6>
                <h1>Build your collection</h1>
                <p className="opacity-80 mb-10">
                  The special moment where your collection comes to life.
                  Generate up to 20,000 tokens randomly based on your assets and
                  recipes. Get ready for some magic.
                </p>
                <InfoCard
                  headerText="Tokens"
                  bodyText="Generating your collection"
                  href="https://lasagna.xyz/guides/generate-tokens"
                />
              </div>
            </PageGridInfo>
            <PageGridCard>
              {isReadOnly ? (
                <div>
                  <h2 className="mb-6">Tokens cannot be generated</h2>
                  <p className="opacity-60">
                    Sorry, but you can't create new tokens when a collection is
                    read-only. You might want to ask the owner for edit access.
                  </p>
                </div>
              ) : Object.keys(layers).length > 0 ? (
                <GenerateTokensForm
                  setStatus={setStatus}
                  tokensPage="beforeGen"
                />
              ) : (
                <NoAssetsYet />
              )}
            </PageGridCard>
          </PageGrid>
        </PageMainContent>
      </PageWrapper>
      <Drawer open={submitting}>
        <GeneratingModalContent animationData={animationData} />
      </Drawer>
    </>
  );
};

export default BeforeTokenGeneration;
