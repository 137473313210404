let SQUARE_SRC: string;
let SQUARE_APP_ID: string;
let SQUARE_LOCATION_ID: string;

if (process.env.REACT_APP_IS_STAGING) {
  SQUARE_SRC = "https://sandbox.web.squarecdn.com/v1/square.js";
  SQUARE_APP_ID = "sandbox-sq0idb-yju0mGobWqVlffinJTvi3g";
  SQUARE_LOCATION_ID = "LG4BV1TYPJJ0Y";
} else {
  SQUARE_SRC = "https://web.squarecdn.com/v1/square.js";
  SQUARE_APP_ID = "sq0idp-8TQpgqCO_wm2dR5k_q-eTA";
  SQUARE_LOCATION_ID = "L5HTK5E0TR0YX";
}

const LASAGNA_STYLES = {
  ".input-container": {
    borderColor: "#1F212E",
    borderRadius: "0px",
  },
  ".input-container.is-focus": {
    borderColor: "#FEB701",
  },
  ".input-container.is-error": {
    borderColor: "#ff7878",
  },
  ".message-text": {
    color: "#ffffff",
  },
  ".message-icon": {
    color: "#ffffff",
  },
  ".message-text.is-error": {
    color: "#ff7878",
  },
  ".message-icon.is-error": {
    color: "#ff7878",
  },
  input: {
    backgroundColor: "#1F212E",
    color: "#FFFFFF",
    fontFamily: "helvetica neue, sans-serif",
  },
  "input::placeholder": {
    color: "#cccccc",
  },
  "input.is-error": {
    color: "#ff7878",
  },
};

export { SQUARE_SRC, SQUARE_APP_ID, SQUARE_LOCATION_ID, LASAGNA_STYLES };
