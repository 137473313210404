import { Token } from "lasagna-commons";
import React from "react";
import { VirtuosoGrid } from "react-virtuoso";
import { useAssets } from "../../components/AssetContext/hooks";
import AssetImageStack from "../../components/AssetImage/AssetImageStack";
import IconButton from "../../components/Button/IconButton";
import FullScreenModal from "../../components/FullScreenModal";
import { useTokens } from "../../components/TokenContext/hooks";
import { useViewport } from "../../components/ViewportContext";
import CloseIcon from "../../icons/Close";
import ImageIcon from "../../icons/Image";

const headerHeight = 56;

const ImageSelect: React.FC<{
  sampleToken: Token;
  setSampleToken: React.Dispatch<React.SetStateAction<Token>>;
}> = ({ sampleToken, setSampleToken }) => {
  const [open, setOpen] = React.useState(false);

  const { layers } = useAssets();
  const { tokens } = useTokens();
  const { height } = useViewport();

  const gridHeight = height - headerHeight;

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <button onClick={openModal} className="w-full group" type="button">
        <AssetImageStack
          aspectRatio={1}
          tokenLayers={sampleToken.tokenLayers}
          layers={layers}
          size="small"
        />
        <div className="hidden group-hover:flex bg-black bg-opacity-70 items-center justify-center flex-col absolute inset-0 text-white z-20">
          <div className="mb-1">
            <ImageIcon />
          </div>
          <span className="text-xs font-secondary">Select new token</span>
        </div>
      </button>
      {open && (
        <FullScreenModal onClose={closeModal}>
          <div>
            <div
              className="px-12 flex justify-between items-center border-b border-gray-light"
              style={{ height: headerHeight }}
            >
              <h4 className="mb-0">Select an image</h4>
              <IconButton onClick={closeModal} title="Close modal">
                <CloseIcon />
              </IconButton>
            </div>
            <div className="px-8">
              <VirtuosoGrid
                style={{ height: gridHeight }}
                totalCount={tokens.length}
                itemClassName="w-1/8 p-2 flex items-center"
                listClassName="flex flex-wrap"
                overscan={50}
                itemContent={(index) => {
                  const selected = sampleToken.id === tokens[index].id;

                  const handleClick = () => {
                    setSampleToken(tokens[index]);
                  };

                  return (
                    <button
                      className="w-full relative"
                      onClick={handleClick}
                      type="button"
                    >
                      <AssetImageStack
                        aspectRatio={1}
                        tokenLayers={tokens[index].tokenLayers}
                        layers={layers}
                        size="small"
                      />
                      {selected && (
                        <>
                          <span className="absolute bottom-0 left-0 right-0 bg-primary text-center text-sm text-black uppercase font-secondary z-20">
                            Selected
                          </span>
                          <span className="inset-0 absolute border-2 border-primary bg-transparent" />
                        </>
                      )}
                    </button>
                  );
                }}
              />
            </div>
          </div>
        </FullScreenModal>
      )}
    </>
  );
};

export default ImageSelect;
