import React from "react";
import type { Placement } from "@popperjs/core";
import { useTargetElement } from "../../utils/hooks";
import Popper from ".";

const Tooltip: React.FC<{
  children: React.ReactNode;
  title: React.ReactNode;
  className?: string;
  placement?: Placement;
  tooltipClassName?: string;
  offset?: number;
}> = ({
  children,
  className = "",
  tooltipClassName = "bg-gray-dark text-xs py-1 px-2 text-white shadow-sm",
  placement,
  title,
  offset = 0,
}) => {
  const { open, close, targetElement } = useTargetElement();

  return (
    <>
      <span
        onMouseEnter={open}
        onMouseLeave={close}
        onFocus={open}
        onBlur={close}
        className={className}
        tabIndex={0}
      >
        {children}
      </span>
      <Popper
        targetElement={targetElement}
        placement={placement}
        offset={offset}
        isTooltip
      >
        <div className={tooltipClassName}>{title}</div>
      </Popper>
    </>
  );
};

export default Tooltip;
