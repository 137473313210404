import React from "react";
import { Link } from "react-router-dom";
import { getCollectionId } from "../utils";

const NoAssetsYet: React.FC = () => {
  const collectionId = getCollectionId();

  return (
    <div className="h-full rounded-xl bg-gray flex items-center justify-center">
      <div>
        <h2 className="mb-4">No assets have been loaded yet 🧐</h2>
        <p>
          <span className="opacity-60">
            We appreciate your passion, but you'll need to{" "}
          </span>
          <Link
            to={`/collections/${collectionId}/assets`}
            className="text-primary hover:underline"
          >
            add some assets
          </Link>
          <span className="opacity-60"> before you can create any tokens.</span>
        </p>
      </div>
    </div>
  );
};

export default NoAssetsYet;
