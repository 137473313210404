import React from "react";
import { calculateAssetKey } from "lasagna-commons";
import AssetImageContainer from "./AssetImageContainer";
import AssetImage from ".";
import type { AssetImageStackProps } from "./types";

const AssetImageStack: React.FC<AssetImageStackProps> = ({
  aspectRatio,
  tokenLayers,
  layers,
  size,
}) => {
  return (
    <AssetImageContainer aspectRatio={aspectRatio}>
      {tokenLayers
        .filter(({ visible }) => visible)
        .map(({ assetId, layerId }, index) => {
          return (
            <AssetImage
              key={calculateAssetKey({ layerId, assetId })}
              asset={layers[layerId].assets[assetId]}
              size={size}
              zIndex={index}
            />
          );
        })}
    </AssetImageContainer>
  );
};

export default React.memo(AssetImageStack);
