import { useCallback, useEffect, useMemo, useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import type { DropResult } from "react-beautiful-dnd";
import cloneDeep from "lodash/cloneDeep";
import { useAssets } from "../../components/AssetContext/hooks";
import { useAssetDispatch } from "../../components/AssetContext";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";
import { AssetWithUrls, sortLayers } from "lasagna-commons";
import {
  getLayerOrderUpdates,
  removeAsset,
  removeLayer,
  updateLayerOrder,
} from "../../components/AssetContext/utils";
import type { AssetDispatch, AssetState, RecipeDispatch } from "../../types";
import { modifyAssociatedRecipes } from "../../components/RecipeContext/utils";
import useCustomFetch from "../../utils/hooks/useCustomFetch";
import { API_ENDPOINT } from "../../config";
import useUpdateAssetCount from "../../utils/hooks/useUpdateAssetCount";
import { useRecipeDispatch } from "../../components/RecipeContext";
import { collectionIsReadOnly, getCollectionId } from "../../utils";
import { useSnackbarDispatch } from "../../components/SnackbarContext";

export type UpdateWarning = {
  type: "asset" | "layer" | "layerOrder";
  callback: () => void;
} | null;

export type SetUpdateWarning = Dispatch<SetStateAction<UpdateWarning>>;

function useUpdateWarning() {
  const [open, setOpen] = useState(false);
  const [updateWarning, setUpdateWarning] = useState<UpdateWarning>(null);

  useEffect(() => {
    setOpen(Boolean(updateWarning));
  }, [updateWarning]);

  return { updateWarning, setUpdateWarning, open, setOpen };
}

export function useAssetViewProps() {
  const [isDeleting, setIsDeleting] = useState(false);
  const { layers } = useAssets();
  const assetDispatch = useAssetDispatch();
  const collection = useCurrentCollection();
  const {
    updateWarning,
    setUpdateWarning: originalSetUpdateWarning,
    open,
    setOpen,
  } = useUpdateWarning();

  const [order, setOrder] = useState<string[]>(() =>
    Object.entries(layers)
      .sort((a, b) => sortLayers(a[1], b[1]))
      .map(([layerId]) => layerId)
  );

  const setUpdateWarning =
    collection.tokenCount > 0 && !collection.mustRegenerate
      ? originalSetUpdateWarning
      : undefined;

  const isReadOnly = collectionIsReadOnly(collection);
  const setSnackbarMessage = useSnackbarDispatch();

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const { destination } = result;

      if (!destination || isDeleting) {
        return;
      }

      if (isReadOnly) {
        setSnackbarMessage(
          "Cannot re-order layers when collection is read-only",
          "error"
        );
        return;
      }

      const callback = () => {
        const newOrder = [...order];
        const [removed] = newOrder.splice(result.source.index, 1);
        newOrder.splice(destination.index, 0, removed);
        setOrder(newOrder);
        updateLayerOrder({
          layerOrder: newOrder,
          assetDispatch,
        });
      };

      if (setUpdateWarning) {
        setUpdateWarning({ type: "layerOrder", callback });
      } else {
        callback();
      }
    },
    [
      setUpdateWarning,
      assetDispatch,
      order,
      isDeleting,
      isReadOnly,
      setSnackbarMessage,
    ]
  );

  return useMemo(
    () => ({
      isDeleting,
      order,
      setOrder,
      collection,
      layers,
      setIsDeleting,
      handleDragEnd,
      updateWarning,
      originalSetUpdateWarning,
      warningDrawerOpen: open,
      setWarningDrawerOpen: setOpen,
      setUpdateWarning,
      isReadOnly,
    }),
    [
      isDeleting,
      handleDragEnd,
      updateWarning,
      order,
      collection,
      layers,
      open,
      originalSetUpdateWarning,
      setOpen,
      setUpdateWarning,
      isReadOnly,
    ]
  );
}

export function useLayerDelete({
  order,
  layerId,
  layers,
  setOrder,
  assetDispatch,
  recipeDispatch,
  setIsDeleting,
  setUpdateWarning,
}: {
  order: string[];
  layerId: string;
  layers: AssetState["layers"];
  setOrder: Dispatch<SetStateAction<string[]>>;
  assetDispatch: AssetDispatch;
  recipeDispatch: RecipeDispatch;
  setIsDeleting: Dispatch<SetStateAction<boolean>>;
  setUpdateWarning: Dispatch<SetStateAction<UpdateWarning>> | undefined;
}) {
  const layer = layers[layerId];
  const fetcher = useCustomFetch();
  const collectionId = getCollectionId();

  return useMemo(() => {
    const callback = async () => {
      const removedIndex = order.indexOf(layerId);
      const newOrder = [...order];
      newOrder.splice(removedIndex, 1);
      setOrder(newOrder);
      const { layerOrders } = getLayerOrderUpdates(layers, newOrder);

      removeLayer({ assetDispatch, layerOrder: newOrder });
      modifyAssociatedRecipes(
        Object.values(layer.assets).map((asset) => ({
          layerId,
          assetId: asset.id,
        })),
        recipeDispatch
      );

      setIsDeleting(true);

      await fetcher({
        url: `${API_ENDPOINT}/collections/${collectionId}/layers`,
        method: "DELETE",
        body: { layerId, layerOrders },
      });

      setIsDeleting(false);
    };
    if (setUpdateWarning) {
      return () => {
        setUpdateWarning({ type: "layer", callback });
      };
    }
    return callback;
  }, [
    layerId,
    setOrder,
    order,
    assetDispatch,
    setUpdateWarning,
    recipeDispatch,
    collectionId,
    fetcher,
    layer,
    layers,
    setIsDeleting,
  ]);
}

export function useAssetDelete({
  layerId,
  asset,
  assetDispatch,
  setIsDeleting,
  setUpdateWarning,
}: {
  layerId: string;
  asset: AssetWithUrls;
  assetDispatch: AssetDispatch;
  setIsDeleting: Dispatch<SetStateAction<boolean>>;
  setUpdateWarning: Dispatch<SetStateAction<UpdateWarning>> | undefined;
}) {
  const updateAssetCount = useUpdateAssetCount();
  const fetcher = useCustomFetch();
  const recipeDispatch = useRecipeDispatch();
  const collectionId = getCollectionId();

  return useMemo(() => {
    const callback = async () => {
      removeAsset({ layerId, assetId: asset.id, assetDispatch });
      updateAssetCount(-1);
      modifyAssociatedRecipes([{ layerId, assetId: asset.id }], recipeDispatch);

      setIsDeleting(true);

      await fetcher({
        url: `${API_ENDPOINT}/collections/${collectionId}/assets`,
        method: "DELETE",
        body: { asset: { ...asset, layerId } },
      });

      setIsDeleting(false);
    };

    if (setUpdateWarning) {
      return () => {
        setUpdateWarning({ type: "asset", callback });
      };
    }

    return callback;
  }, [
    layerId,
    assetDispatch,
    setUpdateWarning,
    updateAssetCount,
    recipeDispatch,
    asset,
    collectionId,
    fetcher,
    setIsDeleting,
  ]);
}

export function useSetAssetHidden({
  assetDispatch,
  layerId,
  asset,
}: {
  layerId: string;
  asset: AssetWithUrls;
  assetDispatch: AssetDispatch;
}) {
  const [submitting, setSubmitting] = useState(false);
  const fetcher = useCustomFetch();
  const collectionId = getCollectionId();

  const setAssetHidden = useCallback(async () => {
    const hide = !asset.hide;

    assetDispatch((current) => {
      if (!current) {
        return;
      }

      const assetState = cloneDeep(current);

      assetState.layers[layerId].assets[asset.id].hide = hide;

      return assetState;
    });

    setSubmitting(true);

    await fetcher({
      url: `${API_ENDPOINT}/collections/${collectionId}/assets/hide`,
      method: "PUT",
      body: { hide, layerId, assetId: asset.id },
    });

    setSubmitting(false);
  }, [asset, assetDispatch, collectionId, fetcher, layerId]);

  return { setAssetHidden, submitting };
}
