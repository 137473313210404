import React from "react";
import Card from "../../../../components/Card";
import FileIcon from "../../../../icons/File";
import AssetContainer from "./AssetContainer";
import body from "./body.png";
import hair from "./hair.png";

const ExportingAssetsSection: React.FC = () => {
  return (
    <Card
      headerIcon={<FileIcon />}
      headerText="Exporting your assets"
      bodyText="Export all of your layers and assets in the same canvas size so they perfectly overlap when combined."
      button={{
        type: "link",
        linkText: "Learn more",
        linkHref: "https://lasagna.xyz/guides/export-assets",
      }}
    >
      <>
        <div className="h-48 flex items-center px-6">
          <div className="grid grid-cols-3 gap-2 w-full">
            <AssetContainer label="Background">
              <div className="w-24 h-24 bg-primary" />
            </AssetContainer>
            <AssetContainer label="Body">
              <img src={body} className="w-full" alt="Body example" />
            </AssetContainer>
            <AssetContainer label="Hair">
              <img src={hair} className="w-full" alt="Hair example" />
            </AssetContainer>
          </div>
        </div>
        <div className="px-2">
          <span className="h-0.5 bg-black block" />
        </div>
      </>
    </Card>
  );
};

export default ExportingAssetsSection;
