import React from "react";
import { Draggable } from "react-beautiful-dnd";
import type { TokenLayer } from "lasagna-commons";
import DragHandleIcon from "../../../../icons/DragHandle";
import Button from "../../../../components/Button";
import EyeSlashIcon from "../../../../icons/EyeSlash";
import ShuffleIcon from "../../../../icons/Shuffle";
import ChevronDownIcon from "../../../../icons/ChevronDown";
import AssetImageContainer from "../../../../components/AssetImage/AssetImageContainer";
import AssetImage from "../../../../components/AssetImage";
import EyeIcon from "../../../../icons/Eye";
import AssetSearch from "./AssetSearch";
import { calculateUniqueness, itemCombinationExists } from "./utils";
import { useSnackbarDispatch } from "../../../../components/SnackbarContext";
import { TOGGLE_VISIBILITY, UPDATE_LAYER_ITEM } from "./hooks";
import type { TempCollectionAction } from "./hooks";
import type { TempCollectionState } from "./types";
import type { AssetState } from "../../../../types";
import { useTokens } from "../../../../components/TokenContext/hooks";

const DraggableModalItem: React.FC<{
  index: number;
  tokenLayer: TokenLayer;
  aspectRatio: number;
  tempCollectionState: TempCollectionState;
  tempCollectionDispatch: React.Dispatch<TempCollectionAction>;
  layers: AssetState["layers"];
  searchKey: string | null;
  setSearchKey: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({
  layers,
  index,
  tokenLayer,
  aspectRatio,
  tempCollectionState,
  tempCollectionDispatch,
  searchKey,
  setSearchKey,
}) => {
  const tokenState = useTokens();
  const setMessage = useSnackbarDispatch();

  const asset = layers[tokenLayer.layerId].assets[tokenLayer.assetId];

  const toggleVisibility = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (tokenLayer.visible) {
        setSearchKey(null);
      }
      tempCollectionDispatch({
        type: TOGGLE_VISIBILITY,
        payload: { index },
      });
    },
    [tempCollectionDispatch, index, tokenLayer, setSearchKey]
  );

  const toggleSearch = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setSearchKey((val) =>
        val === tokenLayer.layerId ? null : tokenLayer.layerId
      );
    },
    [setSearchKey, tokenLayer.layerId]
  );

  const handleShuffleClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const { layerId } = tokenLayer;
      const possibleOptions = Object.values(layers[layerId].assets).filter(
        (asset) =>
          tokenLayer.assetId !== asset.id &&
          !itemCombinationExists({
            tokenSet: tempCollectionState.tokenSet,
            layerId,
            tokenLayers: tempCollectionState.tokenLayers,
            asset,
            isCurrent: false,
          })
      );
      if (possibleOptions.length > 0) {
        const randomIndex = Math.floor(Math.random() * possibleOptions.length);
        const asset = possibleOptions[randomIndex];
        tempCollectionDispatch({
          type: UPDATE_LAYER_ITEM,
          payload: { asset, index },
        });
      } else {
        setMessage("No other combinations available for this layer", "error");
      }
    },
    [
      tokenLayer,
      layers,
      tempCollectionState,
      tempCollectionDispatch,
      index,
      setMessage,
    ]
  );

  if (searchKey && searchKey !== tokenLayer.layerId) {
    return null;
  }

  const searchOpen = searchKey === tokenLayer.layerId;

  return (
    <div>
      <Draggable
        index={index}
        draggableId={`${tokenLayer.layerId}-${tokenLayer.assetId}`}
      >
        {({ dragHandleProps, draggableProps, innerRef }, { isDragging }) => {
          return (
            <div
              ref={innerRef}
              {...draggableProps}
              className={`mb-1 bg-gray ${
                searchOpen ? "sticky top-0 z-30 shadow-lg" : ""
              }`}
            >
              <div
                className={`flex cursor-pointer py-3 px-4 ${
                  isDragging ? "bg-gray-light shadow-2xl" : "bg-gray"
                }`}
                onClick={toggleSearch}
                role="button"
                tabIndex={0}
              >
                <div
                  className={`flex items-center flex-grow overflow-hidden transition-opacity ${
                    tokenLayer.visible ? "opacity-100" : "opacity-30"
                  }`}
                >
                  <div className="mr-4 flex-shrink-0">
                    <span
                      {...dragHandleProps}
                      onClick={(e) => e.stopPropagation()}
                      className={searchOpen ? "pointer-events-none" : ""}
                    >
                      <DragHandleIcon />
                    </span>
                  </div>
                  <div className="w-16 mr-4 flex-shrink-0 overflow-hidden">
                    <AssetImageContainer aspectRatio={1}>
                      <AssetImage asset={asset} size="tiny" />
                    </AssetImageContainer>
                  </div>
                  <div className="flex-grow overflow-hidden pr-4 pl-1 -ml-1">
                    <span className="inline-block truncate text-xs leading-none px-2 py-1 font-secondary bg-gray-dark shadow-layer-label max-w-full">
                      {layers[tokenLayer.layerId].label}
                    </span>
                    <span className="block truncate -mt-1 mb-0.5">
                      {asset.label}
                    </span>
                    <span className="block truncate text-xs opacity-60 font-secondary">
                      {tokenLayer.visible
                        ? calculateUniqueness({
                            tokenState,
                            layerId: tokenLayer.layerId,
                            assetId: tokenLayer.assetId,
                          })
                        : "Layer is hidden"}
                    </span>
                  </div>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  <div>
                    <Button
                      variant={tokenLayer.visible ? "primary" : "secondary"}
                      size="small"
                      onClick={toggleVisibility}
                    >
                      <span className="flex h-6 w-6 justify-center items-center">
                        {tokenLayer.visible ? <EyeSlashIcon /> : <EyeIcon />}
                      </span>
                    </Button>
                  </div>
                  <div className="px-4">
                    <Button
                      size="small"
                      disabled={!tokenLayer.visible}
                      onClick={handleShuffleClick}
                    >
                      <span className="flex py-0.5">
                        <ShuffleIcon size={20} />
                      </span>
                    </Button>
                  </div>
                  <div>
                    <Button
                      size="small"
                      disabled={!tokenLayer.visible}
                      onClick={toggleSearch}
                    >
                      <span className="w-10 text-left py-0.5">
                        {searchOpen ? "Done" : "Edit"}
                      </span>
                      <span
                        className={`transform ${
                          searchOpen ? "rotate-180" : ""
                        }`}
                      >
                        <ChevronDownIcon />
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Draggable>
      {searchOpen && (
        <AssetSearch
          layerId={tokenLayer.layerId}
          index={index}
          tokenLayer={tokenLayer}
          tempCollectionState={tempCollectionState}
          tempCollectionDispatch={tempCollectionDispatch}
        />
      )}
    </div>
  );
};

export default DraggableModalItem;
