import React from "react";
import Checkbox from "./Checkbox";

interface CheckboxInputProps {
  name: string;
  children: React.ReactNode;
  checked: boolean;
  onChange: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  name,
  children,
  checked,
  onChange,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <label
      className="relative flex items-center cursor-pointer font-secondary group"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <input
        type="checkbox"
        name={name}
        className="absolute inset-0 opacity-0 z-1"
        checked={checked}
        onChange={onChange}
      />
      <Checkbox checked={checked} />
      <span
        className={`ml-3 relative selection:bg-transparent flex-grow overflow-hidden ${
          checked ? "text-primary" : "opacity-80 group-hover:opacity-100"
        }`}
      >
        {children}
      </span>
    </label>
  );
};

export default CheckboxInput;
