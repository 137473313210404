import { AssetMeta } from "lasagna-commons";
import type { FileWithPath } from "react-dropzone";
import type {
  AssetErrorMessage,
  InitialClientAssetWithLayerId,
} from "../../types";
import { generateAssetId, validateUploadedFile } from "../../utils";

export async function processAssets({
  files,
  currentAssetIds,
  assetMeta,
  layerId,
}: {
  files: FileWithPath[];
  currentAssetIds: string[];
  assetMeta: AssetMeta;
  layerId: string;
}): Promise<{
  assets: InitialClientAssetWithLayerId[];
  errors: AssetErrorMessage[];
}> {
  const errors: AssetErrorMessage[] = [];
  const assets: InitialClientAssetWithLayerId[] = [];
  const assetIdSet = new Set(currentAssetIds);

  const deriveAssetId = () => {
    let id = assetIdSet.size;
    let assetId = generateAssetId(id);

    while (assetIdSet.has(assetId)) {
      id++;
      assetId = generateAssetId(id);
    }

    assetIdSet.add(assetId);

    return assetId;
  };

  for (const file of files) {
    const { asset, assetError } = await validateUploadedFile({
      file,
      assetMeta,
      deriveAssetId,
    });
    if (assetError) {
      errors.push(assetError);
    } else if (asset) {
      assets.push({ ...asset, layerId });
    }
  }

  if (assets.length === 0) {
    throw new Error("None of these files were compatible with your collection");
  }

  return { assets, errors };
}
