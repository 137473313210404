import React from "react";
import HomeNavbar from "../../../components/Navbar/HomeNavbar";
import NavbarUserInfo from "../../../components/Navbar/NavbarUserInfo";
import Button from "../../../components/Button";
import {
  CAROUSEL_CONTAINER_STYLES,
  CAROUSEL_ITEM_STYLES,
} from "../../../constants";
import NewCollectionForm from "../../../components/NewCollectionForm";
import ChevronRightIcon from "../../../icons/ChevronRight";
import PlaceholderImage from "../PlaceholderImage";
import { numberWithDelimiters } from "../../../utils";
import { useCollectionsInit } from "../../../components/CollectionContext";
import { Link } from "react-router-dom";
import PageWrapper from "../../../components/PageWrapper";
import PageMainContent from "../../../components/PageMainContent";
import CollectionDeleteButton from "./CollectionDeleteButton";
import DemoDrawer from "../../../components/DemoDrawer";

const itemStyles = `${CAROUSEL_ITEM_STYLES} bg-gray shadow-layer-gray w-96`;

const CollectionCarousel: React.FC = () => {
  const [demoDrawerOpen, setDemoDrawerOpen] = React.useState(false);

  const collections = useCollectionsInit();

  const collectionArray = collections
    ? Object.keys(collections)
        .map((key) => ({ key, collection: collections[key] }))
        .sort((a, b) =>
          a.collection.preview
            ? 1
            : a.collection.createdAt > b.collection.createdAt
            ? -1
            : 1
        )
    : [];

  return (
    <PageWrapper
      navbar={
        <HomeNavbar>
          <NavbarUserInfo />
        </HomeNavbar>
      }
    >
      <PageMainContent addLayoutContainer centerContent paddingBottom={40}>
        <div className={`${CAROUSEL_CONTAINER_STYLES} h-full hide-scrollbar`}>
          <div className={`${itemStyles} flex flex-col justify-center h-auto`}>
            <div className="p-8">
              <NewCollectionForm />
              <div className="mt-16 text-sm">
                <span className="opacity-60">Need some inspiration? </span>
                <DemoDrawer
                  open={demoDrawerOpen}
                  setOpen={setDemoDrawerOpen}
                  buttonLabel="Try a demo collection"
                  buttonClassName="text-primary hover:underline"
                />
                <span className="opacity-60">.</span>
              </div>
            </div>
          </div>
          {collectionArray.map(({ collection, key }) => {
            const href = `/collections/${key}${
              collection.tokenCount > 0 && !collection.mustRegenerate
                ? "/tokens"
                : ""
            }`;

            return (
              <div
                className={`${itemStyles} flex flex-col relative`}
                key={key + collection.userId}
              >
                {!collection.preview && (
                  <CollectionDeleteButton
                    collectionName={collection.name}
                    collectionId={collection.id}
                  />
                )}
                <Link
                  className="relative aspect-ratio-1 border-b-2 border-black"
                  to={href}
                >
                  {collection.sampleUrl ? (
                    <div className="absolute inset-0">
                      <img
                        src={collection.sampleUrl}
                        alt={`${collection.name} sample token`}
                        className="object-center object-cover w-full h-full"
                      />
                    </div>
                  ) : (
                    <PlaceholderImage />
                  )}
                  {collection.preview && (
                    <span className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-80 text-white font-secondary text-sm text-center py-3">
                      Shared with me
                    </span>
                  )}
                </Link>
                <div className="pt-4 px-6 pb-6">
                  <h4 className="truncate">{collection.name}</h4>
                  <div className="flex mb-4">
                    <span className="w-32">
                      <span className="block text-xl">
                        {collection.assetCount}
                      </span>
                      <span className="opacity-80">Assets</span>
                    </span>
                    <span>
                      <span className="block text-xl">
                        {numberWithDelimiters(collection.tokenCount)}
                      </span>
                      <span className="opacity-80">Tokens</span>
                    </span>
                  </div>
                  <Button element="link" href={href} fullWidth>
                    <span className="mr-3">View collection</span>
                    <ChevronRightIcon />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </PageMainContent>
    </PageWrapper>
  );
};

export default CollectionCarousel;
