import type { AssetWithUrls } from "lasagna-commons";
import React from "react";
import AssetImage from ".";
import { AssetImageProps } from "./types";

// This component will display a smaller fallback image which has likely already
// been cached and loaded by the browser while the larger image loads, then display
// the larger image once the image has been fully loaded by the browser.
// This helps to prevent a janky loading state.

const AssetImageWithFallback: React.FC<
  AssetImageProps & { fallbackSize: keyof AssetWithUrls["urls"] }
> = ({ asset, size, fallbackSize, zIndex }) => {
  const [hasLoaded, setHasLoaded] = React.useState(false);

  const displaySize = asset.urls[size] ? size : "default";

  const handleLoad = React.useCallback(() => {
    setHasLoaded(true);
  }, []);

  return (
    <>
      <AssetImage
        asset={asset}
        zIndex={zIndex}
        size={hasLoaded ? displaySize : fallbackSize}
      />
      {!hasLoaded && (
        <img
          alt="Placeholder"
          className="opacity-0 absolute top-0 left-0"
          width={1}
          onLoad={handleLoad}
          src={asset.urls[displaySize]}
        />
      )}
    </>
  );
};

export default React.memo(AssetImageWithFallback);
