import React from "react";
import { NAV_HEIGHT } from "../../constants/layoutConstants";
import LayoutContainer from "../LayoutContainer";

const Navbar: React.FC<{ children: React.ReactNode; bgColor?: string }> = ({
  children,
  bgColor = "bg-black",
}) => {
  return (
    <nav
      className={`fixed top-0 left-0 w-full flex items-center z-30 ${bgColor}`}
      style={{ height: NAV_HEIGHT }}
    >
      <LayoutContainer className="flex items-center justify-between font-secondary">
        {children}
      </LayoutContainer>
    </nav>
  );
};

export default Navbar;
