import React from "react";
import type { AssetWithUrls, TokenLayer } from "lasagna-commons";
import SearchInput from "../../../../components/Form/SearchInput";
import { searchFilter, sortByLabel } from "../../../../utils";
import AssetSearchImage from "./AssetSearchImage";
import { calculateUniqueness, itemCombinationExists } from "./utils";
import LockIcon from "../../../../icons/Lock";
import { UPDATE_LAYER_ITEM } from "./hooks";
import type { TempCollectionAction } from "./hooks";
import type { TempCollectionState } from "./types";
import { useAssets } from "../../../../components/AssetContext/hooks";
import { useTokens } from "../../../../components/TokenContext/hooks";
import { useAssetMetadata } from "../../../../components/CollectionContext/hooks";
import AddTraitButton from "./AddTraitButton";

const AssetSearch: React.FC<{
  layerId: string;
  index: number;
  tokenLayer: TokenLayer;
  tempCollectionState: TempCollectionState;
  tempCollectionDispatch: React.Dispatch<TempCollectionAction>;
}> = ({
  layerId,
  tokenLayer,
  index,
  tempCollectionState,
  tempCollectionDispatch,
}) => {
  const [searchValue, setSearchValue] = React.useState("");
  const { layers } = useAssets();
  const tokenState = useTokens();
  const { aspectRatio } = useAssetMetadata();
  const { label, layerOrder, assets: assetsObject } = layers[layerId];
  const assets = Object.values(assetsObject);
  const layerInput = { id: layerId, label, layerOrder };

  const addAssetCallback = React.useCallback(
    (asset: AssetWithUrls) => {
      tempCollectionDispatch({
        type: UPDATE_LAYER_ITEM,
        payload: { asset, index },
      });
    },
    [index, tempCollectionDispatch]
  );

  return (
    <div className="mt-1 bg-gray px-4">
      <div className="py-3">
        <SearchInput
          name={`search-${layerId}`}
          value={searchValue}
          setValue={setSearchValue}
          placeholder={`Search ${label} traits`}
        />
      </div>
      <div className="grid grid-cols-4 gap-4 mt-2 pb-4">
        {assets
          .filter((asset) => searchFilter(asset.label, searchValue))
          .sort(sortByLabel)
          .map((asset) => {
            const handleClick = () => {
              tempCollectionDispatch({
                type: UPDATE_LAYER_ITEM,
                payload: { asset, index },
              });
            };
            const isCurrent = tokenLayer.assetId === asset.id;
            if (
              itemCombinationExists({
                tokenSet: tempCollectionState.tokenSet,
                layerId: tokenLayer.layerId,
                tokenLayers: tempCollectionState.tokenLayers,
                asset,
                isCurrent,
              })
            ) {
              return (
                <div className="opacity-40" key={asset.id}>
                  <AssetSearchImage
                    aspectRatio={aspectRatio}
                    asset={asset}
                    labelText={
                      <span className="flex items-center justify-center mt-1 overflow-hidden">
                        <span className="truncate mr-1">{asset.label}</span>
                        <span className="flex flex-shrink-0">
                          <LockIcon />
                        </span>
                      </span>
                    }
                    footerText="Combination Exists"
                  />
                </div>
              );
            }
            return (
              <div
                key={asset.id}
                role="button"
                tabIndex={0}
                onClick={handleClick}
              >
                <AssetSearchImage
                  asset={asset}
                  aspectRatio={aspectRatio}
                  isCurrent={isCurrent}
                  labelText={
                    <span className="block mt-1 truncate">{asset.label}</span>
                  }
                  footerText={calculateUniqueness({
                    tokenState,
                    layerId,
                    assetId: asset.id,
                  })}
                />
              </div>
            );
          })}
        <div>
          <AddTraitButton
            aspectRatio={aspectRatio}
            layerInput={layerInput}
            currentAssetIds={Object.keys(assetsObject)}
            callback={addAssetCallback}
          />
        </div>
      </div>
    </div>
  );
};

export default AssetSearch;
