import { AssetWithUrls, LayerInput } from "lasagna-commons";
import React from "react";
import AddAsset from "../../../../components/AddAsset";
import PlusIcon from "../../../../icons/Plus";

const AddTraitButton: React.FC<{
  aspectRatio: number;
  layerInput: LayerInput;
  currentAssetIds: string[];
  callback: (asset: AssetWithUrls) => void;
}> = ({ aspectRatio, layerInput, currentAssetIds, callback }) => {
  return (
    <AddAsset
      layerInput={layerInput}
      currentAssetIds={currentAssetIds}
      callback={callback}
    >
      <div
        style={{ paddingBottom: `${aspectRatio * 100}%` }}
        className="border border-white border-dashed relative bg-gray hover:bg-gray-light"
      >
        <span className="flex flex-col absolute inset-0 items-center justify-center">
          <PlusIcon size={20} />
          <span className="mt-2 font-secondary text-sm">Add trait</span>
        </span>
      </div>
    </AddAsset>
  );
};

export default AddTraitButton;
