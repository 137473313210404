import React from "react";

const FooterItem: React.FC<{ count: React.ReactNode; label: string }> = ({
  count,
  label,
}) => {
  return (
    <div className="px-4">
      <span className="block text-xl">{count}</span>
      <span className="block opacity-50 font-secondary text-xs">{label}</span>
    </div>
  );
};

export default FooterItem;
