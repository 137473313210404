import React from "react";

const FormLabel: React.FC<{ name?: string; children: React.ReactNode }> = ({
  name,
  children,
}) => {
  return (
    <label
      htmlFor={name}
      className="font-secondary text-sm inline-flex items-center pb-1"
    >
      {children}
    </label>
  );
};

export default FormLabel;
