import React from "react";
import { useAssets } from "../../components/AssetContext/hooks";
import ExternalLinkButton from "../../components/ExternalLink/ExternalLinkButton";
import H6 from "../../components/H6";
import ProjectNavbar from "../../components/Navbar/ProjectNavbar";
import NoAssetsYet from "../../components/NoAssetsYet";
import PageGrid from "../../components/PageGrid";
import PageGridCard from "../../components/PageGrid/PageGridCard";
import PageGridInfo from "../../components/PageGrid/PageGridInfo";
import PageMainContent from "../../components/PageMainContent";
import PageWrapper from "../../components/PageWrapper";
import ChevronRightIcon from "../../icons/ChevronRight";
import ProgrammingIcon from "../../icons/Programming";
import NewRecipeForm from "./NewRecipeForm";

const NoRecipes: React.FC = () => {
  const { layers } = useAssets();
  return (
    <PageWrapper navbar={<ProjectNavbar />}>
      <PageMainContent addLayoutContainer centerContent>
        <PageGrid>
          <PageGridInfo>
            <div>
              <H6>Recipes</H6>
              <h1>Generate smarter</h1>
              <p className="opacity-80 mb-10">
                Use recipes to make your generating process even smarter by
                creating rules to control or limit specific asset combinations.
              </p>
              <div className="bg-gray shadow-layer-gray-light flex items-center justify-between py-4 px-6">
                <div className="pr-8">
                  <div className="flex items-center mb-2 font-bold">
                    <ProgrammingIcon />
                    <span className="ml-2">Recipes</span>
                  </div>
                  <p>
                    Set individual recipes that permit or force combinations to
                    optimize your results when generating your tokens.
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <ExternalLinkButton href="https://lasagna.xyz/guides/add-recipes">
                    <span className="mr-2">Learn more</span>
                    <ChevronRightIcon />
                  </ExternalLinkButton>
                </div>
              </div>
            </div>
          </PageGridInfo>
          <PageGridCard>
            {Object.keys(layers).length > 0 ? (
              <NewRecipeForm />
            ) : (
              <NoAssetsYet />
            )}
          </PageGridCard>
        </PageGrid>
      </PageMainContent>
    </PageWrapper>
  );
};

export default NoRecipes;
