import type { Collection } from "lasagna-commons";
import React from "react";
import Button from "../../../components/Button";
import { useCollectionDispatch } from "../../../components/CollectionContext";
import { API_ENDPOINT } from "../../../config";
import ChevronRightIcon from "../../../icons/ChevronRight";
import SkipArrowIcon from "../../../icons/SkipArrow";
import Thirdweb from "../../../icons/Thirdweb";
import useCustomFetch from "../../../utils/hooks/useCustomFetch";
import type { PinningRequestArgs } from "./types";

const PinningForm: React.FC<{
  setPrinting: React.Dispatch<React.SetStateAction<boolean>>;
  collection: Collection;
}> = ({ setPrinting, collection }) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [showSubmittingMessage, setShowSubmittingMessage] =
    React.useState(false);

  const fetcher = useCustomFetch();
  const collectionDispatch = useCollectionDispatch();

  React.useEffect(() => {
    if (submitting) {
      const timeoutId = setTimeout(() => {
        setShowSubmittingMessage(true);
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [submitting]);

  const submitCallback = React.useCallback(
    async ({ skipPinning }: PinningRequestArgs) => {
      setSubmitting(true);

      const data = await fetcher({
        url: `${API_ENDPOINT}/collections/${collection.id}/tokens/print`,
        method: "POST",
        body: {
          skipPinning,
        },
      });

      if (data) {
        setPrinting(true);
        collectionDispatch((current) => {
          if (!current) {
            return;
          }
          return {
            ...current,
            [collection.id]: {
              ...current[collection.id],
              pinTokens: !skipPinning,
            },
          };
        });
      } else {
        setSubmitting(false);
      }
    },
    [fetcher, collection.id, setPrinting, collectionDispatch]
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    await submitCallback({
      skipPinning: false,
    });
  };

  const submitWithoutPinning = async () => {
    await submitCallback({
      skipPinning: true,
    });
  };

  return (
    <>
      <div className="mb-5 w-16">
        <Thirdweb />
      </div>
      <h2 className="mb-6">Pin to IPFS with thirdweb</h2>
      <p className="mb-10">
        You can pin all of your tokens to IPFS (for free) by using our thirdweb
        storage integration. If you plan to launch your collection using our
        thirdweb integration, this step is required. Please note that this may
        take a while to complete depending on your collection size.
      </p>
      <form onSubmit={handleSubmit} action="#">
        {showSubmittingMessage ? (
          <div className="animate-pulse mt-8 text-lg text-primary">
            Processing your request...please wait.
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-6 mt-8">
            <div>
              <Button
                fullWidth
                variant="gray"
                onClick={submitWithoutPinning}
                disabled={submitting}
              >
                <SkipArrowIcon />
                <span className="ml-3">Skip pinning</span>
              </Button>
            </div>
            <div>
              <Button type="submit" fullWidth disabled={submitting}>
                <span className="mr-3">Pin to IPFS</span>
                <ChevronRightIcon />
              </Button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default PinningForm;
