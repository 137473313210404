export default function Plus({ size = 14 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 1V0H6V1H8ZM6 13V14H8V13H6ZM6 1V13H8V1H6Z" />
      <path d="M1 6H0V8H1V6ZM13 8H14V6H13V8ZM1 8H13V6H1V8Z" />
    </svg>
  );
}
