import React from "react";
import { createBasicCtx, createCtx } from "../../utils";
import type { CollectionState } from "../../types";

const [useCollectionsInit, CollectionContextProvider] =
  createBasicCtx<CollectionState>();
const [useCollectionDispatch, CollectionDispatchContextProvider] =
  createCtx<
    React.Dispatch<React.SetStateAction<CollectionState | undefined>>
  >();

const CollectionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [collections, setCollections] = React.useState<CollectionState>();

  return (
    <CollectionDispatchContextProvider value={setCollections}>
      <CollectionContextProvider value={collections}>
        {children}
      </CollectionContextProvider>
    </CollectionDispatchContextProvider>
  );
};

export { useCollectionsInit, useCollectionDispatch, CollectionProvider };
