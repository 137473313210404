import { AssetWithUrls } from "lasagna-commons";
import React from "react";
import { useAssets } from "../../../components/AssetContext/hooks";
import AssetImage from "../../../components/AssetImage";
import AssetImageContainer from "../../../components/AssetImage/AssetImageContainer";
import ChevronRightIcon from "../../../icons/ChevronRight";
import type { RecipeAssetListItem, RecipeModalState } from "../types";
import { useRecipeModal } from "./RecipeModalContext";

const RecipeModalButton: React.FC<
  RecipeModalState & { item: RecipeAssetListItem; isReadOnly: boolean }
> = ({ item, isReadOnly, ...props }) => {
  const { setActiveRecipe } = useRecipeModal();
  const { layers } = useAssets();

  const { assets, layerLabel } = React.useMemo(() => {
    if (item) {
      return {
        assets: item.assetIds.map((id) => ({
          ...layers[item.layerId].assets[id],
          layerId: item.layerId,
        })),
        layerLabel: layers[item.layerId].label,
      };
    }
    return {
      assets: null,
      layerLabel: null,
    };
  }, [item, layers]);

  const handleClick = () => {
    setActiveRecipe(props);
  };

  return (
    <button
      className={`flex text-left items-center justify-between w-full transition-colors disabled:pointer-events-none ${
        assets
          ? "text-white hover:text-primary"
          : "text-primary hover:text-secondary"
      }`}
      onClick={handleClick}
      type="button"
      disabled={isReadOnly}
    >
      <div className="flex items-center flex-grow overflow-hidden pr-2">
        <div className="mr-3 flex">
          {assets ? (
            assets
              .slice(0, 3)
              .map((asset, index) => (
                <ImageContainer asset={asset} index={index} key={asset.id} />
              ))
          ) : (
            <ImageContainer asset={null} index={0} />
          )}
        </div>
        <div className="overflow-hidden">
          {assets ? (
            <>
              <span className="block text-xs opacity-60 leading-tight truncate">
                {layerLabel}
              </span>
              <span className="block truncate">
                {assets.length > 1
                  ? `${assets.length} assets`
                  : assets[0].label}
              </span>
            </>
          ) : (
            "Select an asset"
          )}
        </div>
      </div>
      <span className="flex-shrink-0 flex">
        <ChevronRightIcon />
      </span>
    </button>
  );
};

export default RecipeModalButton;

function ImageContainer({
  asset,
  index,
}: {
  asset: AssetWithUrls | null;
  index: number;
}) {
  return (
    <div
      className={`w-9 flex-shrink-0 relative bg-gray-light ${
        index === 0 ? "" : "shadow-3xl -ml-6 border-l border-gray-light"
      }`}
      style={{ zIndex: index + 1 }}
    >
      <AssetImageContainer aspectRatio={1}>
        {asset && <AssetImage asset={asset} size="tiny" />}
      </AssetImageContainer>
    </div>
  );
}
