import React from "react";

const AssetContainer: React.FC<{
  label: string;
  children: React.ReactNode;
}> = ({ label, children }) => {
  return (
    <div>
      <div className="w-24 h-24 bg-gray-light flex items-center justify-center m-auto">
        {children}
      </div>
      <span className="font-secondary text-xs block text-center mt-1 opacity-80">
        {label}
      </span>
    </div>
  );
};

export default AssetContainer;
