import React from "react";
import NumberFormat from "react-number-format";
import InfoTooltip from "../Popper/InfoTooltip";
import FormLabel from "./FormLabel";
import InputBottomBorder from "./InputBottomBorder";
import TextArea from "./TextArea";
import type { InputBaseProps, MemoizedInputProps } from "./types";

type InputProps = InputBaseProps &
  (
    | {
        componentType: "text";
      }
    | {
        componentType: "number";
        min: number;
        max: number;
        decimalScale?: number;
      }
    | { componentType: "textarea"; maxRows?: number; initialRows?: number }
  );

const InputBase: React.FC<InputProps> = ({
  label,
  setValue,
  error,
  endAdornment,
  name,
  value,
  placeholder,
  onBlur,
  isDark,
  readOnly,
  addErrorGutter,
  spellCheck,
  ...props
}) => {
  const [focused, setFocused] = React.useState(false);

  const inputProps = React.useMemo<MemoizedInputProps>(() => {
    return {
      onFocus: () => {
        setFocused(true);
      },
      onBlur: () => {
        setFocused(false);
        onBlur && onBlur();
      },
      onChange: setValue
        ? (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setValue(event.target.value as any);
          }
        : undefined,
      name,
      value,
      placeholder,
      className: `bg-transparent outline-none py-0.5 w-full placeholder:opacity-50 ${
        isDark ? "placeholder:text-black" : ""
      }`,
    };
  }, [name, placeholder, setValue, value, onBlur, isDark]);

  return (
    <div className="relative">
      <div className={`flex items-end relative group`}>
        <div className="flex-grow overflow-hidden">
          {label && (
            <FormLabel name={name}>
              <span className={`mr-3 ${isDark ? "" : "opacity-60"}`}>
                {label.text}
              </span>
              {label.tooltipText && (
                <InfoTooltip
                  title={label.tooltipText}
                  placement={label.tooltipPlacement}
                  contentBackgroundColor={label.tooltipContentBackgroundColor}
                  iconOpacity={isDark ? "opacity-100" : "opacity-70"}
                />
              )}
            </FormLabel>
          )}
          {readOnly ? (
            <span className="block py-0.5 cursor-default opacity-60 truncate">
              {value || <span className="invisible">None</span>}
            </span>
          ) : props.componentType === "number" ? (
            <NumberFormat
              {...inputProps}
              thousandSeparator={true}
              decimalScale={props.decimalScale || 0}
              isAllowed={({ floatValue }) =>
                floatValue === undefined
                  ? true
                  : floatValue >= props.min && floatValue <= props.max
              }
            />
          ) : props.componentType === "textarea" ? (
            <TextArea
              {...inputProps}
              className={`${inputProps.className} resize-none`}
              maxRows={props.maxRows}
              initialRows={props.initialRows}
            />
          ) : (
            <input {...inputProps} spellCheck={spellCheck} autoComplete="off" />
          )}
        </div>
        {endAdornment && (
          <span className="flex-shrink-0 flex items-center">
            {endAdornment}
          </span>
        )}
        <InputBottomBorder
          focused={focused}
          isDark={isDark}
          error={error}
          readOnly={readOnly}
        />
      </div>
      {error && (
        <span
          className={`text-xs text-red truncate ${
            addErrorGutter ? "block pl-1 pt-1" : "absolute left-1 -bottom-5"
          }`}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default React.memo(InputBase);
