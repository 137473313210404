export default function ArrowDown() {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00781 1.00665V10.3267"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M2.01562 7.00464L6.00029 10.9886L9.98496 7.00464"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
}
