import React from "react";
import ChevronRightIcon from "../../icons/ChevronRight";

const Link: React.FC<{
  children: React.ReactNode;
  href: string;
  className?: string;
}> = ({ children, href, className = "text-white hover:text-primary" }) => {
  return (
    <a
      className={`font-secondary transition-colors group inline-flex items-center ${className}`}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="mr-2 underline">{children}</span>
      <span className="flex items-center transform transition-transform group-hover:translate-x-1">
        <ChevronRightIcon />
      </span>
    </a>
  );
};

export default Link;
