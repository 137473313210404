import { useMemo } from "react";
import { useAssets } from "../../components/AssetContext/hooks";

export default function useTotalPossibleCombinations() {
  const { layers } = useAssets();

  return useMemo(() => {
    return Object.values(layers).reduce((a, b) => {
      a *= Object.values(b.assets).filter(({ hide }) => !hide).length;
      return a;
    }, 1);
  }, [layers]);
}
