import { useContract, useContractRead } from "@thirdweb-dev/react";
import { ChainId } from "@thirdweb-dev/sdk";
import { CollectionContract } from "lasagna-commons";
import React, { useEffect } from "react";
import { useAuth } from "../../components/AuthContext";
import IconButton from "../../components/Button/IconButton";
import ExternalLinkButton from "../../components/ExternalLink/ExternalLinkButton";
import { useSnackbarDispatch } from "../../components/SnackbarContext";
import { CAROUSEL_ITEM_STYLES } from "../../constants";
import CheckIcon from "../../icons/Check";
import ChevronRightIcon from "../../icons/ChevronRight";
import { getCollectionId } from "../../utils";
import { NETWORK_OPTION_MAP } from "./NetworkSelect";
import UploadTokensButton from "./UploadTokensButton";

const thirdwebNetworkMap: { [key: number]: string } = {
  [ChainId.Mainnet]: "ethereum",
  [ChainId.Polygon]: "polygon",
  [ChainId.Fantom]: "fantom",
  [ChainId.Avalanche]: "avalanche",
  [ChainId.Optimism]: "optimism",
  [ChainId.Arbitrum]: "arbitrum",
  [ChainId.BinanceSmartChainMainnet]: "binance",
  [ChainId.Goerli]: "goerli",
  [ChainId.Mumbai]: "mumbai",
  [ChainId.FantomTestnet]: "fantom-testnet",
  [ChainId.AvalancheFujiTestnet]: "avalanche-fuji",
  [ChainId.OptimismGoerli]: "optimism-goerli",
  [ChainId.ArbitrumGoerli]: "arbitrum-goerli",
  [ChainId.BinanceSmartChainTestnet]: "binance-testnet",
};

const SmartContractContainer: React.FC<{
  contract: CollectionContract;
  index: number;
}> = ({ contract: { imageUrl, address, chainId, tokensUploaded }, index }) => {
  const [contractData, setContractData] = React.useState<{
    image: string;
  } | null>(null);

  const { contract } = useContract(address);
  const { data } = useContractRead(contract, "contractURI");

  useEffect(() => {
    if (data) {
      fetch(updateIpfsUrl(data))
        .then((value) => value.json())
        .then(setContractData);
    }
  }, [data]);

  const displayedImageUrl = updateIpfsUrl(contractData?.image || imageUrl);
  const collectionId = getCollectionId();
  const { user } = useAuth();
  const setSnackbarMessage = useSnackbarDispatch();

  const copyAddress = async () => {
    await window.navigator.clipboard.writeText(address);
    setSnackbarMessage("Address copied to clipboard");
  };

  return (
    <div
      className={`${CAROUSEL_ITEM_STYLES} bg-gray shadow-layer-gray w-128 flex flex-col relative p-8`}
    >
      <div className="flex">
        <div className="flex-shrink-0 w-20 mr-4">
          <div className="relative aspect-square bg-gray-dark">
            <div className="absolute inset-0 flex items-center justify-center">
              <img
                className="object-center object-contain w-full h-full"
                src={displayedImageUrl}
                alt="Custom sample"
              />
            </div>
          </div>
        </div>
        <ul className="flex-grow">
          <li className="mb-4">
            <h5 className="text-sm font-secondary opacity-60 mb-0.5">
              Contract Address
            </h5>
            <div className="flex items-center">
              <span className="mr-3">
                {address.slice(0, 10)}...
                {address.slice(address.length - 10, address.length)}
              </span>
              <IconButton
                onClick={copyAddress}
                title="Copy contract address to clipboard"
              >
                <div className="relative w-3 h-3">
                  <span className="absolute border-2 border-white inset-0" />
                  <span className="absolute border-2 border-white inset-0 transform translate-x-1 -translate-y-1 bg-gray" />
                </div>
              </IconButton>
            </div>
          </li>
          <li>
            <h5 className="text-sm font-secondary opacity-60 mb-0.5">
              Contract Network
            </h5>
            <div>{NETWORK_OPTION_MAP[chainId]}</div>
          </li>
        </ul>
      </div>
      <div className="mt-12 mb-8">
        {tokensUploaded ? (
          <div className="bg-gradient-to-b from-secondary to-primary flex items-center py-2 px-3">
            <span className="bg-black rounded-full h-6 w-6 flex items-center justify-center text-primary">
              <CheckIcon size={20} />
            </span>
            <span className="ml-2 font-bold text-black">
              Tokens uploaded successfully
            </span>
          </div>
        ) : (
          <UploadTokensButton contractAddress={address} />
        )}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <ExternalLinkButton
            href={`https://thirdweb.com/${thirdwebNetworkMap[chainId]}/${address}/`}
            size="small"
            fullWidth
          >
            <span className="mr-3">Manage contract</span>
            <ChevronRightIcon />
          </ExternalLinkButton>
        </div>
        <div>
          <ExternalLinkButton
            href={`https://mint.lasagna.xyz/${user?.userId}/${collectionId}/${index}`}
            size="small"
            fullWidth
          >
            <span className="mr-3">View mint page</span>
            <ChevronRightIcon />
          </ExternalLinkButton>
        </div>
      </div>
    </div>
  );
};

function updateIpfsUrl(value: string) {
  return value.replace("ipfs://", "https://gateway.ipfscdn.io/ipfs/");
}

export default SmartContractContainer;
