import LottieIcon from "../LottieIcon";

export default function GeneratingModalContent({
  animationData,
}: {
  animationData: any;
}) {
  return (
    <div className="text-center">
      <h2 className="mb-8 animate-pulse">Generating your tokens</h2>
      <div className="flex justify-center mb-2">
        <LottieIcon animationData={animationData} translateX="" />
      </div>
      <p className="animate-pulse">
        This might take a few seconds for larger collections...please wait.
      </p>
    </div>
  );
}
