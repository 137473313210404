import React from "react";
import {
  useCoinbaseWallet,
  useMetamask,
  useWalletConnect,
} from "@thirdweb-dev/react";
import Button from "../../../components/Button";
import { useSnackbarDispatch } from "../../../components/SnackbarContext";
import ChevronRightIcon from "../../../icons/ChevronRight";
import MetaMaskIcon from "../../../icons/MetaMask";
import CoinbaseWalletIcon from "../../../icons/CoinbaseWallet";
import WalletConnectIcon from "../../../icons/WalletConnect";
import { getErrorMessage } from "../../../utils";

const ConnectWalletButtons: React.FC = () => {
  const [loadingWallet, setLoadingWallet] = React.useState<string | null>(null);
  const connectMetaMask = useMetamask();
  const connectCoinbase = useCoinbaseWallet();
  const connectWalletConnect = useWalletConnect();
  const setSnackbarMessage = useSnackbarDispatch();

  const connectors = React.useMemo(() => {
    return [
      { label: "MetaMask", icon: <MetaMaskIcon />, connect: connectMetaMask },
      {
        label: "Coinbase Wallet",
        icon: <CoinbaseWalletIcon />,
        connect: connectCoinbase,
      },
      {
        label: "Wallet Connect",
        icon: <WalletConnectIcon />,
        connect: connectWalletConnect,
      },
    ];
  }, [connectMetaMask, connectCoinbase, connectWalletConnect]);

  return (
    <>
      <h2 className="alt-highlight">Sign in with your wallet</h2>
      <ul>
        {connectors.map((connector) => {
          const handleClick = async () => {
            try {
              setLoadingWallet(connector.label);
              await connector.connect();
            } catch (err) {
              setLoadingWallet(null);
              setSnackbarMessage(getErrorMessage(err), "error");
            }
          };

          const loading = loadingWallet === connector.label;

          return (
            <li key={connector.label} className="py-3">
              <Button
                disabled={loading}
                onClick={handleClick}
                fullWidth
                variant="black"
                size="large"
              >
                <span className="w-full flex justify-between">
                  <span className="flex items-center flex-grow">
                    {connector.icon}
                    <span className="mx-3">{connector.label}</span>
                  </span>
                  <span className="flex items-center flex-shrink-0">
                    {loading ? (
                      <span className="block w-4 h-4 border-white border-2 rounded-full border-r-transparent border-b-transparent animate-spin" />
                    ) : (
                      <ChevronRightIcon size="large" />
                    )}
                  </span>
                </span>
              </Button>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default ConnectWalletButtons;
