import React from "react";
import { useLocation, useNavigate } from "react-router";
import HomeNavbar from "../../components/Navbar/HomeNavbar";
import { MIN_SCREEN_WIDTH, REDIRECT_TO_QUERY_PARAM } from "../../constants";
import EmailSection from "./EmailSection";
import ConnectWalletSection from "./ConnectWalletSection";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (pathname !== "/") {
      navigate(`/?${REDIRECT_TO_QUERY_PARAM}=${pathname}`);
    }
  }, [navigate, pathname]);

  return (
    <div style={{ minWidth: MIN_SCREEN_WIDTH }}>
      <HomeNavbar bgColor="bg-transparent" />
      <div className="grid grid-cols-2 h-screen">
        <div className="relative h-full">
          <EmailSection />
          <span className="absolute font-secondary flex items-center justify-center top-1/2 right-0 h-10 w-10 bg-black border border-primary text-white -mr-5 -mt-5 pointer-events-none">
            OR
          </span>
        </div>
        <ConnectWalletSection />
      </div>
    </div>
  );
};

export default LoginPage;
