import type { AssetWithUrls } from "lasagna-commons";
import React, { Dispatch, SetStateAction } from "react";
import type { UpdateWarning } from "../../hooks";
import AssetGridItemContent from "./AssetGridItemContent";

interface AssetGridItemProps {
  asset: AssetWithUrls;
  layerId: string;
  aspectRatio: number;
  isDeleting: boolean;
  setIsDeleting: Dispatch<SetStateAction<boolean>>;
  setUpdateWarning: Dispatch<SetStateAction<UpdateWarning>> | undefined;
  isReadOnly: boolean;
}

const AssetGridItem: React.FC<AssetGridItemProps> = ({
  asset,
  layerId,
  aspectRatio,
  isDeleting,
  setIsDeleting,
  setUpdateWarning,
  isReadOnly,
}) => {
  if (!asset) {
    return null;
  }

  return (
    <AssetGridItemContent
      asset={asset}
      layerId={layerId}
      aspectRatio={aspectRatio}
      setIsDeleting={setIsDeleting}
      setUpdateWarning={setUpdateWarning}
      isDeleting={isDeleting}
      isReadOnly={isReadOnly}
    />
  );
};

export default AssetGridItem;
