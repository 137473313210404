import React from "react";
import Menu from "../../components/Popper/Menu";
import ChevronDownIcon from "../../icons/ChevronDown";
import { useTargetElement } from "../../utils/hooks";

const buttonStyles =
  "font-secondary flex w-full items-center px-4 cursor-pointer";

function Select<T>({
  value,
  setValue,
  error,
  optionSets,
}: {
  value: T;
  setValue: React.Dispatch<React.SetStateAction<T>>;
  error?: string;
  optionSets: {
    label: string;
    options: { label: string; value: T }[];
  }[];
}) {
  const { open, close, targetElement } = useTargetElement();

  const optionMap = React.useMemo(() => {
    const map: Record<string, string> = {};
    optionSets.forEach((set) => {
      set.options.forEach((option) => {
        map[`${option.value}`] = option.label;
      });
    });
    return map;
  }, [optionSets]);

  return (
    <div>
      <button
        className={`text-left flex w-full items-center justify-between py-1.5 border-b-2 border-white ${
          targetElement
            ? "border-b-2 border-opacity-60"
            : "border-opacity-30 hover:border-b-2 hover:border-opacity-60 hover:-mb-0.25"
        }`}
        onClick={open}
        type="button"
      >
        <span className="flex-grow mr-4 font-secondary truncate">
          {optionMap[`${value}`] || "Select an option"}
        </span>
        <ChevronDownIcon />
      </button>
      {error && (
        <div className="font-secondary text-xs text-red mt-1">{error}</div>
      )}
      <Menu targetElement={targetElement} onClose={close}>
        <div className="py-1 bg-gray-dark h-64 overflow-auto">
          {optionSets.map(({ label, options }) => {
            return (
              <div key={label}>
                <h5 className="opacity-60 font-secondary text-xs my-1 px-3">
                  {label}
                </h5>
                <ul>
                  {options.map((option) => {
                    const handleClick = () => {
                      setValue(option.value);
                    };

                    const selected = Number(option.value) === value;

                    return (
                      <li key={`${option.value}`}>
                        <button
                          className={`${buttonStyles} py-1.5 justify-between ${
                            selected
                              ? "text-primary bg-black"
                              : "text-white bg-gray-dark hover:bg-black"
                          }`}
                          type="button"
                          onClick={handleClick}
                        >
                          <span>{option.label}</span>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </Menu>
    </div>
  );
}

export default Select;
