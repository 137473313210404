import React from "react";
import { CAROUSEL_ITEM_STYLES } from "../../constants";
import NewRecipeForm from "./NewRecipeForm";
import { useRecipes } from "../../components/RecipeContext/hooks";
import RecipeCarouselItem from "./RecipeCarouselItem";
import ProjectNavbar from "../../components/Navbar/ProjectNavbar";
import PageWrapper from "../../components/PageWrapper";
import PageMainContent from "../../components/PageMainContent";
import RecipeFooter from "./RecipeFooter";

const itemStyles = `${CAROUSEL_ITEM_STYLES} bg-gray shadow-layer-gray w-96`;

const RecipeCarousel: React.FC = () => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { recipes } = useRecipes();

  const sortedRecipes = recipes.sort((a, b) => (a.id > b.id ? -1 : 1));

  return (
    <PageWrapper navbar={<ProjectNavbar />} footer={<RecipeFooter />}>
      <PageMainContent>
        <div className="flex overflow-auto pl-16 pr-12 py-1 hide-scrollbar">
          <div className={`${itemStyles} flex flex-col justify-center mb-2`}>
            <div className="p-8">
              <NewRecipeForm />
            </div>
          </div>
          {sortedRecipes.map((recipe, index) => (
            <RecipeCarouselItem
              recipe={recipe}
              key={recipe.id}
              index={index}
              totalRecipes={sortedRecipes.length}
              isDeleting={isDeleting}
              setIsDeleting={setIsDeleting}
            />
          ))}
        </div>
      </PageMainContent>
    </PageWrapper>
  );
};

export default RecipeCarousel;
