import { useCallback, useEffect, useRef } from "react";
import { getCollectionId } from "..";
import { useAssetDispatch, useAssetsInit } from "../../components/AssetContext";
import { useCollectionsInit } from "../../components/CollectionContext";
import { API_ENDPOINT } from "../../config";
import type { AssetState } from "../../types";
import useCustomFetch from "./useCustomFetch";

export default function useFetchAssets() {
  const collectionId = getCollectionId();
  const assetDispatch = useAssetDispatch();
  const fetcher = useCustomFetch();

  const loading = useRef(false);

  return useCallback(async () => {
    if (!loading.current) {
      loading.current = true;
      const data = await fetcher<{
        layers: AssetState["layers"];
      }>({
        url: `${API_ENDPOINT}/collections/${collectionId}/assets`,
        method: "GET",
      });

      if (data) {
        assetDispatch((current) => {
          if (!current || !current.isInitialLoad) {
            return {
              layers: data.layers,
              collectionId,
              updateStack: [],
            };
          }

          // Retain locally hosted images as the default image URL, but add smaller thumbnails if they are available
          const layers = { ...current.layers };

          Object.keys(data.layers).forEach((layerId) => {
            Object.keys(data.layers[layerId].assets).forEach((assetId) => {
              thumbnailSizes.forEach((thumbnailSize) => {
                if (data.layers[layerId].assets[assetId].urls[thumbnailSize]) {
                  layers[layerId].assets[assetId].urls[thumbnailSize] =
                    data.layers[layerId].assets[assetId].urls[thumbnailSize];
                }
              });
            });
          });

          return {
            layers,
            collectionId,
            updateStack: [],
          };
        });
      }

      loading.current = false;
    }
  }, [assetDispatch, collectionId, fetcher]);
}

const thumbnailSizes = ["tiny", "small", "large"] as const;

export function useAssetsEffect(
  { checkForThumbnails }: { checkForThumbnails: boolean } = {
    checkForThumbnails: false,
  }
) {
  const collectionId = getCollectionId();
  const collectionState = useCollectionsInit();
  const fetchAssets = useFetchAssets();
  const assetState = useAssetsInit();

  const assets =
    assetState && assetState.collectionId === collectionId
      ? assetState
      : undefined;

  useEffect(() => {
    if (
      collectionState &&
      (!assets || (assets.isInitialLoad && checkForThumbnails))
    ) {
      fetchAssets();
    }
  }, [fetchAssets, assets, collectionState, checkForThumbnails]);

  return assets;
}
