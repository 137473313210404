import { useState } from "react";

export function useTokenSubmissionStatus() {
  const [status, setStatus] = useState<TokenSubmissionStatus>(null);

  return {
    status,
    setStatus,
    submitting: status !== null,
  };
}

export type TokenSubmissionStatus = "generating" | "fetching" | null;
