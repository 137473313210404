import type { Recipe } from "lasagna-commons";
import React, { SetStateAction } from "react";
import IconButton from "../../components/Button/IconButton";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";
import { useRecipeDispatch } from "../../components/RecipeContext";
import { API_ENDPOINT } from "../../config";
import { CAROUSEL_ITEM_STYLES } from "../../constants";
import TrashIcon from "../../icons/Trash";
import { collectionIsReadOnly } from "../../utils";
import useCustomFetch from "../../utils/hooks/useCustomFetch";
import RecipeLabel from "./RecipeLabel";
import UpdateRecipeForm from "./UpdateRecipeForm";

const itemStyles = `${CAROUSEL_ITEM_STYLES} bg-gray shadow-layer-gray w-96`;

const RecipeCarouselItem: React.FC<{
  recipe: Recipe;
  index: number;
  totalRecipes: number;
  isDeleting: boolean;
  setIsDeleting: React.Dispatch<SetStateAction<boolean>>;
}> = ({ recipe, index, totalRecipes, isDeleting, setIsDeleting }) => {
  const recipeDispatch = useRecipeDispatch();
  const collection = useCurrentCollection();
  const fetcher = useCustomFetch();

  const isReadOnly = collectionIsReadOnly(collection);

  const deleteRecipe = async () => {
    setIsDeleting(true);

    const data = await fetcher({
      url: `${API_ENDPOINT}/collections/${collection.id}/recipes`,
      method: "DELETE",
      body: { recipe: { id: recipe.id } },
    });

    if (data) {
      setIsDeleting(false);

      recipeDispatch((current) => {
        if (!current) {
          return;
        }
        const recipes = [...current.recipes];

        recipes.splice(index, 1);

        return {
          ...current,
          recipes,
        };
      });
    }
  };

  return (
    <div className={`${itemStyles} flex flex-col p-8 mb-2`}>
      <div className="flex items-center justify-between mb-8">
        <RecipeLabel
          index={index}
          totalRecipes={totalRecipes}
          recipe={recipe}
          isReadOnly={isReadOnly}
        />
        <span className="flex flex-shrink-0">
          <IconButton
            onClick={deleteRecipe}
            title="Remove this recipe"
            disabled={isDeleting || isReadOnly}
          >
            <TrashIcon />
          </IconButton>
        </span>
      </div>
      <UpdateRecipeForm recipe={recipe} index={index} isReadOnly={isReadOnly} />
    </div>
  );
};

export default RecipeCarouselItem;
