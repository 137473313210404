import React, { Dispatch, SetStateAction } from "react";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import FooterItem from "../../components/FooterItem";
import { collectionIsReadOnly, numberWithDelimiters } from "../../utils";
import RegenerateTokensDrawer from "../../components/GenerateTokens/RegenerateTokensDrawer";
import {
  useAssetTraitData,
  useCurrentCollection,
} from "../../components/CollectionContext/hooks";
import { useAssets } from "../../components/AssetContext/hooks";
import useTotalPossibleCombinations from "../../utils/hooks/useTotalPossibleCombinations";
import { ASIDE_WIDTH } from "../../constants/layoutConstants";

const AssetFooter: React.FC<{
  showDefaultView: boolean;
  setShowDefaultView: Dispatch<SetStateAction<boolean>>;
}> = ({ showDefaultView, setShowDefaultView }) => {
  const collection = useCurrentCollection();
  const { layers } = useAssets();

  const isReadOnly = collectionIsReadOnly(collection);

  const { totalLayers, totalTraits } = useAssetTraitData(layers);

  const totalPossibleCombinations = useTotalPossibleCombinations();

  const items = [
    { label: "Total layers", count: totalLayers },
    { label: "Total traits", count: numberWithDelimiters(totalTraits) },
    {
      label: "Estimated total combinations",
      count: numberWithDelimiters(totalPossibleCombinations),
    },
  ];

  const handleClick = () => {
    setShowDefaultView((val) => !val);
  };

  return (
    <Footer>
      <div className="flex items-center">
        <div className="flex px-1" style={{ width: ASIDE_WIDTH }}>
          <div className="w-1/2">
            <ToggleButton onClick={handleClick} isActive={showDefaultView}>
              Classic
            </ToggleButton>
          </div>
          <div className="w-1/2">
            <ToggleButton onClick={handleClick} isActive={!showDefaultView}>
              Condensed
            </ToggleButton>
          </div>
        </div>
        <div className="flex">
          {items.map((item) => (
            <FooterItem key={item.label} {...item} />
          ))}
        </div>
      </div>
      {!isReadOnly && (
        <div>
          {collection.tokenCount > 0 ? (
            <RegenerateTokensDrawer collectionId={collection.id} />
          ) : (
            <Button
              element="link"
              href={`/collections/${collection.id}/tokens`}
            >
              Generate tokens
            </Button>
          )}
        </div>
      )}
    </Footer>
  );
};

function ToggleButton({
  children,
  isActive,
  onClick,
}: {
  children: string;
  isActive: boolean;
  onClick: () => void;
}) {
  return (
    <button
      className={`w-full py-2 font-secondary text-sm ${
        isActive ? "bg-white text-black" : "bg-gray-light"
      }`}
      disabled={isActive}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default AssetFooter;
