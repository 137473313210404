import React from "react";
import ImageIcon from "../../../../icons/Image";

const ImageItem: React.FC<{ label: string }> = ({ label }) => {
  return (
    <div className="flex items-center py-1">
      <ImageIcon />
      <span className="ml-2 opacity-60">{label}</span>
    </div>
  );
};

export default ImageItem;
