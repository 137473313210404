import React from "react";
import type { FileWithPath } from "react-dropzone";
import FileInput from "../../../components/Form/FileInput";
import { addLayer } from "../../../components/AssetContext/utils";
import { processLayerFiles } from "./utils";
import useAssetUpload from "../../../utils/hooks/useAssetUpload";
import useUpdateAssetCount from "../../../utils/hooks/useUpdateAssetCount";
import {
  useAssetMetadata,
  useAssetTraitData,
} from "../../../components/CollectionContext/hooks";
import { MAX_ASSET_COUNT } from "lasagna-commons";
import { getErrorMessage } from "../../../utils";
import { useTokenDispatch } from "../../../components/TokenContext";

const AddLayer: React.FC<{
  setOrder: React.Dispatch<React.SetStateAction<string[]>>;
  children: React.ReactNode;
}> = ({ setOrder, children }) => {
  const {
    setLoading,
    setSnackbarMessage,
    setProcessedFileProgress,
    layers,
    assetDispatch,
    run,
    modals,
  } = useAssetUpload();
  const { totalTraits } = useAssetTraitData(layers);
  const updateAssetCount = useUpdateAssetCount();
  const assetMeta = useAssetMetadata();
  const tokenDispatch = useTokenDispatch();

  const handleFilesAdded = async (files: FileWithPath[]) => {
    setProcessedFileProgress({ numerator: 0, denominator: files.length });

    setLoading(true);

    try {
      const { layer, assets, errors } = await processLayerFiles({
        files,
        layers,
        totalTraits,
        assetMeta,
        setProcessedFileProgress,
      });

      const { newLayers } = await run(assets, [layer]);

      addLayer({
        layer: newLayers,
        assetDispatch,
        errors,
        layerId: layer.id,
        tokenDispatch,
      });

      setOrder((current) => [...current, Object.keys(newLayers)[0]]);

      updateAssetCount(assets.length);
    } catch (err) {
      setSnackbarMessage(`Error - ${getErrorMessage(err)}`, "error");

      setProcessedFileProgress({ numerator: 0, denominator: 0 });
    }
    setLoading(false);
  };

  if (totalTraits >= MAX_ASSET_COUNT) {
    return null;
  }

  return (
    <>
      <FileInput onFilesAdded={handleFilesAdded} type="directory">
        {children}
      </FileInput>
      {modals}
    </>
  );
};

export default AddLayer;
