import React from "react";
import Button from "../../../components/Button";
import Drawer from "../../../components/Drawer";
import ShuffleIcon from "../../../icons/Shuffle";
import ShuffleForm from "./ShuffleForm";

const ShuffleTokensDrawer: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} variant="gray" size="small">
        <ShuffleIcon />
        <span className="ml-3">Shuffle tokens</span>
      </Button>
      <Drawer open={open} onClose={handleClose} width="wide">
        <ShuffleForm />
      </Drawer>
    </>
  );
};

export default ShuffleTokensDrawer;
