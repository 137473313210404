export default function Upload() {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 17.2501H1"
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M2.99805 5.25L7.00005 1.25L11.002 5.25"
        stroke="currentColor"
        strokeLinecap="square"
      />
      <path
        d="M7 11.2499V1.58325"
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
