export default function TriangleArrow() {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.78037 3.97031L4.59625 0.970312C4.41838 0.822656 4.21295 0.75 4.00752 0.75C3.80209 0.75 3.59717 0.823242 3.44059 0.969727L0.256467 3.96973C0.00480378 4.18359 -0.0638491 4.50703 0.0602592 4.78594C0.184367 5.06484 0.476926 5.25 0.80085 5.25H7.19165C7.51582 5.25 7.80843 5.06763 7.93269 4.78711C8.05695 4.50659 8.01085 4.18359 7.78037 3.97031Z"
        fill="white"
      />
    </svg>
  );
}
