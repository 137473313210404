import React from "react";
import { numberWithDelimiters } from "../../utils";
import type { ProgressProps } from "./types";

interface ProgressBarProps extends ProgressProps {
  initialMessage?: string;
  showMessage?: boolean;
  disableTransition?: boolean;
  transitionDuration?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  numerator,
  denominator,
  initialMessage = "Please wait...",
  showMessage = true,
  disableTransition,
  transitionDuration = "duration-700",
}) => {
  const progress = numerator / denominator;

  return (
    <div className="flex items-center">
      <div className="flex-grow mr-4">
        <div className="relative h-4 overflow-hidden">
          <span className="absolute top-0 left-0 w-full h-4 bg-gray-light z-0 animate-pulse" />
          <span
            className={`absolute top-0 left-0 w-full h-4 bg-gradient-to-b from-secondary to-primary z-10 transform origin-left ${
              disableTransition
                ? ""
                : `transition-transform ${transitionDuration}`
            }`}
            style={{ transform: `scaleX(${progress})` }}
          />
        </div>
      </div>
      {showMessage && (
        <span className="flex-shrink-0 w-32">
          {progress === 0
            ? initialMessage
            : `${numberWithDelimiters(numerator)}/${numberWithDelimiters(
                denominator
              )}`}
        </span>
      )}
    </div>
  );
};

export default ProgressBar;
