import type { AssetWithUrls } from "lasagna-commons";
import React, { Dispatch, SetStateAction } from "react";
import { VirtuosoGrid } from "react-virtuoso";
import SearchInput from "../../../../components/Form/SearchInput";
import type { ViewportState } from "../../../../components/ViewportContext";
import {
  ASSET_DEFAULT_GRID_TOTAL_HEIGHT,
  ASSET_DEFAULT_HEADER_HEIGHT,
} from "../../../../constants/assetPageConstants";
import PlusIcon from "../../../../icons/Plus";
import { searchFilter, sortByLabel } from "../../../../utils";
import AddAsset from "../../../../components/AddAsset";
import type { UpdateWarning } from "../../hooks";
import AssetGridItem from "./AssetGridItem";
import { calculateButtonStyles } from "../../../../components/Button/utils";

const buttonStyles = calculateButtonStyles({
  size: "small",
  variant: "primary",
});

const AssetsContainer: React.FC<{
  assets: AssetWithUrls[];
  layerId: string;
  layerOrder: number;
  label: string;
  aspectRatio: number;
  isDeleting: boolean;
  setIsDeleting: Dispatch<SetStateAction<boolean>>;
  setUpdateWarning: Dispatch<SetStateAction<UpdateWarning>> | undefined;
  viewport: ViewportState;
  isReadOnly: boolean;
}> = ({
  assets,
  layerId,
  label,
  aspectRatio,
  isDeleting,
  setIsDeleting,
  setUpdateWarning,
  viewport,
  layerOrder,
  isReadOnly,
}) => {
  const [searchValue, setSearchValue] = React.useState("");

  const filteredAssets = React.useMemo(
    () =>
      assets
        .filter(({ label }) => searchFilter(label, searchValue))
        .sort(sortByLabel),
    [assets, searchValue]
  );

  const height = viewport.height - ASSET_DEFAULT_GRID_TOTAL_HEIGHT;

  return (
    <div>
      <div
        className="flex items-center shadow-md relative z-10 px-4"
        style={{
          height: ASSET_DEFAULT_HEADER_HEIGHT,
        }}
      >
        <div className="flex-grow-1 w-full">
          <SearchInput
            name="layer-search"
            placeholder={`Search ${label} traits`}
            value={searchValue}
            setValue={setSearchValue}
          />
        </div>
        {!isReadOnly && (
          <div className="ml-6 flex-shrink-0">
            <AddAsset
              layerInput={{
                id: layerId,
                label,
                layerOrder,
              }}
              currentAssetIds={assets.map(({ id }) => id)}
            >
              <span className={buttonStyles.containerClassName}>
                <span className={buttonStyles.contentClassName}>
                  <PlusIcon size={12} />
                  <span className="ml-3">Add more assets</span>
                </span>
                <span className={buttonStyles.backgroundClassName} />
              </span>
            </AddAsset>
          </div>
        )}
      </div>
      <VirtuosoGrid
        style={{ height }}
        totalCount={filteredAssets.length}
        itemClassName="w-1/4 p-3"
        listClassName="flex flex-wrap"
        overscan={50}
        itemContent={(index) => (
          <AssetGridItem
            asset={filteredAssets[index]}
            aspectRatio={aspectRatio}
            layerId={layerId}
            isDeleting={isDeleting}
            setIsDeleting={setIsDeleting}
            setUpdateWarning={setUpdateWarning}
            isReadOnly={isReadOnly}
          />
        )}
      />
    </div>
  );
};

export default AssetsContainer;
