import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { CAROUSEL_ITEM_STYLES } from "../../../../../constants";
import LayerBody from "./LayerBody";
import LayerHeader from "./LayerHeader";
import type { SetUpdateWarning } from "../../../hooks";
import { AssetState } from "../../../../../types";
import { ASSET_CONDENSED_LAYER_WIDTH } from "../../../../../constants/assetPageConstants";

const LayerContainer: React.FC<{
  layers: AssetState["layers"];
  layerId: string;
  index: number;
  setOrder: React.Dispatch<React.SetStateAction<string[]>>;
  setUpdateWarning: SetUpdateWarning | undefined;
  order: string[];
  viewportHeight: number;
  isDeleting: boolean;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  isReadOnly: boolean;
}> = ({
  layers,
  layerId,
  index,
  setOrder,
  order,
  viewportHeight,
  setUpdateWarning,
  isDeleting,
  setIsDeleting,
  isReadOnly,
}) => {
  const [searchValue, setSearchValue] = React.useState<string>("");

  const layer = layers[layerId];

  return (
    <Draggable draggableId={layerId} index={index}>
      {({ dragHandleProps, draggableProps, innerRef }, { isDragging }) => (
        <div
          className={CAROUSEL_ITEM_STYLES}
          ref={innerRef}
          {...draggableProps}
        >
          <div
            className={`h-full flex flex-col ${
              isDragging
                ? "bg-gray-light shadow-layer-gray-light"
                : "bg-gray shadow-layer-gray"
            }`}
            style={{ width: ASSET_CONDENSED_LAYER_WIDTH }}
          >
            <LayerHeader
              dragHandleProps={dragHandleProps}
              layers={layers}
              layerId={layerId}
              index={index}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setOrder={setOrder}
              order={order}
              setUpdateWarning={setUpdateWarning}
              isDeleting={isDeleting}
              setIsDeleting={setIsDeleting}
              isReadOnly={isReadOnly}
            />
            <LayerBody
              searchValue={searchValue}
              currentAssetIds={Object.keys(layer.assets)}
              layerInput={{
                id: layerId,
                label: layer.label,
                layerOrder: index,
              }}
              viewportHeight={viewportHeight}
              setUpdateWarning={setUpdateWarning}
              isDeleting={isDeleting}
              setIsDeleting={setIsDeleting}
              isReadOnly={isReadOnly}
            />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default React.memo(LayerContainer);
