import type { ButtonSize, ButtonVariant } from ".";

const variantStyles = {
  primary: "bg-white text-black group-hover:bg-primary",
  secondary: "bg-secondary text-black group-hover:bg-primary",
  discord: "bg-white text-black group-hover:bg-discord",
  gray: "bg-gray text-white group-hover:bg-gray-light",
  black: "bg-black text-white group-hover:bg-gray",
};

const sizeStyles = {
  large: "text-lg py-3 px-5",
  normal: "text-base py-2 px-4",
  small: "text-sm py-2 px-3",
};

interface CalculateButtonArgs {
  size?: ButtonSize;
  variant: ButtonVariant;
  fullWidth?: boolean;
}

export function calculateButtonStyles(args?: CalculateButtonArgs) {
  const {
    variant = "primary",
    size = "normal",
    fullWidth = false,
  } = args || {};
  return {
    containerClassName: `relative group cursor-pointer inline-block disabled:opacity-50 disabled:pointer-events-none ${
      fullWidth ? "w-full" : ""
    }`,
    contentClassName: `z-10 relative translate-x-0 translate-y-0 font-secondary flex items-center justify-center transform will-change-transform transition group-hover:translate-x-0.5 group-hover:-translate-y-0.5 ${
      variant === "black" ? "shadow-layer-button-alt" : "shadow-layer-button"
    } ${variantStyles[variant]} ${sizeStyles[size]}`,
    backgroundClassName: `absolute z-0 inset-0 transform -translate-x-1 translate-y-1 transition-colors ${variantStyles[variant]}`,
  };
}
