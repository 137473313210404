import React from "react";
import type { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import IconButton from "../../../../../components/Button/IconButton";
import NumberInput from "../../../../../components/Form/NumberInput";
import SearchInput from "../../../../../components/Form/SearchInput";
import TextInput from "../../../../../components/Form/TextInput";
import DragHandleIcon from "../../../../../icons/DragHandle";
import TrashIcon from "../../../../../icons/Trash";
import { useAssetDispatch } from "../../../../../components/AssetContext";
import { useLayerHeaderState } from "./hooks";
import { SetUpdateWarning, useLayerDelete } from "../../../hooks";
import { useRecipeDispatch } from "../../../../../components/RecipeContext";
import { AssetState } from "../../../../../types";
import PuzzlePieceIcon from "../../../../../icons/PuzzlePiece";
import { ASSET_CONDENSED_LAYER_HEADER_HEIGHT } from "../../../../../constants/assetPageConstants";

const LayerHeader: React.FC<{
  dragHandleProps: DraggableProvidedDragHandleProps | undefined | null;
  layerId: string;
  layers: AssetState["layers"];
  index: number;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<string[]>>;
  setUpdateWarning: SetUpdateWarning | undefined;
  order: string[];
  isDeleting: boolean;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  isReadOnly: boolean;
}> = ({
  dragHandleProps,
  layerId,
  layers,
  searchValue,
  setSearchValue,
  setOrder,
  setUpdateWarning,
  order,
  isDeleting,
  setIsDeleting,
  isReadOnly,
}) => {
  const assetDispatch = useAssetDispatch();
  const recipeDispatch = useRecipeDispatch();
  const layer = layers[layerId];

  const labelState = useLayerHeaderState({
    layer,
    layerId,
    updateKey: "label",
    assetDispatch,
  });

  const weightState = useLayerHeaderState({
    layer,
    layerId,
    updateKey: "weight",
    assetDispatch,
  });

  const deleteLayer = useLayerDelete({
    layers,
    layerId,
    assetDispatch,
    recipeDispatch,
    order,
    setOrder,
    setIsDeleting,
    setUpdateWarning,
  });

  return (
    <div
      className="border-b-2 border-black px-4 py-2 flex-shrink-0 flex flex-col items-center"
      style={{ height: ASSET_CONDENSED_LAYER_HEADER_HEIGHT }}
    >
      <div className="flex items-center pt-2 w-full">
        <div className="flex-shrink-0">
          <span {...dragHandleProps} className="flex items-center pt-1">
            <DragHandleIcon />
          </span>
        </div>
        <div className="flex-grow px-4">
          <TextInput
            name={`layer-label-${layerId}`}
            placeholder={`Layer label`}
            value={labelState.value}
            setValue={labelState.setValue}
            onBlur={labelState.handleBlur}
            error={labelState.error}
            readOnly={isReadOnly}
          />
        </div>
        <div className="w-20 flex-shrink-0 pr-4">
          <NumberInput
            name={`layer-weight-${layerId}`}
            value={weightState.value}
            setValue={weightState.setValue}
            onBlur={weightState.handleBlur}
            endAdornment={<span className="opacity-60">%</span>}
            min={1}
            max={100}
            error={weightState.error}
            readOnly={isReadOnly}
          />
        </div>
        <div className="pt-1 flex-shrink-0">
          <IconButton
            onClick={deleteLayer}
            title="Remove this layer"
            disabled={isDeleting || isReadOnly}
          >
            <TrashIcon />
          </IconButton>
        </div>
      </div>
      <div className="flex items-center py-4 w-full">
        <div className="flex-shrink-0 flex items-center w-16 opacity-60">
          <PuzzlePieceIcon />
          <span className="ml-2">{Object.keys(layer.assets).length}</span>
        </div>
        <div className="flex-grow-1 w-full">
          <SearchInput
            name={`layer-search-${layerId}`}
            placeholder="Search"
            value={searchValue}
            setValue={setSearchValue}
          />
        </div>
      </div>
    </div>
  );
};

export default LayerHeader;
