import React from "react";
import { createPopper, Instance, Placement } from "@popperjs/core";
import { useSpring, animated } from "react-spring";

const Popper: React.FC<{
  targetElement: HTMLElement | null;
  children: React.ReactNode;
  placement?: Placement;
  offset?: number;
  isTooltip?: boolean;
}> = ({
  targetElement,
  children,
  placement = "bottom",
  offset = 8,
  isTooltip,
}) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const popperRef = React.useRef<Instance | null>(null);
  const [mounted, setMounted] = React.useState(false);

  const { opacity, scale } = useSpring({
    opacity: targetElement ? 1 : 0,
    scale: targetElement ? 1 : 0.95,
    config: { duration: 100 },
    onRest: () => {
      if (!targetElement) {
        setMounted(false);
      }
    },
  });

  React.useLayoutEffect(() => {
    if (targetElement && contentRef.current) {
      const offsetValue = [0, offset];

      popperRef.current = createPopper(targetElement, contentRef.current, {
        placement,
        modifiers: [{ name: "offset", options: { offset: offsetValue } }],
      });

      setMounted(true);
    }
  }, [targetElement, placement, offset]);

  React.useLayoutEffect(() => {
    if (!mounted && popperRef.current) {
      popperRef.current.destroy();
    }
  }, [mounted]);

  if (!targetElement && !mounted) {
    return null;
  }

  return (
    <div ref={contentRef} className="z-50">
      <animated.div
        style={{
          opacity,
          transform: isTooltip ? undefined : scale.to((val) => `scale(${val})`),
        }}
      >
        {children}
      </animated.div>
    </div>
  );
};

export default Popper;
