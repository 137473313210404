export default function Trash({ size = 20 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9512 17.5H7.04881C6.57642 17.5 6.12144 17.3217 5.77484 17.0007C5.42824 16.6798 5.21556 16.2398 5.17933 15.7688L4.375 5.3125H15.625L14.8207 15.7688C14.7844 16.2398 14.5718 16.6798 14.2252 17.0007C13.8786 17.3217 13.4236 17.5 12.9512 17.5V17.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6668 5.3125H3.3335"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.65625 2.5H12.3437C12.5924 2.5 12.8308 2.59877 13.0067 2.77459C13.1825 2.9504 13.2812 3.18886 13.2812 3.4375V5.3125H6.71875V3.4375C6.71875 3.18886 6.81752 2.9504 6.99334 2.77459C7.16915 2.59877 7.40761 2.5 7.65625 2.5V2.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3335 14.1667H11.6668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
