import { TokenLayer } from "lasagna-commons";
import React from "react";
import { useSnackbarDispatch } from "../../../../components/SnackbarContext";
import useCalculateTokenMetadata from "../../../../utils/hooks/useCalculateTokenMetadata";

const buttonStyles =
  "border border-white px-2 opacity-70 hover:opacity-100 text-sm font-secondary";

const Metadata: React.FC<{
  id: number;
  description: string;
  tokenLayers: TokenLayer[];
  onClose: () => void;
}> = ({ id, description, tokenLayers, onClose }) => {
  const setSnackbarMessage = useSnackbarDispatch();
  const calculateMetadata = useCalculateTokenMetadata();

  const data = JSON.stringify(
    calculateMetadata({ id, tokenLayers, description }),
    null,
    2
  );

  const copy = async () => {
    await window.navigator.clipboard.writeText(data);
    setSnackbarMessage("Metadata copied to clipboard");
  };

  return (
    <div className="p-4 relative h-screen flex flex-col justify-center">
      <div className="bg-black py-2 px-4 flex items-center justify-end">
        <div className="mr-2">
          <button className={buttonStyles} onClick={copy}>
            Copy
          </button>
        </div>
        <div>
          <button className={buttonStyles} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
      <div className="bg-black p-4 max-h-full overflow-auto">
        <code className="font-secondary text-sm">
          <pre>{data}</pre>
        </code>
      </div>
    </div>
  );
};

export default Metadata;
