export default function Search() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7842 15.0983C7.53543 15.0983 4.90175 12.4647 4.90175 9.21584C4.90175 5.96703 7.53543 3.33334 10.7842 3.33334C14.0331 3.33334 16.6667 5.96703 16.6667 9.21584C16.6667 12.4647 14.0331 15.0983 10.7842 15.0983Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33334 16.6667L6.625 13.375"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
