export default function Copy() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.25H2H1.25V6V18V18.75H2H14H14.75V18V14H13.25V17.25H2.75V6.75H6V5.25Z"
        fill="currentColor"
      />
      <rect
        x="6"
        y="2"
        width="12"
        height="12"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
}
