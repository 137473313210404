import cloneDeep from "lodash/cloneDeep";
import type {
  LayerOrderInput,
  AssetWithUrls,
  LayerWithAssets,
} from "lasagna-commons";
import { API_ENDPOINT } from "../../config";
import type {
  AssetDispatch,
  AssetErrorMessage,
  AssetState,
  TokenDispatch,
} from "../../types";

export const addAssets = ({
  layerId,
  newAssets,
  assetDispatch,
  errors,
}: {
  layerId: string;
  newAssets: { [assetId: string]: AssetWithUrls };
  assetDispatch: AssetDispatch;
  errors: AssetErrorMessage[];
}) => {
  assetDispatch((current) => {
    if (!current) {
      return;
    }
    const assetState = cloneDeep(current);

    assetState.layers[layerId].assets = {
      ...assetState.layers[layerId].assets,
      ...newAssets,
    };

    assetState.errors = errors;

    return assetState;
  });
};

export function updateLayerOrder({
  layerOrder,
  assetDispatch,
}: {
  layerOrder: string[];
  assetDispatch: AssetDispatch;
}) {
  assetDispatch((current) => {
    if (!current) {
      return;
    }
    const assetState = cloneDeep(current);
    const { layers, layerOrders } = getLayerOrderUpdates(
      assetState.layers,
      layerOrder
    );
    assetState.layers = layers;
    assetState.updateStack.push({
      url: `${API_ENDPOINT}/collections/${current.collectionId}/layers/order`,
      method: "PUT",
      body: { layerOrders },
    });

    return assetState;
  });
}

export function removeLayer({
  layerOrder,
  assetDispatch,
}: {
  layerOrder: string[];
  assetDispatch: AssetDispatch;
}) {
  assetDispatch((current) => {
    if (!current) {
      return;
    }
    const assetState = cloneDeep(current);
    const { layers } = getLayerOrderUpdates(assetState.layers, layerOrder);
    assetState.layers = layers;

    return assetState;
  });
}

export const addLayer = ({
  layer,
  layerId,
  assetDispatch,
  tokenDispatch,
  errors,
}: {
  layer: { [key: string]: LayerWithAssets };
  layerId: string;
  assetDispatch: AssetDispatch;
  tokenDispatch: TokenDispatch;
  errors: AssetErrorMessage[];
}) => {
  tokenDispatch((current) => {
    if (!current) {
      return;
    }
    return {
      ...current,
      meta: {
        ...current.meta,
        assetCounts: {
          ...current.meta.assetCounts,
          [layerId]: Object.keys(layer[layerId]).reduce<{
            [assetId: string]: number;
          }>((a, b) => {
            a[b] = 0;
            return a;
          }, {}),
        },
      },
      tokens: current.tokens.map((token) => {
        const tokenLayers = [...token.tokenLayers];

        // Add the first asset for the new layer to every token, but hide it
        tokenLayers.push({
          layerId: layerId,
          assetId:
            layer[layerId].assets[Object.keys(layer[layerId].assets)[0]].id,
          visible: false,
        });

        return { ...token, tokenLayers };
      }),
    };
  });

  assetDispatch((assetState) => {
    if (!assetState) {
      return;
    }
    return {
      ...assetState,
      layers: {
        ...assetState.layers,
        ...layer,
      },
      errors,
    };
  });
};

export const updateLayer = ({
  layerId,
  layer,
  assetDispatch,
}: {
  layerId: string;
  layer: Partial<LayerWithAssets>;
  assetDispatch: AssetDispatch;
}) => {
  assetDispatch((current) => {
    if (!current) {
      return;
    }
    const assetState = cloneDeep(current);
    const updatedLayer = {
      ...assetState.layers[layerId],
      ...layer,
    };
    assetState.layers[layerId] = updatedLayer;

    assetState.updateStack.push({
      url: `${API_ENDPOINT}/collections/${current.collectionId}/layers`,
      method: "PUT",
      body: {
        layer: {
          id: updatedLayer.id,
          label: updatedLayer.label,
          weight: updatedLayer.weight,
          hideMetadata: updatedLayer.hideMetadata,
        },
      },
    });
    return assetState;
  });
};

export const updateAsset = ({
  layerId,
  assetId,
  asset,
  assetDispatch,
}: {
  layerId: string;
  assetId: string;
  asset: Partial<AssetWithUrls>;
  assetDispatch: AssetDispatch;
}) => {
  assetDispatch((current) => {
    if (!current) {
      return;
    }
    const assetState = cloneDeep(current);
    const updatedAsset = {
      ...assetState.layers[layerId].assets[assetId],
      ...asset,
    };
    assetState.layers[layerId].assets[assetId] = updatedAsset;
    assetState.updateStack.push({
      url: `${API_ENDPOINT}/collections/${current.collectionId}/assets`,
      method: "PUT",
      body: { asset: { ...updatedAsset, layerId } },
    });
    return assetState;
  });
};

export const removeAsset = ({
  layerId,
  assetId,
  assetDispatch,
}: {
  layerId: string;
  assetId: string;
  assetDispatch: AssetDispatch;
}) => {
  assetDispatch((current) => {
    if (!current) {
      return;
    }
    const assetState = cloneDeep(current);
    delete assetState.layers[layerId].assets[assetId];
    return assetState;
  });
};

export function getLayerOrderUpdates(
  currentLayers: AssetState["layers"],
  newLayerOrder: string[]
) {
  const layers: AssetState["layers"] = {};
  const layerOrders: LayerOrderInput[] = [];

  newLayerOrder.forEach((layerId, index) => {
    if (currentLayers[layerId].layerOrder !== index) {
      layerOrders.push({ id: layerId, layerOrder: index });
    }
    layers[layerId] = currentLayers[layerId];
    layers[layerId].layerOrder = index;
  });

  return { layers, layerOrders };
}
