import { TokenLayer } from "lasagna-commons";
import React from "react";
import Button from "../../../../components/Button";
import { AssetState } from "../../../../types";
import type { TokenFilterProps } from "../../types";

const QuicklyViewButtons: React.FC<{
  layers: AssetState["layers"];
  tokenLayers: TokenLayer[];
  setTokenFilters: React.Dispatch<
    React.SetStateAction<TokenFilterProps["tokenFilters"]>
  >;
  onClose: () => void;
}> = ({ layers, tokenLayers, setTokenFilters, onClose }) => {
  return (
    <div className="mt-8">
      <h5 className="text-lg mb-3">Quickly view all tokens with</h5>
      <ul className="pl-1">
        {tokenLayers.map((tokenLayer) => {
          const handleClick = () => {
            setTokenFilters((current) => {
              const newState = Object.keys(current).reduce<
                TokenFilterProps["tokenFilters"]
              >((a, b) => {
                a[b] = {};
                return a;
              }, {});

              newState[tokenLayer.layerId][tokenLayer.assetId] = true;

              return newState;
            });

            onClose();
          };
          return (
            <li key={tokenLayer.layerId} className="mr-4 mb-4 inline-block">
              <Button variant="gray" size="small" onClick={handleClick}>
                <span className="opacity-60">
                  {layers[tokenLayer.layerId].label}
                </span>
                <span className="opacity-60 px-1">/</span>
                <span>
                  {layers[tokenLayer.layerId].assets[tokenLayer.assetId].label}
                </span>
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default QuicklyViewButtons;
