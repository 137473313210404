import React from "react";
import type { PaymentFormData } from "../types";
import { pluralize } from "../../../../utils";
import InfoTooltip from "../../../../components/Popper/InfoTooltip";
import {
  Collection,
  convertCentsToCurrency,
  INITIAL_FEE,
  PRICE_PER_TOKEN,
} from "lasagna-commons";
import LottieIcon from "../../../../components/LottieIcon";
import animationData from "../../../../components/LottieIcon/animations/payment-details.json";
import { useAuth } from "../../../../components/AuthContext";
import PaymentForm from "./PaymentForm";
import CopyButton from "./CopyButton";

const isStaging = Boolean(process.env.REACT_APP_IS_STAGING);
const FAKE_CARD_NUMBER = "5105 1051 0510 5100";

const PaymentSection: React.FC<{
  collection: Collection;
  tokenCount: number;
  paymentFormData: PaymentFormData;
  setShowPinningForm: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentFormData: React.Dispatch<
    React.SetStateAction<PaymentFormData | null>
  >;
}> = ({
  collection,
  paymentFormData,
  tokenCount,
  setShowPinningForm,
  setPaymentFormData,
}) => {
  const { user } = useAuth();

  return (
    <>
      <LottieIcon animationData={animationData} />
      <h2 className="mb-4">Finalize your collection</h2>
      <div className="mb-2 text-sm flex items-center">
        <span className="opacity-60">
          Please enter your payment details to finalize your collection.
        </span>
        {isStaging && (
          <>
            <span className="ml-2 inline-flex">
              <InfoTooltip
                title={`Use card number ${FAKE_CARD_NUMBER} with any expiration date, CVC and
              ZIP code.`}
                placement="top"
              />
            </span>
            <span className="ml-2">
              <CopyButton cardNumber={FAKE_CARD_NUMBER} />
            </span>
          </>
        )}
      </div>
      <div className="flex items-center">
        <span>Amount owed:</span>{" "}
        <span className="font-bold text-primary mx-2">
          {convertCentsToCurrency(paymentFormData.amountOwed)}
        </span>{" "}
        <InfoTooltip
          title={
            paymentFormData.amountPaid === 0
              ? `Your balance consists of an initial fee of ${convertCentsToCurrency(
                  INITIAL_FEE
                )} plus ${convertCentsToCurrency(
                  PRICE_PER_TOKEN
                )} per token (your collection has ${pluralize(
                  tokenCount,
                  "token"
                )}).`
              : `You have already paid ${convertCentsToCurrency(
                  paymentFormData.amountPaid
                )}, but still owe ${convertCentsToCurrency(
                  paymentFormData.amountOwed
                )} before we can finalize your collection.`
          }
          placement="top"
        />
      </div>
      {user?.coupon &&
        user.coupon.usage &&
        user.coupon.usage.collectionId === collection.id && (
          <p className="text-xs font-secondary my-1">
            <span className="opacity-60">Coupon</span>{" "}
            <span className="uppercase text-primary">{user.coupon.code}</span>{" "}
            <span className="opacity-60">
              redeemed for {convertCentsToCurrency(user.coupon.usage.amount)}{" "}
              off
            </span>
          </p>
        )}
      <div className="mt-6">
        <PaymentForm
          collectionId={collection.id}
          setShowPinningForm={setShowPinningForm}
          amountOwed={paymentFormData.amountOwed}
          showCouponForm={Boolean(
            user?.coupon && !collection.preview && !user.coupon.usage
          )}
          setPaymentFormData={setPaymentFormData}
        />
      </div>
    </>
  );
};

export default PaymentSection;
