import React from "react";
import LoadingIcon from "../../components/LoadingIcon";
import { useAssetsEffect } from "../../utils/hooks/useFetchAssets";
import { useCollectionsEffect } from "../../utils/hooks/useFetchCollections";
import PageWrapper from "../../components/PageWrapper";
import AssetEdit from "./AssetEdit";
import AssetUpload from "./AssetUpload";
import { useRecipesEffect } from "../../utils/hooks/useFetchRecipes";
import PageMainContent from "../../components/PageMainContent";

const Assets: React.FC = () => {
  const collectionState = useCollectionsEffect();
  const assetState = useAssetsEffect();
  const recipeState = useRecipesEffect();

  const loading = !collectionState || !assetState || !recipeState;

  if (loading) {
    return (
      <PageWrapper>
        <PageMainContent centerContent>
          <LoadingIcon />
        </PageMainContent>
      </PageWrapper>
    );
  }

  return Object.keys(assetState.layers).length > 0 ? (
    <AssetEdit />
  ) : (
    <AssetUpload />
  );
};

export default Assets;
