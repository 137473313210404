import React from "react";
import { useNavigate } from "react-router";
import Button from "../Button";
import Drawer from "../Drawer";
import { useTokensInit } from "../TokenContext";
import RotateIcon from "../../icons/Rotate";
import GenerateTokensForm from "./GenerateTokensForm";
import GeneratingModalContent from "./GeneratingModalContent";
import { useTokenSubmissionStatus } from "./hooks";
import animationData from "../LottieIcon/animations/regenerate-tokens.json";

const RegenerateTokensDrawer: React.FC<{
  collectionId: string;
  tokensPage?: "beforeGen" | "afterGen";
  disabled?: boolean;
}> = ({ collectionId, tokensPage, disabled }) => {
  const [open, setOpen] = React.useState(false);
  const { status, submitting, setStatus } = useTokenSubmissionStatus();
  const navigate = useNavigate();
  const tokenState = useTokensInit();

  React.useEffect(() => {
    if (status === "fetching" && tokenState && tokenState.tokens.length > 0) {
      navigate(`/collections/${collectionId}/tokens`);
    }
  }, [navigate, status, tokenState, collectionId]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose =
    status === null
      ? () => {
          setOpen(false);
        }
      : undefined;

  return (
    <>
      <Button onClick={handleOpen} disabled={disabled}>
        <RotateIcon />
        <span className="ml-2">Regenerate tokens</span>
      </Button>
      <Drawer open={open} onClose={handleClose}>
        {submitting ? (
          <GeneratingModalContent animationData={animationData} />
        ) : (
          <GenerateTokensForm
            setStatus={setStatus}
            isRegenerateForm
            tokensPage={tokensPage}
          />
        )}
      </Drawer>
    </>
  );
};

export default RegenerateTokensDrawer;
