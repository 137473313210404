import React from "react";
import { createCtx } from "../../utils";
import type { AuthDispatch, AuthState } from "./types";

const [useAuth, AuthContextProvider] = createCtx<AuthState>();
const [useAuthDispatch, AuthDispatchContextProvider] =
  createCtx<AuthDispatch>();

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [authState, setAuthState] = React.useState<AuthState>({
    user: null,
  });

  return (
    <AuthDispatchContextProvider value={setAuthState}>
      <AuthContextProvider value={authState}>{children}</AuthContextProvider>
    </AuthDispatchContextProvider>
  );
};

export { useAuth, useAuthDispatch, AuthProvider };
