import {
  Collection,
  generateMetadataZipFileName,
  generateRarityZipFileName,
  generateTokenZipFileName,
  getTokenRangeFromPage,
  getTotalPages,
  ZIP_TOKENS_PER_PAGE,
} from "lasagna-commons";
import React from "react";
import { useCurrentCollection } from "../../../components/CollectionContext/hooks";
import { API_ENDPOINT } from "../../../config";
import useCustomFetch from "../../../utils/hooks/useCustomFetch";
import DownloadButton from "./DownloadButton";
import LottieIcon from "../../../components/LottieIcon";
import animationData from "../../../components/LottieIcon/animations/ready-for-download.json";
import Button from "../../../components/Button";
import ChevronRightIcon from "../../../icons/ChevronRight";
import ResetMetadataButton from "./ResetMetadataButton";
import { useAssets } from "../../../components/AssetContext/hooks";

const DownloadReadySection: React.FC<{ drawerOpen: boolean }> = ({
  drawerOpen,
}) => {
  const [zipDownloadUrls, setZipDownloadUrls] = React.useState<{
    tokenZipUrls: string[];
    metadataZipUrl: string;
    rarityZipUrl?: string;
  } | null>(null);

  const collection = useCurrentCollection();
  const fetcher = useCustomFetch();
  const { layers } = useAssets();

  const totalPages = getTotalPages(collection.tokenCount, ZIP_TOKENS_PER_PAGE);

  const callback = React.useCallback(async () => {
    const data = await fetcher<{
      collection: Collection;
      tokenZipUrls: string[];
      metadataZipUrl: string;
      rarityZipUrl?: string;
    }>({
      url: `${API_ENDPOINT}/collections/${collection.id}`,
      method: "GET",
      query: { zip: true },
    });

    if (data) {
      const { tokenZipUrls, metadataZipUrl, rarityZipUrl } = data;
      setZipDownloadUrls({ tokenZipUrls, metadataZipUrl, rarityZipUrl });
    }
  }, [collection, fetcher]);

  React.useEffect(() => {
    if (!zipDownloadUrls && drawerOpen) {
      callback();
    }
  }, [callback, zipDownloadUrls, drawerOpen]);

  return (
    <>
      <LottieIcon animationData={animationData} />
      <h2 className="mb-4">Ready for download!</h2>
      <p className="mb-6">
        Your complete package with all images and metadata for your final
        collection is ready to be downloaded.
      </p>
      <div className="mb-8">
        <div className="bg-gray shadow-layer-gray flex items-center justify-between py-3 px-5">
          <div className="pr-4">
            <h6 className="font-secondary gradient-text text-sm mb-1">
              Ready for launch
            </h6>
            <p>
              Deploy a smart contract and get your collection ready for launch.
            </p>
          </div>
          <div className="flex-shrink-0">
            <Button
              element="link"
              href={`/collections/${collection.id}/launch`}
              size="small"
            >
              <span className="mr-2">Get started</span>
              <ChevronRightIcon />
            </Button>
          </div>
        </div>
      </div>
      <ul>
        {Array.from({ length: totalPages }, (v, i) => i).map((pageNumber) => {
          const { startId, endId } = getTokenRangeFromPage({
            pageNumber,
            tokenCount: collection.tokenCount,
            tokensPerPage: ZIP_TOKENS_PER_PAGE,
            tokenIdStartsAt: collection.tokenIdStartsAt,
          });

          return (
            <li className="mb-4" key={`download-${pageNumber}`}>
              <DownloadButton
                href={
                  zipDownloadUrls
                    ? zipDownloadUrls.tokenZipUrls[pageNumber]
                    : undefined
                }
                label={generateTokenZipFileName({
                  collectionId: collection.id,
                  pageNumber,
                })}
                type="Images"
                tokenCount={endId - startId + 1}
              />
            </li>
          );
        })}
        <li>
          <DownloadButton
            href={zipDownloadUrls ? zipDownloadUrls.metadataZipUrl : undefined}
            label={generateMetadataZipFileName(collection.id)}
            type="Metadata"
            tokenCount={collection.tokenCount}
          />
        </li>
        {collection.includeTraitUsageData && (
          <li className="mt-4">
            <DownloadButton
              href={zipDownloadUrls ? zipDownloadUrls.rarityZipUrl : undefined}
              label={generateRarityZipFileName(collection.id)}
              type="Metadata"
              tokenCount={collection.tokenCount}
              layerCount={Object.keys(layers).length}
            />
          </li>
        )}
      </ul>
      <div className="mt-6 py-4 border-t border-gray-light">
        <ResetMetadataButton />
      </div>
    </>
  );
};

export default DownloadReadySection;
