import React from "react";
import ProjectNavbar from "../../components/Navbar/ProjectNavbar";
import PageWrapper from "../../components/PageWrapper";
import TokenFilters from "./TokenFilters";
import type {
  GridStyleOption,
  SortTypeOption,
  TokenFilterProps,
} from "./types";
import TokenFooter from "./TokenFooter";
import TokenGrid from "./TokenGrid";
import TokenHeader from "./TokenHeader";
import { useViewport } from "../../components/ViewportContext";
import type { TokenWithRarityRank } from "../../types";
import { useAssets } from "../../components/AssetContext/hooks";
import { useTokens } from "../../components/TokenContext/hooks";
import {
  useAssetMetadata,
  useCurrentCollection,
} from "../../components/CollectionContext/hooks";
import PageMainContent from "../../components/PageMainContent";
import LoadingSidePanel from "./LoadingSkeleton/LoadingSidePanel";
import { collectionIsReadOnly } from "../../utils";

const AfterTokenGeneration: React.FC = () => {
  const [gridStyle, setGridStyle] = React.useState<GridStyleOption>("large");
  const [sortType, setSortType] =
    React.useState<SortTypeOption>("idDescending");

  const { layers } = useAssets();
  const collection = useCurrentCollection();

  const isReadOnly = collectionIsReadOnly(collection);

  const viewport = useViewport();
  const {
    tokens,
    meta: { assetCounts, tokenSet },
  } = useTokens();

  const { aspectRatio } = useAssetMetadata();

  const [savedTokenFilter, setSavedTokenFilter] = React.useState(false);

  const [tokenFilters, setTokenFilters] = React.useState<
    TokenFilterProps["tokenFilters"]
  >(() => {
    return Object.keys(layers).reduce<TokenFilterProps["tokenFilters"]>(
      (a, layerId) => {
        a[layerId] = {};
        return a;
      },
      {}
    );
  });

  const { filteredTokens, tokensWithRarityRank } = React.useMemo<{
    filteredTokens: TokenWithRarityRank[];
    tokensWithRarityRank: TokenWithRarityRank[];
  }>(() => {
    const conditions = calculateConditions(tokenFilters);

    const tokensWithRarityRank = [...tokens]
      .sort((a, b) => {
        if (a.rarityScore && b.rarityScore) {
          return b.rarityScore > a.rarityScore ? -1 : 1;
        }
        return b.id > a.id ? -1 : 1;
      })
      .map((token, index) => ({
        ...token,
        rarityRank: token.rarityScore ? index + 1 : undefined,
      }));

    let filteredTokens =
      conditions.length === 0
        ? tokensWithRarityRank
        : tokensWithRarityRank.filter((token) => {
            for (const condition of conditions) {
              for (const { layerId, assetId, visible } of token.tokenLayers) {
                if (
                  layerId === condition.layerId &&
                  (!visible || !condition.assetIds[assetId])
                ) {
                  return false;
                }
              }
            }
            return true;
          });

    if (savedTokenFilter) {
      filteredTokens = filteredTokens.filter(({ saved }) => saved);
    }

    if (sortType === "rarityDescending") {
      return { filteredTokens, tokensWithRarityRank };
    } else if (sortType === "rarityAscending") {
      return { filteredTokens: filteredTokens.reverse(), tokensWithRarityRank };
    } else {
      const sortValues =
        sortType === "idAscending" ? { up: -1, down: 1 } : { up: 1, down: -1 };

      return {
        filteredTokens: filteredTokens.sort((a, b) =>
          a.id > b.id ? sortValues.up : sortValues.down
        ),
        tokensWithRarityRank,
      };
    }
  }, [tokenFilters, tokens, sortType, savedTokenFilter]);

  const highlightTokenId =
    sortType === "idDescending" || sortType === "idAscending";

  const isLoading = Object.keys(assetCounts).length === 0;

  return (
    <PageWrapper
      navbar={<ProjectNavbar />}
      footer={!isReadOnly && <TokenFooter />}
    >
      <PageMainContent addLayoutContainer>
        <TokenHeader
          gridStyle={gridStyle}
          setGridStyle={setGridStyle}
          sortType={sortType}
          setSortType={setSortType}
          totalTokenCount={tokens.length}
          filteredTokenCount={filteredTokens.length}
          isLoading={isLoading}
          tokenCount={collection.tokenCount}
          printedTokenCount={collection.printedTokenCount}
          tokensLoaded={tokens.length}
          isPreview={Boolean(collection.preview)}
        />
        <div className="flex relative">
          {isLoading ? (
            <LoadingSidePanel />
          ) : (
            <TokenFilters
              tokenFilters={tokenFilters}
              setTokenFilters={setTokenFilters}
              savedTokenFilter={savedTokenFilter}
              setSavedTokenFilter={setSavedTokenFilter}
              isReadOnly={isReadOnly}
            />
          )}
          <TokenGrid
            gridStyle={gridStyle}
            aspectRatio={aspectRatio}
            viewport={viewport}
            filteredTokens={filteredTokens}
            assetCounts={assetCounts}
            tokenSet={tokenSet}
            highlightTokenId={highlightTokenId}
            isReadOnly={isReadOnly}
            tokens={tokensWithRarityRank}
            setTokenFilters={setTokenFilters}
            isLoading={isLoading}
          />
        </div>
      </PageMainContent>
    </PageWrapper>
  );
};

function calculateConditions(
  tokenFilters: TokenFilterProps["tokenFilters"]
): { layerId: string; assetIds: { [assetId: string]: boolean } }[] {
  const conditions: {
    layerId: string;
    assetIds: { [assetId: string]: boolean };
  }[] = [];
  Object.entries(tokenFilters).forEach(([layerId, assetIds]) => {
    if (Object.keys(assetIds).length > 0) {
      conditions.push({ layerId, assetIds });
    }
  });

  return conditions;
}

export default AfterTokenGeneration;
