import { AssetWithUrls } from "lasagna-commons";
import React from "react";
import AssetImage from "../../../../../components/AssetImage";
import AssetImageContainer from "../../../../../components/AssetImage/AssetImageContainer";
import AssetIgnoredMessage from "../../AssetIgnoredMessage";

const LayerListItemAsset: React.FC<{
  asset: AssetWithUrls;
}> = ({ asset }) => {
  const [focused, setFocused] = React.useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <div
      className="relative w-20 flex-shrink-0"
      tabIndex={0}
      role="button"
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseOver={handleFocus}
      onMouseLeave={handleBlur}
    >
      <AssetImageContainer aspectRatio={1}>
        {asset.hide && <AssetIgnoredMessage>Ignored</AssetIgnoredMessage>}
        <AssetImage asset={asset} size="tiny" />
      </AssetImageContainer>
      {focused && (
        <div className="absolute w-36 z-30 top-0 left-0 bg-gray-light shadow-xl">
          <AssetImageContainer aspectRatio={1}>
            <AssetImage asset={asset} size="tiny" />
          </AssetImageContainer>
        </div>
      )}
    </div>
  );
};

export default LayerListItemAsset;
