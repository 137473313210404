export default function EyeSlash({ size = 20 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
    >
      <path
        d="M12.1309 11.2976C11.9364 11.6254 11.6696 11.9045 11.3508 12.1135C11.0321 12.3225 10.6697 12.4558 10.2915 12.5034C9.9133 12.5509 9.52923 12.5114 9.16864 12.3878C8.80805 12.2642 8.48047 12.0599 8.21093 11.7903C7.94139 11.5208 7.73703 11.1932 7.61344 10.8326C7.48986 10.472 7.45033 10.088 7.49787 9.70974C7.54541 9.33154 7.67876 8.9692 7.88774 8.65041C8.09673 8.33162 8.37581 8.06481 8.70367 7.87036"
        strokeLinecap="square"
      />
      <path
        d="M14.9951 14.1617C13.5583 15.2514 11.8033 15.8388 10 15.8333C8.53507 15.8594 7.09189 15.4764 5.83265 14.7274C4.57341 13.9783 3.548 12.893 2.87167 11.5933C2.62718 11.0977 2.50001 10.5525 2.50001 9.99995C2.50001 9.44736 2.62718 8.90218 2.87167 8.40661C3.56159 7.0327 4.66138 5.90712 6.01893 5.18555"
        strokeLinecap="square"
      />
      <path
        d="M17.0198 11.7781C17.0533 11.7148 17.0968 11.658 17.1284 11.5934C17.3728 11.0979 17.5 10.5527 17.5 10.0001C17.5 9.44751 17.3728 8.90233 17.1284 8.40677C16.452 7.10704 15.4266 6.02169 14.1674 5.27268C12.9081 4.52367 11.4649 4.14069 10 4.16678C9.81268 4.16678 9.63058 4.19178 9.44565 4.20396"
        strokeLinecap="square"
      />
      <path d="M18.3333 16.6667L4.16667 2.5" strokeLinecap="square" />
    </svg>
  );
}
