import React from "react";
import type { MemoizedInputProps } from "./types";

const TextArea: React.FC<
  MemoizedInputProps & { maxRows?: number; initialRows?: number }
> = ({
  value,
  onChange,
  onBlur,
  onFocus,
  className,
  maxRows = 2,
  initialRows = 1,
}) => {
  const hiddenRef = React.useRef<HTMLSpanElement>(null);
  const initialRef = React.useRef<HTMLSpanElement>(null);
  const [rerender, setRerender] = React.useState(false);
  const [rows, setRows] = React.useState(() => initialRows);

  React.useLayoutEffect(() => {
    if (hiddenRef.current && initialRef.current) {
      const hiddenHeight = hiddenRef.current.getBoundingClientRect().height;
      if (!hiddenHeight) {
        setRerender((val) => !val); // If the height is showing up as 0 for some reason, we need to trigger a re-render
      } else {
        setRows(
          Math.min(
            Math.ceil(
              (hiddenHeight /
                initialRef.current.getBoundingClientRect().height) *
                initialRows
            ),
            maxRows
          )
        );
      }
    }
  }, [value, maxRows, rerender, initialRows]);

  return (
    <div className="relative">
      <textarea
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        className={className}
        rows={rows}
        value={value}
      />
      <span
        className="absolute block top-0 left-0 right-0 z-0 invisible"
        tabIndex={-1}
        aria-hidden="true"
        ref={hiddenRef}
      >
        {value || "X"}
      </span>
      <span
        className="absolute block top-0 left-0 right-0 z-0 invisible"
        tabIndex={-1}
        aria-hidden="true"
        ref={initialRef}
      >
        {"X"}
      </span>
    </div>
  );
};

export default TextArea;
