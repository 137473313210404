import React from "react";
import { Link } from "react-router-dom";
import { calculateButtonStyles } from "./utils";

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "gray"
  | "discord"
  | "black";
export type ButtonSize = "large" | "normal" | "small";
interface ButtonBaseProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  variant?: ButtonVariant;
  fullWidth?: boolean;
  size?: ButtonSize;
  children: React.ReactNode;
}

type DefaultButton = ButtonBaseProps & {
  element?: "button";
  type?: "button" | "submit";
  disabled?: boolean;
};
type SpanButton = ButtonBaseProps & { element: "span" };
type LinkButton = ButtonBaseProps & { element: "link"; href: string };

const Button: React.FC<DefaultButton | SpanButton | LinkButton> = ({
  variant = "primary",
  size = "normal",
  fullWidth,
  onClick,
  children,
  ...props
}) => {
  const { contentClassName, containerClassName, backgroundClassName } =
    calculateButtonStyles({ variant, size, fullWidth });

  const styledChildren = (
    <>
      <span className={contentClassName}>{children}</span>
      <span className={backgroundClassName} />
    </>
  );

  if (props.element === "link") {
    return (
      <Link to={props.href} className={containerClassName}>
        {styledChildren}
      </Link>
    );
  }

  if (props.element === "span") {
    return (
      <span className={`${containerClassName} inline-flex`}>
        {styledChildren}
      </span>
    );
  }

  return (
    <button
      className={containerClassName}
      type={props.type || "button"}
      onClick={onClick}
      disabled={props.disabled}
    >
      {styledChildren}
    </button>
  );
};

export default Button;
