import {
  calculateTokenDescription,
  calculateTokenName,
  TokenLayer,
} from "lasagna-commons";
import { useCallback } from "react";
import { useAssets } from "../../components/AssetContext/hooks";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";

export default function useCalculateTokenMetadata() {
  const collection = useCurrentCollection();
  const { layers } = useAssets();

  return useCallback(
    (token: {
      id: number;
      description: string | undefined;
      tokenLayers: TokenLayer[];
    }) => {
      const attributes = token.tokenLayers
        .filter(({ visible }) => visible)
        .map(({ layerId, assetId }) => {
          const trait_type = layers[layerId].label;
          const value = layers[layerId].assets[assetId].label;
          return {
            trait_type,
            value,
          };
        });

      return {
        id: token.id,
        attributes,
        description: calculateTokenDescription({
          tokenDescription: token.description,
          collectionDescription: collection.description,
        }),
        name: calculateTokenName(collection.tokenName, token.id),
      };
    },
    [collection, layers]
  );
}
