import React from "react";
import { animated } from "react-spring";
import CloseIcon from "../icons/Close";
import { createCtx } from "../utils";
import { usePortalAnimation } from "../utils/hooks";
import Portal from "./Portal";

type SnackbarMessageVariant = "info" | "error";
type SnackbarMessage = {
  message: string;
  variant: SnackbarMessageVariant;
} | null;

export type SetSnackbarMessage = (
  message: string,
  variant?: SnackbarMessageVariant
) => void;

const [useSnackbarDispatch, SnackbarContextProvider] =
  createCtx<SetSnackbarMessage>();

const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [message, updateMessage] = React.useState<SnackbarMessage>(null);

  const removeMessage = React.useCallback(() => {
    updateMessage(null);
  }, []);

  const setMessage = React.useCallback(
    (newMessage: string, variant: SnackbarMessageVariant = "info") => {
      updateMessage({ message: newMessage, variant: variant });
    },
    []
  );

  return (
    <SnackbarContextProvider value={setMessage}>
      {children}
      <Snackbar snackbarMessage={message} removeMessage={removeMessage} />
    </SnackbarContextProvider>
  );
};

const SNACKBAR_TIMEOUT = 6000;

const Snackbar: React.FC<{
  snackbarMessage: SnackbarMessage;
  removeMessage: () => void;
}> = ({ snackbarMessage, removeMessage }) => {
  const [open, setOpen] = React.useState(false);
  const { mounted, opacity, scale } = usePortalAnimation(open, removeMessage);

  const timeout = React.useRef<number>();
  React.useLayoutEffect(() => {
    if (snackbarMessage) {
      setOpen(true);
      timeout.current = window.setTimeout(() => {
        setOpen(false);
      }, SNACKBAR_TIMEOUT);
      return () => {
        window.clearTimeout(timeout.current);
      };
    }
  }, [snackbarMessage]);

  if (!snackbarMessage) {
    return null;
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Portal mounted={mounted}>
      <animated.div
        className={`fixed top-4 left-1/2 z-50 transform inline-flex items-center shadow-lg px-4 py-3 ${
          snackbarMessage.variant === "error"
            ? "bg-red-dark text-white"
            : "bg-white text-black"
        }`}
        style={{
          transform: scale.to((val) => `scale(${val}) translateX(-50%)`),
          opacity,
        }}
      >
        <span className="mr-8 block truncate">{snackbarMessage.message}</span>
        <button
          onClick={handleClose}
          className="flex opacity-80 hover:opacity-100 flex-shrink-0"
        >
          <CloseIcon />
        </button>
      </animated.div>
    </Portal>
  );
};

export { useSnackbarDispatch, SnackbarProvider };
