import React from "react";
import { MIN_SCREEN_WIDTH } from "../constants";
import { FOOTER_HEIGHT } from "../constants/layoutConstants";
import LayoutContainer from "./LayoutContainer";

const Footer: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <footer
      className={
        "fixed bottom-0 left-0 right-0 bg-black z-30 flex items-center"
      }
      style={{ height: FOOTER_HEIGHT, minWidth: MIN_SCREEN_WIDTH }}
    >
      <LayoutContainer className="flex items-center justify-between">
        {children}
      </LayoutContainer>
    </footer>
  );
};

export default Footer;
