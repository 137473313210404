import React from "react";
import { createBasicCtx, createCtx } from "../../utils";
import type {
  FetchHandlerParams,
  RecipeState,
  RecipeDispatch,
} from "../../types";
import useUpdateStack from "../../utils/hooks/useUpdateStack";

const [useRecipesInit, RecipeContextProvider] = createBasicCtx<RecipeState>();
const [useRecipeDispatch, RecipeDispatchContextProvider] =
  createCtx<RecipeDispatch>();

const RecipeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [recipeState, setRecipeState] = React.useState<RecipeState>();

  const callback = React.useCallback((updateStack: FetchHandlerParams[]) => {
    setRecipeState((current) => {
      if (!current) {
        return;
      }
      return { ...current, updateStack };
    });
  }, []);

  useUpdateStack(recipeState?.updateStack, callback);

  return (
    <RecipeDispatchContextProvider value={setRecipeState}>
      <RecipeContextProvider value={recipeState}>
        {children}
      </RecipeContextProvider>
    </RecipeDispatchContextProvider>
  );
};

export { useRecipesInit, useRecipeDispatch, RecipeProvider };
