export default function Programming() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.83317 17.4167H6.58317V15.9167H5.83317V17.4167ZM3.74984 12.0834H4.49984H3.74984ZM3.74984 7.91671H4.49984H3.74984ZM3.74984 5.41671H2.99984H3.74984ZM5.83317 4.08337H6.58317V2.58337H5.83317V4.08337ZM5.83317 15.9167C5.47955 15.9167 5.14041 15.7762 4.89036 15.5262L3.8297 16.5868C4.36105 17.1182 5.08172 17.4167 5.83317 17.4167V15.9167ZM4.89036 15.5262C4.64031 15.2761 4.49984 14.937 4.49984 14.5834H2.99984C2.99984 15.3348 3.29835 16.0555 3.8297 16.5868L4.89036 15.5262ZM4.49984 14.5834V12.0834H2.99984V14.5834H4.49984ZM4.49984 12.0834C4.49984 11.3319 4.20133 10.6113 3.66997 10.0799L2.60931 11.1406C2.85936 11.3906 2.99984 11.7298 2.99984 12.0834H4.49984ZM3.66997 10.0799C3.13862 9.54855 2.41795 9.25004 1.6665 9.25004V10.75C2.02013 10.75 2.35926 10.8905 2.60931 11.1406L3.66997 10.0799ZM1.6665 10.75C2.03858 10.75 2.40702 10.6768 2.75077 10.5344L2.17675 9.14855C2.01498 9.21555 1.8416 9.25004 1.6665 9.25004V10.75ZM2.75077 10.5344C3.09453 10.392 3.40687 10.1833 3.66997 9.92018L2.60931 8.85952C2.4855 8.98333 2.33852 9.08154 2.17675 9.14855L2.75077 10.5344ZM3.66997 9.92018C3.93307 9.65708 4.14177 9.34473 4.28416 9.00098L2.89834 8.42695C2.83134 8.58872 2.73312 8.7357 2.60931 8.85952L3.66997 9.92018ZM4.28416 9.00098C4.42655 8.65722 4.49984 8.28878 4.49984 7.91671H2.99984C2.99984 8.0918 2.96535 8.26518 2.89834 8.42695L4.28416 9.00098ZM4.49984 7.91671V5.41671H2.99984V7.91671H4.49984ZM4.49984 5.41671C4.49984 5.06309 4.64031 4.72395 4.89036 4.4739L3.8297 3.41324C3.29835 3.94459 2.99984 4.66526 2.99984 5.41671L4.49984 5.41671ZM4.89036 4.4739C5.14041 4.22385 5.47955 4.08337 5.83317 4.08337V2.58337C5.08172 2.58337 4.36105 2.88189 3.8297 3.41324L4.89036 4.4739Z" />
      <path d="M14.1665 2.58337H13.4165V4.08337H14.1665L14.1665 2.58337ZM16.2498 7.91671H16.9998H16.2498ZM14.1665 15.9167H13.4165V17.4167H14.1665V15.9167ZM14.1665 4.08337C14.5201 4.08337 14.8593 4.22385 15.1093 4.4739L16.17 3.41324C15.6386 2.88189 14.918 2.58337 14.1665 2.58337L14.1665 4.08337ZM15.1093 4.4739C15.3594 4.72395 15.4998 5.06309 15.4998 5.41671H16.9998C16.9998 4.66526 16.7013 3.94459 16.17 3.41324L15.1093 4.4739ZM15.4998 5.41671V7.91671H16.9998V5.41671H15.4998ZM15.4998 7.91671C15.4998 8.28879 15.5731 8.65722 15.7155 9.00098L17.1013 8.42695C17.0343 8.26518 16.9998 8.0918 16.9998 7.91671H15.4998ZM15.7155 9.00098C15.8579 9.34473 16.0666 9.65708 16.3297 9.92018L17.3904 8.85952C17.2666 8.7357 17.1683 8.58872 17.1013 8.42695L15.7155 9.00098ZM16.3297 9.92018C16.8611 10.4515 17.5817 10.75 18.3332 10.75V9.25004C17.9795 9.25004 17.6404 9.10956 17.3904 8.85952L16.3297 9.92018ZM18.3332 9.25004C17.5817 9.25004 16.8611 9.54855 16.3297 10.0799L17.3904 11.1406C17.6404 10.8905 17.9795 10.75 18.3332 10.75V9.25004ZM16.3297 10.0799C15.7983 10.6113 15.4998 11.3319 15.4998 12.0834H16.9998C16.9998 11.7298 17.1403 11.3906 17.3904 11.1406L16.3297 10.0799ZM15.4998 12.0834V14.5834H16.9998V12.0834H15.4998ZM15.4998 14.5834C15.4998 14.937 15.3594 15.2761 15.1093 15.5262L16.17 16.5868C16.7013 16.0555 16.9998 15.3348 16.9998 14.5834H15.4998ZM15.1093 15.5262C14.8593 15.7762 14.5201 15.9167 14.1665 15.9167V17.4167C14.918 17.4167 15.6386 17.1182 16.17 16.5868L15.1093 15.5262Z" />
    </svg>
  );
}
