import { ChainId } from "@thirdweb-dev/react";
import React from "react";
import Select from "../../components/Form/Select";

const mainNetworkOptions = [
  { label: "Ethereum (ETH)", value: ChainId.Mainnet },
  { label: "Polygon (MATIC)", value: ChainId.Polygon },
  { label: "Fantom (FTM)", value: ChainId.Fantom },
  { label: "Avalanche (AVAX)", value: ChainId.Avalanche },
  { label: "Optimism (ETH)", value: ChainId.Optimism },
  { label: "Arbitrum (ETH)", value: ChainId.Arbitrum },
  {
    label: "Binance Smart Chain (BNB)",
    value: ChainId.BinanceSmartChainMainnet,
  },
];

const testNetworkOptions = [
  { label: "Goerli (GOR)", value: ChainId.Goerli },
  { label: "Mumbai (MATIC)", value: ChainId.Mumbai },
  { label: "Fantom Testnet (FTM)", value: ChainId.FantomTestnet },
  {
    label: "Avalanche Fuji Testnet (AVAX)",
    value: ChainId.AvalancheFujiTestnet,
  },
  { label: "Optimism Goerli (ETH)", value: ChainId.OptimismGoerli },
  { label: "Arbitrum Goerli (AGOR)", value: ChainId.ArbitrumGoerli },
  {
    label: "Binance Smart Chain Testnet (TBNB)",
    value: ChainId.BinanceSmartChainTestnet,
  },
];

const optionSets = [
  { label: "Mainnets", options: mainNetworkOptions },
  { label: "Testnets", options: testNetworkOptions },
];

export const NETWORK_OPTION_MAP = [
  ...mainNetworkOptions,
  ...testNetworkOptions,
].reduce<{
  [key: string]: string;
}>((a, b) => {
  a[b.value] = b.label;
  return a;
}, {});

const NetworkSelect: React.FC<{
  selectedChain: number;
  setSelectedChain: React.Dispatch<React.SetStateAction<number>>;
  chainMismatch: boolean;
}> = ({ selectedChain, setSelectedChain, chainMismatch }) => {
  return (
    <Select
      optionSets={optionSets}
      value={selectedChain}
      setValue={setSelectedChain}
      error={
        chainMismatch
          ? "Chain mismatch - please update the network chain in your wallet."
          : undefined
      }
    />
  );
};

export default NetworkSelect;
