import React from "react";
import cloneDeep from "lodash/cloneDeep";
import { generateTokenSetKey } from "lasagna-commons";
import type { TokenLayer, AssetWithUrls } from "lasagna-commons";
import type { TempCollectionState } from "./types";

export const TOGGLE_VISIBILITY = "TOGGLE_VISIBILITY";
export const UPDATE_LAYER_ITEM = "UPDATE_LAYER_ITEM";
export const UPDATE_LAYER_ORDER = "UPDATE_LAYER_ORDER";
export const TOGGLE_SAVED = "TOGGLE_SAVED";
export const UPDATE_TOKEN = "UPDATE_TOKEN";

interface ToggleVisibilityAction {
  type: typeof TOGGLE_VISIBILITY;
  payload: {
    index: number;
  };
}

interface UpdateLayerItemAction {
  type: typeof UPDATE_LAYER_ITEM;
  payload: {
    index: number;
    asset: AssetWithUrls;
  };
}

interface UpdateLayerOrderAction {
  type: typeof UPDATE_LAYER_ORDER;
  payload: {
    tokenLayers: TokenLayer[];
  };
}

interface ToggleSavedAction {
  type: typeof TOGGLE_SAVED;
}

interface UpdateTokenAction {
  type: typeof UPDATE_TOKEN;
  payload: {
    state: TempCollectionState;
  };
}

export type TempCollectionAction =
  | ToggleVisibilityAction
  | UpdateLayerItemAction
  | UpdateLayerOrderAction
  | ToggleSavedAction
  | UpdateTokenAction;

function updateState({
  state,
  index,
  newTokenLayer,
}: {
  state: TempCollectionState;
  index: number;
  newTokenLayer: TokenLayer;
}): TempCollectionState {
  const tokenLayers = [...state.tokenLayers];
  const tokenSet = new Set(state.tokenSet);

  const oldSetKey = generateTokenSetKey(tokenLayers);
  const oldTokenLayer = { ...tokenLayers[index] };
  tokenLayers[index] = newTokenLayer;

  const newSetKey = generateTokenSetKey(tokenLayers);

  tokenSet.delete(oldSetKey);
  tokenSet.add(newSetKey);

  const assetCounts = cloneDeep(state.assetCounts);

  assetCounts[oldTokenLayer.layerId][oldTokenLayer.assetId] +=
    oldTokenLayer.visible ? -1 : 0;
  assetCounts[newTokenLayer.layerId][newTokenLayer.assetId] +=
    newTokenLayer.visible ? 1 : 0;

  return {
    tokenLayers,
    tokenSet,
    assetCounts,
    saved: state.saved,
  };
}

function temporaryCollectionReducer(
  state: TempCollectionState,
  action: TempCollectionAction
): TempCollectionState {
  if (action.type === TOGGLE_VISIBILITY) {
    const { index } = action.payload;
    const newTokenLayer = {
      ...state.tokenLayers[index],
      visible: !state.tokenLayers[index].visible,
    };

    return updateState({ state, index, newTokenLayer });
  } else if (action.type === TOGGLE_SAVED) {
    return { ...state, saved: !state.saved };
  } else if (action.type === UPDATE_LAYER_ITEM) {
    const { index } = action.payload;
    const newTokenLayer = {
      ...state.tokenLayers[index],
      assetId: action.payload.asset.id,
    };
    return updateState({ state, index, newTokenLayer });
  } else if (action.type === UPDATE_LAYER_ORDER) {
    const tokenSet = new Set(state.tokenSet);

    const oldKey = generateTokenSetKey(state.tokenLayers);
    const newKey = generateTokenSetKey(action.payload.tokenLayers);

    tokenSet.delete(oldKey);
    tokenSet.add(newKey);

    return {
      ...state,
      tokenLayers: action.payload.tokenLayers,
      tokenSet,
    };
  } else if (action.type === UPDATE_TOKEN) {
    return action.payload.state;
  }
  return state;
}

export function useTemporaryCollection(
  stateInitializer: () => TempCollectionState
): [TempCollectionState, React.Dispatch<TempCollectionAction>] {
  return React.useReducer(
    temporaryCollectionReducer,
    undefined,
    stateInitializer
  );
}
