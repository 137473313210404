import { RARITY_LOWER_BOUND, RARITY_UPPER_BOUND } from "lasagna-commons";
import React from "react";
import type { Dispatch, SetStateAction } from "react";
import ReactSlider, { ReactSliderProps } from "react-slider";

const valueMap: { [key: number]: string } = {
  0: "Very common",
  1: "Common",
  2: "Uncommon",
  3: "Rare",
  4: "Ultra rare 🤯",
};

const increment = 25;

const marks = Object.keys(valueMap).map((val) => parseInt(val) * increment);

const getValueLabel = (value: number) => {
  return valueMap[Math.floor(value / increment)];
};

const Slider: React.FC<{
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  onBlur: () => void;
  mouseIsOver: boolean;
  setMouseIsOver?: (mouseIsOver: boolean) => void;
  disabled?: boolean;
}> = ({ value, setValue, onBlur, mouseIsOver, setMouseIsOver, disabled }) => {
  const props = React.useMemo(() => {
    const className = "relative p-1 mt-3";
    if (!setMouseIsOver) {
      return {
        className,
      };
    }
    return {
      onMouseEnter() {
        setMouseIsOver(true);
      },
      onMouseLeave() {
        setMouseIsOver(false);
      },
      className,
    };
  }, [setMouseIsOver]);

  const renderMark = React.useCallback<
    Exclude<ReactSliderProps["renderMark"], undefined>
  >(
    (props) => {
      return (
        <span
          {...props}
          className={`${props.className} ${
            props.key && value < props.key ? "" : "slider-mark-dark"
          }`}
        />
      );
    },
    [value]
  );

  const handleChange = React.useCallback(
    (val: number) => {
      setValue(val);
    },
    [setValue]
  );

  const handleAfterUpdate = React.useCallback(onBlur, [onBlur]);

  return (
    <div {...props}>
      <ReactSlider
        min={RARITY_LOWER_BOUND}
        max={RARITY_UPPER_BOUND}
        marks={marks}
        className="slider"
        trackClassName="slider-track"
        markClassName="slider-mark"
        thumbClassName="slider-thumb"
        renderMark={renderMark}
        value={value}
        onChange={handleChange}
        onAfterChange={handleAfterUpdate}
        disabled={disabled}
      />
      <span
        className={`text-white text-xs pt-2 block absolute left-0 ${
          mouseIsOver ? "opacity-100" : "opacity-0"
        }`}
      >
        {getValueLabel(value)}
      </span>
    </div>
  );
};

export default Slider;
