import React from "react";
import { matchPath } from "react-router";
import ChevronRightIcon from "../icons/ChevronRight";
import PageWrapper from "./PageWrapper";
import Button from "./Button";
import PageMainContent from "./PageMainContent";
import { customFetch } from "../utils";
import { API_ENDPOINT } from "../config";
import { NoCollectionError } from "../constants";

let emailSent = false;

export default class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { errorType: "noCollection" | "generic" | "none" }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { errorType: "none" };
  }

  static getDerivedStateFromError(error: unknown) {
    if (error instanceof NoCollectionError) {
      return { errorType: "noCollection" };
    }
    return { errorType: "generic" };
  }

  componentDidCatch(error: unknown) {
    console.error(error);

    if (error instanceof NoCollectionError) {
      return;
    }

    if (error instanceof Error && !emailSent) {
      const match = matchPath(
        "/collections/:collectionId/*",
        window.location.pathname
      );

      emailSent = true;

      customFetch({
        url: `${API_ENDPOINT}/client-errors`,
        method: "POST",
        body: {
          collectionId: match?.params.collectionId,
          error: error.stack,
          href: window.location.href,
        },
      });
    }
  }

  render() {
    const handleClick = () => {
      window.location.href = "/";
    };

    if (
      this.state.errorType === "generic" ||
      this.state.errorType === "noCollection"
    ) {
      const { header, message } =
        this.state.errorType === "generic"
          ? {
              header: "Something went wrong",
              message:
                "We'll work on getting it straightened out. In the meantime, you can refresh the page and try again, or go back to the home page.",
            }
          : {
              header: "Collection not found",
              message:
                "This collection does not exist or has been deleted. Perhaps you typed in the wrong URL?",
            };

      return (
        <PageWrapper>
          <PageMainContent addLayoutContainer centerContent>
            <div className="flex justify-center flex-col items-center">
              <h3>{header} 😔</h3>
              <p className="opacity-60 mb-8 max-w-prose m-auto text-center">
                {message}
              </p>
              <div>
                <Button onClick={handleClick} size="large">
                  <span className="mr-3">Go home</span>
                  <ChevronRightIcon />
                </Button>
              </div>
            </div>
          </PageMainContent>
        </PageWrapper>
      );
    }

    return this.props.children;
  }
}
