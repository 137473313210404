import { calculateCustomMetadata, calculateTokenName } from "lasagna-commons";
import React from "react";
import IconButton from "../../../components/Button/IconButton";
import { useCurrentCollection } from "../../../components/CollectionContext/hooks";
import FullScreenModal from "../../../components/FullScreenModal";
import { useViewport } from "../../../components/ViewportContext";
import ChevronRight from "../../../icons/ChevronRight";
import CloseIcon from "../../../icons/Close";
import MetadataForm from "./MetadataForm";
import MetadataListField from "./MetadataListField";
import MetadataNumberField from "./MetadataNumberField";
import MetadataTextField from "./MetadataTextField";
import type { MetadataFieldProps, CustomTokenMetadata } from "./types";

const MetadataEditor: React.FC<{
  customTokenMetadata: CustomTokenMetadata;
  setCustomTokenMetadata: React.Dispatch<
    React.SetStateAction<CustomTokenMetadata>
  >;
}> = ({ customTokenMetadata, setCustomTokenMetadata }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <button
          type="button"
          className="border-2 w-full border-transparent font-secondary inline-flex items-center justify-center text-sm px-4 py-1.5 bg-gray-light hover:bg-gray-dark"
          onClick={handleOpen}
        >
          <span className="mr-2 whitespace-nowrap">Edit metadata</span>
          <ChevronRight />
        </button>
      </div>

      {open && (
        <MetadataEditorContent
          onClose={handleClose}
          customTokenMetadata={customTokenMetadata}
          setCustomTokenMetadata={setCustomTokenMetadata}
        />
      )}
    </>
  );
};

export default MetadataEditor;

export const METADATA_SECTIONS: {
  label: string;
  tooltipText: string;
  options: {
    key: string;
    label: string;
    initialValue: any;
    Component?: React.FC<MetadataFieldProps>;
    tooltipText?: string;
    calculatedByLasagna?: boolean;
  }[];
}[] = [
  {
    label: "Optional Ethereum properties",
    tooltipText:
      "These are optional fields that are commonly used with Ethereum NFT protocols. You may mix and match with the optional Solana fields if you wish.",
    options: [
      {
        key: "external_url",
        label: "External URL",
        initialValue: "",
        Component: MetadataTextField,
      },
      {
        key: "background_color",
        label: "Background color",
        initialValue: "",
        Component: MetadataTextField,
        tooltipText:
          "This should be the last six characters of the hex value - do not prefix with the '#' character.",
      },
      {
        key: "youtube_url",
        label: "Youtube URL",
        initialValue: "",
        Component: MetadataTextField,
      },
    ],
  },
  {
    label: "Optional Solana properties",
    tooltipText:
      "These are optional fields that are commonly used with Solana NFT protocols. You may mix and match with the optional Ethereum fields if you wish.",
    options: [
      {
        key: "symbol",
        label: "Symbol",
        initialValue: "",
        Component: MetadataTextField,
      },
      {
        key: "edition",
        label: "Edition",
        initialValue: 0,
        Component: MetadataNumberField,
      },
      {
        key: "update_authority",
        label: "Update authority",
        initialValue: "",
        Component: MetadataTextField,
      },
      {
        key: "seller_fee_basis_points",
        label: "Seller fee basis points",
        initialValue: 0,
        Component: MetadataNumberField,
      },
      {
        key: "properties.creators",
        label: "Creators",
        initialValue: [{ address: "", share: 100 }],
        Component: MetadataListField,
      },
      {
        key: "properties.category",
        label: "Category",
        initialValue: "image",
        calculatedByLasagna: true,
      },
      {
        key: "properties.files",
        label: "File base URI",
        initialValue: "",
        Component: MetadataTextField,
        calculatedByLasagna: true,
        tooltipText:
          "You can add a base URI which will be prefixed to your image URI. Example: '{your_prefix}0.png'. Leave this blank if you don't want a prefix.",
      },
    ],
  },
];

const HEADER_HEIGHT = 56;

function MetadataEditorContent({
  onClose,
  customTokenMetadata,
  setCustomTokenMetadata,
}: {
  onClose: () => void;
  customTokenMetadata: CustomTokenMetadata;
  setCustomTokenMetadata: React.Dispatch<
    React.SetStateAction<CustomTokenMetadata>
  >;
}) {
  const { height } = useViewport();
  const collection = useCurrentCollection();

  const editorHeight = height - HEADER_HEIGHT;

  const metadataString = React.useMemo(() => {
    const customMetadata = calculateCustomMetadata(0, customTokenMetadata);

    const metadata = {
      id: 0,
      attributes: [
        { trait_type: "Example type #1", value: "Example trait #1" },
        { trait_type: "Example type #2", value: "Example trait #2" },
      ],
      description: collection.description,
      image: "0.png",
      name: calculateTokenName(collection.tokenName, 0),
      ...customMetadata,
    };

    return JSON.stringify(metadata, null, 2);
  }, [collection, customTokenMetadata]);

  return (
    <FullScreenModal onClose={onClose}>
      <div>
        <header
          className="flex items-center justify-between px-8 border-b border-gray"
          style={{ height: HEADER_HEIGHT }}
        >
          <h4 className="mb-0">Metadata editor</h4>
          <IconButton onClick={onClose} title="Close modal">
            <CloseIcon />
          </IconButton>
        </header>
        <div className="grid grid-cols-2">
          <div
            style={{ height: editorHeight }}
            className="overflow-auto py-4 px-8"
          >
            <MetadataForm
              customTokenMetadata={customTokenMetadata}
              setCustomTokenMetadata={setCustomTokenMetadata}
            />
          </div>
          <div
            className="bg-gray overflow-auto"
            style={{ height: editorHeight }}
          >
            <div className="px-8 py-2 border-b border-black text-lg">
              Token metadata preview
            </div>
            <div className="px-8 py-4">
              <code className="font-secondary text-sm">
                <pre>{metadataString}</pre>
              </code>
            </div>
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
}
