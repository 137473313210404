import React from "react";

const AssetImageContainer: React.FC<{
  aspectRatio: number;
  children: React.ReactNode;
  bgClassName?: "bg-opacity-0" | "bg-opacity-5";
}> = ({ aspectRatio, children, bgClassName = "bg-opacity-5" }) => {
  return (
    <div
      className={`relative bg-white ${bgClassName}`}
      style={{ paddingBottom: `${aspectRatio * 100}%` }}
    >
      {children}
    </div>
  );
};

export default React.memo(AssetImageContainer);
