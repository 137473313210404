import React from "react";
import type { Placement } from "@popperjs/core";
import Popper from ".";

const Menu: React.FC<{
  targetElement: HTMLElement | null;
  onClose: () => void;
  children: React.ReactNode;
  placement?: Placement;
  offset?: number;
}> = ({
  targetElement,
  onClose,
  children,
  placement = "bottom-start",
  offset,
}) => {
  const hasRendered = React.useRef(false);
  const menuRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (targetElement && menuRef.current) {
      menuRef.current.style.minWidth = `${
        targetElement.getBoundingClientRect().width
      }px`;

      const clickListener = (e: any) => {
        if (hasRendered.current) {
          onClose();
        }
        hasRendered.current = true;
      };

      window.document.addEventListener("click", clickListener);

      return () => {
        hasRendered.current = false;
        window.document.removeEventListener("click", clickListener);
      };
    }
  }, [onClose, targetElement]);

  return (
    <Popper targetElement={targetElement} placement={placement} offset={offset}>
      <div ref={menuRef} className="bg-gray shadow-md">
        {children}
      </div>
    </Popper>
  );
};

export default Menu;
