import React from "react";
import TriangleArrowIcon from "../../../../icons/TriangleArrow";
import FolderOpenIcon from "../../../../icons/FolderOpen";

const FolderItem: React.FC<{ label: string }> = ({ label }) => {
  return (
    <div className="flex items-center py-1">
      <TriangleArrowIcon />
      <span className="ml-4 mr-2">
        <FolderOpenIcon />
      </span>
      <span className="opacity-80">{label}</span>
    </div>
  );
};
export default FolderItem;
