import React from "react";
import TextField from "../../../components/Form/TextInput";
import type { MetadataFieldProps } from "./types";

const MetadataTextField: React.FC<MetadataFieldProps> = ({
  customTokenMetadata,
  setCustomTokenMetadata,
  metadataKey,
  label,
  tooltipText,
}) => {
  const setValue = React.useCallback(
    (value: string) => {
      setCustomTokenMetadata((current) => ({
        ...current,
        [metadataKey]: value,
      }));
    },
    [setCustomTokenMetadata, metadataKey]
  );

  return (
    <TextField
      value={customTokenMetadata[metadataKey]}
      setValue={setValue}
      name={metadataKey}
      label={{
        text: label,
        tooltipText,
        tooltipPlacement: "right",
        tooltipContentBackgroundColor: "bg-black",
      }}
    />
  );
};

export default MetadataTextField;
