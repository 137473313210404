import React from "react";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { useActiveChain } from "./ActiveChainProvider";
import { THIRDWEB_CLIENT_ID } from "../../config";

const WalletProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [activeChain] = useActiveChain();

  return (
    <ThirdwebProvider activeChain={activeChain} clientId={THIRDWEB_CLIENT_ID}>
      {children}
    </ThirdwebProvider>
  );
};

export default WalletProvider;
