import React from "react";
import ExternalLink from "../../../components/ExternalLink";

const PreheatingCard: React.FC<{
  headerText: string;
  bodyText: string;
  icon: React.ReactNode;
  href: string;
}> = ({ headerText, bodyText, icon, href }) => {
  return (
    <div>
      <div className="flex items-center mb-2">
        {icon}
        <span className="ml-2 tracking-wider">{headerText}</span>
      </div>
      <p className="text-sm mb-3">{bodyText}</p>
      <div className="text-sm">
        <ExternalLink href={href}>Learn more</ExternalLink>
      </div>
    </div>
  );
};

export default PreheatingCard;
