import { ASIDE_WIDTH, LAYOUT_GUTTER } from "../../constants/layoutConstants";

export function calculateGridHeight({
  viewportWidth,
  aspectRatio,
  numColumns,
  footerHeight,
  totalTokens,
}: {
  viewportWidth: number;
  aspectRatio: number;
  numColumns: number;
  footerHeight: number;
  totalTokens: number;
}): number {
  const gridWidth = viewportWidth - ASIDE_WIDTH - LAYOUT_GUTTER * 2;
  const itemHeight =
    (gridWidth / numColumns - ITEM_PADDING) * aspectRatio +
    footerHeight +
    ITEM_PADDING;

  return Math.ceil((totalTokens * itemHeight) / numColumns);
}

const ITEM_PADDING = 12; // 6px on each side
