import React from "react";
import type { Recipe } from "lasagna-commons";
import isEqual from "lodash/isEqual";
import RecipeTypeRadio from "./RecipeTypeRadio";
import useRecipeForm from "./utils/useRecipeForm";
import RecipeFormInputList from "./RecipeFormInputList";
import { calculateRecipeAssets, filterRecipeAssetKeys } from "./utils";
import { API_ENDPOINT } from "../../config";
import { useRecipeModal } from "./RecipeModal/RecipeModalContext";
import { useRecipeDispatch } from "../../components/RecipeContext";

const UpdateRecipeForm: React.FC<{
  recipe: Recipe;
  index: number;
  isReadOnly: boolean;
}> = ({ recipe, index, isReadOnly }) => {
  const recipeDispatch = useRecipeDispatch();
  const {
    recipeState: { targetAssetKeys, pairedAssetKeys, recipeType },
    lists: { targetAssetList, pairedAssetList },
    recipeStateDispatch,
  } = useRecipeForm(recipe);

  const { recipeAssetMap } = useRecipeModal();

  const recipeRef = React.useRef(recipe);

  React.useEffect(() => {
    const { keys: targetKeys, hasNullValues: targetHasNulls } =
      filterRecipeAssetKeys(targetAssetKeys);
    const { keys: pairedKeys, hasNullValues: pairedHasNulls } =
      filterRecipeAssetKeys(pairedAssetKeys);

    if (targetHasNulls || pairedHasNulls) {
      return;
    }

    const updatedRecipe: Recipe = {
      id: recipe.id,
      recipeType,
      label: recipe.label,
      ...calculateRecipeAssets({
        targetAssetKeys: targetKeys,
        pairedAssetKeys: pairedKeys,
        recipeAssetMap,
      }),
    };

    if (!isEqual(recipeRef.current, updatedRecipe)) {
      recipeDispatch((current) => {
        if (!current) {
          return;
        }

        const recipes = [...current.recipes];
        recipes[index] = updatedRecipe;

        const updateStack = [...current.updateStack];

        updateStack.push({
          url: `${API_ENDPOINT}/collections/${current.collectionId}/recipes`,
          method: "PUT",
          body: { recipe: updatedRecipe },
        });

        return {
          ...current,
          recipes,
          updateStack,
        };
      });
    }
    recipeRef.current = updatedRecipe;
  }, [
    targetAssetKeys,
    pairedAssetKeys,
    recipeType,
    recipeDispatch,
    index,
    recipeAssetMap,
    recipe,
  ]);

  return (
    <form>
      <div className="mb-4">
        <RecipeTypeRadio
          recipeType={recipeType}
          recipeStateDispatch={recipeStateDispatch}
          pairedAssetKeys={pairedAssetKeys}
          recipeAssetMap={recipeAssetMap}
          isReadOnly={isReadOnly}
        />
      </div>
      <div className="mb-6">
        <RecipeFormInputList
          targetAssetKeys={targetAssetKeys}
          pairedAssetKeys={pairedAssetKeys}
          recipeAssetType="targetAssetKeys"
          recipeStateDispatch={recipeStateDispatch}
          recipeType={recipeType}
          recipeId={recipe.id}
          list={targetAssetList}
          isReadOnly={isReadOnly}
        />
      </div>
      <RecipeFormInputList
        targetAssetKeys={targetAssetKeys}
        pairedAssetKeys={pairedAssetKeys}
        recipeAssetType="pairedAssetKeys"
        recipeStateDispatch={recipeStateDispatch}
        recipeType={recipeType}
        recipeId={recipe.id}
        list={pairedAssetList}
        isReadOnly={isReadOnly}
      />
    </form>
  );
};

export default UpdateRecipeForm;
