import React from "react";

const PageGrid: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className="max-w-screen-2xl m-auto grid grid-cols-3">{children}</div>
  );
};

export default PageGrid;
