import type { Recipe } from "lasagna-commons";
import React from "react";
import TextInput from "../../components/Form/TextInput";
import { useRecipeDispatch } from "../../components/RecipeContext";
import { API_ENDPOINT } from "../../config";

interface RecipeLabelProps {
  recipe: Recipe;
  index: number;
  totalRecipes: number;
}

const getDefaultLabel = ({ recipe, totalRecipes, index }: RecipeLabelProps) =>
  recipe.label || `Recipe ${totalRecipes - index}`;

const RecipeLabel: React.FC<{
  recipe: Recipe;
  index: number;
  totalRecipes: number;
  isReadOnly: boolean;
}> = ({ recipe, index, totalRecipes, isReadOnly }) => {
  const [value, setValue] = React.useState(
    getDefaultLabel({ recipe, index, totalRecipes })
  );

  const recipeDispatch = useRecipeDispatch();

  const handleBlur = () => {
    recipeDispatch((current) => {
      if (!current) {
        return;
      }

      const recipes = [...current.recipes];

      recipes[index].label = value;

      const updateStack = [...current.updateStack];

      updateStack.push({
        url: `${API_ENDPOINT}/collections/${current.collectionId}/recipes`,
        method: "PUT",
        body: { recipe: recipes[index] },
      });

      return {
        ...current,
        recipes,
        updateStack,
      };
    });

    const label = value || getDefaultLabel({ recipe, index, totalRecipes });
    setValue(label);
  };

  return (
    <div className="flex-grow pr-4 text-lg">
      <TextInput
        value={value}
        setValue={setValue}
        name={`recipe-${index}-label`}
        onBlur={handleBlur}
        readOnly={isReadOnly}
      />
    </div>
  );
};

export default RecipeLabel;
