import React from "react";
import HomeNavbar from "../../components/Navbar/HomeNavbar";
import NavbarUserInfo from "../../components/Navbar/NavbarUserInfo";
import Card from "../../components/Card";
import GuidesIcon from "../../icons/Guides";
import PageGrid from "../../components/PageGrid";
import H6 from "../../components/H6";
import PageGridInfo from "../../components/PageGrid/PageGridInfo";
import PageGridCard from "../../components/PageGrid/PageGridCard";
import NewCollectionForm from "../../components/NewCollectionForm";
import PageWrapper from "../../components/PageWrapper";
import PageMainContent from "../../components/PageMainContent";
import DemoDrawer from "../../components/DemoDrawer";
import ImageIcon from "../../icons/Image";
import ChevronRightIcon from "../../icons/ChevronRight";

const NoCollections: React.FC<{
  demoDrawerOpen: boolean;
  setDemoDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ demoDrawerOpen, setDemoDrawerOpen }) => {
  return (
    <PageWrapper
      navbar={
        <HomeNavbar>
          <NavbarUserInfo />
        </HomeNavbar>
      }
    >
      <PageMainContent addLayoutContainer centerContent>
        <PageGrid>
          <PageGridInfo>
            <div>
              <H6>Get started</H6>
              <h1>Launching your collection has never been easier</h1>
              <p className="opacity-80 mb-10">
                Once you've created your artwork, jump into lasagna and generate
                your tokens, apply recipes, set up a primary page and launch
                your collection - all on your own custom smart contract without
                writing a single line of code.
              </p>
              <div className="grid grid-cols-2 gap-4">
                <Card
                  headerIcon={<GuidesIcon />}
                  headerText="Guides"
                  bodyText="We've written a lot of useful guides so that you can learn the basics of launching your own project using lasagna."
                  button={{
                    type: "link",
                    linkHref: "https://lasagna.xyz/guides/welcome",
                    linkText: "View our guides",
                  }}
                />
                <Card
                  headerIcon={<ImageIcon />}
                  headerText="Demo collections"
                  bodyText="Need inspiration? Use our home-grown traits to generate your own collection - no assets required. It's a great way to explore lasagna!"
                  button={{
                    type: "button",
                    component: (
                      <DemoDrawer
                        open={demoDrawerOpen}
                        setOpen={setDemoDrawerOpen}
                        buttonLabel={
                          <>
                            <span className="mr-2 underline">
                              Make a demo collection
                            </span>
                            <span className="flex items-center transform transition-transform group-hover:translate-x-1">
                              <ChevronRightIcon />
                            </span>
                          </>
                        }
                        buttonClassName="font-secondary transition-colors group inline-flex items-center text-white hover:text-primary"
                      />
                    ),
                  }}
                />
              </div>
            </div>
          </PageGridInfo>
          <PageGridCard>
            <NewCollectionForm />
          </PageGridCard>
        </PageGrid>
      </PageMainContent>
    </PageWrapper>
  );
};

export default NoCollections;
