import React from "react";
import {
  AssetCounts,
  Token,
  AssetMeta,
  ADMIN_HTTP_HEADER,
  Collection,
  AssetExtension,
} from "lasagna-commons";
import type { FileWithPath } from "react-dropzone";
import { matchPath } from "react-router";
import type {
  AssetErrorMessage,
  FetchHandlerParams,
  DeriveAssetId,
  InitialClientAsset,
} from "../types";
import { AuthError } from "./AuthError";
import { NoCollectionError } from "../constants";

export function getCollectionId() {
  const match = matchPath(
    "/collections/:collectionId/*",
    window.location.pathname
  );

  if (!match?.params.collectionId) {
    throw new NoCollectionError();
  }

  return match.params.collectionId;
}

export function createBasicCtx<A extends {}>() {
  const ctx = React.createContext<A | undefined>(undefined);
  function useCtx() {
    return React.useContext(ctx);
  }
  return [useCtx, ctx.Provider] as const;
}

export function createCtx<A extends {}>() {
  const ctx = React.createContext<A | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (c === undefined)
      throw new Error(
        "This context hook must be wrapped in a provider with a value"
      );
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

export function pluralizeWord(count: number, word: string) {
  return `${word}${count !== 1 ? "s" : ""}`;
}

export function pluralize(count: number, word: string) {
  return `${numberWithDelimiters(count)} ${pluralizeWord(count, word)}`;
}

export function numberWithDelimiters(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function generateAssetId(prefix: number) {
  return prefix.toString();
}

const MAX_IMAGE_WIDTH = 512;

async function analyzeClientAsset(
  file: FileWithPath,
  assetMeta: AssetMeta | null
): Promise<{ previewUrl: string; assetMeta: AssetMeta }> {
  const image = new Image();
  image.src = URL.createObjectURL(file);

  await new Promise((res, rej) => {
    image.onload = res;
    image.onerror = rej;
  });

  const { width: imageWidth, height: imageHeight } = image;

  if (
    assetMeta &&
    (imageWidth !== assetMeta.imageWidth ||
      imageHeight !== assetMeta.imageHeight)
  ) {
    throw new Error(
      `assets must all have same dimensions. Project assets are ${assetMeta.imageWidth}x${assetMeta.imageWidth} - this image is ${imageWidth}x${imageHeight}.`
    );
  }

  const aspectRatio = imageHeight / imageWidth;

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context) {
    throw new Error("Image could not be uploaded");
  }

  const resizedWidth = Math.min(imageWidth, MAX_IMAGE_WIDTH);
  const resizedHeight = resizedWidth * aspectRatio;

  canvas.width = resizedWidth;
  canvas.height = resizedHeight;

  context.drawImage(image, 0, 0, resizedWidth, resizedHeight);

  URL.revokeObjectURL(image.src);

  image.remove();

  return {
    previewUrl: canvas.toDataURL(),
    assetMeta: { imageHeight, imageWidth, aspectRatio },
  };
}

export async function initializeClientAsset({
  file,
  extension,
  deriveAssetId,
  assetMeta: inputAssetMeta,
}: {
  file: FileWithPath;
  extension: AssetExtension;
  deriveAssetId: DeriveAssetId;
  assetMeta: AssetMeta | null;
}): Promise<{ asset: InitialClientAsset; assetMeta: AssetMeta }> {
  const filePath = file.name.split(".");
  filePath.pop();

  const id =
    typeof deriveAssetId === "function"
      ? deriveAssetId()
      : generateAssetId(deriveAssetId);

  const { previewUrl, assetMeta } = await analyzeClientAsset(
    file,
    inputAssetMeta
  );

  return {
    asset: {
      id,
      label: filePath.join("."),
      previewUrl,
      file,
      extension,
    },
    assetMeta,
  };
}

interface ValidateUploadedFileData {
  assetError: AssetErrorMessage | null;
  pathArray: string[];
  asset: InitialClientAsset | null;
  assetMeta: AssetMeta | null;
}

const fileTypeMap: { [key: string]: AssetExtension } = {
  "image/png": "png",
  "image/jpeg": "jpeg",
  "image/jpg": "jpg",
  "image/svg+xml": "svg",
};

export async function validateUploadedFile({
  file,
  assetMeta: inputAssetMeta,
  directoryValidation,
  deriveAssetId,
}: {
  file: FileWithPath;
  assetMeta: AssetMeta | null;
  deriveAssetId: DeriveAssetId;
  directoryValidation?: { layerCount: number; errorMessage: string };
}): Promise<ValidateUploadedFileData> {
  const data: ValidateUploadedFileData = {
    assetError: null,
    asset: null,
    assetMeta: null,
    pathArray: [],
  };

  if (!file.path) {
    throw new Error("Path not found");
  }

  if (file.name === ".DS_Store") {
    return data;
  }

  data.pathArray = file.path.split("/").filter(Boolean);

  if (
    directoryValidation &&
    data.pathArray.length < directoryValidation.layerCount
  ) {
    data.assetError = {
      name: file.name,
      reason: directoryValidation.errorMessage,
    };
  } else if (!fileTypeMap[file.type]) {
    data.assetError = {
      name: file.name,
      reason: "file must be an image of type PNG, JPG or SVG",
    };
  } else {
    try {
      const { asset, assetMeta } = await initializeClientAsset({
        file,
        extension: fileTypeMap[file.type],
        deriveAssetId,
        assetMeta: inputAssetMeta,
      });

      data.asset = asset;
      data.assetMeta = assetMeta;
    } catch (err) {
      data.assetError = {
        name: file.name,
        reason: getErrorMessage(err),
      };
    }
  }

  return data;
}

export function searchFilter(inputValue: string, searchValue: string): boolean {
  return (
    !searchValue ||
    inputValue.toLowerCase().includes(searchValue.trim().toLowerCase())
  );
}

export function sortByLabel(a: { label: string }, b: { label: string }) {
  return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
}

async function customFetchHandler<T>({
  method,
  url,
  body,
  jwt,
  query = {},
}: FetchHandlerParams): Promise<T> {
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };

  if (window.adminUserId) {
    headers[ADMIN_HTTP_HEADER] = window.adminUserId;
  }

  if (jwt) {
    query.token = jwt;
  }

  let queryString = Object.entries(query)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  if (queryString) {
    queryString = "?" + queryString;
  }

  const res = await fetch(`${url}${queryString}`, {
    headers,
    method,
    body: body ? JSON.stringify(body) : undefined,
    credentials: "include",
  });
  const responseData = await res.json();
  if (res.status >= 200 && res.status < 300) {
    return responseData as T;
  } else if (res.status === 401) {
    throw new AuthError();
  } else {
    throw new Error(responseData.error || "unknown");
  }
}

const REQUEST_TIMEOUT = 60000;

export async function customFetch<T>(params: FetchHandlerParams): Promise<T> {
  return Promise.race([
    customFetchHandler<T>(params),
    new Promise<T>((_, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, REQUEST_TIMEOUT);
    }),
  ]);
}

export function getErrorMessage(error: any): string {
  if (error instanceof Error || (typeof error == "object" && error.message)) {
    return error.message === "Failed to fetch"
      ? "Could not communicate with server. Refresh to try again."
      : error.message;
  }
  return "unknown";
}

export function calculateRarityScore({
  token,
  assetCounts,
  tokenCount,
}: {
  token: Token;
  assetCounts: AssetCounts;
  tokenCount: number;
}): number {
  return token.tokenLayers.reduce((a, { assetId, layerId, visible }) => {
    if (visible) {
      a += assetCounts[layerId][assetId];
    } else {
      a += Math.ceil(tokenCount / Object.keys(assetCounts[layerId]).length);
    }
    return a;
  }, 0);
}

export function collectionIsReadOnly(collection: Collection): boolean {
  return collection.preview?.role === "view";
}
