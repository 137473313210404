export default function Image() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="15" height="15" stroke="white" />
      <path
        d="M0.799805 12.8L4.7998 8.80005L7.9998 12L15.1998 4.80005"
        stroke="white"
      />
      <circle cx="4.4002" cy="4.39995" r="1.2" fill="white" />
    </svg>
  );
}
