export default function FolderOpen() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9056 8.39719L13.2389 13.7305C13.0889 14.0333 12.7806 14.2222 12.4194 14.2222H0L3.31111 7.60275C3.46111 7.29997 3.76944 7.11108 4.10556 7.11108H15.1111C15.7694 7.11108 16.2 7.80553 15.9056 8.39719Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M13.3333 4.88894V7.11117H4.10556C3.76944 7.11117 3.46111 7.30005 3.31111 7.60283L0 14.2223V3.11117C0 2.37478 0.596944 1.77783 1.33333 1.77783H5.77778L7.55556 3.55561H12C12.7361 3.55561 13.3333 4.15283 13.3333 4.88894Z"
        fill="white"
      />
    </svg>
  );
}
