import React from "react";
import { createBasicCtx, createCtx } from "../../utils";
import type {
  TokenDispatch,
  TokenState,
  FetchHandlerParams,
} from "../../types";
import useUpdateStack from "../../utils/hooks/useUpdateStack";

const [useTokensInit, TokenContextProvider] = createBasicCtx<TokenState>();
const [useTokenDispatch, TokenDispatchContextProvider] =
  createCtx<TokenDispatch>();

const TokenProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tokenState, setTokenState] = React.useState<TokenState>();

  const callback = React.useCallback((updateStack: FetchHandlerParams[]) => {
    setTokenState((current) => {
      if (!current) {
        return;
      }
      return { ...current, updateStack };
    });
  }, []);

  useUpdateStack(tokenState?.updateStack, callback);

  return (
    <TokenDispatchContextProvider value={setTokenState}>
      <TokenContextProvider value={tokenState}>{children}</TokenContextProvider>
    </TokenDispatchContextProvider>
  );
};

export { useTokensInit, useTokenDispatch, TokenProvider };
