import { useState } from "react";
import Check from "../../../../icons/Check";

export default function CopyButton({ cardNumber }: { cardNumber: string }) {
  const [copied, setCopied] = useState(false);

  const handleClick = async () => {
    await window.navigator.clipboard.writeText(cardNumber);
    setCopied(true);
  };

  return (
    <button
      className={`font-mono text-xs uppercase inline-flex items-center ${
        copied ? "text-secondary" : "text-primary"
      }`}
      onClick={handleClick}
    >
      {copied ? (
        <>
          <span>Copied</span>
          <Check />
        </>
      ) : (
        "Copy card"
      )}
    </button>
  );
}
