import { AssetWithUrls } from "lasagna-commons";
import React from "react";
import type { Dispatch, SetStateAction } from "react";
import { useAssetDispatch } from "../../../../components/AssetContext";
import Tooltip from "../../../../components/Popper/Tooltip";
import { UpdateWarning, useAssetDelete, useSetAssetHidden } from "../../hooks";
import TrashIcon from "../../../../icons/Trash";
import AssetGridItemFileInput from "./AssetGridItemFileInput";
import EyeIcon from "../../../../icons/Eye";
import EyeSlashIcon from "../../../../icons/EyeSlash";

export const menuButtonStyles =
  "cursor-pointer flex items-center justify-center h-6 w-6 bg-black transition-colors hover:bg-gray disabled:pointer-events-none";
const tooltipStyles = "bg-gray-dark text-xs py-1 px-2 text-white shadow-sm";

const AssetGridItemMenu: React.FC<{
  layerId: string;
  asset: AssetWithUrls;
  isDeleting: boolean;
  setIsDeleting: Dispatch<SetStateAction<boolean>>;
  setUpdateWarning: Dispatch<SetStateAction<UpdateWarning>> | undefined;
  mouseIsOver: boolean;
  setMouseIsOver: Dispatch<SetStateAction<boolean>>;
  isReadOnly: boolean;
}> = ({
  mouseIsOver,
  layerId,
  asset,
  isDeleting,
  setIsDeleting,
  setUpdateWarning,
  setMouseIsOver,
  isReadOnly,
}) => {
  const assetDispatch = useAssetDispatch();

  const deleteAsset = useAssetDelete({
    layerId,
    asset,
    assetDispatch,
    setIsDeleting,
    setUpdateWarning,
  });

  const { setAssetHidden, submitting } = useSetAssetHidden({
    asset,
    assetDispatch,
    layerId,
  });

  return (
    <div
      className={`absolute top-2 right-2 z-20 flex items-center ${
        mouseIsOver && !isReadOnly ? "visible" : "invisible"
      }`}
    >
      <Tooltip
        title={
          asset.hide
            ? "Re-enable this asset for token generation."
            : "Ignore this asset during token generation. This can be useful if you do not want it to initially appear in your collection - that way you can manually add it as a unique trait later."
        }
        tooltipClassName={`${tooltipStyles} w-56`}
        offset={4}
      >
        <button
          onClick={setAssetHidden}
          className={menuButtonStyles}
          disabled={submitting}
        >
          {asset.hide ? <EyeIcon /> : <EyeSlashIcon />}
        </button>
      </Tooltip>
      <span className="mx-3">
        <Tooltip
          title="Replace this asset image with another one"
          tooltipClassName={`${tooltipStyles} w-40`}
          offset={4}
        >
          <AssetGridItemFileInput
            layerId={layerId}
            assetId={asset.id}
            assetLabel={asset.label}
            setMouseIsOver={setMouseIsOver}
          />
        </Tooltip>
      </span>
      <Tooltip
        title="Delete this asset"
        tooltipClassName={`${tooltipStyles} whitespace-nowrap`}
        offset={4}
      >
        <button
          onClick={deleteAsset}
          className={`${menuButtonStyles}`}
          disabled={isDeleting}
        >
          <TrashIcon />
        </button>
      </Tooltip>
    </div>
  );
};

export default AssetGridItemMenu;
