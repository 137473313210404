import React from "react";
import type { Dispatch, SetStateAction } from "react";
import Button from "../../components/Button";
import Drawer from "../../components/Drawer";
import ChevronLeftIcon from "../../icons/ChevronLeft";
import TrashIcon from "../../icons/Trash";
import type { SetUpdateWarning, UpdateWarning } from "./hooks";
import { useCollectionDispatch } from "../../components/CollectionContext";
import ShuffleIcon from "../../icons/Shuffle";
import WarningMessage from "../../components/WarningMessage";
import LottieIcon from "../../components/LottieIcon";
import animationData from "../../components/LottieIcon/animations/delete-warning.json";
import { getCollectionId } from "../../utils";

const WarningDrawer: React.FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setUpdateWarning: SetUpdateWarning;
  updateWarning: UpdateWarning;
}> = ({ updateWarning, setUpdateWarning, open, setOpen }) => {
  const collectionDispatch = useCollectionDispatch();
  const collectionId = getCollectionId();

  const handleConfirm = () => {
    if (updateWarning?.callback) {
      updateWarning.callback();
      collectionDispatch((current) => {
        if (!current) {
          return;
        }
        return {
          ...current,
          [collectionId]: {
            ...current[collectionId],
            mustRegenerate: true,
          },
        };
      });
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExited = () => {
    setUpdateWarning(null);
  };

  const { heading, cancelLabel, confirmLabel, warning } = React.useMemo(() => {
    if (
      updateWarning &&
      (updateWarning.type === "asset" || updateWarning.type === "layer")
    ) {
      return {
        heading: `Are you sure you want to delete this ${updateWarning.type}?`,
        warning: (
          <WarningMessage>
            If you have saved any custom tokens that use{" "}
            {updateWarning.type === "layer"
              ? "any assets in this layer"
              : "this asset"}
            , they will also be deleted. You may want make updates accordingly
            before deleting.
          </WarningMessage>
        ),
        cancelLabel: (
          <>
            <ChevronLeftIcon />
            <span className="ml-3">No, don't delete</span>
          </>
        ),
        confirmLabel: (
          <>
            <TrashIcon size={18} />
            <span className="ml-3">Yes, delete it</span>
          </>
        ),
      };
    }
    return {
      heading: "Are you sure you want to update your layer order?",
      warning: null,
      cancelLabel: (
        <>
          <ChevronLeftIcon />
          <span className="ml-3">No, don't update</span>
        </>
      ),
      confirmLabel: (
        <>
          <ShuffleIcon />
          <span className="ml-3">Yes, update it</span>
        </>
      ),
    };
  }, [updateWarning]);

  return (
    <Drawer open={open} onClose={handleClose} onExited={handleExited}>
      <LottieIcon animationData={animationData} />
      <h2>{heading}</h2>
      <p className="opacity-60 mb-6">
        You'll have to regenerate your tokens and will lose any existing tokens
        you've made so far.
      </p>
      {warning}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Button size="small" fullWidth onClick={handleClose}>
            {cancelLabel}
          </Button>
        </div>
        <div>
          <Button
            size="small"
            fullWidth
            onClick={handleConfirm}
            variant="secondary"
          >
            {confirmLabel}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default WarningDrawer;
