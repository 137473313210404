import React from "react";
import Button from "../../components/Button";
import Drawer from "../../components/Drawer";
import ProjectNavbar from "../../components/Navbar/ProjectNavbar";
import PageMainContent from "../../components/PageMainContent";
import PageWrapper from "../../components/PageWrapper";
import ChevronRightIcon from "../../icons/ChevronRight";
import uploadAnimationData from "../../components/LottieIcon/animations/upload-asset.json";
import LottieIcon from "../../components/LottieIcon";
import ProgressBar from "../../components/Progress/ProgressBar";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";
import useCustomFetch from "../../utils/hooks/useCustomFetch";
import { useCollectionDispatch } from "../../components/CollectionContext";
import { API_ENDPOINT } from "../../config";
import { Collection } from "lasagna-commons";
import CollectionErrorSection from "../../components/CollectionErrorSection";

const PinTokensPage: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const openDrawer = () => {
    setOpen(true);
  };

  return (
    <PageWrapper navbar={<ProjectNavbar />}>
      <PageMainContent centerContent addLayoutContainer>
        <div className="flex justify-center flex-col items-center">
          <h3>Tokens have not been pinned yet 🧐</h3>
          <p className="mb-6 m-auto max-w-lg text-center">
            <span className="opacity-60">
              In order to launch your collection on the Ethereum network, you'll
              need to pin your tokens to IPFS using our{" "}
            </span>
            <a
              className="text-primary hover:underline"
              href="https://thirdweb.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              thirdweb
            </a>
            <span className="opacity-60"> integration.</span>
          </p>
          <div>
            <Button onClick={openDrawer} size="large">
              <span className="mr-3">Pin tokens to IPFS</span>
              <ChevronRightIcon />
            </Button>
          </div>
        </div>
        <PinTokensDrawer drawerOpen={open} setDrawerOpen={setOpen} />
      </PageMainContent>
    </PageWrapper>
  );
};

function PinTokensDrawer({
  drawerOpen,
  setDrawerOpen,
}: {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [shouldClearInterval, setShouldClearInterval] = React.useState(false);

  const collectionDispatch = useCollectionDispatch();
  const collection = useCurrentCollection();
  const fetcher = useCustomFetch();

  const hasSubmitted = React.useRef(collection.pinnedTokenCount !== 0);
  const interval = React.useRef<number>();

  const collectionId = collection.id;

  const callback = React.useCallback(async () => {
    const data = await fetcher<{ collection: Collection }>({
      url: `${API_ENDPOINT}/collections/${collectionId}`,
      method: "GET",
    });

    if (data) {
      collectionDispatch((current) => {
        if (!current) {
          return;
        }

        return {
          ...current,
          [collectionId]: data.collection,
        };
      });

      if (
        data.collection.pinnedTokenCount === data.collection.tokenCount ||
        data.collection.collectionError
      ) {
        setShouldClearInterval(true);
      }
    } else {
      setShouldClearInterval(true);
    }
  }, [collectionDispatch, fetcher, collectionId]);

  React.useEffect(() => {
    if (drawerOpen) {
      interval.current = window.setInterval(callback, 3000);
      return () => {
        window.clearInterval(interval.current);
      };
    }
  }, [callback, drawerOpen]);

  React.useEffect(() => {
    if (interval.current !== undefined && shouldClearInterval) {
      window.clearInterval(interval.current);
    }
  }, [shouldClearInterval]);

  const triggerPinning = React.useCallback(async () => {
    const res = fetcher({
      url: `${API_ENDPOINT}/collections/${collection.id}/tokens/pin`,
      method: "POST",
    });

    if (!res) {
      setDrawerOpen(false);
      return;
    }

    hasSubmitted.current = true;
  }, [fetcher, setDrawerOpen, collection.id]);

  React.useEffect(() => {
    if (
      drawerOpen &&
      collection.pinnedTokenCount === 0 &&
      !hasSubmitted.current
    ) {
      triggerPinning();
    }
  }, [drawerOpen, collection, triggerPinning]);

  return (
    <Drawer open={drawerOpen}>
      {collection.collectionError ? (
        <CollectionErrorSection
          collection={collection}
          collectionError={collection.collectionError}
          disablePinningRetry
        />
      ) : (
        <>
          <LottieIcon animationData={uploadAnimationData} />
          <div className="animate-pulse mb-5">
            <h2>Pinning your tokens</h2>
            <p>This might take a while for larger collections...please wait.</p>
          </div>
          <ProgressBar
            numerator={collection.pinnedTokenCount}
            denominator={collection.tokenCount}
          />
        </>
      )}
    </Drawer>
  );
}

export default PinTokensPage;
