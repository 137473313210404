import React from "react";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "../SnackbarContext";
import Main from "./Main";
import { AuthProvider } from "../AuthContext";
import { ViewportProvider } from "../ViewportContext";
import ErrorBoundary from "../ErrorBoundary";
import WalletProvider from "../WalletProvider";
import { ActiveChainProvider } from "../WalletProvider/ActiveChainProvider";

const Root: React.FC = () => {
  return (
    <ActiveChainProvider>
      <WalletProvider>
        <BrowserRouter>
          <ErrorBoundary>
            <SnackbarProvider>
              <ViewportProvider>
                <AuthProvider>
                  <Main />
                </AuthProvider>
              </ViewportProvider>
            </SnackbarProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </WalletProvider>
    </ActiveChainProvider>
  );
};

export default Root;
