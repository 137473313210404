import React from "react";
import AssetImageStack from "../../../components/AssetImage/AssetImageStack";
import Tooltip from "../../../components/Popper/Tooltip";
import SaveTokenIcon from "../../../icons/SaveToken";
import type { AssetState, TokenWithRarityRank } from "../../../types";
import type { GridStyleOption } from "../types";
import { GRID_STYLE_IMAGE_SIZE_MAP } from "./constants";

interface TokenGridItemProps {
  token: TokenWithRarityRank;
  onClick: (tokenId: number) => void;
  aspectRatio: number;
  layers: AssetState["layers"];
  highlightTokenId: boolean;
  gridStyle: GridStyleOption;
  footerHeight: number;
}

const TokenGridItem: React.FC<TokenGridItemProps> = ({
  token,
  onClick,
  aspectRatio,
  layers,
  highlightTokenId,
  gridStyle,
  footerHeight,
}) => {
  const handleOpen = React.useCallback(() => {
    onClick(token.id);
  }, [token.id, onClick]);

  if (!token) {
    return null;
  }

  return (
    <button
      onClick={handleOpen}
      className={`bg-gray-light w-full ${
        !token.rarityRank ? "pointer-events-none" : ""
      }`}
      disabled={!token.rarityRank}
    >
      <div className="bg-gray-light relative">
        <AssetImageStack
          tokenLayers={token.tokenLayers}
          aspectRatio={aspectRatio}
          layers={layers}
          size={GRID_STYLE_IMAGE_SIZE_MAP[gridStyle]}
        />
        <div
          className={`px-2 text-sm flex items-center justify-between font-secondary ${
            token.saved ? "bg-primary text-black" : ""
          }`}
          style={{
            height: footerHeight,
          }}
        >
          <span className={`flex items-center`}>
            <Tooltip title="Token ID">
              <span
                className={`${
                  highlightTokenId
                    ? "font-bold"
                    : token.saved
                    ? ""
                    : "opacity-60"
                }`}
              >
                {token.id}
              </span>
            </Tooltip>
            {token.saved && gridStyle === "large" && (
              <span className="inline-flex ml-2">
                <Tooltip title="Saved as custom token">
                  <SaveTokenIcon />
                </Tooltip>
              </span>
            )}
          </span>
          {token.rarityRank ? (
            <Tooltip
              className={
                highlightTokenId
                  ? token.saved
                    ? ""
                    : "opacity-60"
                  : "font-bold"
              }
              title="Rarity rank"
            >
              #{token.rarityRank}
            </Tooltip>
          ) : (
            <span />
          )}
        </div>
      </div>
    </button>
  );
};

export default React.memo(TokenGridItem);
