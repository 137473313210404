import React from "react";
import Button from "../../components/Button";
import ProjectNavbar from "../../components/Navbar/ProjectNavbar";
import PageMainContent from "../../components/PageMainContent";
import PageWrapper from "../../components/PageWrapper";
import ChevronLeftIcon from "../../icons/ChevronLeft";
import ThirdwebIcon from "../../icons/Thirdweb";
import SmartContractForm from "./SmartContractForm";

const NewContract: React.FC<{
  showContractForm: boolean;
  setShowContractForm: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ showContractForm, setShowContractForm }) => {
  return (
    <PageWrapper navbar={<ProjectNavbar />}>
      <PageMainContent addLayoutContainer>
        <div className="max-w-screen-2xl m-auto grid grid-cols-4 gap-8">
          <div className="col-span-3">
            {showContractForm && (
              <div className="mb-5 pt-1">
                <Button
                  size="small"
                  variant="gray"
                  onClick={() => setShowContractForm(false)}
                >
                  <ChevronLeftIcon />
                  <span className="ml-2">Go back</span>
                </Button>
              </div>
            )}
            <SmartContractForm setShowContractForm={setShowContractForm} />
          </div>
          <div className={showContractForm ? "pt-1" : ""}>
            <div className="bg-gray p-4 shadow-layer-gray">
              <h5 className="font-secondary mb-2 opacity-70 text-sm">
                Powered by
              </h5>
              <div className="mb-5">
                <a
                  href="https://thirdweb.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <ThirdwebIcon />
                </a>
              </div>
              <p className="mb-5">
                Launch your collection using thirdweb's no-code smart contract
                and dashboard straight from lasagna!
              </p>
              <h5 className="text-sm font-secondary opacity-70 mb-3">
                Steps to launch
              </h5>
              <ul>
                <ListItem
                  step={1}
                  text="Complete your smart contract and deploy within lasagna."
                />
                <ListItem
                  step={2}
                  text="Pin your tokens to IPFS using lasagna's thirdweb integration"
                />
                <ListItem
                  step={3}
                  text="Set up mint phases, manage your contract, and launch from the thirdweb dashboard."
                />
              </ul>
            </div>
          </div>
        </div>
      </PageMainContent>
    </PageWrapper>
  );
};

function ListItem({ step, text }: { step: number; text: string }) {
  return (
    <li className={`py-2 px-3 bg-black ${step === 3 ? "" : "mb-2"}`}>
      <h5 className="text-xs font-secondary opacity-60 mb-1">Step {step}</h5>
      <p>{text}</p>
    </li>
  );
}

export default NewContract;
