import React from "react";
import lottie from "lottie-web";

const LottieIcon: React.FC<{
  animationData: any;
  translateX?: string;
}> = ({ animationData, translateX = "-translate-x-8" }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        animationData,
      });
      return () => {
        animation.destroy();
      };
    }
  }, [animationData]);

  return (
    <div
      className={`h-40 w-40 -mt-8 -mb-4 lottie-icon transform ${translateX}`}
      ref={ref}
    />
  );
};

export default LottieIcon;
