import React from "react";

const IconButton: React.FC<{
  onClick: React.MouseEventHandler<HTMLElement>;
  title?: string;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
}> = ({ onClick, title, children, disabled, className = "" }) => {
  return (
    <button
      className={`inline-flex items-center justify-center rounded-full opacity-50 transform transition-transform hover:opacity-90 scale-100 active:scale-95 disabled:opacity-20 disabled:pointer-events-none text-white ${className}`}
      onClick={onClick}
      title={title}
      disabled={disabled}
      type="button"
    >
      {children}
    </button>
  );
};

export default IconButton;
