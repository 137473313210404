import React from "react";
import type { ViewportState } from "../../../../components/ViewportContext";
import type { AssetState } from "../../../../types";
import { SetUpdateWarning } from "../../hooks";
import AssetsContainer from "./AssetsContainer";
import LayerHeader from "./LayerHeader";

const LayerContainer: React.FC<{
  layers: AssetState["layers"];
  layerId: string;
  order: string[];
  setOrder: React.Dispatch<React.SetStateAction<string[]>>;
  isDeleting: boolean;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateWarning: SetUpdateWarning | undefined;
  aspectRatio: number;
  viewport: ViewportState;
  isReadOnly: boolean;
}> = (props) => {
  const {
    layerId,
    layers,
    aspectRatio,
    setIsDeleting,
    setUpdateWarning,
    viewport,
    isDeleting,
    isReadOnly,
  } = props;

  const assets = React.useMemo(() => {
    return Object.keys(layers[layerId].assets).map((assetId) => {
      return layers[layerId].assets[assetId];
    });
  }, [layers, layerId]);

  return (
    <section className="bg-gray shadow-layer-gray">
      <LayerHeader {...props} />
      <AssetsContainer
        assets={assets}
        layerId={layerId}
        label={layers[layerId].label}
        layerOrder={layers[layerId].layerOrder}
        aspectRatio={aspectRatio}
        setIsDeleting={setIsDeleting}
        setUpdateWarning={setUpdateWarning}
        viewport={viewport}
        isDeleting={isDeleting}
        isReadOnly={isReadOnly}
      />
    </section>
  );
};

export default LayerContainer;
