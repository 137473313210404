import { useEffect } from "react";
import { ASIDE_WIDTH, FOOTER_HEIGHT } from "../../../constants/layoutConstants";
import { TOKEN_PAGE_HEADER_HEIGHT } from "../../../constants/tokenPageConstants";
import LoadingSidePanel from "./LoadingSidePanel";

export default function LoadingSkeleton() {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div>
      <div
        className="pb-2 flex items-center"
        style={{ height: TOKEN_PAGE_HEADER_HEIGHT }}
      >
        <div style={{ width: ASIDE_WIDTH }} className="flex-shrink-0">
          <span className="block h-3 w-40 bg-gray-light animate-pulse" />
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            <span className="block h-6 w-6 bg-gray-light animate-pulse mr-4" />
            <span className="block h-6 w-6 bg-gray-light animate-pulse" />
          </div>
          <div>
            <span className="block h-7 w-32 bg-gray-light animate-pulse" />
          </div>
        </div>
      </div>
      <div className="flex items-start">
        <LoadingSidePanel />
        <div className="grid grid-cols-4 gap-3 flex-grow">
          {Array.from({ length: 12 }).map((v, i) => (
            <div
              key={i}
              style={{ paddingBottom: "100%" }}
              className="bg-gray-light animate-pulse"
            >
              <span className="h-6 block" />
            </div>
          ))}
        </div>
      </div>
      <div
        className="fixed bottom-0 left-0 right-0 bg-black"
        style={{ height: FOOTER_HEIGHT }}
      />
    </div>
  );
}
