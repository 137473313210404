import {
  AssetWithUrls,
  RARITY_LOWER_BOUND,
  RARITY_UPPER_BOUND,
} from "lasagna-commons";
import React, { Dispatch, SetStateAction } from "react";
import { useAssetDispatch } from "../../../../components/AssetContext";
import AssetImage from "../../../../components/AssetImage";
import AssetImageContainer from "../../../../components/AssetImage/AssetImageContainer";
import TextInput from "../../../../components/Form/TextInput";
import { useLayerListItemState } from "../AssetEditCollapsedView/LayerContainer/hooks";
import Slider from "../../Slider";
import type { UpdateWarning } from "../../hooks";
import AssetGridItemMenu from "./AssetGridItemMenu";
import AssetIgnoredMessage from "../AssetIgnoredMessage";
import NumberInput from "../../../../components/Form/NumberInput";

const AssetGridItemContent: React.FC<{
  asset: AssetWithUrls;
  aspectRatio: number;
  isDeleting: boolean;
  setIsDeleting: Dispatch<SetStateAction<boolean>>;
  setUpdateWarning: Dispatch<SetStateAction<UpdateWarning>> | undefined;
  layerId: string;
  isReadOnly: boolean;
}> = ({
  layerId,
  asset,
  aspectRatio,
  setIsDeleting,
  setUpdateWarning,
  isDeleting,
  isReadOnly,
}) => {
  const hasMousedOver = React.useRef(false);
  const [mouseIsOver, setMouseIsOver] = React.useState(false);

  const handleMouseEnter = () => {
    hasMousedOver.current = true; // Not rendering menu until moused over at least once for performance improvements
    setMouseIsOver(true);
  };

  const handleMouseLeave = () => {
    setMouseIsOver(false);
  };

  const assetDispatch = useAssetDispatch();

  const labelState = useLayerListItemState({
    asset,
    updateKey: "label",
    layerId,
    assetDispatch,
  });

  const rarityState = useLayerListItemState({
    asset,
    updateKey: "rarity",
    layerId,
    assetDispatch,
  });

  return (
    <div
      className={`relative w-full pb-3 ${
        mouseIsOver ? "bg-black shadow-black" : ""
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {hasMousedOver.current && (
        <AssetGridItemMenu
          layerId={layerId}
          asset={asset}
          setUpdateWarning={setUpdateWarning}
          isDeleting={isDeleting}
          setIsDeleting={setIsDeleting}
          mouseIsOver={mouseIsOver}
          setMouseIsOver={setMouseIsOver}
          isReadOnly={isReadOnly}
        />
      )}
      <div className="bg-gray-light">
        <AssetImageContainer aspectRatio={1}>
          {asset.hide && (
            <AssetIgnoredMessage>
              Ignored during token generation
            </AssetIgnoredMessage>
          )}
          <AssetImage asset={asset} size="small" />
        </AssetImageContainer>
      </div>
      <div className="pt-2">
        <div>
          <TextInput
            name={`asset-label-${asset.id}`}
            value={labelState.value}
            setValue={labelState.setValue}
            onBlur={labelState.handleBlur}
            placeholder="Trait label"
            error={labelState.error}
            readOnly={isReadOnly}
          />
        </div>
        <div className="flex items-center">
          <div className="flex-grow">
            <Slider
              value={rarityState.value}
              setValue={rarityState.setValue}
              onBlur={rarityState.handleBlur}
              mouseIsOver={mouseIsOver}
              disabled={isReadOnly}
            />
          </div>
          <div className="flex-shrink-0 w-16 pl-4">
            <NumberInput
              name={`asset-rarity-${asset.id}`}
              value={rarityState.value}
              setValue={rarityState.setValue}
              onBlur={rarityState.handleBlur}
              endAdornment={<span className="opacity-60">%</span>}
              min={RARITY_LOWER_BOUND}
              max={RARITY_UPPER_BOUND}
              error={rarityState.error}
              readOnly={isReadOnly}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetGridItemContent;
