import React from "react";
import { convertCentsToCurrency } from "lasagna-commons";
import Button from "../../../../components/Button";
import { useSquare } from "./utils/hooks";
import LoadingIcon from "../../../../components/LoadingIcon";
import CouponForm from "./CouponForm";
import { PaymentFormData } from "../types";

const PaymentForm: React.FC<{
  collectionId: string;
  amountOwed: number;
  setShowPinningForm: React.Dispatch<React.SetStateAction<boolean>>;
  showCouponForm: boolean;
  setPaymentFormData: React.Dispatch<
    React.SetStateAction<PaymentFormData | null>
  >;
}> = ({
  amountOwed,
  setShowPinningForm,
  collectionId,
  showCouponForm,
  setPaymentFormData,
}) => {
  const { cardContainerRef, loadingStatus, submitting, submitPayment } =
    useSquare({
      collectionId,
      setShowPinningForm,
    });

  return (
    <>
      <div ref={cardContainerRef}></div>
      {loadingStatus.hasError ? (
        <div className="py-4">
          <p className="text-red">
            Payment form could not be loaded. Please try again later.
          </p>
        </div>
      ) : !loadingStatus.cardIsLoaded ? (
        <div className="py-4 text-center">
          <LoadingIcon />
          <p>Preparing payment form...</p>
        </div>
      ) : (
        <>
          <Button fullWidth disabled={submitting} onClick={submitPayment}>
            {submitting
              ? "Submitting payment..."
              : `Pay balance of ${convertCentsToCurrency(amountOwed)}`}
          </Button>
          {showCouponForm && (
            <CouponForm
              collectionId={collectionId}
              setPaymentFormData={setPaymentFormData}
              setShowPinningForm={setShowPinningForm}
            />
          )}
        </>
      )}
    </>
  );
};

export default PaymentForm;
