import React from "react";
import Button from "../Button";
import Drawer from "../Drawer";
import { useAssetDispatch, useAssetsInit } from ".";

const AssetErrorDrawer: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const assetState = useAssetsInit();
  const assetDispatch = useAssetDispatch();

  React.useEffect(() => {
    if (assetState?.errors && assetState.errors.length > 0) {
      setOpen(true);
    }
  }, [assetState?.errors]);

  const handleExited = () => {
    assetDispatch((current) => {
      if (!current) {
        return;
      }
      return {
        ...current,
        errors: undefined,
      };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!assetState || !assetState.errors) {
    return null;
  }

  const errors = assetState.errors;

  return (
    <Drawer open={open} onClose={handleClose} onExited={handleExited}>
      <h2>Upload Errors</h2>
      <p className="opacity-60">
        We had trouble uploading some of your assets.
      </p>
      <ul className="mt-2 mb-6">
        {errors.map(({ name, reason }, index) => {
          return (
            <li
              key={name}
              className={`py-2 ${
                index + 1 !== errors.length
                  ? "border-white border-opacity-10 border-b"
                  : ""
              }`}
            >
              {name && (
                <div>
                  <span className="opacity-60">File: </span>
                  <span>{name}</span>
                </div>
              )}
              <div>
                <span className="opacity-60">Error: </span>
                <span>{reason}</span>
              </div>
            </li>
          );
        })}
      </ul>
      <div>
        <Button onClick={handleClose} fullWidth>
          Close
        </Button>
      </div>
    </Drawer>
  );
};

export default AssetErrorDrawer;
