import React from "react";
import {
  CAROUSEL_CONTAINER_STYLES,
  CAROUSEL_ITEM_STYLES,
} from "../../constants";
import PageWrapper from "../../components/PageWrapper";
import PageMainContent from "../../components/PageMainContent";
import { useCurrentCollection } from "../../components/CollectionContext/hooks";
import Button from "../../components/Button";
import ChevronRightIcon from "../../icons/ChevronRight";
import ProjectNavbar from "../../components/Navbar/ProjectNavbar";
import SmartContractContainer from "./SmartContractContainer";

const SmartContractCarousel: React.FC<{
  setShowContractForm: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setShowContractForm }) => {
  const collection = useCurrentCollection();

  const contracts = collection.contracts || [];

  // We want to persist the original index since it's referenced elsewhere (like the mint pages)
  // but we want to display in chronological order
  const sortedContracts = contracts
    .map((contract, index) => ({ ...contract, index }))
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

  const handleClick = () => {
    setShowContractForm(true);
  };

  return (
    <PageWrapper navbar={<ProjectNavbar />}>
      <PageMainContent addLayoutContainer centerContent paddingBottom={40}>
        <div className={`${CAROUSEL_CONTAINER_STYLES} hide-scrollbar`}>
          <div
            className={`${CAROUSEL_ITEM_STYLES} bg-gray shadow-layer-gray w-96 flex flex-col justify-center h-auto`}
          >
            <div className="p-8">
              <h3>Deploy a new smart contract</h3>
              <p className="opacity-60 mb-6">
                Use lasagna's integration with thirdweb to deploy your own smart
                contracts to allow your collection to be minted without any
                code.
              </p>
              <div>
                <Button fullWidth onClick={handleClick}>
                  <span className="mr-3">Create new contract</span>
                  <ChevronRightIcon />
                </Button>
              </div>
            </div>
          </div>
          {sortedContracts.map((contract) => {
            return (
              <SmartContractContainer
                key={contract.address}
                contract={contract}
                index={contract.index}
              />
            );
          })}
        </div>
      </PageMainContent>
    </PageWrapper>
  );
};

export default SmartContractCarousel;
